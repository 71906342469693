import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import checkIcon from "../../../../../assets/images/Tick_Mark_Icon.svg";
import { useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import CustomScrollbar from "../../../../shared/CustomScrollbar/index"
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";
import * as Actions from "../store/actions";
const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    boxShadow: "none",
  },
  customScrollbar: {
    maxHeight: "200px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
    },
  },
  filterContinueButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    float: "right",
    marginRight: "15px",
    boxShadow: "none",
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    "&.MuiListItem-gutters": {
      marginLeft: "-26px",
      marginRight: "16px",
    },
  },
  dropdown: {
    position: "absolute",
    // width: "155px",
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    height: "226px",
  },
  chkroot: {
    padding: 2,
  },
  icon: {
    border: "1px solid #454A92",
    borderRadius: 0,
    width: "18px",
    height: "18px",
  },
  checkedIcon: {
    backgroundColor: "#ffffff",
    "&:before": {
      display: "block",
      width: "18px",
      height: "18px",
      backgroundImage: `url(${checkIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "14px 14px",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffffff",
    },
  },
}));
// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.chkroot}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      color="primary"
      {...props}
    />
  );
}
function AssignMentorToRefugeeMentorsData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const timer = React.useRef();
  const [mentors, setMentors] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selectedMentorIds, setSelectedMentorIds] = React.useState([]);
  React.useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedMentors(true));
    };
  }, []);

  React.useEffect(() => {
    const storedMentorIds = JSON.parse(
      localStorage.getItem("selectedMentorIds")
    );
    dispatch(
      Actions.getAllMiniSearchedMentors(
        page + 1,
        rowsPerPage,
        props.searchText,
        ""
      )
    );
    setSelectedMentorIds(storedMentorIds || []);
  }, [rowsPerPage, page, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const existingSelectedIds =
      JSON.parse(localStorage.getItem("selectedMentorIds")) || [];
    const randomNumber = Math.floor(Math.random() * 15) + 1;

    const newString = "hi".replace(/hi/, randomNumber);

    props.setState(newString);
    let newObj = Object.assign({}, mentors);
    for (let i = 0; i < newObj.docs.length; i++) {
      const element = newObj.docs[i];
      if (i !== index) {
        element.isSelected = false;
      }
    }
    newObj.docs[index].isSelected = !mentors.docs[index].isSelected;
    if (newObj.docs[index].isSelected) {
      let newElement = {
        bsn: newObj.docs[index].bsn,
        id: newObj.docs[index]?.id,
        name: newObj.docs[index].name,
      };
      props.setSelectedMentors([newElement]);
    }
    if (!newObj.docs[index].isSelected) {
      props.setSelectedMentors(
        props.selectedMentors.filter(
          (item) => item?.id !== newObj.docs[index]?.id
        )
      );
    }
    const isSelectedCount = newObj.docs.filter(
      (item) => item.isSelected
    ).length;

    if (isSelectedCount === 1) {
      props.setButtonDisabled(false);
    } else {
      props.setButtonDisabled(true);
    }
    const newSelectedIds = newObj.docs
      .filter((mentor) => mentor.isSelected)
      .map((mentor) => mentor.id);
    const combinedIds = Array.from(
      new Set([...existingSelectedIds, ...newSelectedIds])
    );
    localStorage.setItem("selectedMentorIds", JSON.stringify(combinedIds));
    setSelectedMentorIds(combinedIds);
    setMentors(newObj);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const SearchedMentors = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.data
  );
  const loading = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.isLoading
  );

  const errMsg = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.errMsg
  );
  React.useEffect(() => {
    timer.current = window.setTimeout(() => {
      if (
        SearchedMentors &&
        SearchedMentors.miniSearchAllMentors &&
        props.selectedMentors.length === 0
      ) {
        let mentorsObj = SearchedMentors.miniSearchAllMentors;
        if (mentorsObj.docs !== null && mentorsObj.docs.length > 0) {
          for (let i = 0; i < mentorsObj.docs.length; i++) {
            const doc = mentorsObj.docs[i];
            doc["isSelected"] = false;
          }
        }
        setMentors(mentorsObj);
      }
      if (
        SearchedMentors &&
        SearchedMentors.miniSearchAllMentors &&
        props.selectedMentors.length > 0
      ) {
        let selmentorsObj = SearchedMentors.miniSearchAllMentors;
        if (selmentorsObj.docs !== null && selmentorsObj.docs.length > 0) {
          for (let i = 0; i < selmentorsObj.docs.length; i++) {
            const seldoc = selmentorsObj.docs[i];
            seldoc["isSelected"] = false;
            for (let j = 0; j < props.selectedMentors.length; j++) {
              const selMentor = props.selectedMentors[j];
              if (seldoc?.id === selMentor?.id) {
                seldoc["isSelected"] = true;
              }
            }
          }
        }
        setMentors(selmentorsObj);
      }
    }, 1000);
  }, [SearchedMentors, props.setSelectedMentors]);
  React.useEffect(() => {
    if (
      SearchedMentors &&
      SearchedMentors.miniSearchAllMentors &&
      props.selectedMentors.length > 0 &&
      props.editCheck
    ) {
      let selmentorsObj = SearchedMentors.miniSearchAllMentors;
      if (selmentorsObj.docs !== null && selmentorsObj.docs.length > 0) {
        for (let i = 0; i < selmentorsObj.docs.length; i++) {
          const seldoc = selmentorsObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedMentors.length; j++) {
            const selMentor = props.selectedMentors[j];
            if (seldoc?.id === selMentor?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setMentors(selmentorsObj);
    }
  }, [props.editCheck, SearchedMentors, props.setSelectedMentors]);
  React.useEffect(() => {
    if (
      SearchedMentors &&
      SearchedMentors.miniSearchAllMentors &&
      props.unAssignId
    ) {
      let selmentorsObj = SearchedMentors.miniSearchAllMentors;
      if (selmentorsObj.docs !== null && selmentorsObj.docs.length > 0) {
        for (let i = 0; i < selmentorsObj.docs.length; i++) {
          const seldoc = selmentorsObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedMentors.length; j++) {
            const selMentor = props.selectedMentors[j];
            if (seldoc?.id === selMentor?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setMentors(selmentorsObj);
      props.setUnAssignId(false);
    }
  }, [props.unAssignId, props.selectedMentors, props.setSelectedMentors]);

  React.useEffect(() => {
    if (props.disabled) {
      props.setSelectedMentors([]);
      sessionStorage.removeItem("assignedMentors");

      if (mentors.docs && mentors.docs.length > 0) {
        const updatedMentors = {
          ...mentors,
          docs: mentors.docs.map((mentor) => ({
            ...mentor,
            isSelected: false,
          })),
        };

        setMentors(updatedMentors);
      }
    }
  }, [props.disabled]);
  return (
    <div>
      <h3 style={{ marginLeft: "2px" }}>Select the Mentor</h3>
      <div>
        <CustomScrollbar>
        <List>
          <ListItem
            key="dont-assign"
            button
            className={classes.listItem}
            onClick={() => props.handleMentorAssignmentChange()}
            disabled={props?.disabledPost}
          >
            <Radio
              checked={props?.checked || props?.checkMentor}
              onClick={(event) => {
                event.stopPropagation();
                props.handleMentorAssignmentChange();
              }}
              value="dont-assign"
              name="MentorsRadio"
            />
            <ListItemText primary={t("Don’t assign to a Mentor")} />
          </ListItem>

          {mentors &&
            mentors.docs &&
            mentors.docs.length > 0 &&
            mentors.docs
              .sort((a, b) => {
                if (a.isSelected && !b.isSelected) return -1;
                if (!a.isSelected && b.isSelected) return 1;
                const isSelectedA = selectedMentorIds?.includes(a.id);
                const isSelectedB = selectedMentorIds?.includes(b.id);
                if (isSelectedA && !isSelectedB) return -1;
                if (!isSelectedA && isSelectedB) return 1;

                if (isSelectedA === isSelectedB) {
                  const nameA = a.name.toLowerCase();
                  const nameB = b.name.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                }
                return 0;
              })
              .map((doc, index) => (
                <ListItem
                  key={doc.id}
                  button
                  className={classes.listItem}
                  onClick={() => handleChangeSelected(index)}
                  disabled={props.disabled || props?.disabledPost}
                >
                  <Radio
                    checked={doc.isSelected}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleChangeSelected(index);
                    }}
                    value={doc.id}
                    name="MentorsRadio"
                  />
                  <ListItemText primary={doc.name} />
                </ListItem>
              ))}
        </List>
        </CustomScrollbar>
        
      </div>
    </div>
  );
}
export default withReducer(
  "AssignMentorToRefugeeMentorsDataReducer",
  reducer
)(AssignMentorToRefugeeMentorsData);
