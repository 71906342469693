import { Text, View, Page, Link } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate } from "../../../utils/helpers";

function isPrimitive(value) {
  return value !== Object(value);
}


const WORK_AND_ACTIVITIES_LABELS = {
  "workedInCountryOfOrigin": "I have worked in my country of origin",
  "workedInCountryOfOriginAnswers": "Answers",
  "workedDuringFlight": "I worked during my flight",
  "workedInNetherlands": "I've worked in the Netherlands",
  "workedInNetherlandsAnswers": "Answers",
  "doneVolunteerWorkInNetherlands": "I have done volunteer work in the Netherlands",
  "stillWorkVolunteer": "I still work here",
  "desiredWorkSector": "My desired work sector is",
  "dreamJob": "My dream job is",
  "goodAt": "This is what I am good at",
  "needAdjustmentsForStartingWork": "I need certain adjustments when I start work",
  "adjustmentsForStartingWork": "The adjustments I need are",
  "stoppedVolunteerWorkBecause": "I stopped here because",
  "workedDuringFlightAnswers": "Answers",
  "from": "From",
  "to": "To",
  "whichFunctionAndWhere": "Which function and where"
};

const checkDataRender = (key , data) => {
  if (key === "stoppedVolunteerWorkBecause" ) {
    if(data?.doneVolunteerWorkInNetherlands && data?.stillWorkVolunteer === false) {
      return true
    }
    return false;
  }
  return true;
};

const getWorkAndActivitiesData = (dataKey, dataValue, workAndActivitiesPageData) => {
  if (dataValue !== null && isPrimitive(dataValue)) {
    return (
      <View>
        <View style={styles.viewRow}>
          <View style={{ width: "55%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(WORK_AND_ACTIVITIES_LABELS?.[dataKey])}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            {typeof dataValue === 'boolean' ? (
              <Text>{dataValue ? i18next.t("Yes") : i18next.t("No")}</Text>
            ) : (
              <Text>{`${dataValue}`.split('')}</Text>
            )}
          </View>
        </View>
      </View>
    )
  }
  return (
    <View>
      {dataValue.map((data, index) => {
        const { from, to, whichFunctionAndWhere } = data;

        return (
          <View key={`whichFunctionAndWhere_${index}`}>
            <View style={[styles.viewRow, { padding: 10 }]}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(WORK_AND_ACTIVITIES_LABELS?.["whichFunctionAndWhere"])}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{`${whichFunctionAndWhere}`.split('')}</Text>
              </View>
            </View>
            <View style={[styles.viewRow, { padding: 10 }]}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(WORK_AND_ACTIVITIES_LABELS?.["from"])}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{formatDate(from)}</Text>
              </View>
            </View>
            <View style={[styles.viewRow, { padding: 10 }]}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(WORK_AND_ACTIVITIES_LABELS?.["to"])}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{formatDate(to)}</Text>
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
};

function WorkAndActivitiesPdf({ workAndActivitiesPageData, headerText }) {
  delete workAndActivitiesPageData.__typename
  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View><Text style={styles.subtitle}>{i18next.t('Work and activities')}</Text></View>
        <View style={styles.viewRowContainer}>
          {Object.entries(workAndActivitiesPageData)?.map((workAndActivities) => {
            const [key, value] = workAndActivities;
            return (
              <View key={`workAndActivities_${key}`}>
                {value !== null &&
                  checkDataRender(key, workAndActivitiesPageData) &&
                  getWorkAndActivitiesData(key, value, workAndActivitiesPageData)}
              </View>
            )
          })}
        </View>
      </View>
    </Page>
  );
};

export default WorkAndActivitiesPdf;
