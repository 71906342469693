import React, { useEffect, useState } from "react";

import Container from "@material-ui/core/Container";

import { makeStyles } from "@material-ui/core/styles";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

// store

const useStyles = makeStyles((theme) => ({
  conform: {
    padding: "30px",
    marginTop: "60px",
    backgroundColor: "white",
    borderRadius: "12px",
    webkitboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    mozboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    boxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
  },
  con: {
    marginTop: "30px",
  },
  crossIcon: {
    color: "black",
    fontSize: 23,
    float: "right",
    cursor: "pointer",
    marginTop: "12px",
  },
  roorCon: {
    maxWidth: "890px",
  },
  heading: {
    color: "white",
    fontSize: "32pt",
    fontFamily: "opensans-semibold",
    // fontWeight: "500"
  },
  headingaddform: {
    color: "#000000",
    fontFamily: "opensans-regular",
    fontSize: "30px",
  },
  subheadingaddform: {
    fontFamily: "opensans-regular",
    fontSize: "18px",
    color: "#757575",
  },

  lable: {
    padding: "0px !important",
    paddingLeft: "8px !important",
  },
  cornerbtn: {
    textAlign: "right",
  },
  cornerbtn1: {
    marginTop: "20px",
    fontSize: "16px",
    backgroundColor: "#454a92",
    fontFamily: "opensans-semibold",
    opacity: 1,
    height: "40px",
    borderRadius: "2px",
    minWidth: "150px",
    boxShadow: "none",
  },

  button: {
    // margin: theme.spacing(1),
    fontSize: "14px",
    backgroundColor: "transparent",
    width: "100%",
    height: "38px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "lowercase",
  },

  textField: {
    width: "100%",
    margin: 0,
    backgroundColor: "#93C7D2",
  },
  notchedOutline: {
    borderWidth: "0px",
    // borderColor: "yellow !important"
  },
  input1: {
    height: "13px",
    minHeight: "13px",
    border: 0,
    fontSize: "16px",
    fontFamily: "opensans-Regular",
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-Regular",
      letterSpacing: "0px",
    },
  },
  input: {
    display: "none",
  },
}));

export default function PrivacyPage(props) {
  const [t] = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className="main">
      <div className="bgforform">
        <Container maxWidth="md" className={classes.roorCon}>
          <div className="create-user-main-div">
            <p className="create-user-required-text">
              {t("Privacy policy")}
            </p>
            <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </div>
          </div>
          
        </Container>
      </div>
    </div>
  );
}
