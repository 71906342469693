import { combineReducers } from "redux";
import {
  HandleReducer,
  AuthenticateUserReducer,
  AllUsersReducer,
  ResendOtpReducer,
  VerifyOtpReducer,
} from "./login.reducers";

const reducer = combineReducers({
  HandleReducer,
  AuthenticateUserReducer,
  AllUsersReducer,
  ResendOtpReducer,
  VerifyOtpReducer,
});

export default reducer;
