import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import SuccessDialog from "./sub_components/AddMunicipalityDetailSuccessDialog";
import { useTranslation } from "react-i18next";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";
import AssignMunicipalityToSupplier from "./AssignMunicipalityToSupplier";
import AssignMentorToRefugee from "./AssignMentorToRefugee";
import AssignSupplierToRefugee from "./AssignSupplierToRefugee";
const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  editButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginLeft: "auto",
    marginTop: "50px",
    boxShadow: "none",
  },
  btnsCon: {
    marginTop: "20px",
  },
  button: {
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "150px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
  },
}));
function PostRefugeeDetails(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [state, setState] = useState("1")
  const [stateRefugee, setStateRefugee] = useState("1")
  const [clikcState, setClickState] = useState("1")
  const [checkMentor,setCheckMentor] = useState(false)
  const add_confirmation = useSelector(
    ({ PostRefugeeDetailsReducer }) =>
      PostRefugeeDetailsReducer?.assignUnassignRolesToRefugeeReducer
  );
  useEffect(() => {
    dispatch(Actions.getRefugeeSummary(sessionStorage.getItem("user_id")));
    return () => {
      dispatch(Actions.resetRefugeeSummary(true));
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, []);
  const refugeeSummary = useSelector(
    ({ PostRefugeeDetailsReducer }) =>
      PostRefugeeDetailsReducer?.getRefugeeSummaryReducer?.data
  );
  const loading = useSelector(
    ({ PostRefugeeDetailsReducer }) =>
      PostRefugeeDetailsReducer?.getRefugeeSummaryReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ PostRefugeeDetailsReducer }) =>
      PostRefugeeDetailsReducer?.getRefugeeSummaryReducer?.errMsg
  );
  useEffect(() => {
    if (sessionStorage.getItem("user_id")) {
      dispatch(Actions.getBredeintake(sessionStorage.getItem("user_id")));
    }
  }, [dispatch, sessionStorage.getItem("user_id")]);

  const brede_confirmation = useSelector(
    ({ PostRefugeeDetailsReducer }) =>
    PostRefugeeDetailsReducer?.BredeintakeReducer?.data
  );
  const loadingg = useSelector(
    ({ BImodalReducer }) => BImodalReducer?.BredeintakeReducer?.isLoading
  );
  useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
    }
  }, [setSelectedMunicipalities,state]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors,clikcState,state,stateRefugee]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers,clikcState,state,stateRefugee]);
  const handleClickSuccessDialogclose = () => {
    setSuccessOpen(false);
  };
  const handleClickSuccessDialogopen = (data) => {
    setSuccessOpen(true);
  };
  const handleClickEdit = (path) => {
    navigate(path);
  };
  const handleClickEditUser = () => {
    sessionStorage.removeItem("assignedSuppliers")
    sessionStorage.removeItem("assignedMentors")
    sessionStorage.removeItem("assignedMunicipalities")
    sessionStorage.setItem("user_type", "refugee")
    navigate("/editUserByAdmin");

  };  React.useEffect(() => {
    const mentor = JSON.parse(sessionStorage.getItem("assignedMentors"))
    const suppliers = JSON.parse(sessionStorage.getItem("assignedSuppliers")) 
    const municipalities = JSON.parse(sessionStorage.getItem("assignedMunicipalities")) 


    
    let values = {
      refugee_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      amentorIds: [],
      asupplierIds: [],
      umunicipalityIds: [],
      umentorIds: [],
      usupplierIds: [],
    };
    if (municipalities?.length > 0) {
      for (let i = 0; i < municipalities?.length; i++) {
        const element = municipalities[i];
        values.amunicipalityIds.push(element?.id);
      }
    }
    if (mentor?.length > 0) {
      for (let i = 0; i < mentor?.length; i++) {
        const element = mentor[i];
        values.amentorIds.push(element?.id);
      }
    }
    if (suppliers?.length > 0) {
      for (let i = 0; i < suppliers?.length; i++) {
        const element = suppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToRefugee(values));
  },[])
  
  
  useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignUnassignRolesToRefugee
    ) {
      ;
    }
  }, [add_confirmation]);
  const handleNavigateToDashboard = () => {
    sessionStorage.clear();
    navigate("/dashboard");
  };
  useEffect(() => {
    if(state !== "1"){
      setSelectedMentors([])
      setSelectedSuppliers([])
    }
  }, [state])
  useEffect(() => {
    setState("1")
  },[])
  const UrlBI = brede_confirmation?.getBredeIntakeFile?.bredeIntakeFileUrl;
  const filenameParts =
    refugeeSummary?.getRefugeeSummary?.bredeIntakeFile?.split("-");
  const displayedFilename =
    filenameParts?.length > 1
      ? filenameParts?.slice(1).join("-").trim()
      : refugeeSummary?.getRefugeeSummary?.bredeIntakeFile;
    
   
  return (
    <div className="main">
      {successOpen === true && (
        <SuccessDialog
          status={successOpen}
          methodd={handleClickSuccessDialogclose}
          role="Refugee"
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <div className="edit-municipality-detail-view-div">
            <h3 className="edit-municipality-detail-view-title">
              {t("Refugee")}
            </h3>
            {refugeeSummary && refugeeSummary.getRefugeeSummary && (
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Name")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.name}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("BSN")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.bsn}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={3}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Email")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.email}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Phone")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.phoneNumber}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={6}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("POI (DISK) Link")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.municipality ? (
                        <a
                          target="_blank"
                          href={
                            refugeeSummary.getRefugeeSummary.municipality
                              .poiLink
                          }
                        >
                          {
                            refugeeSummary.getRefugeeSummary.municipality
                              .poiLink
                          }
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Brede Intake")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      <a target="_blank" href={UrlBI} rel="noreferrer">
                      {refugeeSummary.getRefugeeSummary.profileImageName}
                      </a>
                    </span>
                  </p>

                  <div className="edit-municipality-detail-view-image-div">
                    <p className="edit-municipality-detail-view-logo-label">
                      {t("Photo")}:&nbsp;
                    </p>
                    <p className="edit-municipality-detail-view-logo-text">
                    {refugeeSummary.getRefugeeSummary.bredeIntakeName}
                    </p>
                    <div className="edit-municipality-detail-view-image-span">
                      <img
                        alt="logo"
                        className="edit-municipality-detail-view-image"
                        src={refugeeSummary.getRefugeeSummary.profileImageUrl}
                      ></img>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              
            </Grid>
          </div>
          <div className="edit-municipality-detail-view-div">
            <AssignMunicipalityToSupplier TimeOut={true} state={state} setState={setState}/>
            <Grid container style={{width: "100%"}}>
             
              <Grid item sm={5}>
              <AssignMentorToRefugee TimeOut={true} setStateRefugee={setStateRefugee} stateRefugee={stateRefugee}   setClickState={setClickState} setState={setState} state={state} />
              </Grid>
              <Grid item sm={5}>
              <AssignSupplierToRefugee TimeOut={true}  setStateRefugee={setStateRefugee} stateRefugee={stateRefugee} state={state}/>
              </Grid>

              <Grid item sm={5}></Grid>
              {add_confirmation.errMsg && (
                <Grid item sm={12} xs={12}>
                  <Alert severity="error">{t(add_confirmation.errMsg)}</Alert>
                </Grid>
              )}
              {add_confirmation.isLoading && (
                <Grid item sm={12} xs={12}>
                  Loading.....
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container spacing={2} className={classes.btnsCon}>
            <Grid item sm={8} xs={12}></Grid>
            <Grid item sm={2} xs={12}>
            
            </Grid>
            <Grid item sm={2} xs={12}>
            <Button
                variant="contained"
                className={classes.button}
                onClick={() => handleClickEditUser()}
                color="primary"
              >
                {t("Edit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
export default withReducer(
  "PostRefugeeDetailsReducer",
  reducer
)(PostRefugeeDetails);
