import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Toolbar,
  WeekView,
  TodayButton,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { connectProps } from "@devexpress/dx-react-core";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import jwt from "jsonwebtoken";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  endOfWeek,
  getMonday,
} from "../../../../../utils/functions";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";
import * as Actions from "../store/actions";
import { useState } from "react";
import { useEffect } from "react";
import AppointmentComponent from "./bc_sub_components/AppointmentComponent";
import AppointmentContent from "./bc_sub_components/AppointmentContent";
import AppointmentFormContainerBasic from "./bc_sub_components/AppointmentFormContainerBasic";

const styles = ({ spacing, palette }) => ({
  addButton: {
    position: "absolute",
    bottom: spacing(1) * 3,
    right: spacing(1) * 4,
  },
});

const date = new Date();
const ROLES = ["admin", "mentor", "refugee", "manager", "suppliers"];
const resources = [
  {
    fieldName: "role",
    title: "Booked By",
    instances: [
      { id: ROLES[0], text: capitalizeFirstLetter(ROLES[0]), color: "#3f51b5" },
      { id: ROLES[1], text: capitalizeFirstLetter(ROLES[1]), color: "#f4865c" },
      { id: ROLES[2], text: capitalizeFirstLetter(ROLES[2]), color: "#ffbc4d" },
      { id: ROLES[3], text: capitalizeFirstLetter(ROLES[3]), color: "#72B0B4" },
      { id: ROLES[4], text: capitalizeFirstLetter(ROLES[3]), color: "#85B0B4" },
    ],
  },
];
let events_data = [];
let added_data;
let edited_data;
function Bc(props) {
  //all custom hooks
  const [t] = useTranslation();
  const dispatch = useDispatch();

  //all useState hooks
  const disabled = localStorage.getItem("selected");
  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [editingFormVisible, setEditingFormVisible] = useState(!!disabled);
  const [deletedAppointmentId, setDeletedAppointmentIdd] = useState(undefined);
  const [deletedAgendaId, setDeletedAgendaId] = useState(undefined);
  const [editingAppointment, setEditingAppointment] = useState(!!disabled);
  const [previousAppointment, setPreviousAppointment] = useState(undefined);
  const [addedAppointment, setAddedAppointment] = useState({});
  const [startDayHour, setStartDayHour] = useState(10);
  const [endDayHour, setEndDayHour] = useState(18);
  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [startDate, setStartDate] = useState(getMonday(date).toISOString());
  const [endDate, setEndDate] = useState(endOfWeek(date).toISOString());
  const [isShowDeleteButton, setIsShowDeleteButton] = useState(true);
  const [isShowOpenButton, setIsShowOpenButton] = useState(true);
  const [canOpenApplicationForm, setCanOpenApplicationForm] = useState(true);
  const [eventNot, setEventNot] = useState();

  //all useSelector hooks
  const agendas = useSelector(
    ({ Bcreducer }) => Bcreducer.AllAgendasReducer?.data
  );
  const loadings = useSelector(
    ({ Bcreducer }) => Bcreducer.AllAgendasReducer?.isLoading
  );
  const errMsga = useSelector(
    ({ Bcreducer }) => Bcreducer.AllAgendasReducer?.errMsg
  );
  const add_confirmation = useSelector(
    ({ Bcreducer }) => Bcreducer.AddNewEventReducer
  );
  const edit_confirmation = useSelector(
    ({ Bcreducer }) => Bcreducer.editEventReducer
  );
  const delete_confirmation = useSelector(
    ({ Bcreducer }) => Bcreducer.deleteEventReducer
  );

  //all useEffect hooks
  useEffect(() => {
    return () => {
      setData([]);
      events_data = [];
      setAddedAppointment({});
      setIsAdded(false);
      dispatch(Actions.resetaddNewEvent(true));
      dispatch(Actions.resetDeleteEvent(true));
      dispatch(Actions.resetEditEvent(true));
    };
  }, []);

  useEffect(() => {
    setData([]);
    events_data = [];
    setAddedAppointment({});
    setIsAdded(false);
    dispatch(Actions.resetaddNewEvent(true));
    dispatch(Actions.resetDeleteEvent(true));
    dispatch(Actions.resetEditEvent(true));
  }, []);

  useEffect(() => {
    const mentorId = jwt.decode(localStorage.jwtToken)?.id;
    const role = jwt.decode(localStorage.jwtToken)?.role;

    if (
      role &&
      mentorId &&
      props?.data?.mentor &&
      mentorId === props?.data?.mentor?._id
    ) {
      setCanOpenApplicationForm(true);
    } else {
      setCanOpenApplicationForm(false);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate && props.data) {
      setData([]);
      events_data = [];
      dispatch(Actions.getAllAgendas(props.data?.id, startDate, endDate));
    }
  }, [startDate, endDate, props.data]);

  React.useEffect(() => {
    if (
      agendas &&
      agendas.listAllAgendas &&
      agendas.listAllAgendas.agendas !== null &&
      agendas.listAllAgendas.agendas.length > 0
    ) {
      events_data = [];
      for (let i = 0; i < agendas.listAllAgendas.agendas.length; i++) {
        const agenda = agendas.listAllAgendas.agendas[i];

        if (agenda.events !== null && agenda.events.length > 0) {
          for (let j = 0; j < agenda.events.length; j++) {
            const event = agenda.events[j];
            let uniqueSchoolData = new Set();
            let uniqueWorkData = new Set();

            setEventNot(event);
            let mentor = false;
            let school = [];
            let work = [];

            if (event.bookedWith !== null && event.bookedWith.length > 0) {
              for (let z = 0; z < event.bookedWith.length; z++) {
                let bookedWith = event.bookedWith[z];
                console.log(bookedWith);

                if (bookedWith?.role === "mentor") {
                  mentor = true;
                }
                if (bookedWith?.role === "school_supplier") {
                  school.push(bookedWith);
                }
                if (bookedWith?.role === "work_supplier") {
                  work.push(bookedWith);
                }
              }
            }

            events_data.push({
              title: event.title,
              startDate: new Date(event.startDate),
              endDate: new Date(event.endDate),
              reminderBefore: event.reminderBefore,
              bookedWith: event.bookedWith,
              bookedBy: event.bookedBy,
              notes: event.notes,
              event_id: event._id,
              agenda_id: agenda._id,
              refugee_id: agenda.refugeeId,
              agendaDate: new Date(agenda.agendaDate),
              role: event.bookedBy?.role,
              id: event._id,
              mentor: mentor,
              school: school,
              work: work,
            });

            const schoolData = Array.from(uniqueSchoolData).map((entry) =>
              JSON.parse(entry)
            );
            const workData = Array.from(uniqueWorkData).map((entry) =>
              JSON.parse(entry)
            );

            sessionStorage.setItem(
              "school_supplier_data",
              JSON.stringify(schoolData)
            );
            sessionStorage.setItem(
              "work_supplier_data",
              JSON.stringify(workData)
            );
          }
        }
      }

      setData(events_data);
    }
  }, [agendas]);

  const appointmentForm = connectProps(AppointmentFormContainerBasic, () => {
    const selectedAgenda = JSON.parse(localStorage.getItem("selected"));

    if (selectedAgenda) {
      const editingFormVisiblee = editingFormVisible;
      const editingAppointmentt = editingAppointment;
      const dataa = data;
      const addedAppointmentt = addedAppointment;
      const isNewAppointmentt = isNewAppointment;
      const previousAppointmentt = previousAppointment;
      let currentAppointment =
        events_data.filter(
          (appointment) =>
            editingAppointmentt && appointment.event_id === selectedAgenda._id
        )[0] || selectedAgenda;
        
      const cancelAppointment = () => {
        if (isNewAppointmentt) {
          setEditingAppointment(previousAppointmentt);
          setIsNewAppointment(false);
        }
      };

      currentAppointment.refugeeMentorId = props?.data?.mentor?._id;
      console.log(currentAppointment)
      setTimeout(() => {
        localStorage.removeItem("selected");
      }, 1500);
      return {
        visible: editingFormVisiblee,
        appointmentData: currentAppointment,
        commitChanges: commitChanges,
        visibleChange: toggleEditingFormVisibility,
        onEditingAppointmentChange: onEditingAppointmentChange,
        cancelAppointment,
        refugeeData: props.data,
      };
    } else {
      const editingFormVisiblee = editingFormVisible;
      const editingAppointmentt = editingAppointment;
      const dataa = data;
      const addedAppointmentt = addedAppointment;
      const isNewAppointmentt = isNewAppointment;
      const previousAppointmentt = previousAppointment;
      let currentAppointment =
        events_data.filter(
          (appointment) =>
            editingAppointmentt &&
            appointment.event_id === editingAppointmentt.event_id
        )[0] || addedAppointmentt;
      const cancelAppointment = () => {
        if (isNewAppointmentt) {
          setEditingAppointment(previousAppointmentt);
          setIsNewAppointment(false);
        }
      };

      currentAppointment.refugeeMentorId = props?.data?.mentor?._id;

      return {
        visible: editingFormVisiblee,
        appointmentData: currentAppointment,
        commitChanges: commitChanges,
        visibleChange: toggleEditingFormVisibility,
        onEditingAppointmentChange: onEditingAppointmentChange,
        cancelAppointment,
        refugeeData: props.data,
      };
    }
  });

  useEffect(() => {
    appointmentForm.update();
  }, [appointmentForm]);

  React.useEffect(() => {
    if (isAdded) {
      const storedSelectedSuppliersWork =
        JSON.parse(sessionStorage.getItem("selectedSuppliersWork")) || [];
      const storedSelectedSuppliersSchool =
        JSON.parse(sessionStorage.getItem("selectedSuppliersSchool")) || [];

      let bookedWith = [];

      if (added_data.mentor) {
        bookedWith.push({
          userId: props.data.mentor._id,
          role: "mentor",
          name: "mentor-name",
        });
      }

      for (let i = 0; i < storedSelectedSuppliersSchool.length; i++) {
        bookedWith.push({
          userId: storedSelectedSuppliersSchool[i]._id,
          role: "school_supplier",
          name: storedSelectedSuppliersSchool[i].name,
        });
      }

      for (let i = 0; i < storedSelectedSuppliersWork.length; i++) {
        bookedWith.push({
          userId: storedSelectedSuppliersWork[i]._id,
          role: "work_supplier",
          name: storedSelectedSuppliersWork[i].name,
        });
      }

      if (
        storedSelectedSuppliersSchool.length === 0 &&
        storedSelectedSuppliersWork?.length === 0
      ) {
        bookedWith.push({
          userId: props.data.mentor._id,
          role: "mentor",
          name: "mentor-name",
        });
      }
      let values = {
        refugeeId: props.data?.id,
        agendaDate: new Date(added_data.startDate).toISOString(),
        title: added_data.title,
        startDate: new Date(added_data.startDate).toISOString(),
        endDate: new Date(added_data.endDate).toISOString(),
        reminderBefore: added_data.reminderBefore || 0,
        note: added_data.notes || "",
        bookedWith: bookedWith,
      };
      
      dispatch(Actions.addNewEvent(values));
    }
  }, [isAdded]);
  useEffect(() => {
    const storedSelectedSuppliersWork =
      JSON.parse(sessionStorage.getItem("selectedSuppliersWork")) || [];
    const storedSelectedSuppliersSchool =
      JSON.parse(sessionStorage.getItem("selectedSuppliersSchool")) || [];
    const SelectedWork = JSON.parse(sessionStorage.getItem("SelectedWork"));
    const SelectedSchool = JSON.parse(sessionStorage.getItem("SelectedSchool"));

    if (isEdited) {
      let bookedWith = [];
      if (edited_data.mentor) {
        bookedWith.push({
          userId: props.data.mentor._id,
          role: "mentor",
          name: "mentor-name",
        });
      }

      if (storedSelectedSuppliersSchool !== null) {
        for (let i = 0; i < storedSelectedSuppliersSchool.length; i++) {
          bookedWith.push({
            userId: storedSelectedSuppliersSchool[i]._id,
            role: "school_supplier",
            name: storedSelectedSuppliersSchool[i].name,
          });
        }
      } else {
        for (let i = 0; i < SelectedSchool.length; i++) {
          bookedWith.push({
            userId: SelectedSchool[i].userId,
            role: "school_supplier",
            name: SelectedSchool[i].name,
          });
        }
      }

      if (storedSelectedSuppliersWork !== null) {
        for (let i = 0; i < storedSelectedSuppliersWork.length; i++) {
          bookedWith.push({
            userId: storedSelectedSuppliersWork[i]._id,
            role: "work_supplier",
            name: storedSelectedSuppliersWork[i].name,
          });
        }
      } else {
        for (let i = 0; i < SelectedWork.length; i++) {
          bookedWith.push({
            userId: SelectedWork[i].userId,
            role: "work_supplier",
            name: SelectedWork[i].name,
          });
        }
      }
      if (Array.isArray(edited_data.notes)) {
        let note;
        for (let i = 0; i < edited_data.notes.length; i++) {
          const element = edited_data.notes[i];
          if (element.userId === jwt.decode(localStorage.jwtToken)?.id) {
            note = element.note;
          }
        }
        if (!edited_data.mentor && !edited_data.school && !edited_data.work) {
          bookedWith.push({
            userId: props.data.mentor._id,
            role: "mentor",
            name: "mentor",
          });
        }
        let values = {
          agendaId: edited_data.agenda_id,
          eventId: edited_data.event_id,
          refugeeId: props.data?.id,
          agendaDate: new Date(edited_data.startDate).toISOString(),
          title: edited_data.title,
          startDate: new Date(edited_data.startDate).toISOString(),
          endDate: new Date(edited_data.endDate).toISOString(),
          reminderBefore: edited_data.reminderBefore || 0,
          note: note,
          bookedWith: bookedWith,
        };
        dispatch(Actions.editEvent(values));
      } else {
        let values = {
          agendaId: edited_data.agenda_id,
          eventId: edited_data.event_id,
          refugeeId: props.data?.id,
          agendaDate: new Date(edited_data.startDate).toISOString(),
          title: edited_data.title,
          startDate: new Date(edited_data.startDate).toISOString(),
          endDate: new Date(edited_data.endDate).toISOString(),
          reminderBefore: edited_data.reminderBefore || 0,
          note: edited_data.notes,
          bookedWith: bookedWith,
        };
        dispatch(Actions.editEvent(values));
      }
    }
  }, [isEdited]);

  useEffect(() => {
    if (add_confirmation.data.data) {
      setData([]);
      events_data = [];
      setAddedAppointment({});
      setIsAdded(false);
      dispatch(Actions.resetaddNewEvent(true));
      dispatch(Actions.getAllAgendas(props.data?.id, startDate, endDate));
      alert("successfully added Event");
      sessionStorage.clear();
    }
  }, [add_confirmation, dispatch, startDate, endDate]);

  useEffect(() => {
    if (edit_confirmation.data.data) {
      setData([]);
      events_data = [];
      setAddedAppointment({});
      setIsEdited(false);
      dispatch(Actions.resetEditEvent(true));
      dispatch(Actions.getAllAgendas(props.data?.id, startDate, endDate));
      alert("successfully Updated Event");
      sessionStorage.clear();
    }
  }, [edit_confirmation, dispatch, startDate, endDate]);

  useEffect(() => {
    if (edit_confirmation.errMsg) {
      setIsEdited(false);
    }
  }, [add_confirmation]);

  useEffect(() => {
    if (add_confirmation.errMsg) {
      setIsAdded(false);
    }
  }, [add_confirmation]);

  useEffect(() => {
    if (isDeleted && deletedAppointmentId && deletedAgendaId) {
      dispatch(Actions.deleteEvent(deletedAgendaId, deletedAppointmentId));
    }
  }, [isDeleted, deletedAppointmentId, deletedAgendaId]);

  useEffect(() => {
    if (delete_confirmation.data.data) {
      setData([]);
      events_data = [];
      setAddedAppointment({});
      setIsDeleted(false);
      dispatch(Actions.resetDeleteEvent(true));
      dispatch(Actions.getAllAgendas(props.data?.id, startDate, endDate));
      setDeletedAppointmentIdd(null);
      setDeletedAgendaId(null);
      toggleConfirmationVisible();
      alert("successfully Deleted Event");
    }
  }, [delete_confirmation, dispatch]);

  useEffect(() => {
    if (delete_confirmation.errMsg) {
      setIsDeleted(false);
      setDeletedAppointmentIdd(null);
      setDeletedAgendaId(null);
      toggleConfirmationVisible();
    }
  }, [delete_confirmation]);

  const currentDateChange = (currentDate) => {
    const dt = new Date(currentDate.toISOString());
    setStartDate(getMonday(dt).toISOString());
    setEndDate(endOfWeek(dt).toISOString());
  };
  function onEditingAppointmentChange(editingAppointment) {
    setEditingAppointment(editingAppointment);
  }

  function onAddedAppointmentChange(addedAppointment) {
    setAddedAppointment(addedAppointment);
    const editingAppointmenttt = editingAppointment;
    if (editingAppointmenttt !== undefined) {
      setPreviousAppointment(editingAppointmenttt);
    }
    setEditingAppointment(undefined);
    setIsNewAppointment(true);
  }

  function setDeletedAppointmentId(id) {
    setDeletedAppointmentIdd(id);
  }

  function toggleEditingFormVisibility() {
    const editingFormVisiblee = editingFormVisible;
    setEditingFormVisible(!editingFormVisiblee);
  }

  function toggleConfirmationVisible() {
    const confirmationVisiblee = confirmationVisible;
    setConfirmationVisible(!confirmationVisiblee);
  }

  function commitDeletedAppointment() {
    const deletedAppointmentIdg = deletedAppointmentId;
    const del_data = data.filter(
      (appointment) => appointment?.id === deletedAppointmentIdg
    );
    setDeletedAgendaId(del_data[0].agenda_id);
    setIsDeleted(true);
  }

  function commitChanges({ added, changed, deleted }) {
    if (added) {
      setIsAdded(true);
      added_data = added;
    }
    if (deleted !== undefined) {
      setDeletedAppointmentId(deleted);
      toggleConfirmationVisible();
    }
    if (changed) {
      setIsEdited(true);
      edited_data = changed.data;
    }
  }

  const ToolbarWithLoading = withStyles(styles, { name: "Toolbar" })(
    ({ children, classes, ...restProps }) => (
      <div className={classes.toolbarRoot}>
        <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
        <LinearProgress className={classes.progress} />
      </div>
    )
  );
  console.log(data);
  return (
    <Grid container>
      {add_confirmation.errMsg && (
        <Grid item sm={12} xs={12}>
          <Alert severity="error">{t(add_confirmation.errMsg)}</Alert>
        </Grid>
      )}
      {add_confirmation.isLoading && (
        <Grid item sm={12} xs={12}>
          Loading.....
        </Grid>
      )}
      {delete_confirmation.errMsg && (
        <Grid item sm={12} xs={12}>
          <Alert severity="error">{t(delete_confirmation.errMsg)}</Alert>
        </Grid>
      )}
      {delete_confirmation.isLoading && (
        <Grid item sm={12} xs={12}>
          Loading.....
        </Grid>
      )}
      {edit_confirmation.errMsg && (
        <Grid item sm={12} xs={12}>
          <Alert severity="error">{t(edit_confirmation.errMsg)}</Alert>
        </Grid>
      )}
      {edit_confirmation.isLoading && (
        <Grid item sm={12} xs={12}>
          Loading.....
        </Grid>
      )}

      <Paper>
        <Scheduler data={data} height={660} allDay={true}>
          <ViewState
            defaultCurrentDate={currentDate}
            onCurrentDateChange={currentDateChange}
          />
          <EditingState
            onCommitChanges={commitChanges}
            onEditingAppointmentChange={onEditingAppointmentChange}
            onAddedAppointmentChange={onAddedAppointmentChange}
          />
          <WeekView
            startDayHour={startDayHour}
            endDayHour={endDayHour}
          />
          <EditRecurrenceMenu />
          <Appointments appointmentComponent={AppointmentComponent} />
          <Resources data={resources} />
          <AppointmentTooltip
            showCloseButton
            showDeleteButton={isShowDeleteButton}
            showOpenButton={
              isShowOpenButton &&
              props.data &&
              (props.data.mentor ||
                (props.data.suppliers &&
                  (props.data.suppliers.school || props.data.suppliers.work)))
                ? true
                : false
            }
            contentComponent={AppointmentContent}
          />
          <Toolbar
            {...(loadings ? { rootComponent: ToolbarWithLoading } : null)}
          />
          <DateNavigator />
          <TodayButton />
          {canOpenApplicationForm && (
            <AppointmentForm
              overlayComponent={appointmentForm}
              visible={editingFormVisible}
              onVisibilityChange={toggleEditingFormVisibility}
            />
          )}
        </Scheduler>

        <Dialog open={confirmationVisible}>
          <DialogTitle>{t("Delete Appointment")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("Are you sure you want to delete this appointment")}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => toggleConfirmationVisible()}
              color="primary"
              variant="outlined"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => commitDeletedAppointment()}
              color="secondary"
              variant="outlined"
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  );
}
export default withReducer("Bcreducer", reducer)(Bc);
