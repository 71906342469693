import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import checkIcon from "../../../../../assets/images/Tick_Mark_Icon.svg";
import { useTranslation } from "react-i18next";
// store
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import { useDispatch, useSelector } from "react-redux";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";
import * as Actions from "../store/actions";
import CustomScrollbar from "../../../../shared/CustomScrollbar/index"
const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
  },
  customScrollbar: {
    maxHeight: "200px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
    },
  },
  filterContinueButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    float: "right",
    marginRight: "15px",
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  dropdown: {
    position: "absolute",
    // width: "155px",
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    height: "226px",
  },
  chkroot: {
    padding: 2,
  },
  icon: {
    border: "1px solid #454A92",
    borderRadius: 0,
    width: "18px",
    height: "18px",
  },
  listItem: {
    "&.MuiListItem-gutters": { marginLeft: "-26px", marginRight: "16px" },
  },
  checkedIcon: {
    backgroundColor: "#ffffff",
    "&:before": {
      display: "block",
      width: "18px",
      height: "18px",
      backgroundImage: `url(${checkIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "14px 14px",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffffff",
    },
  },
}));
// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.chkroot}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      color="primary"
      {...props}
    />
  );
}

function AssignSupplierToRefugeeSuppliersData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const timer = React.useRef();
  const [suppliers, setSuppliers] = React.useState({});
  const [selectedSupplierIds, setSelectedSupplierIds] = React.useState([]);

  React.useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedSuppliers(true));
    };
  }, []);

  React.useEffect(() => {
    const storedSupplierIds = JSON.parse(
      localStorage.getItem("selectedSupplierIds")
    );
    dispatch(
      Actions.getAllMiniSearchedSuppliers(page + 1, 10000, props.searchText, "")
    );
    setSelectedSupplierIds(storedSupplierIds || []);
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const existingSelectedIds =
      JSON.parse(localStorage.getItem("selectedSupplierIds")) || [];
    const randomNumber = Math.floor(Math.random() * 15) + 1;

    const newString = "hi".replace(/hi/, randomNumber);

    props.setState(newString);
    let newObj = Object.assign({}, suppliers);
    newObj.docs[index].isSelected = !suppliers.docs[index].isSelected;
    let newArr = [];
    if (props.selectedSuppliers.length > 0) {
      newArr = [...props.selectedSuppliers];
    }
    if (newObj.docs[index].isSelected && newObj.docs[index].type === "school") {
      let newElement = {
        id: newObj.docs[index]?.id,
        name: newObj.docs[index].name,
        type: newObj.docs[index].type,
        contactPerson: newObj.docs[index].contactPerson,
        municipality: newObj.docs[index].municipality,
      };

      newArr.push(newElement);

      props.setSelectedSuppliers(newArr);
    }
    if (newObj.docs[index].isSelected && newObj.docs[index].type === "work") {
      let newElement = {
        id: newObj.docs[index]?.id,
        name: newObj.docs[index].name,
        type: newObj.docs[index].type,
        contactPerson: newObj.docs[index].contactPerson,
        municipality: newObj.docs[index].municipality,
      };

      newArr.push(newElement);
      props.setSelectedSuppliers(newArr);
    }
    if (!newObj.docs[index].isSelected) {
      props.setSelectedSuppliers(
        props.selectedSuppliers.filter(
          (item) => item?.id !== newObj.docs[index]?.id
        )
      );
    }
    const newSelectedIds = newObj.docs
      .filter((supplier) => supplier.isSelected)
      .map((supplier) => supplier.id);
    const combinedIds = Array.from(
      new Set([...existingSelectedIds, ...newSelectedIds])
    );
    localStorage.setItem("selectedSupplierIds", JSON.stringify(combinedIds));
    setSelectedSupplierIds(combinedIds);
    setSuppliers(newObj);
    props.setUnAssignId(true);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const SearchedSuppliers = useSelector(
    ({ AssignSupplierToRefugeeSuppliersDataReducer }) =>
      AssignSupplierToRefugeeSuppliersDataReducer
        ?.allMiniSearchedSuppliersReducer?.data
  );

  const loading = useSelector(
    ({ AssignSupplierToRefugeeSuppliersDataReducer }) =>
      AssignSupplierToRefugeeSuppliersDataReducer
        ?.allMiniSearchedSuppliersReducer?.isLoading
  );

  const errMsg = useSelector(
    ({ AssignSupplierToRefugeeSuppliersDataReducer }) =>
      AssignSupplierToRefugeeSuppliersDataReducer
        ?.allMiniSearchedSuppliersReducer?.errMsg
  );

  React.useEffect(() => {
    if (
      SearchedSuppliers &&
      SearchedSuppliers.miniSearchAllSuppliers &&
      props.selectedSuppliers.length === 0
    ) {
      let suppliersObj = SearchedSuppliers.miniSearchAllSuppliers;
      if (suppliersObj.docs !== null && suppliersObj.docs.length > 0) {
        for (let i = 0; i < suppliersObj.docs.length; i++) {
          const doc = suppliersObj.docs[i];
          doc["isSelected"] = false;
        }
      }

      setSuppliers(suppliersObj);
    }
    if (
      SearchedSuppliers &&
      SearchedSuppliers.miniSearchAllSuppliers &&
      props.selectedSuppliers.length > 0
    ) {
      let selsuppliersObj = SearchedSuppliers.miniSearchAllSuppliers;
      if (selsuppliersObj.docs !== null && selsuppliersObj.docs.length > 0) {
        for (let i = 0; i < selsuppliersObj.docs.length; i++) {
          const seldoc = selsuppliersObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedSuppliers.length; j++) {
            const selSupplier = props.selectedSuppliers[j];
            if (seldoc?.id === selSupplier?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setSuppliers(selsuppliersObj);
    }
  }, [SearchedSuppliers]);
  React.useEffect(() => {
    if (
      SearchedSuppliers &&
      SearchedSuppliers.miniSearchAllSuppliers &&
      props.selectedSuppliers.length > 0 &&
      props.editCheck
    ) {
      let selsuppliersObj = SearchedSuppliers.miniSearchAllSuppliers;
      if (selsuppliersObj.docs !== null && selsuppliersObj.docs.length > 0) {
        for (let i = 0; i < selsuppliersObj.docs.length; i++) {
          const seldoc = selsuppliersObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedSuppliers.length; j++) {
            const selSupplier = props.selectedSuppliers[j];
            if (seldoc?.id === selSupplier?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setSuppliers(selsuppliersObj);
    }
  }, [SearchedSuppliers]);

  React.useEffect(() => {
    if (
      SearchedSuppliers &&
      SearchedSuppliers.miniSearchAllSuppliers &&
      props.unAssignId
    ) {
      let selsuppliersObj = SearchedSuppliers.miniSearchAllSuppliers;
      if (selsuppliersObj.docs !== null && selsuppliersObj.docs.length > 0) {
        for (let i = 0; i < selsuppliersObj.docs.length; i++) {
          const seldoc = selsuppliersObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedSuppliers.length; j++) {
            const selSupplier = props.selectedSuppliers[j];
            if (seldoc?.id === selSupplier?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setSuppliers(selsuppliersObj);
      props.setUnAssignId(false);
    }
  }, [props.unAssignId, props.selectedSuppliers]);

  return (
    <div>
      <h3>Select the Supplier(s)</h3>
      <div>
        <CustomScrollbar>
        <List>
          {suppliers && suppliers.docs && suppliers.docs.length > 0 && (
            <>
              {suppliers.docs

                .sort((a, b) => {
                  if (a.isSelected && !b.isSelected) return -1;
                  if (!a.isSelected && b.isSelected) return 1;
                  const isSelectedA = selectedSupplierIds?.includes(a.id);
                  const isSelectedB = selectedSupplierIds?.includes(b.id);
                  if (isSelectedA && !isSelectedB) return -1;
                  if (!isSelectedA && isSelectedB) return 1;

                  if (isSelectedA === isSelectedB) {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  }
                  return 0;
                })
                .map((doc, index) => (
                  <ListItem
                    key={doc.id}
                    button
                    className={classes.listItem}
                    onClick={() => handleChangeSelected(index)}
                    disabled={props?.disabled}
                  >
                    <Checkbox checked={doc.isSelected} />
                    <ListItemText primary={doc.name} />
                  </ListItem>
                ))}
            </>
          )}
        </List>
        </CustomScrollbar>
      
      </div>
    </div>
  );
}
export default withReducer(
  "AssignSupplierToRefugeeSuppliersDataReducer",
  reducer
)(AssignSupplierToRefugeeSuppliersData);
