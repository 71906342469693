const answerAndProblem = `
  answer
  problem
`
const teachingDayGraphic = `
  haveTeaching
  morning
  afternoon
`
const completedTeaching = `
  from
  to
`
const goalsAndAgreement = `
  by
  until
  objective
`

export const pipPlanAttributes = `
pipPlan {
  _id,
  date,
  refugee,
  frontPage {
    name,
    integrationDirector,
    phoneNumber,
    emailAddress
  }

  workAndActivities {
    workedInCountryOfOrigin
    workedInCountryOfOriginAnswers {
      whichFunctionAndWhere
      from
      to
    }
    workedDuringFlight
    workedDuringFlightAnswers {
      whichFunctionAndWhere
      from
      to
    }
    workedInNetherlands
    workedInNetherlandsAnswers {
      whichFunctionAndWhere
      from
      to
    }
    doneVolunteerWorkInNetherlands
    stillWorkVolunteer
    stoppedVolunteerWorkBecause
    desiredWorkSector
    dreamJob
    goodAt
    needAdjustmentsForStartingWork
    adjustmentsForStartingWork
  }

  socialGuidance {
    dateLastModified
    guidedBy {
      from
      to
    }
    agreementsAboutGuidance
    scannedPDF
    lifeStory {
      from
      livedIn
      normalDayForMe
      leftMyCountryOn
      willBeInTheNetherlands
      oftenHaveContactWith
      familyLocation
      friendsLocation
      missMostIs
      concernedAbout
      moreAbout
    }
  }

  consentForm {
    supervisor
    supervisorAnswer
    integrationSchool
    integrationSchoolAnswer
    socialWork
    socialWorkAnswer
    employersServicePoint
    employersServicePointAnswer
    other
    otherAnswer
    date
    base64Signature
    signatureImageUrl
  }

  details {
    myDetails {
      firstName
      lastName
      gender
      birthDate
      streetAndHouseNumber
      postalCode
      place
      phoneNumber
      emailAddress
    }
    dataPartner {
      firstName
      lastName
      gender
      birthDate
      phoneNumber
      emailAddress
    }
    childrenData {
      haveChildren
      countChildren
      childrens {
        childName
        birthDate
        needChildcare
        organized
        organizedDeadline
        comments
        childcareFor {
          childcareStartDate
          childcareName
          streetAndHouseNumber
          postalCode
          place
          phoneNumber
          emailAddress
        }
      }
    }
  }

  healthAndLeisure {
    dateLastModified
    physicalComplaints {
      ${answerAndProblem}
    }
    psychologicalComplaints {
      ${answerAndProblem}
    }
    emotionalProblems {
      ${answerAndProblem}
    }
    shockingEventsOrExperiences {
      ${answerAndProblem}
    }
    sufferFromAnAddiction {
      ${answerAndProblem}
    }
    underMedicalTreatment {
      ${answerAndProblem}
    }
    medicalFacilitiesOrAdjustmentsInWork {
      ${answerAndProblem}
    }
    sportsAndLeisure {
      exerciseEveryDay{
        answer
        whatDoYouDo
      }
      doYouSports {
        answer
        whichSport
        youLikeToExercise
      }
      languageCafeSamensp
      becomeMemberOfLibrary
      canSwim
      canSwimChildren
    }
  }

  trainingAndDigitalSkills {
    course {
      schoolInMyCountry {
        answer
        comment
      }
      totalNumberOfYearEducation
      nameOfHighestLevelOfEducation
      haveHighestEducationDiploma {
        answer
        comment
      }
      attendedISK {
        answer
        comment
      }
      haveOneOrMoreCertificates {
        answer
        comment
      }
      wouldLikeToFollowACourse {
        answer
        comment
      }
    }
    digitalSkills {
      haveComputerOrLaptopAtHome
      doEverythingOnPhone
      canLookUpInformationOnInternet
      canSendAnEmail
      canSaveDownloadFiles
      useFacebookTwitterInstagram
      canPayOnline
      useWhatsApp
      canUseWord
    }
    transport {
      transportType
      almostWalking
      haveNnOVChipCard
      canCycle
      wantToLearnCycle
      commentForBicycleQuestion
      travelByBusOrTrain
      wouldLikeAnExplanation
      haveDrivingLicense
      typeOfDrivingLicense
      busyWith
    }
  }

  integration {
    dateLastModified
    learningPath
    languageLevel
    additionToMyNativeLanguageIAlsoSpeak
    nameOfIntegrationSchool
    locationOfIntegrationSchool
    contactPersonIntegrationSchool
    myTeacherNameIs
    teachingDaysAreAsFollows {
      monday {
        ${teachingDayGraphic}
      }
      tuesday {
        ${teachingDayGraphic}
      }
      wednesday {
        ${teachingDayGraphic}
      }
      thursday {
        ${teachingDayGraphic}
      }
      friday {
        ${teachingDayGraphic}
      }
    }
    haveLanguageBuddy
    necessary
    participationDeclarationProcess {
      haveCompletedThePVT
      by
      until
      roundingFor
    }
    completedTheMAP {
      completed
      theory
      practice
      completing {
        theory
        practice
      }
      completedPractice {
        ${completedTeaching}
      }
      completedTheory {
        ${completedTeaching}
      }
    }
    completedTheKNM {
      completed
      by
      until
      roundingFor
    }
  }

  goalsAndAgreements {
    argumentOne {
      ${goalsAndAgreement}
    }
    argumentTwo {
      ${goalsAndAgreement}
    }
    argumentThree {
      ${goalsAndAgreement}
    }
  }

}`;
