import { combineReducers } from "redux";
import {
  municipalityInfo,
  pipPlanInfo
} from "./municipality.reducers";

const reducer = combineReducers({
  municipalityInfo,
  pipPlanInfo
});

export default reducer;
