export const desiredWorkSectors = [
  "Agriculture, Forestry And Fisheries",
  "Industry",
  "Extraction of Mineral Substances",
  "Production, Distribution and Trade of Electricity, Natural Gas, Steam and Cooled Air",
  "Water Extraction And Distribution; Waste And Effluent Management And Remediation",
  "Construction industry",
  "Wholesale and Retail; Repair of Cars",
  "Transport And Storage",
  "Accommodation, Meal and Beverage Provision",
  "Information And Communication",
  "Financial Institutions",
  "Rental and Trade in Real Estate",
  "Advice, Research And Other Specialist Business Services",
  "Rental of Movable Property and Other Business Services",
  "Public Administration, Government Services And Compulsory Social Insurance",
  "Education",
  "Health and Welfare Care",
  "Culture, Sports And Recreation",
  "Other services",
  "Households as Employers; Non-differentiated Production of Goods and Services by Households for Own Use",
  "Extraterritorial Organizations And Bodies",
]
