import {  Document } from "@react-pdf/renderer";
import FrontPagePdf from "./FrontPagePdf";
import DetailsPagePdf from "./DetailsPagePdf";
import SocialGuidancePdf from "./SocialGuidancePdf";
import HealthAndLeisurePdf from "./HealthAndLeisurePdf";
import TrainingAndDigitalSkillsPdf from "./TrainingAndDigitalSkillsPdf"
import WorkAndActivitiesPdf from "./WorkAndActivitiesPdf";
import IntegrationPdf from "./IntegrationPdf";
import GoalsAndAgreementsPdf from "./GoalsAndAgreementsPdf";
import PermissionDocumentPdf from "./PermissionDocumentPdf";

function PDFFile({ pipData, municipality }) {
  return (
    <Document title="PIPPlan.pdf">
      <FrontPagePdf frontPageData={pipData?.frontPage} headerText={"My Future Plan"} municipality={municipality} />
      <DetailsPagePdf detailsPageData={pipData?.details} headerText={"My details"} />
      <SocialGuidancePdf socialGuidancePageData={pipData?.socialGuidance} headerText={"Social guidance +LV"} />
      <HealthAndLeisurePdf
        healthAndLeisurePageData={pipData?.healthAndLeisure}
        headerText={"Health and leisure"}
      />
      <TrainingAndDigitalSkillsPdf
        trainingAndDigitalSkillsData={pipData.trainingAndDigitalSkills}
        headerText={"Education & digital skills"}
      />
      <WorkAndActivitiesPdf
        workAndActivitiesPageData={pipData.workAndActivities}
        headerText={"Work and activities"}
      />
      <IntegrationPdf
        integrationPageData={pipData.integration}
        headerText={"Integration"}
      />
      <GoalsAndAgreementsPdf
        goalsAndAgreementsPageData={pipData.goalsAndAgreements}
        headerText={"Goals and agreements"}
      />
      <PermissionDocumentPdf
        permissionDocumentPageData={pipData.consentForm}
        headerText={"Permission document"}
        pipPlanId={pipData?._id}
      />
    </Document>
  );
};

export default PDFFile;
