import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MentorsData from "./sub_components/AssignMentorToRefugeeMentorsData";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import AssignedMentorsData from "./sub_components/AssignedMentorToRefugeeMentorsData";
import { useTranslation } from "react-i18next";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    // borderWidth: "0px",
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignMentorToRefugee(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const [selectedMentors, setSelectedMentors] = React.useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = React.useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = React.useState(
    []
  );
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = React.useState(false);
  const [editCheck, setEditCheck] = React.useState(false);
  const [unAssignId, setUnAssignId] = React.useState(false);
  const [state, setState] = React.useState(1)
  const [checkMentor, setCheckMentor] = React.useState(false)
  const add_confirmation = useSelector(
    ({ AssignMentorToRefugeeReducer }) =>
      AssignMentorToRefugeeReducer?.assignUnassignRolesToRefugeeReducer
  );
  React.useEffect(() => {
    return () => {
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, []);
  React.useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
      setEditCheck(true);
    }
  }, [setSelectedMunicipalities]);
  React.useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors, props.disabled]);
  React.useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers]);
  
 
  React.useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignUnassignRolesToRefugee
    ) {
      
      sessionStorage.setItem("user_type", "mentor");
      setLeaveScreenDialog(false);
    
    }
  }, [add_confirmation]);
  const unAssignMentor = (data) => {
    setUnAssignId(true);
    setSelectedMentors(selectedMentors.filter((item) => item?.id !== data?.id));
  };
  React.useEffect(() => {
    if(props.disabledPost === true) {
      let mentor = JSON.parse(sessionStorage.getItem("assignedMentors"))
      
      if (mentor === null) {
        setCheckMentor(true)
      } 
    }
  },[])
  
  return (
   
    
     
        <Container fixed>
         
          <Grid container spacing={4} className={classes.con}>
            <Grid item sm={12} md={7} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  
              
                </div>
                <MentorsData
                  searchText={searchText}
                  selectedMentors={selectedMentors}
                  setSelectedMentors={setSelectedMentors}
                  unAssignId={unAssignId}
                  setUnAssignId={setUnAssignId}
                  editCheck={editCheck}
                  setEditCheck={setEditCheck}
                  setState={setState}
                  disabled={props.disabled}
                  setButtonDisabled={props.setButtonDisabled}
                  disabledPost={props?.disabledPost}
                  handleMentorAssignmentChange={props?.handleMentorAssignmentChange}
                  checked={props?.checked}
                  checkMentor={checkMentor}
                />
              </div>
            </Grid>
            <Grid item sm={12} md={5} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
               
                <AssignedMentorsData
                  selectedMentors={selectedMentors}
                  unAssignMentor={unAssignMentor}
                  state={state}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
    
  );
}
export default withReducer(
  "AssignMentorToRefugeeReducer",
  reducer
)(AssignMentorToRefugee);
