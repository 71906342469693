import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";

function ContactData({
  isAdmin,
  contact,
  handleClose,
  handleEditContact,
  classes,
  t,
}) {
  if (contact) {
    return (
      <Grid container>
        <Grid item sm={11} xs={11}>
          <div style={{ marginTop: "5px", marginLeft: "10px" }}>
            <p className="citemshead">
              <Typography
                variant="h4"
                gutterBottom
                className={classes.heading1}
              >
                {t("Contact Details")}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.subheading1}
              >
                {contact.name}
              </Typography>
            </p>
            {contact.manager !== null && (
              <div>
                <p className="citems">
                  <i
                    style={{
                      fontSize: "24px",
                      display: "inline-block",
                      color: "#7ec9cf",
                    }}
                    className="fa fa-user"
                  ></i>
                  <span
                    style={{
                      marginLeft: "13px",
                      fontSize: "10pt",
                      fontFamily: "opensans-semibold",
                      marginTop: "30px",
                    }}
                  >
                    {contact.manager.name}
                  </span>
                </p>
                <p className="citems">
                  <i
                    style={{
                      fontSize: "24px",
                      display: "inline-block",
                      color: "#7ec9cf",
                    }}
                    className="fa fa-phone"
                  ></i>
                  <span
                    style={{
                      marginLeft: "13px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    {contact.manager.phoneNumber}
                  </span>
                </p>
                <p className="citems">
                  <i
                    style={{
                      fontSize: "22px",
                      display: "inline-block",
                      color: "#7ec9cf",
                    }}
                    className="fa fa-envelope"
                  ></i>
                  <span
                    style={{
                      marginLeft: "13px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    {contact.manager.email}
                  </span>
                </p>
              </div>
            )}
          </div>
        </Grid>

        <Grid item sm={1} xs={1}>
          <IconButton
            aria-label="cross"
            className={classes.crossIconBtn}
            onClick={() => handleClose()}
          >
            <ClearIcon className={classes.crossIcon} />
          </IconButton>
        </Grid>
        {isAdmin && isAdmin === true && (
          <Grid item sm={12} xs={12}>
            {contact.manager !== null && (
              <div className="contact-edit-btn-div">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditContact(contact)}
                  className={classes.editBtn}
                >
                  {t("Edit")}
                </Button>
              </div>
            )}
            {contact.manager === null && (
              <div className="contact-edit-btn-div">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditContact(contact)}
                  className={classes.addBtn}
                >
                  Add
                </Button>
              </div>
            )}
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
}

export default ContactData;
