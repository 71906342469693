import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
  },
  listItem: {
    "&.MuiListItem-gutters": {
      marginLeft: "-26px",
      marginRight: "16px",
    },
  },
  filterContinueButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    float: "right",
    marginRight: "15px",
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  dropdown: {
    position: "absolute",
    // width: "155px",
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    height: "226px",
  },
  chkroot: {
    padding: 2,
  },
  icon: {
    border: "1px solid #454A92",
    borderRadius: 0,
    width: "18px",
    height: "18px",
  },
  container: {
    height: "17rem",
    overflow: "hidden",
    position: "relative",
  },
  container__content: {
    height: "100%",
    marginRight: "-1rem",
    overflow: "auto",
    padding: "0.5rem",
    paddingRight: "1rem",
  },
  scrollbar: {
    backgroundColor: "rgb(243, 244, 246)",
    borderLeftWidth: 0,
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "0.75rem",
  },
  scrollbar__thumb: {
    backgroundColor: "rgb(156, 163, 175)",
    cursor: "grab",
    left: 0,
    position: "absolute",
    width: "100%",
  },
  scrollbar__track: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
}));

const CustomScrollbar = ({ children }) => {
  const classes = useStyles();
  const trackRef = useRef();
  const thumbRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const thumbEle = thumbRef.current;
    const contentEle = contentRef.current;
    if (!thumbEle || !contentEle) {
      return;
    }
    const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
    const thumbHeight = Math.max(scrollRatio * contentEle.clientHeight, 20); // Adjusted for minimum height
    thumbEle.style.height = `${thumbHeight}px`;
  }, [children]); // Re-calculate when children change

  const handleScrollContent = () => {
    const thumbEle = thumbRef.current;
    const contentEle = contentRef.current;
    if (!thumbEle || !contentEle) {
      return;
    }
    thumbEle.style.top = `${
      (contentEle.scrollTop * 100) / contentEle.scrollHeight
    }%`;
  };

  const handleClickTrack = (e) => {
    const trackEle = trackRef.current;
    const contentEle = contentRef.current;
    if (!trackEle || !contentEle) {
      return;
    }
    const bound = trackEle.getBoundingClientRect();
    const percentage = (e.clientY - bound.top) / bound.height;
    contentEle.scrollTop =
      percentage * (contentEle.scrollHeight - contentEle.clientHeight);
  };

  const handleMouseDown = (e) => {
    const ele = thumbRef.current;
    const contentEle = contentRef.current;
    if (!ele || !contentEle) {
      return;
    }
    const startPos = {
      top: contentEle.scrollTop,
      y: e.clientY,
    };

    const handleMouseMove = (e) => {
      const dy = e.clientY - startPos.y;
      const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
      contentEle.scrollTop = startPos.top + dy / scrollRatio;
      updateCursor(ele);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      resetCursor(ele);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    updateCursor(ele);
  };

  const handleTouchStart = (e) => {
    const ele = thumbRef.current;
    const contentEle = contentRef.current;
    if (!ele || !contentEle) {
      return;
    }
    const touch = e.touches[0];
    const startPos = {
      top: contentEle.scrollTop,
      y: touch.clientY,
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const dy = touch.clientY - startPos.y;
      const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
      contentEle.scrollTop = startPos.top + dy / scrollRatio;
      updateCursor(ele);
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
      resetCursor(ele);
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
    updateCursor(ele);
  };

  const updateCursor = (ele) => {
    ele.style.cursor = "grabbing";
    ele.style.userSelect = "none";
    document.body.style.cursor = "grabbing";
    document.body.style.userSelect = "none";
  };

  const resetCursor = (ele) => {
    ele.style.cursor = "grab";
    ele.style.userSelect = "";
    document.body.style.cursor = "";
    document.body.style.userSelect = "";
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.container__content}
        ref={contentRef}
        onScroll={handleScrollContent}
      >
        {children}
      </div>
      <div className={classes.scrollbar}>
        <div
          className={classes.scrollbar__track}
          ref={trackRef}
          onClick={handleClickTrack}
        />
        <div
          className={classes.scrollbar__thumb}
          ref={thumbRef}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        />
      </div>
    </div>
  );
};

CustomScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomScrollbar;
