import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PanoramaIcon from '@material-ui/icons/Panorama';
import HealthAndLeisureForm from "./HealthAndLeisureForm";

const useStyles = makeStyles(() => ({
  title: {
    color: "#253c8c",
    textAlign: "center"
  },
}));

function HealthAndLeisure({ data, setPipPlanPage }) {
  const [t] = useTranslation();

  const classes = useStyles();


  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <h1 className={classes.title}>{t("Health and leisure")}</h1>
      <Grid
        container
        item
        style={{
          minWidth: "100%",
        }}
      >
        {<HealthAndLeisureForm data={data} setPipPlanPage={setPipPlanPage}/>}
      </Grid>
    </Grid>
  );
};

export default HealthAndLeisure;
