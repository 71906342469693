import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import DatePicker from "../../../shared/DatePicker/index";

import { useFormik } from "formik";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { goalsAgreementsPageSchema } from "../../../../resources/scheme";
import { pipPlanNavigationKeys } from "../../../../resources/constants";
import { removeKey, formatDate } from "../../../../utils/helpers";
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from "../../store/actions";

const theme = createTheme();
const useStyles = makeStyles({
  input: {
    display: "none",
  },
  btnupload: {
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    width: "450px",
    height: "90px",
    textTransform: "capitalize",
    boxShadow: "none",
  },
  customInput: {
    "& .MuiOutlinedInput-input": {
      padding: "5px",
    },
  },
  containerMaxWidthLg: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1500px",
    },
  },
});


function GoalsAndAgreementsForm({ data, setPipPlanPage }) {
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const params = useParams();
  const { refugeeId } = params;
  const classes = useStyles();
  const [selectedPage , setSelectedPage] = useState("")
  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.consentForm)
  }
  const navigatePreviousPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.integration)
  }
  const formik = useFormik({
    initialValues: {
      argumentOne: {
        objective: data?.argumentOne?.objective ?? '',
        by: data?.argumentOne?.by ?? '',
        until: data?.argumentOne?.until ?? '',
      },
      argumentTwo: {
        objective: data?.argumentTwo?.objective ?? '',
        by: data?.argumentTwo?.by ?? '',
        until: data?.argumentTwo?.until ?? '',
      },
      argumentThree: {
        objective: data?.argumentThree?.objective ?? '',
        by: data?.argumentThree?.by ?? '',
        until: data?.argumentThree?.until ?? '',
      },
    },
    enableReinitialize: true,
    validationSchema: goalsAgreementsPageSchema,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        const queryString = JSON
          .stringify(removeKey(values, '__typename'), null, 2)
          .replace(/"([^"]+)":/g, '$1:');
        const detailsQuery = `goalsAndAgreements: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: detailsQuery })
          .then(() => {
            if (selectedPage === "saveAndPrevious") {
              dispatch(getPipPlanByRefugee(refugeeId))
              setPipPlanPage(pipPlanNavigationKeys.integration)
            } else{
              dispatch(getPipPlanByRefugee(refugeeId))
               setPipPlanPage(pipPlanNavigationKeys.consentForm)
            }
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      } else {
        helpers.setSubmitting(false);
        if (selectedPage === "saveAndPrevious") {
          navigatePreviousPage()
        } else{
          navigateNextPage();
        }
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Grid item>
        <Container
          className={classes.containerMaxWidthLg}
          style={{
            flex: "1",
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "40px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid>
            <h1 style={{ marginLeft: "200px" }}>{t('Goals and agreements')}</h1>
          </Grid>

          <h2>{t('Goals and agreements')}</h2>
          <h5>
              {t(`Describe below the goals you want to achieve that will contribute to your self-reliance.`)}
          </h5>
          <h5 style={{ width: "600px" }}>
              {t(`Please note that the objectives must be formulated SMART. To formulate SMART goals, make sure they are Specific, Measurable, Achievable, Realistic and Time-bound. This means making your goals as specific and measurable as possible so that you can track your progress and assess whether or not you're on the right track.`)}
          </h5>

          {/* Objective 1 */}
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
            style={{ marginBottom: "35px" }}
          >
            <Grid item>
              <TextField
                required
                fullWidth
                multiline
                name="argumentOne.objective"
                label={t("Objective") + " 1"}
                value={formik.values.argumentOne.objective}
                onChange={formik.handleChange}
                style={{ width: "230px", marginTop: "0px", height: "20px" }}
                helperText={
                  Boolean(formik.touched?.argumentOne?.objective) &&
                  t(formik?.errors?.argumentOne?.objective)
                }
                error={
                  Boolean(formik.touched?.argumentOne?.objective) &&
                  Boolean(formik.errors?.argumentOne?.objective)
                }
              />
            </Grid>
            <Grid item style={{ marginLeft: "80px", marginRight: "40px" }}>
              <DatePicker
                id="argumentOne_by"
                fullWidth
                required
                name="argumentOne.by"
                label={t("by")}
                value={formatDate(formik.values.argumentOne.by)}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.argumentOne?.by) &&
                  t(formik?.errors?.argumentOne?.by)
                }
                error={
                  Boolean(formik.touched?.argumentOne?.by) &&
                  Boolean(formik.errors?.argumentOne?.by)
                }
              />
            </Grid>
            <Grid item>
              <DatePicker
                id="argumentOne_until"
                fullWidth
                required
                name="argumentOne.until"
                label={t("until")}
                value={formatDate(formik.values.argumentOne.until)}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.argumentOne?.until) &&
                  t(formik?.errors?.argumentOne?.until)
                }
                error={
                  Boolean(formik.touched?.argumentOne?.until) &&
                  Boolean(formik.errors?.argumentOne?.until)
                }
              />
            </Grid>
          </Grid>

          {/* Objective 2 */}
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
            style={{ marginBottom: "35px" }}
          >
            <Grid item>
              <TextField
                required
                fullWidth
                multiline
                name="argumentTwo.objective"
                label={t("Objective") + " 2"}
                value={formik.values.argumentTwo.objective}
                onChange={formik.handleChange}
                style={{ width: "230px", marginTop: "0px", height: "20px" }}
                helperText={
                  Boolean(formik.touched?.argumentTwo?.objective) &&
                  t(formik?.errors?.argumentTwo?.objective)
                }
                error={
                  Boolean(formik.touched?.argumentTwo?.objective) &&
                  Boolean(formik.errors?.argumentTwo?.objective)
                }
              />
            </Grid>
            <Grid item style={{ marginLeft: "80px", marginRight: "40px" }}>
              <DatePicker
                id="argumentTwo_by"
                fullWidth
                required
                name="argumentTwo.by"
                label={t("by")}
                value={formatDate(formik.values.argumentTwo.by)}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.argumentTwo?.by) &&
                  t(formik?.errors?.argumentTwo?.by)
                }
                error={
                  Boolean(formik.touched?.argumentTwo?.by) &&
                  Boolean(formik.errors?.argumentTwo?.by)
                }
              />
            </Grid>
            <Grid item>
              <DatePicker
                id="argumentTwo_until"
                fullWidth
                required
                name="argumentTwo.until"
                label={t("until")}
                value={formatDate(formik.values.argumentTwo.until)}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.argumentTwo?.until) &&
                  t(formik?.errors?.argumentTwo?.until)
                }
                error={
                  Boolean(formik.touched?.argumentTwo?.until) &&
                  Boolean(formik.errors?.argumentTwo?.until)
                }
              />
            </Grid>
          </Grid>

          {/* Objective 3 */}
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
            style={{ marginBottom: "70px" }}
          >
            <Grid item>
              <TextField
                required
                fullWidth
                multiline
                name="argumentThree.objective"
                label={t("Objective") + " 3"}
                value={formik.values.argumentThree.objective}
                onChange={formik.handleChange}
                style={{ width: "230px", marginTop: "0px", height: "20px" }}
                helperText={
                  Boolean(formik.touched?.argumentThree?.objective) &&
                  t(formik?.errors?.argumentThree?.objective)
                }
                error={
                  Boolean(formik.touched?.argumentThree?.objective) &&
                  Boolean(formik.errors?.argumentThree?.objective)
                }
              />
            </Grid>
            <Grid item style={{ marginLeft: "80px", marginRight: "40px" }}>
              <DatePicker
                id="argumentThree_by"
                fullWidth
                required
                name="argumentThree.by"
                label={t("by")}
                value={formatDate(formik.values.argumentThree.by)}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.argumentThree?.by) &&
                  t(formik?.errors?.argumentThree?.by)
                }
                error={
                  Boolean(formik.touched?.argumentThree?.by) &&
                  Boolean(formik.errors?.argumentThree?.by)
                }
              />
            </Grid>
            <Grid item>
              <DatePicker
                id="argumentThree_until"
                fullWidth
                required
                name="argumentThree.until"
                label={t("until")}
                value={formatDate(formik.values.argumentThree.until)}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.argumentThree?.until) &&
                  t(formik?.errors?.argumentThree?.until)
                }
                error={
                  Boolean(formik.touched?.argumentThree?.until) &&
                  Boolean(formik.errors?.argumentThree?.until)
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <Button
                type="submit"
                style={{
                  mt: 3,
                  mb: 2,
                  width: "auto",
                  marginBottom: "20px",
                  height: "40px",
                  fontSize: "16px",
                  fontFamily: "opensans-semibold",
                  backgroundColor: "#454a92",
                  color: "#fff",
                }}
                onClick={() => setSelectedPage("saveAndPrevious")}
              >
                {t("SAVE & PREVIOUS")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                style={{
                  mt: 3,
                  mb: 2,
                  width: "auto",
                  marginBottom: "20px",
                  height: "40px",
                  fontSize: "16px",
                  fontFamily: "opensans-semibold",
                  backgroundColor: "#454a92",
                  color: "#fff",
                }}
              >
                {t('Save and Next')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </form>
  );
}

export default GoalsAndAgreementsForm;
