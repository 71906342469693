import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { roles } from "../../../../../../utils/roles";
import jwt from "jsonwebtoken";

function AppointmentContent({
  children,
  style,
  appointmentData,
  classes,
  ...restProps
}) {
  const [t] = useTranslation();

  appointmentData.setIsShowDeleteButton = function (value) {
    this.isShowDeleteButton = value;
  };

  appointmentData.setIsShowOpenButton = function (value) {
    this.isShowOpenButton = value;
  };

  appointmentData?.setIsShowDeleteButton(
    appointmentData.bookedBy.userId === jwt.decode(localStorage.jwtToken)?.id
      ? true
      : false
  );
  appointmentData.setIsShowOpenButton(
    appointmentData.bookedBy.userId === jwt.decode(localStorage.jwtToken)?.id
      ? true
      : false
  );
  
  return (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
        <div style={{ width: "100%", paddingLeft: "25px" }}>
  <h4>{t("Booked With")}:</h4>
  <ul style={{ marginTop: "-20px", marginLeft: "65px" }}>
    {appointmentData &&
      appointmentData.bookedWith !== null &&
      appointmentData.bookedWith.length > 0 && (() => {
        // Create a set to store unique names
        const uniqueNames = new Set();
        console.log(appointmentData.bookedWith)
        return appointmentData.bookedWith.map((doc, index) => {
          // Check if the name is already in the set
          if (!uniqueNames.has(doc.name)) {
            // If not, add it to the set and display it in the list
            uniqueNames.add(doc.name);

            return (
              <li key={index}>
                {doc.role === "mentor"
                  ? roles[doc.role].title
                  : `${roles[doc.role].title} - ${doc.name || "N/A"}`}
              </li>
            );
          }

          // If the name is already in the set, skip rendering
          return null;
        });
      })()}
  </ul>
</div>
      <div style={{ width: "100%", paddingLeft: "25px", marginTop: "-25px" }}>
        <h4>{t("Notes")} :</h4>
        <ul style={{ marginTop: "-20px", marginLeft: "65px" }}>
          {appointmentData &&
            appointmentData.notes !== null &&
            appointmentData.notes.length > 0 &&
            appointmentData.notes.map((doc, index) => (
              <li key={index}>{doc.note}</li>
            ))}
        </ul>
      </div>
    </AppointmentTooltip.Content>
  );
}
export default AppointmentContent;
