import * as Yup from "yup";
import moment from "moment";
import { isAdult, getBooleanValue } from "../../utils/helpers"
import { stringRegExp, phoneRegExp, emailRegExp } from "../constants/regExp"
import { DATE_FORMAT } from "../constants";

export const commonValidationSchema = {
  firstName: () => (Yup
    .string('Must be string')
    .required('This is a mandatory field')
    .test("regex test", `Most be string`, (val) => {
      return stringRegExp.test(val);
    })),
  lastName: () => (Yup
    .string('Must be string')
    .required('This is a mandatory field')
    .test("regex test", `Must be string`, (val) => {
      return stringRegExp.test(val);
    })),
  gender: () => (Yup
    .mixed().oneOf(["Male", "Female", "Other", "Man", "Vrouw", "Ander"],
      "Gender must be one of the following values: Male, Female, Other, Man, Vrouw, Ander")
    .required("This is a mandatory field")
    .test("regex test", `Most be string`, (val) => {
      return stringRegExp.test(val);
    })),
  birthDate: () => (Yup
    .date()
    .required("This is a mandatory field")
    .typeError(`The value must be a date (${DATE_FORMAT})`)),
  postalCode: () => (Yup
    .string()
    .required('This is a mandatory field')),
  streetAndHouseNumber: () => (Yup
    .string()
    .required('This is a mandatory field')),
  place: () => (Yup
    .string()
    .required('This is a mandatory field')),
  phoneNumber: () => (Yup
    .string()
    .required('This is a mandatory field'))
    .min(10, "too short")
    .max(15, "too long")
    .matches(phoneRegExp, 'Invalid Phone Number')
    .typeError('Most be Number'),
  emailAddress: () => (Yup
    .string()
    .matches(emailRegExp, 'Invalid Email address')
    .required('This is a mandatory field')),
  radio: () => (Yup
    .boolean()
    .typeError('This is a mandatory field')
    .required('This is a mandatory field')
  ),
  numberSelect: () => (Yup
    .number()
    .typeError('This is a mandatory field')),
  deadline: () => (Yup
    .date()
    .required("This is a mandatory field")
    .typeError(`This is a mandatory field`)
    .test(
      "DOB",
      "Please choose a valid deadline date",
      (date) => {
        return moment().diff(moment(date), "year") <= 0
      })),
  workAndActivitiesArray: () => (
    Yup.array(
      Yup.object().shape({
        whichFunctionAndWhere: Yup.string().required('This is a mandatory field'),
        from: Yup.date().required('This is a mandatory field')
          .max(moment().format(DATE_FORMAT), `The field must be earlier than ${moment().format(DATE_FORMAT)}`),
        to: Yup.date()
          .when("from", (from, schema) => {
            if (from) {
              return schema.required('This is a mandatory field')
                .min(moment(from).format(DATE_FORMAT), `The field must be later than ${moment(from).format(DATE_FORMAT)}`);
            }
            return schema;
          })
      }),
    ).nullable(true)
  ),
  goalsAgreement: () => (
    Yup.object().shape({
      objective: Yup.string().required('This is a mandatory field').nullable(false),
      by: Yup.date().required('This is a mandatory field')
        .typeError('Required, please select the valid date').nullable(false),
      until: Yup.date().when("by", (by, schema) => {
        return schema
          .min(moment(by).format(DATE_FORMAT), `The field must be earlier than ${moment(by).format(DATE_FORMAT)}`)
          .required('This is a mandatory field')
          .typeError('This is a mandatory field').nullable(false)
      }),
    })
  )
};

export const pipFrontPageSchema = Yup.object({
  name: Yup
    .string()
    .required('This is a mandatory field'),
  integrationDirector: Yup
    .string()
    .required('This is a mandatory field'),
  phoneNumber: commonValidationSchema.phoneNumber(),
  emailAddress: commonValidationSchema.emailAddress(),
});

export const pipDetailsPageSchema = Yup.object().shape({
  myDetails: Yup.object().shape({
    firstName: commonValidationSchema.firstName(),
    lastName: commonValidationSchema.lastName(),
    gender: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
    birthDate: commonValidationSchema.birthDate(),
    postalCode: commonValidationSchema.postalCode(),
    streetAndHouseNumber: commonValidationSchema.streetAndHouseNumber(),
    place: commonValidationSchema.place(),
    phoneNumber: commonValidationSchema.phoneNumber(),
    emailAddress: commonValidationSchema.emailAddress(),
  }),
  dataPartner: Yup.object().shape({
    firstName: commonValidationSchema.firstName(),
    lastName: commonValidationSchema.lastName(),
    gender: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
    birthDate: commonValidationSchema.birthDate(),
    phoneNumber: commonValidationSchema.phoneNumber(),
    emailAddress: commonValidationSchema.emailAddress(),
  }),
  childrenData: Yup.object().shape({
    haveChildren: commonValidationSchema.radio(),
    countChildren: Yup.number().when("haveChildren", (haveChildren, schema) => {
      if (haveChildren === true) {
        return commonValidationSchema.numberSelect().nullable(false)
      } else {
        return schema.optional().nullable(true)
      }
    }),
    childrens: Yup.array(
      Yup.object().shape({
        childName: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
        birthDate: Yup.date().required('This is a mandatory field').typeError('This is a mandatory field'),
        needChildcare: Yup.boolean().when("birthDate", (birthDate, schema) => {
          if (birthDate && !isAdult(birthDate)) {
            return commonValidationSchema.radio()
          }
          return schema.optional().nullable(true);
        }),
        organized: Yup.boolean().when("needChildcare", (needChildcare, schema) => {
          if (needChildcare === true) {
            return schema.required('This is a mandatory field').typeError('This is a mandatory field')
          }
          return schema.nullable(true)
        }),
        comments: Yup.string().when("needChildcare", (needChildcare, schema) => {
          if (needChildcare === false) {
            return schema.required().typeError('This is a mandatory field')
          }
          return schema.nullable(true);
        }),
        organizedDeadline: Yup.date().when(["organized"], (organized, schema) => {
          if (organized === false) {
            return schema.required('This is a mandatory field').typeError('This is a mandatory field')
          }
          return schema.optional().nullable(true);
        }),
        childcareFor: Yup.object().when("organized", (organized, schema) => {
          if (organized === true) {
            return schema.shape({
              childcareStartDate: Yup.date().required('This is a mandatory field'),
              childcareName: Yup.string().required('This is a mandatory field'),
              streetAndHouseNumber: commonValidationSchema.streetAndHouseNumber(),
              postalCode: commonValidationSchema.postalCode(),
              place: commonValidationSchema.place(),
              phoneNumber: commonValidationSchema.phoneNumber(),
              emailAddress: commonValidationSchema.emailAddress(),
            })
          }
          return schema.optional().nullable(true);
        }),
      })
    ).nullable(true)
  })
});

export const pipHealthAndLeisurePageSchema = Yup.object().shape({
  physicalComplaints: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),
  psychologicalComplaints: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),

  emotionalProblems: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),
  shockingEventsOrExperiences: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),
  sufferFromAnAddiction: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),
  underMedicalTreatment: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),
  medicalFacilitiesOrAdjustmentsInWork: Yup.object().shape({
    answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    problem: Yup.string().when("answer", (answer, schema) => {
      if (answer === true) {
        return schema.typeError('This is a mandatory field').required('This is a mandatory field')
      }
      return schema.nullable(true)
    }),
  }),
  sportsAndLeisure: Yup.object().shape({
    exerciseEveryDay: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      whatDoYouDo: Yup.string().when("answer", (answer, schema) => {
        if (answer === true) {
          return schema.typeError('This is a mandatory field').required('This is a mandatory field')
        }
        return schema.nullable(true)
      }),
    }),
    doYouSports: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      whichSport: Yup.string().when("answer", (answer, schema) => {
        if (answer === true) {
          return schema.typeError('This is a mandatory field').required('This is a mandatory field')
        }
        return schema.nullable(true)
      }),
      youLikeToExercise: Yup.string().when("answer", (answer, schema) => {
        if (!answer) {
          return schema.typeError('This is a mandatory field').required('This is a mandatory field')
        }
        return schema.nullable(true)
      }),
    }),
    becomeMemberOfLibrary: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canSwim: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    languageCafeSamensp: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canSwimChildren: Yup.mixed()
      .oneOf(['n/a', 'yes', 'no'])
      .typeError('This is a mandatory field')
      .required('This is a mandatory field')
  })
})

export const pipSocialPageSchema = Yup.object({
  guidedBy: Yup.object().shape({
    from: Yup.date().required('This is a mandatory field').typeError('This is a mandatory field'),
    to: Yup.date().required('This is a mandatory field').typeError('This is a mandatory field'),
  }),
  agreementsAboutGuidance: Yup.string().required("This is a mandatory field"),
  pdfFile: Yup.mixed(),
  lifeStory: Yup.object().shape({
    from: Yup.string().required('This is a mandatory field'),
    normalDayForMe: Yup.string().required('This is a mandatory field'),
    leftMyCountryOn: Yup.date().required('This is a mandatory field'),
    willBeInTheNetherlands: Yup.date().required('This is a mandatory field'),
    familyLocation: Yup.string().required('This is a mandatory field'),
    friendsLocation: Yup.string().required('This is a mandatory field'),
    missMostIs: Yup.string().required('This is a mandatory field'),
    concernedAbout: Yup.string().required('This is a mandatory field'),
    moreAbout: Yup.string().required('This is a mandatory field'),
  }),
});


export const pipTrainingAndDigitalSkillsSchema = Yup.object({
  course: Yup.object().shape({
    schoolInMyCountry: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      comment: Yup.string().typeError('This is a mandatory field').required('This is a mandatory field'),
    }),
    totalNumberOfYearEducation: Yup.number().min(1, 'This is a mandatory field').nullable(false)
      .typeError('This is a mandatory field'),
    nameOfHighestLevelOfEducation: Yup.string().typeError('This is a mandatory field').required('This is a mandatory field'),
    haveHighestEducationDiploma: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      comment: Yup.string().typeError('This is a mandatory field').required('This is a mandatory field'),
    }),
    attendedISK: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      comment: Yup.string().typeError('This is a mandatory field').required('This is a mandatory field'),
    }),
    haveOneOrMoreCertificates: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      comment: Yup.string().typeError('This is a mandatory field').required('This is a mandatory field').required('This is a mandatory field'),
    }),
    wouldLikeToFollowACourse: Yup.object().shape({
      answer: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
      comment: Yup.string().typeError('This is a mandatory field').required('This is a mandatory field'),
    }),
  }),
  transport: Yup.object().shape({
    canCycle: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    wantToLearnCycle: Yup.boolean().when("canCycle", (canCycle, schema) => {
      if (!canCycle) {
        return schema.required('This is a mandatory field').typeError('This is a mandatory field')
      }
      return schema.optional().nullable(true)
    }),
    haveNnOVChipCard: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    almostWalking: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    travelByBusOrTrain: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    haveDrivingLicense: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    commentForBicycleQuestion: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
    wouldLikeAnExplanation: Yup.boolean().when("travelByBusOrTrain", (travelByBusOrTrain, schema) => {
      if (travelByBusOrTrain === false) {
        return schema.required('This is a mandatory field').typeError('This is a mandatory field')
      }
      return schema.optional().nullable(true)
    }),
    typeOfDrivingLicense: Yup.string().when("haveDrivingLicense", (haveDrivingLicense, schema) => {
      if (haveDrivingLicense) {
        return schema.required('This is a mandatory field').typeError('This is a mandatory field')
      }
      return schema.optional().nullable(true)
    }),
    busyWith: Yup.string().when("haveDrivingLicense", (haveDrivingLicense, schema) => {
      if (!haveDrivingLicense) {
        return schema.required('This is a mandatory field').typeError('This is a mandatory field')
      }
      return schema.optional().nullable(true)
    }),
  }),
  digitalSkills: Yup.object().shape({
    haveComputerOrLaptopAtHome: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    doEverythingOnPhone: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canLookUpInformationOnInternet: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canSendAnEmail: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canSaveDownloadFiles: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    useFacebookTwitterInstagram: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canPayOnline: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    useWhatsApp: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
    canUseWord: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
  }),
});


export const pipWorkAndActivitiesSchema = Yup.object({
  workedDuringFlight: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
  workedInNetherlands: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
  workedInCountryOfOrigin: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
  doneVolunteerWorkInNetherlands: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),

  workedInCountryOfOriginAnswers: commonValidationSchema.workAndActivitiesArray(),
  workedDuringFlightAnswers: commonValidationSchema.workAndActivitiesArray(),
  workedInNetherlandsAnswers: commonValidationSchema.workAndActivitiesArray(),
  stillWorkVolunteer: Yup.boolean().when("doneVolunteerWorkInNetherlands", (doneVolunteerWorkInNetherlands, schema) => {
    if (doneVolunteerWorkInNetherlands) {
      return schema.required('This is a mandatory field').typeError('This is a mandatory field')
    }
    return schema.optional().nullable(true)
  }),
  stoppedVolunteerWorkBecause: Yup.string()
    .when(
      ["doneVolunteerWorkInNetherlands", "stillWorkVolunteer"],
      (doneVolunteerWorkInNetherlands, stillWorkVolunteer, schema) => {
        if (doneVolunteerWorkInNetherlands && stillWorkVolunteer === false) {
          return schema.required('This is a mandatory field').typeError('This is a mandatory field')
        }
        return schema.optional().nullable(true)
      }),
  desiredWorkSector: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  dreamJob: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  goodAt: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  needAdjustmentsForStartingWork: Yup.boolean().typeError('This is a mandatory field').required('This is a mandatory field'),
  adjustmentsForStartingWork: Yup.string()
    .when("needAdjustmentsForStartingWork", (needAdjustmentsForStartingWork, schema) => {
      if (needAdjustmentsForStartingWork === true) {
        return schema.required('This is a mandatory field').typeError('This is a mandatory field')
      }
      return schema.nullable(true)
    })
});

export const pipIntegrationPageSchema = Yup.object({
  learningPath: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  languageLevel: Yup.string().when("learningPath", (learningPath, schema) => {
    if (learningPath) {
      return schema.required('This is a mandatory field').typeError('This is a mandatory field')
    }
    return schema.optional().nullable(true)
  }),
  additionToMyNativeLanguageIAlsoSpeak: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  nameOfIntegrationSchool: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  locationOfIntegrationSchool: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  contactPersonIntegrationSchool: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  myTeacherNameIs: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  haveLanguageBuddy: Yup.string().required('This is a mandatory field').typeError('This is a mandatory field'),
  necessary: Yup.boolean().when("haveLanguageBuddy", (haveLanguageBuddy, schema) => {
    if (getBooleanValue(haveLanguageBuddy) === false) {
      return schema.required('This is a mandatory field').typeError('This is a mandatory field')
    }
    return schema.optional().nullable(true)
  }),

  participationDeclarationProcess: Yup.object().shape({
    haveCompletedThePVT: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    by: Yup.date()
      .when("haveCompletedThePVT", (haveCompletedThePVT, schema) => {
        if (haveCompletedThePVT === true) {
          return schema.required('This is a mandatory field')
            .max(moment().format(DATE_FORMAT), `The field must be earlier than ${moment().format(DATE_FORMAT)}`)
            .typeError('Required, please select the valid date')
        }
        return schema.optional().nullable(true)
      }),
    until: Yup.date()
      .when(["by", "haveCompletedThePVT"], (by, haveCompletedThePVT, schema) => {
        if (by && moment(by).isValid() && haveCompletedThePVT === true) {
          return schema.required('This is a mandatory field')
            .min(moment(by).format(DATE_FORMAT), `The field must be later than ${moment(by).format(DATE_FORMAT)}`)
            .typeError('Required, please select the valid date')
        }
        return schema.optional().nullable(true);
      }),
    roundingFor: Yup.date()
      .when("haveCompletedThePVT", (haveCompletedThePVT, schema) => {
        if (haveCompletedThePVT === false) {
          return schema.required('This is a mandatory field')
            .typeError('Required, please select the valid date')
        }
        return schema.optional().nullable(true);
      })
  }).nullable(true),

  completedTheMAP: Yup.object().shape({
    completed: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    completedPractice: Yup.object().when("practice", (practice, schema) => {
      if (practice === true) {
        return schema.shape({
          from: Yup.date()
            .required('This is a mandatory field')
            .typeError('Required, please select the valid date'),
          to: Yup.date()
            .when("from", (from, schema) => {
              if (from && moment(from).isValid()) {
                return schema.required('This is a mandatory field')
                  .typeError('Required, please select the valid date')
              }
              return schema.optional().typeError('Required, please select the valid date')
            })
        })
      }
      return schema.nullable(true)
    }),

    completedTheory: Yup.object().when("theory", (theory, schema) => {
      if (theory === true) {
        return schema.shape({
          from: Yup.date()
            .required('This is a mandatory field')
            .typeError('Required, please select the valid date').nullable(false),
          to: Yup.date()
            .when("from", (from, schema) => {
              if (from && moment(from).isValid()) {
                return schema.required('This is a mandatory field')
                  .typeError('Required, please select the valid date')
              }
              return schema.optional().typeError('Required, please select the valid date')
            })
        })
      }
      return schema.nullable(true)
    }),

    completing: Yup.object().when(["practice", "theory", "completed"], (practice, theory, completed, schema) => {
      if (completed === false) {
        return schema.shape({
          practice: practice === false ? Yup.date().required('This is a mandatory field').typeError('Required, please select the valid date') :
            Yup.date().nullable(true),
          theory: theory === false ? Yup.date().required('This is a mandatory field').typeError('Required, please select the valid date') :
            Yup.date().nullable(true)
        }).nullable(false).typeError('This is a mandatory field')
      }
      return schema
    }).nullable(true),
  }),

  completedTheKNM: Yup.object().shape({
    completed: Yup.boolean().required('This is a mandatory field').typeError('This is a mandatory field'),
    by: Yup.date().when("completed", (completed, schema) => {
      if (completed) {
        return schema
          .required('This is a mandatory field')
          .typeError('Required, please select the valid date').nullable(false)
      }
      return schema.nullable(true)
    }),
    until: Yup.date().when("completed", (completed, schema) => {
      if (completed) {
        return schema
          .required('This is a mandatory field')
          .typeError('Required, please select the valid date').nullable(false)
      }
      return schema.nullable(true)
    }),

    roundingFor: Yup.date().when("completed", (completed, schema) => {
      if (!completed) {
        return schema
          .required('This is a mandatory field')
          .typeError('Required, please select the valid date').nullable(false)
      }
      return schema.nullable(true)
    }),

  })
});

export const goalsAgreementsPageSchema = Yup.object({
  argumentOne: commonValidationSchema.goalsAgreement(),
  argumentTwo: commonValidationSchema.goalsAgreement(),
  argumentThree: commonValidationSchema.goalsAgreement()
});
