import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import SuccessDialog from "./sub_components/AddMunicipalityDetailSuccessDialog";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";
import AssignSupplierToRefugee from "./AssignSupplierToRefugee";

const useStyles = makeStyles((theme) => ({
  editButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginLeft: "auto",
    marginTop: "50px",
    boxShadow: "none",
  },
  btnsCon: {
    marginTop: "20px",
  },
  button: {
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "150px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
  },
}));

function ViewEditRefugeeSummary(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [unSelectedSuppliers, setUnSelectedSuppliers] = useState([]);
  const [totalSuppliers, setTotalSuppliers] = useState(0);
  const [clickState, setClickState] = useState(1);
  const [imageName, setImageName] = useState('');
  const refugeeSummary = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.getRefugeeSummaryReducer?.data
  );
  const loading = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.getRefugeeSummaryReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.getRefugeeSummaryReducer?.errMsg
  );
  const add_confirmation = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.assignUnassignRolesToRefugeeReducer
  );

  useEffect(() => {
    dispatch(Actions.getRefugeeSummary(sessionStorage.getItem("user_id")));
    return () => {
      dispatch(Actions.resetRefugeeSummary(true));
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers, clickState]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedSuppliers")) {
      setUnSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("unAssignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers, clickState]);

  const handleClickSuccessDialogclose = () => {
    setSuccessOpen(false);
  };

  const handleClickSuccessDialogopen = (data) => {
    setSuccessOpen(true);
  };

  const handleClickEdit = (path) => {
    navigate(path);
  };

  const handleClickEditUser = () => {
    sessionStorage.setItem("user_type", "refugee");
    navigate("/editUserByMentor");
  };

  const handleNavigateToDashboard = () => {
    sessionStorage.clear();
    navigate("/mentordashboard");
  };

  const handleClickSave = () => {
    setClickState(true);
    const values = {
      refugee_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      amentorIds: [],
      asupplierIds: [],
      umunicipalityIds: [],
      umentorIds: [],
      usupplierIds: [],
    };
    if (selectedSuppliers.length > 0) {
      for (let i = 0; i < selectedSuppliers.length; i++) {
        const element = selectedSuppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (unSelectedSuppliers.length > 0) {
      for (let i = 0; i < unSelectedSuppliers.length; i++) {
        const element = unSelectedSuppliers[i];
        values.usupplierIds.push(element?.id);
      }
    }

    dispatch(Actions.assignUnassignRolesToRefugee(values));
  };
  useEffect(() => {
    if (refugeeSummary?.getRefugeeSummary?.profileImageUrl) {
      const profileImageUrl = refugeeSummary?.getRefugeeSummary?.profileImageUrl;
      const urlSegments = profileImageUrl.split('/');
      const filenameWithExtension = urlSegments[urlSegments.length - 1];
      const filenameParts = filenameWithExtension.split('-');
      const extractedName = filenameParts[filenameParts.length - 1];
      setImageName(extractedName);
    }
  }, [refugeeSummary]);
  useEffect(() => {
    setClickState(true);
    const suppliers = JSON.parse(sessionStorage.getItem("assignedSuppliers"));
    const unsuppliers = JSON.parse(
      sessionStorage.getItem("unAssignedSuppliers")
    );

    const values = {
      refugee_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      amentorIds: [],
      asupplierIds: [],
      umunicipalityIds: [],
      umentorIds: [],
      usupplierIds: [],
    };
    if (suppliers?.length > 0) {
      for (let i = 0; i < suppliers?.length; i++) {
        const element = suppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (unsuppliers?.length > 0) {
      for (let i = 0; i < unsuppliers?.length; i++) {
        const element = unsuppliers[i];
        values.usupplierIds.push(element?.id);
      }
    }

    dispatch(Actions.assignUnassignRolesToRefugee(values));
    sessionStorage.removeItem("assignedSuppliers")
    sessionStorage.removeItem("unAssignedSuppliers")
  }, []);
  useEffect(() => {
    dispatch(Actions.getBredeintake(sessionStorage.getItem("user_id")));
  }, [dispatch, refugeeSummary]);

  const brede_confirmation = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.BredeintakeReducer?.data
  );

  useEffect(() => {
    if (add_confirmation?.data?.data?.assignUnassignRolesToRefugee) {
      sessionStorage.removeItem("assignedSuppliers")
      sessionStorage.removeItem("unAssignedSuppliers")
    }
  }, [add_confirmation]);

  useEffect(() => {
    if (refugeeSummary?.getRefugeeSummary) {
      setTotalSuppliers(
        refugeeSummary.getRefugeeSummary.totalSuppliers +
          selectedSuppliers.length -
          unSelectedSuppliers.length
      );
    }
  }, [refugeeSummary, selectedSuppliers, unSelectedSuppliers]);
  useEffect(() => {
    if (sessionStorage.getItem("EditOrSave")) {
    } else {
      sessionStorage.setItem("EditOrSave", false);
    }
  }, []);
  const UrlBI = brede_confirmation?.getBredeIntakeFile?.bredeIntakeFileUrl;
  const filenameParts =
    refugeeSummary?.getRefugeeSummary?.bredeIntakeFile.split("-");
  const displayedFilename =
    filenameParts?.length > 1
      ? filenameParts?.slice(1).join("-").trim()
      : refugeeSummary?.getRefugeeSummary?.bredeIntakeFile;
  
  
  return (
    <div className="main">
      {successOpen === true && (
        <SuccessDialog
          status={successOpen}
          methodd={handleClickSuccessDialogclose}
          role="Refugee"
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <div className="edit-municipality-back-btn-div"></div>
          <div className="edit-municipality-detail-view-div">
            <h3 className="edit-municipality-detail-view-title">
              {t("Refugee")}
            </h3>
            {refugeeSummary && refugeeSummary.getRefugeeSummary && (
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Name")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.name}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("BSN")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.bsn}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={3}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Email")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.email}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Phone")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.phoneNumber}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={6}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("POI (DISK) Link")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.municipality ? (
                        <a
                          target="_blank"
                          href={
                            refugeeSummary.getRefugeeSummary.municipality
                              .poiLink
                          }
                        >
                          {
                            refugeeSummary.getRefugeeSummary.municipality
                              .poiLink
                          }
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Brede Intake")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      <a target="_blank" href={UrlBI} rel="noreferrer">
                      {refugeeSummary.getRefugeeSummary.profileImageName}
                      </a>
                    </span>
                  </p>
                  <div className="edit-municipality-detail-view-image-div">
                    <p className="edit-municipality-detail-view-logo-label">
                      {t("Photo")}:&nbsp;
                    </p>
                    <p className="edit-municipality-detail-view-logo-text">
                    {refugeeSummary.getRefugeeSummary.bredeIntakeName}
                    </p>
                    <div className="edit-municipality-detail-view-image-span">
                      <img
                        alt="logo"
                        className="edit-municipality-detail-view-image"
                        src={refugeeSummary.getRefugeeSummary.profileImageUrl}
                      ></img>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}></Grid>
          </div>

          <div className="edit-municipality-detail-view-div">
            <Grid container spacing={2}>
              <Grid item sm={8}>
                <AssignSupplierToRefugee
                  setClickState={setClickState}
                  clickState={clickState}
                  disabled={true}
                />
              </Grid>

              <Grid item sm={4}></Grid>
              {add_confirmation.errMsg && (
                <Grid item sm={12} xs={12}>
                  <Alert severity="error">{t(add_confirmation.errMsg)}</Alert>
                </Grid>
              )}
              {add_confirmation.isLoading && (
                <Grid item sm={12} xs={12}>
                  Loading.....
                </Grid>
              )}
            </Grid>
          </div>

          <Grid container spacing={2} className={classes.btnsCon}>
            <Grid item sm={8} xs={12}></Grid>
            <Grid item sm={2} xs={12}></Grid>
            <Grid item sm={2} xs={12}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => handleClickEditUser()}
                color="primary"
              >
                {t("Edit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withReducer(
  "ViewEditRefugeeSummaryReducer",
  reducer
)(ViewEditRefugeeSummary);
