import React, { useState, useEffect, useRef } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import checkIcon from "../../../assets/images/Tick_Mark_Icon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Radio from "@material-ui/core/Radio";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CustomScrollbar from "../../shared/CustomScrollbar/index"
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";
const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    boxShadow: "none",
  },
  customScrollbar: {
    maxHeight: "200px",
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
    },
    /* For Firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5)',
    /* For Edge */
    '-ms-overflow-style': 'none',
    'scrollbar-3dlight-color': 'rgba(255, 255, 255, 0.5)',
    'scrollbar-arrow-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-base-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-darkshadow-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-face-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-highlight-color': 'rgba(255, 255, 255, 0.5)',
    'scrollbar-shadow-color': 'rgba(0, 0, 0, 0.5)',
  },
  listItem: {
    "&.MuiListItem-gutters": {
      marginLeft: "-26px",
      marginRight: "16px",
    },
  },
  filterContinueButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    float: "right",
    marginRight: "15px",
    boxShadow: "none",
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  dropdown: {
    position: "absolute",
    // width: "155px",
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    height: "226px",
  },
  chkroot: {
    padding: 2,
  },
  icon: {
    border: "1px solid #454A92",
    borderRadius: 0,
    width: "18px",
    height: "18px",
  },
  checkedIcon: {
    backgroundColor: "#ffffff",
    "&:before": {
      display: "block",
      width: "18px",
      height: "18px",
      backgroundImage: `url(${checkIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "14px 14px",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffffff",
    },
  },
}));
// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.chkroot}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      color="primary"
      {...props}
    />
  );
}
function AssignMentorToRefugeeMentorsData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const timer = useRef();
  const [mentors, setMentors] = useState({});
  const [selectedMentorIds, setSelectedMentorIds] = useState([]);
  const trackRef = useRef();
  const thumbRef = useRef();
  const contentRef = useRef();

  const handleScrollContent = () => {
    const thumbEle = thumbRef.current;
    const contentEle = contentRef.current;
    if (!thumbEle || !contentEle) {
      return;
    }
    thumbEle.style.top = `${(contentEle.scrollTop * 100) /
      contentEle.scrollHeight}%`;
  };

  const handleClickTrack = (e) => {
    const trackEle = trackRef.current;
    const contentEle = contentRef.current;
    if (!trackEle || !contentEle) {
      return;
    }
    const bound = trackEle.getBoundingClientRect();
    const percentage = (e.clientY - bound.top) / bound.height;
    contentEle.scrollTop =
      percentage * (contentEle.scrollHeight - contentEle.clientHeight);
  };
  useEffect(() => {
    const thumbEle = thumbRef.current;
    const contentEle = contentRef.current;
    if (!thumbEle || !contentEle) {
      return;
    }
    const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
    thumbEle.style.height = `${scrollRatio * 100}%`;
  }, []);
  const handleMouseDown = (e) => {
    const ele = thumbRef.current;
    const contentEle = contentRef.current;
    if (!ele || !contentEle) {
        return;
    }
    const startPos = {
        top: contentEle.scrollTop,
        x: e.clientX,
        y: e.clientY,
    };
    const updateCursor = (ele) => {
      ele.style.cursor = "grabbing";
      ele.style.userSelect = "none";
      document.body.style.cursor = "grabbing";
      document.body.style.userSelect = "none";
    };
  
   
    const resetCursor = (ele) => {
      ele.style.cursor = "grab";
      ele.style.userSelect = "";
      document.body.style.cursor = "";
      document.body.style.userSelect = "";
    };
    const handleMouseMove = (e) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
        contentEle.scrollTop = startPos.top + dy / scrollRatio;
        updateCursor(ele);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor(ele);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
};
const handleTouchStart = (e) => {
  const ele = thumbRef.current;
  const contentEle = contentRef.current;
  if (!ele || !contentEle) {
    return;
  }
  const touch = e.touches[0];
  const startPos = {
    top: contentEle.scrollTop,
    x: touch.clientX,
    y: touch.clientY,
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const dy = touch.clientY - startPos.y;
    const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
    contentEle.scrollTop = startPos.top + dy / scrollRatio;
    updateCursor(ele);
};


  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
    resetCursor(ele);
  };

  document.addEventListener("touchmove", handleTouchMove);
  document.addEventListener("touchend", handleTouchEnd);
};

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedMentors(true));
    };
  }, []);

  useEffect(() => {
    let municipalities = JSON.parse(
      sessionStorage.getItem("assignedMunicipalities")
    );
    let municipalityId =
      municipalities && municipalities.length > 0
        ? municipalities[0]?.id
        : undefined;
    const storedMentorIds = JSON.parse(
      localStorage.getItem("selectedMentorIds")
    );
    dispatch(
      Actions.getAllMiniSearchedMentors(
        page + 1,
        rowsPerPage,
        props.searchText,
        "",
        municipalityId
      )
    );
    setSelectedMentorIds(storedMentorIds || []);
  }, [rowsPerPage, page, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const existingSelectedIds =
      JSON.parse(localStorage.getItem("selectedMentorIds")) || [];
    const randomNumber = Math.floor(Math.random() * 15) + 1;
    const newString = "hi".replace(/hi/, randomNumber);
    props.setStateRefugee(newString);

    const randomNumberTwo = Math.floor(Math.random() * 15) + 1;
    const newStringTwo = "hi".replace(/hi/, randomNumberTwo);
    props.setStatePost(newStringTwo);
    if (props.setClickState) {
      props?.setClickState(newStringTwo);
    }
    let newObj = Object.assign({}, mentors);
    for (let i = 0; i < newObj.docs.length; i++) {
      const element = newObj.docs[i];
      if (i !== index) {
        element.isSelected = false;
      }
    }
    newObj.docs[index].isSelected = !mentors.docs[index].isSelected;
    if (newObj.docs[index].isSelected) {
      let newElement = {
        bsn: newObj.docs[index].bsn,
        id: newObj.docs[index]?.id,
        name: newObj.docs[index].name,
      };
      props.setSelectedMentors([newElement]);
    }
    if (!newObj.docs[index].isSelected) {
      props.setSelectedMentors(
        props.selectedMentors.filter(
          (item) => item?.id !== newObj.docs[index]?.id
        )
      );
    }
    const isSelectedCount = newObj.docs.filter(
      (item) => item.isSelected
    ).length;

    if (isSelectedCount === 1) {
      props?.setButtonDisabled(false);
    } else {
      props?.setButtonDisabled(true);
    }
    const newSelectedIds = newObj.docs
      .filter((mentor) => mentor.isSelected)
      .map((mentor) => mentor.id);
    const combinedIds = Array.from(
      new Set([...existingSelectedIds, ...newSelectedIds])
    );
    localStorage.setItem("selectedMentorIds", JSON.stringify(combinedIds));
    setSelectedMentorIds(combinedIds);
    setMentors(newObj);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const SearchedMentors = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.data
  );
  const loading = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.isLoading
  );

  const errMsg = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.errMsg
  );

  useEffect(() => {
    timer.current = window.setTimeout(() => {
      if (
        SearchedMentors &&
        SearchedMentors.miniSearchAllMentors &&
        props.selectedMentors.length === 0
      ) {
        let mentorsObj = SearchedMentors.miniSearchAllMentors;
        if (mentorsObj.docs !== null && mentorsObj.docs.length > 0) {
          for (let i = 0; i < mentorsObj.docs.length; i++) {
            const doc = mentorsObj.docs[i];
            doc["isSelected"] = false;
          }
        }
        setMentors(mentorsObj);
      }
      if (
        SearchedMentors &&
        SearchedMentors.miniSearchAllMentors &&
        props.selectedMentors.length > 0
      ) {
        let selmentorsObj = SearchedMentors.miniSearchAllMentors;
        if (selmentorsObj.docs !== null && selmentorsObj.docs.length > 0) {
          for (let i = 0; i < selmentorsObj.docs.length; i++) {
            const seldoc = selmentorsObj.docs[i];
            seldoc["isSelected"] = false;
            for (let j = 0; j < props.selectedMentors.length; j++) {
              const selMentor = props.selectedMentors[j];
              if (seldoc?.id === selMentor?.id) {
                seldoc["isSelected"] = true;
              }
            }
          }
        }
        setMentors(selmentorsObj);
      }
    }, 1000);
  }, [SearchedMentors]);
  useEffect(() => {
    if (
      SearchedMentors &&
      SearchedMentors.miniSearchAllMentors &&
      props.selectedMentors.length > 0 &&
      props.editCheck
    ) {
      let selmentorsObj = SearchedMentors.miniSearchAllMentors;
      if (selmentorsObj.docs !== null && selmentorsObj.docs.length > 0) {
        for (let i = 0; i < selmentorsObj.docs.length; i++) {
          const seldoc = selmentorsObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedMentors.length; j++) {
            const selMentor = props.selectedMentors[j];
            if (seldoc?.id === selMentor?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setMentors(selmentorsObj);
    }
  }, [props.editCheck, SearchedMentors]);
  useEffect(() => {
    if (
      SearchedMentors &&
      SearchedMentors.miniSearchAllMentors &&
      props.unAssignId
    ) {
      let selmentorsObj = SearchedMentors.miniSearchAllMentors;
      if (selmentorsObj.docs !== null && selmentorsObj.docs.length > 0) {
        for (let i = 0; i < selmentorsObj.docs.length; i++) {
          const seldoc = selmentorsObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedMentors.length; j++) {
            const selMentor = props.selectedMentors[j];
            if (seldoc?.id === selMentor?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setMentors(selmentorsObj);
      props.setUnAssignId(false);
    }
  }, [props.unAssignId, props.selectedMentors]);
  React.useEffect(() => {
    if (props?.mentorDont) {
      props.setSelectedMentors([]);
      sessionStorage.removeItem("assignedMentors");

      if (mentors.docs && mentors.docs.length > 0) {
        const updatedMentors = {
          ...mentors,
          docs: mentors.docs.map((mentor) => ({
            ...mentor,
            isSelected: false,
          })),
        };

        setMentors(updatedMentors);
      }
    }
  }, [props?.mentorDont]);

  let municipalities = JSON.parse(
    sessionStorage.getItem("assignedMunicipalities")
  );

  return (
    <div>
      <h3 style={{marginRight: "9px"}}>Select the Mentor</h3>
      <div >
        {municipalities?.length === undefined ? (
          <>
            <List>
              <ListItem>
                <ListItemText />
              </ListItem>
            </List>
          </>
        ) : (
          <>
          <CustomScrollbar>
          <List>
              <ListItem
                key="dont-assign"
                button
                className={classes.listItem}
                onClick={() => props.handleMentorAssignmentChange()}
                disabled={props?.TimeOut}
              >
                <Radio
                  checked={props?.mentorDont || props?.checkMentor}
                  onClick={(event) => {
                    event.stopPropagation();
                    props.handleMentorAssignmentChange();
                  }}
                  value="dont-assign"
                  name="MentorsRadio"
                />
                <ListItemText primary={t("Don’t assign to a Mentor")} />
              </ListItem>

              {mentors &&
                mentors.docs &&
                mentors.docs.length > 0 &&
                mentors.docs
                  .sort((a, b) => {
                    if (a.isSelected && !b.isSelected) return -1;
                    if (!a.isSelected && b.isSelected) return 1;
                    const isSelectedA = selectedMentorIds?.includes(a.id);
                    const isSelectedB = selectedMentorIds?.includes(b.id);
                    if (isSelectedA && !isSelectedB) return -1;
                    if (!isSelectedA && isSelectedB) return 1;

                    if (isSelectedA === isSelectedB) {
                      const nameA = a.name.toLowerCase();
                      const nameB = b.name.toLowerCase();
                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;
                      return 0;
                    }
                    return 0;
                  })
                  .map((doc, index) => (
                    <ListItem
                      key={doc.id}
                      button
                      className={classes.listItem}
                      onClick={() => handleChangeSelected(index)}
                      disabled={props?.mentorDont || props?.TimeOut}
                    >
                      <Radio
                        checked={doc.isSelected}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleChangeSelected(index);
                        }}
                        value={doc.id}
                        name="MentorsRadio"
                      />
                      <ListItemText primary={doc.name} />
                    </ListItem>
                  ))}
            </List>
          </CustomScrollbar>
          </>
        )}
      </div>
    </div>
  );
}
export default withReducer(
  "AssignMentorToRefugeeMentorsDataReducer",
  reducer
)(AssignMentorToRefugeeMentorsData);
