import client from "../../../../utils/client";
import { gql } from "apollo-boost";
import { logout } from "../../../login/store/actions/login.actions";
import jwt from "jsonwebtoken";
import { roles } from "../../../../utils/roles";
import moment from 'moment';
import { DATE_FORMAT } from "../../../../resources/constants";
import { pipPlanAttributes } from "../../../../resources/constants/graphqlAttributes";
// ACTION NAMES
// ****************************** / /

// GET PIPPLAN
export const GET_PIP_PLAN = "GET_PIP_PLAN";
export const PIP_PLAN_IS_LOADING = "PIP_PLAN_IS_LOADING";
export const PIP_PLAN_FAILED = "PIP_PLAN_FAILED";
export const RESET_PIP_PLAN = "RESET_PIP_PLAN";


// GET Municipality Summary
export const GET_MUNICIPALITY_SUMMARY = "GET_MUNICIPALITY_SUMMARY";
export const MUNICIPALITY_SUMMARY_IS_LOADING = "MUNICIPALITY_SUMMARY_IS_LOADING";
export const MUNICIPALITY_SUMMARY_FAILED = "MUNICIPALITY_SUMMARY_FAILED";
export const RESET_MUNICIPALITY_SUMMARY = "RESET_MUNICIPALITY_SUMMARY";



// GET PIP Plan  MAIN ACTION

export const pipPlanIsLoading = (ms) => ({
  type: PIP_PLAN_IS_LOADING,
  payload: ms,
});

export const pipPlanFailed = (ms) => ({
  type: PIP_PLAN_FAILED,
  payload: ms,
});

export const resetPipPlan = (ms) => ({
  type: GET_PIP_PLAN,
  payload: ms,
});


export function getPipPlanByRefugee(refugee) {
  return (dispatch) => {
    dispatch(pipPlanIsLoading(true));

    client
      .query({
        query: gql`
          {
            getPIPPlanByRefugee(refugee: "${refugee}") {
              ${pipPlanAttributes}
            }
          }
        `,
        fetchPolicy: 'network-only'
      })
      .then((response) => {
        dispatch({
          type: GET_PIP_PLAN,
          payload: response.data,
        });
      })

      .catch((err) => {
        if (err.networkError && err.networkError.result && err.networkError.result.errors && err.networkError.result.errors.length && err.networkError.result.errors[0] && err.networkError.result.errors[0].extensions && err.networkError.result.errors[0].extensions.code && err.networkError.result.errors[0].extensions.code === 401) {
          dispatch(logout());
        }
        else {
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            dispatch(
              pipPlanFailed(
                JSON.stringify(err.graphQLErrors[0].message)
              )
            );
          }
          else {
            dispatch(
              pipPlanFailed(
                "error in list or not found list"
              )
            );
          }
        }
      });
  };
}

export function getPipPlan(pipPlanId) {
  return (dispatch) => {
    dispatch(pipPlanIsLoading(true));
    client
      .query({
        query: gql`
          {
            getPIPPlan(pipPlanId: "${pipPlanId}") {
              ${pipPlanAttributes}
            }
          }
        `,
        fetchPolicy: 'network-only'
      })
      .then((response) => {
        dispatch({
          type: GET_PIP_PLAN,
          payload: response.data,
        });
      })

      .catch((err) => {
        if (err.networkError && err.networkError.result && err.networkError.result.errors && err.networkError.result.errors.length && err.networkError.result.errors[0] && err.networkError.result.errors[0].extensions && err.networkError.result.errors[0].extensions.code && err.networkError.result.errors[0].extensions.code === 401) {
          dispatch(logout());
        }
        else {
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            dispatch(
              pipPlanFailed(
                JSON.stringify(err.graphQLErrors[0].message)
              )
            );
          }
          else {
            dispatch(
              pipPlanFailed(
                "error in list or not found list"
              )
            );
          }
        }
      });
  };
}

export function createOrUpdateDraftPipPlan({ refugeeId, section }) {
  const date = moment().format(DATE_FORMAT);
  return client.mutate({
    mutation: gql`
      mutation {
        addPIPPlan ( input: {
          refugeeId: "${refugeeId}",
          pipPlan: {
            date: "${date}",
            ${section}
          }
        })
        {
          ${pipPlanAttributes}
        }
      }
    `,
    fetchPolicy: "no-cache"
  })
}

// GET getMunicipalitySummary  MAIN ACTION

export function getMunicipalitySummary(municipalityId) {
  return (dispatch) => {
    dispatch(municipalitySummaryIsLoading(true));

    client
      .query({
        query: gql`
          {
            getMunicipalitySummary(municipalityId: "${municipalityId}") {
              _id
              name
              province
              poiLink
              logoUrl
              manager {
                _id
                name
                email
                phoneNumber
              }
              contactPerson {
                name
              }
              totalMentors
              totalSuppliers
              totalRefugees
            }
          }
        `,
        fetchPolicy: 'network-only'
      })
      .then((response) => {
        dispatch({
          type: GET_MUNICIPALITY_SUMMARY,
          payload: response.data,
        });
      })

      .catch((err) => {
        if (err.networkError && err.networkError.result && err.networkError.result.errors && err.networkError.result.errors.length && err.networkError.result.errors[0] && err.networkError.result.errors[0].extensions && err.networkError.result.errors[0].extensions.code && err.networkError.result.errors[0].extensions.code === 401) {
          dispatch(logout());
        }
        else {
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            dispatch(
              municipalitySummaryFailed(
                JSON.stringify(err.graphQLErrors[0].message)
              )
            );
          }
          else {
            dispatch(
              municipalitySummaryFailed(
                "error in list or not found list"
              )
            );
          }
        }
      });
  };
}


// GET Municipality Summary HELPERS
export const municipalitySummaryIsLoading = (ms) => ({
  type: MUNICIPALITY_SUMMARY_IS_LOADING,
  payload: ms,
});

export const municipalitySummaryFailed = (ms) => ({
  type: MUNICIPALITY_SUMMARY_FAILED,
  payload: ms,
});

export const resetMunicipalitySummary = (ms) => ({
  type: RESET_MUNICIPALITY_SUMMARY,
  payload: ms,
});

