import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import io from "socket.io-client";
import nl from "../../../../../assets/images/nl.svg";
import en from "../../../../../assets/images/en.svg";
import Agendamodal from "../../municipality_pip/sub_components/Agendamodal";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import logob from "../../../../../assets/images/logob.png";
import bell from "../../../../../assets/images/bell.svg";
import header from "../../../../../assets/images/home-footer.png";
import { Button, Modal, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../../login/store/actions/login.actions";
import { useTranslation } from "react-i18next";
import Chatmodal from "../../municipality_pip/sub_components/Chatmodal";
const drawerWidth = 395;

const useStyles = makeStyles((theme) => ({
  link: {
    color: "white",
  },
  root: {
    flexGrow: 1,
    paddingTop: "0px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appbar: {
    backgroundImage: `url(${header})`,
    position: "relative",
    backgroundColor: "#454a92",
    height: "92px",
    zIndex: "1",
    boxShadow: "-1px 0px 16px 4px rgba(0, 0, 0, 0.65)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // boxShadow:
    //   "0px 7px 6px -1px rgba(0,0,0,0.2), 0px 6px 7px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
  },
  button: {
    // marginTop: "-60px",
    padding: "0px",
  },
  con: {
    // backgroundColor: "red"
  },
  tool: {
    // backgroundColor: "yellow",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  drawerPaper: {
    // position: "relative",
    width: drawerWidth,
    backgroundColor: "#FFFFFF",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  drawerHeader: {
    display: "inline-block",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  logout: {
    fontFamily: "opensans-bold",
    fontSize: "16px",
    float: "right",
    padding: "12px",
    marginTop: "10px",
    color: "#454a92",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchDiv: {
    width: "100%",
  },
  parentDiv: {
    width: "100%",
    // marginTop: "25px",
    backgroundColor: "#FFFFFF",
    color: "#454a92",
  },
  headDiv: {
    width: "100%",
    backgroundColor: "#454a92",
    height: "42px",
    fontFamily: "opensans-semibold",
    fontSize: "16px",
    alignItems: "center",
    display: "flex",
    lineHeight: "19px",
    textIndent: "32px",
    color: "#FFFFFF",
  },
  headDivWithNoChild: {
    width: "100%",
    backgroundColor: "#454a92",
    height: "42px",
    fontFamily: "opensans-semibold",
    fontSize: "16px",
    alignItems: "center",
    display: "flex",
    lineHeight: "19px",
    textIndent: "32px",
    color: "#FFFFFF",
    cursor: "pointer",
    marginTop: "2px",
  },
  childDiv: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: "42px",
    fontFamily: "opensans-semibold",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "left",
    display: "flex",
    lineHeight: "19px",
    textIndent: "32px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    backgroundColor: "#ffffff",
    border: 0,
  },
  notchedOutline: {
    borderWidth: "0px",
  },
  input1: {
    height: "30px",
    border: 0,
    "&::placeholder": {
      textAlign: "center",
      fontFamily: "Calibri",
    },
  },
  nested: {
    paddingLeft: "45px",
  },
  menuItem: {
    fontSize: 16,
    minHeight: 40,
    "& img": {
      marginRight: 10,
      height: 20,
      width: "auto",
    },
  },
  selectEmpty: {
    "&:before": {
      border: "none",
    },
    "& .MuiSelect-selectMenu": {
      display: "grid",
      color: "white",
    },
    "&:hover:not(.Mui-disabled):before": {
      border: "none",
    },
  },
  formControl: {
    width: "53px",
  },
}));
function Header(props) {
  const [t, i18n] = useTranslation();
  const navItems = [
    {
      id: "account_settings",
      headTitle: "Account Settings",
      list: false,
      hasChild: true,
      items: [
        {
          id: "account_settings_profile",
          name: "Update Profile",
          path: "/MentorUserProfile",
        },
        {
          id: "account_settings_update_password",
          name: "Update Password",
          path: "/updateUserPassword",
        },
      ],
    },
    {
      id: "all_system_users",
      headTitle: "All System Users",
      list: false,
      hasChild: true,
      items: [
        {
          id: "all_system_users_mentors",
          name: "Mentors",
          path: "/systemSearchByMentor",
        },
        {
          id: "all_system_users_suppliers",
          name: "Suppliers",
          path: "/systemSearchByMentor",
        },
        {
          id: "all_system_users_refugees",
          name: "Refugees",
          path: "/systemSearchByMentor",
        },
      ],
    },
    {
      id: "create_new_system_user",
      headTitle: "Create New System User",
      list: false,
      hasChild: false,
      path: "/createUserByMentor",
    },
    {
      id: "system_search",
      headTitle: "System Search",
      list: false,
      hasChild: false,
      path: "/systemSearchByMentor",
    },
  ];
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [navId, setNavId] = useState("");
  const [list, setlist] = useState(navItems);
  const navigate = useNavigate();
  const socket = React.useRef();
  const [messages, setMessages] = useState([]);
  const [refugee, setRefugee] = useState([]);
  const [chatopen, setChatopen] = useState(false);
  const [agenda, setAgenda] = useState([]);
  const [agendaRefugee, setAgendaRefugee] = useState([]);
  const [agendaopen, setAgendaopen] = useState(false);
  const [chatUser, setChatUser] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language") || "en"
  );
  const [selectedFlag, setSelectedFlag] = React.useState({});
  const [selectedRefugee, setSelectedRefugee] = useState([]);
  const email = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  const id = localStorage.getItem("mentor")
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(Actions.logout());
  };

  const [flags, setFlags] = React.useState([
    { id: "en", url: en, label: "EN", selected: true },
    { id: "nl", url: nl, label: "NL", selected: false },
    // { id: "arb", url: arb, label: "ARB", selected: false },
    // { id: "fr", url: fr, label: "FR", selected: false },
    // { id: "be", url: be, label: "BE", selected: false }
  ]);

  function join() {
    let mentorId = localStorage.getItem("mentor");

    socket.current.emit(
      "join",
      {
        receiverId: mentorId,
        dataId: "test",
      },

      (res) => {
        if (res.refugees) {
          setRefugee(res.refugees);
        }

        setAgendaRefugee(res.refugeesAgenda);

        setAgenda(res.uniqueAgendas);
        setMessages(res.messages);
      }
    );
  }
  React.useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
      path: "/chat",
      query: {
        token: localStorage.jwtToken,
      },
    });
    socket.current.on("connect", () => {
      join();
    });
    setInterval(() => {
      join();
    }, 2200);
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("language")) {
      let newData = [...flags];
      for (let i = 0; i < newData.length; i++) {
        const data = newData[i];
        if (data?.id === localStorage.getItem("language")) {
          newData[i].selected = true;
        } else {
          newData[i].selected = false;
        }
      }
      setFlags(newData);
    }
  }, []);

  React.useEffect(() => {
    if (selectedFlag && ["en", "nl"].includes(selectedFlag.id)) {
      i18n.changeLanguage(selectedFlag.id);
      localStorage.setItem("language", selectedFlag.id);
    }
  }, [selectedFlag, i18n]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    const selectedFlag = flags.find((flag) => flag.id === selectedLanguage);

    setSelectedLanguage(selectedLanguage);
    setSelectedFlag(selectedFlag);

    localStorage.setItem("language", selectedLanguage);
  };

  const handleNavigateToDashboard = () => {
    sessionStorage.clear();
    navigate("/mentordashboard");
  };
  const handleNavigate = (path, id = "") => {
    toggleDrawer();
    sessionStorage.clear();
    if (id === "all_system_users_refugees") {
      navigate(`${path}/?entity=refugees`);
    } else if (id === "all_system_users_mentors") {
      navigate(`${path}/?entity=mentors`);
    } else if (id === "all_system_users_suppliers") {
      navigate(`${path}/?entity=suppliers`);
    } else {
      navigate(path);
    }
  };
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  const handleClick = (id, first, second) => {
    // 1. Make a shallow copy of the items
    let items = [...list];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[first]["items"][second] };
    // 3. Replace the property you're intested in
    item.open = !item.open;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[first]["items"][second] = item;
    // 5. Set the state to our new copy
    setlist(items);
    setNavId(id);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (messages.length >= 1 && agenda.length >= 1) {
      setOpen(true);
    } else if (messages.length >= 2) {
      setOpen(true);
    } else if (agenda.length >= 2) {
      setOpen(true);
    } else if (agenda.length == 1) {
      const singleAgenda = agendaRefugee[0];
      const agendaSend = agenda[0];
      agendaSend.id = id
      localStorage.setItem("selected", JSON.stringify(agenda[0]));
      socket.current.emit("savedAgenda", { clickedMessage: agendaSend });
      setSelectedRefugee(singleAgenda);
      setAgendaopen(true);
    } else {
      setChatUser(refugee[0]);
      sessionStorage.setItem("defaultValue", messages[0].supplierName);
      setChatopen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleTypographyClick = (clickedMessage) => {
    if (clickedMessage.startDate) {
      clickedMessage.id = id
      socket.current.emit("savedAgenda", { clickedMessage });
      agendaRefugee.forEach((value) => {
        if (value._id === clickedMessage.refugeeId) {
          setSelectedRefugee(value);
          localStorage.setItem("selected", JSON.stringify(clickedMessage));
        }
        setAgendaopen(true);
      });
    } else {
      refugee.forEach((value) => {
        if (value.id === clickedMessage.refugee) {
          setChatUser(value);
        }
      });

      sessionStorage.setItem("defaultValue", clickedMessage.supplierName);

      setChatopen(true);
    }
  };

  return (
    <div className={classes.root}>
      {chatopen === true && chatUser && (
        <Chatmodal status={chatopen} methodd={setChatopen} data={chatUser} />
      )}
      {agendaopen === true && selectedRefugee && (
        <Agendamodal
          status={agendaopen}
          methodd={setAgendaopen}
          data={selectedRefugee}
        />
      )}
      <AppBar position="static" className={classes.appbar}>
        <Container fixed className={classes.con}>
          <Toolbar className={classes.tool}>
            <Typography variant="div" className={classes.title}>
              <img
                className="blockh-logob"
                onClick={() => handleNavigateToDashboard()}
                src={logob}
                alt="logob"
              ></img>
            </Typography>
            {(messages?.length !== 0 || agenda?.length !== 0) && (
              <div
                style={{
                  marginTop: "-60px",
                  marginRight: "30px",
                  width: "90px",
                }}
              >
                <img
                  onClick={handleOpen}
                  style={{ width: "22px", height: "26px", cursor: "pointer" }}
                  src={bell}
                />
              </div>
            )}

            <div
              style={{ marginTop: "-60px", marginRight: "30px", width: "90px" }}
            >
              <FormControl className={classes.formControl}>
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  IconComponent={ArrowDropDownIcon}
                >
                  {flags.map((flag) => (
                    <MenuItem
                      key={flag.id}
                      value={flag.id}
                      className={classes.menuItem}
                    >
                      <img
                        src={flag.url}
                        alt={flag.label}
                        className={classes.flagIcon}
                      />
                      {flag.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="menu-div">
              <IconButton
                className={classes.button}
                color="inherit"
                aria-label="Menu"
                onClick={toggleDrawer}
              >
                <MenuIcon style={{ fontSize: "50px" }} />
              </IconButton>
              <p className="menu-text">{t("MENU")}</p>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 400,
            bgcolor: "white",
            borderRadius: 8,
            p: 3,
            textAlign: "center",
            position: "absolute",
            right: "75px",
            top: "65px",
            outline: "none",
            boxShadow: 4,
            maxHeight: "160px", // Set a maximum height for the Box
            overflowY: "auto", // Enable vertical scrolling if content exceeds maxHeight
          }}
        >
          <Typography
            style={{ marginBottom: "15px" }}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Notifications
          </Typography>
          {[...messages, ...agenda].map((item, index) => (
            <Typography
              key={index}
              style={{
                textAlign: "left",
                marginTop: index === 0 ? 0 : "15px",
                borderBottom: "1px solid #e0e0e0",
                paddingBottom: "10px",
                cursor: "pointer",
              }}
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1 }}
              onClick={() => handleTypographyClick(item)}
            >
              {item.type === "text"
                ? `New message from ${item.supplierName}`
                : `Event: ${item.title} is just around the corner, starting in just ${item.reminderBefore} minutes!`}
            </Typography>
          ))}
        </Box>
      </Modal>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer} style={{ float: "left" }}>
            <ChevronRightIcon fontSize="large" style={{ color: "#454a92" }} />
          </IconButton>

          <Typography
            className={classes.logout}
            component="h5"
            variant="h5"
            onClick={() => logout()}
          >
            {t("Logout")}
          </Typography>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              component="h6"
              variant="h6"
              style={{
                marginRight: "10px",
                color: "#454a92",
                fontSize: "16px",
              }}
            >
              {name}
            </Typography>
            <Typography
              component="span"
              style={{ color: "#454a92", fontSize: "16px" }}
            >
              {email}
            </Typography>
          </div>
        </div>

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <div className={classes.parentDiv}>
            {list.map((doc, firstIndex) =>
              !doc.list && doc.hasChild ? (
                <>
                  <div className={classes.headDiv}>{t(doc.headTitle)}</div>
                  {doc.items.map((item) => (
                    <div
                      key={item?.id}
                      className={classes.childDiv}
                      onClick={() => handleNavigate(item.path, item?.id)}
                    >
                      {t(item.name)}
                    </div>
                  ))}
                </>
              ) : !doc.list && !doc.hasChild ? (
                <div
                  className={classes.headDivWithNoChild}
                  onClick={() => handleNavigate(doc.path)}
                >
                  {t(doc.headTitle)}
                </div>
              ) : (
                <>
                  <div className={classes.headDiv}>{t(doc.headTitle)}</div>
                  {doc.items.map((item, secondIndex) => (
                    <>
                      <ListItem
                        button
                        onClick={() =>
                          handleClick(item?.id, firstIndex, secondIndex)
                        }
                      >
                        <ListItemText
                          disableTypography
                          component
                          primary={
                            <Typography
                              type="body2"
                              style={{
                                fontFamily: "opensans-semibold",
                                fontSize: "16px",
                                textIndent: "18px",
                              }}
                            >
                              {t(item.name)}
                            </Typography>
                          }
                        />
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={item.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.items_child.map((child) => (
                            <ListItem button className={classes.nested}>
                              <ListItemText
                                onClick={() => handleNavigate(child.path)}
                                primary={
                                  <Typography
                                    type="body2"
                                    style={{
                                      fontFamily: "opensans-regular",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {t(child.name)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ))}
                </>
              )
            )}
          </div>
        </List>
      </Drawer>
    </div>
  );
}
export default Header;
