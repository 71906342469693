import { Grid } from "@material-ui/core";
import React from "react";
import GraphData from "./GraphData";

function RightCont({
  classes,
  details,
  handleClose,
  setTotalProgress,
  totalProgress,
  t,
}) {
  if (details) {
    return (
      <Grid
        container
        spacing={2}
        item
        lg={8}
        md={12}
        sm={12}
        xs={12}
        className={classes.con1}
      >
        <GraphData
          classes={classes}
          details={details}
          handleClose={handleClose}
          setTotalProgress={setTotalProgress}
          totalProgress={totalProgress}
          t={t}
        />
      </Grid>
    );
  }
  return null;
}

export default RightCont;
