import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import DatePicker from "../../../shared/DatePicker";
import PhoneInputNumber from "../../../shared/PhoneInputNumber";
import { COUNT_CHILDRENS } from "../../../../resources/constants";
import { pipDetailsPageSchema } from "../../../../resources/scheme";
import {
  createOrUpdateDraftPipPlan,
  getPipPlanByRefugee,
  pipPlanFailed,
} from "../../store/actions";
import { removeKey, isAdult, formatDate } from "../../../../utils/helpers";
import { pipPlanNavigationKeys, GENDER } from "../../../../resources/constants";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "center",
  },
  subTitle: {
    fontSize: "10px",
    color: "#253c8c",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "fit-content",
  },
  buttonStyle: {
    mt: 3,
    mb: 2,
    width: "auto",
    marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9,
    },
  },
  marginChildcareFor: {
    marginTop: "16px",
  },
}));

function DetailsForm({ data, setPipPlanPage }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState("");
  const classes = useStyles();

  const params = useParams();
  const { refugeeId } = params;

  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.socialGuidance);
  };
  const navigatePreviousPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.frontPage);
  };
  const formik = useFormik({
    initialValues: {
      myDetails: {
        firstName: data?.myDetails.firstName ?? "",
        lastName: data?.myDetails.lastName ?? "",
        gender: data?.myDetails.gender ?? "",
        birthDate: data?.myDetails.birthDate ?? "",
        streetAndHouseNumber: data?.myDetails.streetAndHouseNumber ?? "",
        postalCode: data?.myDetails.postalCode ?? "",
        place: data?.myDetails.place ?? "",
        phoneNumber: data?.myDetails.phoneNumber ?? "",
        emailAddress: data?.myDetails.emailAddress ?? "",
      },
      dataPartner: {
        firstName: data?.dataPartner.firstName ?? "",
        lastName: data?.dataPartner.lastName ?? "",
        gender: data?.dataPartner.gender ?? "",
        birthDate: data?.dataPartner.birthDate ?? "",
        phoneNumber: data?.dataPartner.phoneNumber ?? "",
        emailAddress: data?.dataPartner.emailAddress ?? "",
      },
      childrenData: {
        haveChildren: data?.childrenData.haveChildren ?? null,
        countChildren: data?.childrenData.countChildren ?? null,
        childrens: data?.childrenData?.childrens ?? [],
      },
    },
    enableReinitialize: true,
    validationSchema: pipDetailsPageSchema,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        const queryString = JSON.stringify(
          removeKey(values, "__typename"),
          null,
          2
        ).replace(/"([^"]+)":/g, "$1:");
        const detailsQuery = `details: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: detailsQuery })
          .then(() => {
            dispatch(getPipPlanByRefugee(refugeeId));
            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.frontPage);
            } else {
              setPipPlanPage(pipPlanNavigationKeys.socialGuidance);
            }
          })
          .catch((err) => {
            dispatch(pipPlanFailed(err.graphQLErrors[0].message));
          })
          .finally(() => {
            helpers.setSubmitting(false);
          });
      } else {
        helpers.setSubmitting(false);

        if (selectedPage === "saveAndPrevious") {
          navigatePreviousPage();
        } else {
          navigateNextPage();
        }
      }
    },
  });

  const getBooleanValue = (value) => {
    if (value == "true") return true;
    return false;
  };

  const onChildrenCountSelect = (event) => {
    const childrenCount = Number(event.target.value);
    const childrens = formik.values?.childrenData?.childrens;
    const initialChildrensCount =
      childrens?.length && childrenCount > childrens.length
        ? childrenCount - childrens.length
        : childrenCount;

    const childrensInitialValues = Array(initialChildrensCount)
      .fill()
      .map(() => ({
        childName: "",
        birthDate: "",
        needChildcare: null,
        organized: null,
        organizedDeadline: null,
      }));

    if (childrens?.length) {
      if (childrenCount > childrens.length) {
        const updatedListChildrens = [...childrens].concat(
          childrensInitialValues
        );
        formik.setFieldValue("childrenData.childrens", updatedListChildrens);
      } else if (childrenCount < childrens.length) {
        const diffCount = childrens.length - childrenCount;
        formik.setFieldValue(
          "childrenData.childrens",
          [...childrens].splice(0, childrens.length - diffCount)
        );
      }
    } else {
      formik.setFieldValue("childrenData.childrens", childrensInitialValues);
    }
    formik.setFieldValue("childrenData.countChildren", childrenCount);
  };

  const onDoYouHaveAnyChildrenHandler = (event) => {
    const { name, value } = event.target;
    if (!getBooleanValue(value)) {
      formik.setFieldValue("childrenData.countChildren", null);
      formik.setFieldValue("childrenData.childrens", null);
    }
    formik.setFieldValue(name, getBooleanValue(value));
  };

  const onNeedChildcareHandler = (event, childSection) => {
    const { value } = event.target;
    const needChildcare = getBooleanValue(value);
    formik.setFieldValue(`${childSection}.childcareFor`, null);
    formik.setFieldValue(`${childSection}.comments`, null);
    formik.setFieldValue(`${childSection}.organizedDeadline`, null);
    formik.setFieldValue(`${childSection}.organized`, null);
    formik.setFieldValue(`${childSection}.needChildcare`, null);
    formik.setFieldValue(`${childSection}.needChildcare`, needChildcare);
  };

  const onOrganizedHandler = (event, childSection) => {
    const booleanValue = getBooleanValue(event.target.value);
    if (booleanValue) {
      formik.setFieldValue(`${childSection}.childcareFor`, {
        childcareStartDate: "",
        childcareName: "",
        streetAndHouseNumber: "",
        postalCode: "",
        place: "",
        phoneNumber: "",
        emailAddress: "",
      });
      formik.setFieldValue(`${childSection}.organizedDeadline`, null);
    } else {
      formik.setFieldValue(`${childSection}.childcareFor`, null);
    }
    formik.setFieldValue(`${childSection}.organized`, booleanValue);
  };

  const onChangeChildDate = (event, childSection) => {
    const { name, value } = event.target;
    if (isAdult(value)) {
      formik.setFieldValue(`${childSection}.childcareFor`, null);
      formik.setFieldValue(`${childSection}.comments`, null);
      formik.setFieldValue(`${childSection}.organizedDeadline`, null);
      formik.setFieldValue(`${childSection}.organized`, null);
      formik.setFieldValue(`${childSection}.needChildcare`, null);
    }
    formik.setFieldValue(name, value);
  };

  const onPhoneNumberChange = (phone, name) => {
    formik.setFieldValue(name, `+${phone}`);
  };

  return (
    <Grid container>
      <form noValidate style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <Grid container item justifyContent='center'>
          <Box pl={10}>
            <Grid
              item
              container
              spacing={1}
              alignContent='center'
              justifyContent='center'
              alignItems='flex-start'
              style={{
                marginBottom: "40px",
              }}
            >
              {/* My  details */}
              <Grid item container xs={6} justifyContent='center'>
                <Grid container item>
                  <Grid item>
                    <h2 className={classes.title}>{t("My details")}</h2>
                  </Grid>
                </Grid>
                <Grid item xs={12} alignContent='left'>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='myDetails.firstName'
                    label={t("First Name")}
                    onBlur={formik.handleBlur}
                    value={formik.values.myDetails.firstName}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(formik.touched?.myDetails?.firstName) &&
                      t(formik?.errors?.myDetails?.firstName)
                    }
                    error={
                      Boolean(formik.touched?.myDetails?.firstName) &&
                      Boolean(formik.errors?.myDetails?.firstName)
                    }
                  />
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='myDetails.lastName'
                    label={t("Last Name")}
                    onBlur={formik.handleBlur}
                    value={formik.values.myDetails.lastName}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(formik.touched?.myDetails?.lastName) &&
                      t(formik?.errors?.myDetails?.lastName)
                    }
                    error={
                      Boolean(formik.touched?.myDetails?.lastName) &&
                      Boolean(formik.errors?.myDetails?.lastName)
                    }
                  />
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <FormControl
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    required
                    error={
                      Boolean(formik.touched?.myDetails?.gender) &&
                      Boolean(formik.errors?.myDetails?.gender)
                    }
                  >
                    <InputLabel>
                      {Boolean(formik.touched?.myDetails?.gender) &&
                      Boolean(formik.errors?.myDetails?.gender)
                        ? t(formik.errors?.myDetails?.gender)
                        : t("Gender")}
                    </InputLabel>
                    <Select
                      required
                      name='myDetails.gender'
                      label={t("Gender")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.myDetails.gender}
                    >
                      {GENDER.map((gender, index) => (
                        <MenuItem
                          key={`${gender}`.toLocaleLowerCase()}
                          value={gender}
                        >
                          {t(`${gender}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} alignContent='left' mb={2}>
                  <Box mb={2}>
                    <DatePicker
                      id='myDetails_birthDate'
                      name='myDetails.birthDate'
                      label={t("Birthday")}
                      value={formatDate(formik.values.myDetails?.birthDate)}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      helperText={
                        Boolean(formik.touched?.myDetails?.birthDate) &&
                        t(formik?.errors?.myDetails?.birthDate)
                      }
                      error={
                        Boolean(formik.touched?.myDetails?.birthDate) &&
                        Boolean(formik.errors?.myDetails?.birthDate)
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='myDetails.streetAndHouseNumber'
                    label={t("Street and house number")}
                    onBlur={formik.handleBlur}
                    value={formik.values.myDetails.streetAndHouseNumber}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(
                        formik.touched?.myDetails?.streetAndHouseNumber
                      ) && t(formik?.errors?.myDetails?.streetAndHouseNumber)
                    }
                    error={
                      Boolean(
                        formik.touched?.myDetails?.streetAndHouseNumber
                      ) &&
                      Boolean(formik.errors?.myDetails?.streetAndHouseNumber)
                    }
                  />
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='myDetails.postalCode'
                    label={t("Post code")}
                    onBlur={formik.handleBlur}
                    value={formik.values.myDetails.postalCode}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(formik.touched?.myDetails?.postalCode) &&
                      t(formik?.errors?.myDetails?.postalCode)
                    }
                    error={
                      Boolean(formik.touched?.myDetails?.postalCode) &&
                      Boolean(formik.errors?.myDetails?.postalCode)
                    }
                  />
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='myDetails.place'
                    label={t("Place")}
                    onBlur={formik.handleBlur}
                    value={formik.values.myDetails.place}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(formik.touched?.myDetails?.place) &&
                      t(formik?.errors?.myDetails?.place)
                    }
                    error={
                      Boolean(formik.touched?.myDetails?.place) &&
                      Boolean(formik.errors?.myDetails?.place)
                    }
                  />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <PhoneInputNumber
                    label={t("Phone number")}
                    onChange={onPhoneNumberChange}
                    name='myDetails.phoneNumber'
                    id={"myDetails.phoneNumber"}
                    value={formik.values.myDetails.phoneNumber}
                    disabled={formik.isSubmitting}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik?.touched?.myDetails?.phoneNumber) &&
                      Boolean(formik?.errors?.myDetails?.phoneNumber)
                    }
                    errorMessage={
                      Boolean(formik.touched?.myDetails?.phoneNumber) &&
                      t(formik?.errors?.myDetails?.phoneNumber)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='myDetails.emailAddress'
                    label={t("E-mail address")}
                    onBlur={formik.handleBlur}
                    value={formik.values.myDetails.emailAddress}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    helperText={
                      Boolean(formik.touched?.myDetails?.emailAddress) &&
                      t(formik?.errors?.myDetails?.emailAddress)
                    }
                    error={
                      Boolean(formik.touched?.myDetails?.emailAddress) &&
                      Boolean(formik.errors?.myDetails?.emailAddress)
                    }
                  />
                </Grid>
              </Grid>

              {/* Data partner */}
              <Grid item container xs={6} justifyContent='center'>
                <Grid container item>
                  <Grid item>
                    <h2 className={classes.title}>{t("Data partner")}</h2>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='dataPartner.firstName'
                    label={t("First Name")}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataPartner.firstName}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(formik.touched?.dataPartner?.firstName) &&
                      t(formik?.errors?.dataPartner?.firstName)
                    }
                    error={
                      Boolean(formik.touched?.dataPartner?.firstName) &&
                      Boolean(formik.errors?.dataPartner?.firstName)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='dataPartner.lastName'
                    label={t("Last Name")}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataPartner.lastName}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    helperText={
                      Boolean(formik.touched?.dataPartner?.lastName) &&
                      t(formik?.errors?.dataPartner?.lastName)
                    }
                    error={
                      Boolean(formik.touched?.dataPartner?.lastName) &&
                      Boolean(formik.errors?.dataPartner?.lastName)
                    }
                  />
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <FormControl
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    required
                    error={
                      Boolean(formik.touched?.dataPartner?.gender) &&
                      Boolean(formik.errors?.dataPartner?.gender)
                    }
                  >
                    <InputLabel>
                      {Boolean(formik.touched?.dataPartner?.gender) &&
                      Boolean(formik.errors?.dataPartner?.gender)
                        ? t(formik.errors?.dataPartner?.gender)
                        : t("Gender")}
                    </InputLabel>
                    <Select
                      required
                      name='dataPartner.gender'
                      label={t("Gender")}
                      onBlur={formik.handleBlur}
                      value={formik.values.dataPartner.gender}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                    >
                      {GENDER.map((gender, index) => (
                        <MenuItem
                          key={`${gender}`.toLocaleLowerCase()}
                          value={gender}
                        >
                          {t(`${gender}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} alignContent='left'>
                  <Box mb={2}>
                    <DatePicker
                      id='dataPartner_birthDate'
                      name='dataPartner.birthDate'
                      label={t("Birthday")}
                      value={formatDate(formik.values.dataPartner?.birthDate)}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      helperText={
                        Boolean(formik.touched?.dataPartner?.birthDate) &&
                        t(formik?.errors?.dataPartner?.birthDate)
                      }
                      error={
                        Boolean(formik.touched?.dataPartner?.birthDate) &&
                        Boolean(formik.errors?.dataPartner?.birthDate)
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <PhoneInputNumber
                    label={t("Phone number")}
                    onChange={onPhoneNumberChange}
                    name='dataPartner.phoneNumber'
                    id={"dataPartner.phoneNumber"}
                    value={formik.values.dataPartner.phoneNumber}
                    disabled={formik.isSubmitting}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.touched?.dataPartner?.phoneNumber) &&
                      Boolean(formik.errors?.dataPartner?.phoneNumber)
                    }
                    errorMessage={
                      Boolean(formik.touched?.dataPartner?.phoneNumber) &&
                      t(formik?.errors?.dataPartner?.phoneNumber)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    style={{ marginBottom: "20px", maxWidth: "300px" }}
                    fullWidth
                    name='dataPartner.emailAddress'
                    label={t("E-mail address")}
                    onBlur={formik.handleBlur}
                    value={formik.values.dataPartner.emailAddress}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    helperText={
                      Boolean(formik.touched?.dataPartner?.emailAddress) &&
                      t(formik?.errors?.dataPartner?.emailAddress)
                    }
                    error={
                      Boolean(formik.touched?.dataPartner?.emailAddress) &&
                      Boolean(formik.errors?.dataPartner?.emailAddress)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent='center'
              alignItems='flex-start'
              style={{
                marginBottom: "40px",
              }}
            >
              {/* Children's data */}
              <Grid item container xs={12} justifyContent='center'>
                <Grid container item xs={12}>
                  <Grid item>
                    <h2 className={classes.title}>{t(`Children's data`)}</h2>
                  </Grid>
                </Grid>
                <Grid item xs={12} alignContent='left'>
                  <FormControl
                    component='fieldset'
                    error={
                      Boolean(formik.touched?.childrenData?.haveChildren) &&
                      Boolean(formik.errors?.childrenData?.haveChildren)
                    }
                  >
                    <FormLabel component='legend'>{`${t(
                      "Do you have any children"
                    )}?`}</FormLabel>
                    <RadioGroup
                      aria-label='gender'
                      name='childrenData.haveChildren'
                      value={`${formik.values.childrenData?.haveChildren}`}
                      onChange={onDoYouHaveAnyChildrenHandler}
                      className={classes.radioGroup}
                      defaultChecked={false}
                      error={
                        Boolean(formik.touched?.childrenData?.haveChildren) &&
                        Boolean(formik.errors?.childrenData?.haveChildren)
                      }
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color='primary' />}
                        label={t("Yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color='primary' />}
                        label={t("No")}
                      />
                    </RadioGroup>
                    <FormLabel component='legend'>
                      {Boolean(formik.touched?.childrenData?.haveChildren) &&
                        t(formik?.errors?.childrenData?.haveChildren)}
                    </FormLabel>
                  </FormControl>
                </Grid>

                {formik.values.childrenData.haveChildren && (
                  <Grid item xs={12} alignContent='left'>
                    <FormControl
                      className={classes.margin}
                      fullWidth
                      style={{
                        marginBottom: "20px",
                        maxWidth: "300px",
                      }}
                      error={
                        Boolean(formik.touched?.childrenData?.countChildren) &&
                        Boolean(formik.errors?.childrenData?.countChildren)
                      }
                    >
                      <InputLabel id='demo-customized-select-label'>{`${t(
                        "How many children do you have"
                      )}?`}</InputLabel>
                      <Select
                        name='childrenData.countChildren'
                        labelId='demo-customized-select-label'
                        id='demo-customized-select'
                        value={formik.values.childrenData.countChildren}
                        onChange={onChildrenCountSelect}
                        selected={formik.values.childrenData.countChildren}
                      >
                        {COUNT_CHILDRENS.map((value) => (
                          <MenuItem value={value} key={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormLabel
                        component='legend'
                        style={{ marginTop: "6px" }}
                      >
                        {Boolean(formik.touched?.childrenData?.countChildren) &&
                          t(formik?.errors?.childrenData?.countChildren)}
                      </FormLabel>
                    </FormControl>
                  </Grid>
                )}

                {formik.values.childrenData?.childrens?.map(
                  (children, index) => (
                    <Grid container item xs={12}>
                      <Grid
                        item
                        container
                        flexDirection='column'
                        xs={6}
                        alignItems='flex-start'
                        height='fit-content'
                      >
                        <Grid item display='block'>
                          <Typography
                            display='inline'
                            variant='h6'
                            className={classes.title}
                          >
                            {`${t("Children")} ${index + 1}`}
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            name={`childrenData.childrens[${index}].childName`}
                            value={children.childName}
                            label={t("Name")}
                            onBlur={formik.handleBlur}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            helperText={
                              Boolean(
                                formik.touched?.childrenData?.childrens?.[index]
                                  ?.childName
                              ) &&
                              t(
                                formik?.errors?.childrenData?.childrens?.[index]
                                  ?.childName
                              )
                            }
                            error={
                              Boolean(
                                formik.touched?.childrenData?.childrens?.[index]
                                  ?.childName
                              ) &&
                              Boolean(
                                formik.errors?.childrenData?.childrens?.[index]
                                  ?.childName
                              )
                            }
                          />
                          <Box mb={2} mt={2}>
                            <DatePicker
                              id={`childrenData_childrens_${index}_birthDate`}
                              label={t("Birthday")}
                              name={`childrenData.childrens[${index}].birthDate`}
                              value={formatDate(children.birthDate)}
                              disabled={formik.isSubmitting}
                              onChange={(event) =>
                                onChangeChildDate(
                                  event,
                                  `childrenData.childrens[${index}]`
                                )
                              }
                              fullWidth={true}
                              helperText={
                                Boolean(
                                  formik.touched?.childrenData?.childrens?.[
                                    index
                                  ]?.birthDate
                                ) &&
                                t(
                                  formik?.errors?.childrenData?.childrens?.[
                                    index
                                  ]?.birthDate
                                )
                              }
                              error={
                                Boolean(
                                  formik.touched?.childrenData?.childrens?.[
                                    index
                                  ]?.birthDate
                                ) &&
                                Boolean(
                                  formik.errors?.childrenData?.childrens?.[
                                    index
                                  ]?.birthDate
                                )
                              }
                            />
                          </Box>

                          {children.birthDate &&
                            !isAdult(children.birthDate) && (
                              <Grid container item>
                                <FormControl
                                  component='fieldset'
                                  error={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.needChildcare
                                    ) &&
                                    Boolean(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.needChildcare
                                    )
                                  }
                                >
                                  <FormLabel component='legend'>{`${t(
                                    "Need childcare"
                                  )}?`}</FormLabel>
                                  <RadioGroup
                                    aria-label='needChildcare'
                                    name={`childrenData.childrens[${index}].needChildcare`}
                                    value={`${children.needChildcare}`}
                                    onChange={(event) =>
                                      onNeedChildcareHandler(
                                        event,
                                        `childrenData.childrens[${index}]`
                                      )
                                    }
                                    className={classes.radioGroup}
                                  >
                                    <FormControlLabel
                                      value={"true"}
                                      control={<Radio color='primary' />}
                                      label={t("Yes")}
                                    />
                                    <FormControlLabel
                                      value={"false"}
                                      control={<Radio color='primary' />}
                                      label={t("No")}
                                    />
                                  </RadioGroup>
                                  <FormLabel
                                    component='legend'
                                    style={{ marginTop: "6px" }}
                                  >
                                    {Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.needChildcare
                                    ) &&
                                      t(
                                        formik.errors?.childrenData
                                          ?.childrens?.[index]?.needChildcare
                                      )}
                                  </FormLabel>
                                </FormControl>

                                {children?.needChildcare === true && (
                                  <Grid container>
                                    <FormControl
                                      component='fieldset'
                                      fullWidth
                                      error={
                                        Boolean(
                                          formik.touched?.childrenData
                                            ?.childrens?.[index]?.organized
                                        ) &&
                                        Boolean(
                                          formik.errors?.childrenData
                                            ?.childrens?.[index]?.organized
                                        )
                                      }
                                    >
                                      <FormLabel component='legend'>{`${t(
                                        "Organized"
                                      )}?`}</FormLabel>
                                      <RadioGroup
                                        aria-label='organized'
                                        name={`childrenData.childrens[${index}].organized`}
                                        value={`${children.organized}`}
                                        onChange={(event) =>
                                          onOrganizedHandler(
                                            event,
                                            `childrenData.childrens[${index}]`
                                          )
                                        }
                                        className={classes.radioGroup}
                                      >
                                        <FormControlLabel
                                          value={"true"}
                                          control={<Radio color='primary' />}
                                          label={t("Yes")}
                                        />
                                        <FormControlLabel
                                          value={"false"}
                                          control={<Radio color='primary' />}
                                          label={t("No")}
                                        />
                                      </RadioGroup>
                                      <FormLabel
                                        component='legend'
                                        style={{ marginTop: "6px" }}
                                      >
                                        {Boolean(
                                          formik.touched?.childrenData
                                            ?.childrens?.[index]?.organized
                                        ) &&
                                          t(
                                            formik.errors?.childrenData
                                              ?.childrens?.[index]?.organized
                                          )}
                                      </FormLabel>
                                    </FormControl>
                                    {children.organized === false && (
                                      <Box mb={2} mt={2} width='inherit'>
                                        <DatePicker
                                          id={`childrenData_childrens_${index}_organizedDeadline`}
                                          label={t(
                                            "Arrange childcare organizedDeadline"
                                          )}
                                          onChange={formik.handleChange}
                                          value={formatDate(
                                            children?.organizedDeadline
                                          )}
                                          name={`childrenData.childrens.[${index}].organizedDeadline`}
                                          disabled={formik.isSubmitting}
                                          fullWidth={true}
                                          error={
                                            Boolean(
                                              formik.touched?.childrenData
                                                ?.childrens?.[index]
                                                ?.organizedDeadline
                                            ) &&
                                            Boolean(
                                              formik.errors?.childrenData
                                                ?.childrens?.[index]
                                                ?.organizedDeadline
                                            )
                                          }
                                          helperText={
                                            Boolean(
                                              formik.touched?.childrenData
                                                ?.childrens?.[index]
                                                ?.organizedDeadline
                                            ) &&
                                            t(
                                              formik.errors?.childrenData
                                                ?.childrens?.[index]
                                                ?.organizedDeadline
                                            )
                                          }
                                        />
                                      </Box>
                                    )}
                                  </Grid>
                                )}

                                {children.needChildcare === false && (
                                  <TextField
                                    id='outlined-multiline-static'
                                    required
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    maxRows={6}
                                    label={t("Comments")}
                                    onChange={formik.handleChange}
                                    value={children.comments}
                                    name={`childrenData.childrens.[${index}].comments`}
                                    rows={4}
                                    type='textarea'
                                    disabled={formik.isSubmitting}
                                    error={
                                      Boolean(
                                        formik.touched?.childrenData
                                          ?.childrens?.[index]?.comments
                                      ) &&
                                      Boolean(
                                        formik.errors?.childrenData
                                          ?.childrens?.[index]?.comments
                                      )
                                    }
                                    helperText={
                                      Boolean(
                                        formik.touched?.childrenData
                                          ?.childrens?.[index]?.comments
                                      ) &&
                                      t(
                                        formik.errors?.childrenData
                                          ?.childrens?.[index]?.comments
                                      )
                                    }
                                  />
                                )}
                              </Grid>
                            )}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        flexDirection='column'
                        xs={6}
                        alignItems='flex-start'
                      >
                        {children.birthDate &&
                          !isAdult(children.birthDate) &&
                          children?.organized && (
                            <Box ml={2} mb={2} width='inherit'>
                              <Typography
                                display='inline'
                                variant='h6'
                                className={classes.title}
                              >
                                {t("Childcare for")} {children?.childName}
                              </Typography>
                              <Box mb={2}>
                                <DatePicker
                                  id={`childrenData_childrens_${index}_childcareFor_childcareStartDate`}
                                  name={`childrenData.childrens[${index}].childcareFor.childcareStartDate`}
                                  label={t("Childcare start date")}
                                  value={formatDate(
                                    children.childcareFor?.childcareStartDate
                                  )}
                                  disabled={formik.isSubmitting}
                                  onChange={formik.handleChange}
                                  fullWidth={true}
                                  error={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.childcareStartDate
                                    ) &&
                                    Boolean(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.childcareStartDate
                                    )
                                  }
                                  helperText={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.childcareStartDate
                                    ) &&
                                    t(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.childcareStartDate
                                    )
                                  }
                                />
                              </Box>
                              <TextField
                                fullWidth
                                name={`childrenData.childrens[${index}].childcareFor.childcareName`}
                                label={t("Childcare name")}
                                onBlur={formik.handleBlur}
                                value={children.childcareFor?.childcareName}
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.childcareName
                                  ) &&
                                  Boolean(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.childcareName
                                  )
                                }
                                helperText={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.childcareName
                                  ) &&
                                  t(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.childcareName
                                  )
                                }
                              />
                              <Box width='inherit' mt={2}>
                                <TextField
                                  fullWidth
                                  name={`childrenData.childrens[${index}].childcareFor.streetAndHouseNumber`}
                                  label={t("Street and house number")}
                                  onBlur={formik.handleBlur}
                                  value={
                                    children.childcareFor?.streetAndHouseNumber
                                  }
                                  disabled={formik.isSubmitting}
                                  onChange={formik.handleChange}
                                  error={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.streetAndHouseNumber
                                    ) &&
                                    Boolean(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.streetAndHouseNumber
                                    )
                                  }
                                  helperText={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.streetAndHouseNumber
                                    ) &&
                                    t(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.streetAndHouseNumber
                                    )
                                  }
                                />
                              </Box>
                              <TextField
                                className={classes.marginChildcareFor}
                                fullWidth
                                name={`childrenData.childrens[${index}].childcareFor.postalCode`}
                                label={t("Post code")}
                                onBlur={formik.handleBlur}
                                value={children.childcareFor?.postalCode}
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.postalCode
                                  ) &&
                                  Boolean(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.postalCode
                                  )
                                }
                                helperText={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.postalCode
                                  ) &&
                                  t(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.postalCode
                                  )
                                }
                              />
                              <TextField
                                className={classes.marginChildcareFor}
                                fullWidth
                                name={`childrenData.childrens[${index}].childcareFor.place`}
                                label={t("Place")}
                                onBlur={formik.handleBlur}
                                value={children.childcareFor?.place}
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.place
                                  ) &&
                                  Boolean(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.place
                                  )
                                }
                                helperText={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.place
                                  ) &&
                                  t(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.place
                                  )
                                }
                              />

                              <Grid
                                item
                                xs={12}
                                className={classes.marginChildcareFor}
                              >
                                <PhoneInputNumber
                                  name={`childrenData.childrens[${index}].childcareFor.phoneNumber`}
                                  label={t("Phone number")}
                                  onBlur={formik.handleBlur}
                                  value={children.childcareFor?.phoneNumber}
                                  disabled={formik.isSubmitting}
                                  onChange={onPhoneNumberChange}
                                  error={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.phoneNumber
                                    ) &&
                                    Boolean(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.phoneNumber
                                    )
                                  }
                                  errorMessage={
                                    Boolean(
                                      formik.touched?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.phoneNumber
                                    ) &&
                                    t(
                                      formik.errors?.childrenData?.childrens?.[
                                        index
                                      ]?.childcareFor?.phoneNumber
                                    )
                                  }
                                />
                              </Grid>
                              <TextField
                                fullWidth
                                className={classes.marginChildcareFor}
                                name={`childrenData.childrens[${index}].childcareFor.emailAddress`}
                                label={t("E-mail address")}
                                onBlur={formik.handleBlur}
                                value={children.childcareFor?.emailAddress}
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.emailAddress
                                  ) &&
                                  Boolean(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.emailAddress
                                  )
                                }
                                helperText={
                                  Boolean(
                                    formik.touched?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.emailAddress
                                  ) &&
                                  t(
                                    formik.errors?.childrenData?.childrens?.[
                                      index
                                    ]?.childcareFor?.emailAddress
                                  )
                                }
                              />
                            </Box>
                          )}
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
            <Grid
                item
                style={{
                  marginTop: "20px",
                  width: "89%",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  isSubmitting={true}
                  disabled={formik.isSubmitting}
                  className={classes.buttonStyle}
                  onClick={() => setSelectedPage("saveAndPrevious")}
                >
                  {t("SAVE & PREVIOUS")}
                </Button>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  isSubmitting={true}
                  disabled={formik.isSubmitting}
                  className={classes.buttonStyle}
                  endIcon={
                    formik.isSubmitting && (
                      <CircularProgress size={18} color='inherit' />
                    )
                  }
                >
                  {t("Save and Next")}
                </Button>
              </Grid>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
}

export default DetailsForm;
