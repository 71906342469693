import moment from 'moment';
import { DATE_FORMAT } from '../../resources/constants';
import { endpoints, API_PREFIX } from "../../resources/constants/endpoints";
import environment from "../../resources/constants/environment";

export function removeKey(obj, keyToRemove) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const newObj = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key !== keyToRemove) {
        newObj[key] = removeKey(obj[key], keyToRemove);
      }
    }
  }
  return newObj;
}

export function isAdult(date) {
  const formattedGivenDate = moment(date, DATE_FORMAT)
  const currentDate = moment();
  const date18YearsAgo = currentDate.clone().subtract(18, 'years');
  return formattedGivenDate.isSameOrBefore(date18YearsAgo, 'day')
}

export function formatDate(date) {
  if (date && moment(date).isValid()) {
    return moment(date).format(DATE_FORMAT)
  }
  return "";
};

export const getBooleanValue = (value) => {
  if (value == "true") return true
  return false;
}

export const fromFileToBase64 = (file) => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })
);

export const generateDownloadFileLink = (fileName, storageFileUrl, pipPlanId) => {
  const { DOWNLOAD_FILE } = endpoints;
  const { REACT_APP_URL } = environment;
  const [GATEWAY_URL] = REACT_APP_URL.split('/graphql');
  if(pipPlanId) {
    return `${GATEWAY_URL}/${API_PREFIX}${DOWNLOAD_FILE}?pipPlanId=${pipPlanId}&fileName=${fileName}`;
  } else {
    return `${GATEWAY_URL}/${API_PREFIX}${DOWNLOAD_FILE}?storageUrl=${storageFileUrl}&fileName=${fileName}`;
  }
};
