import { ApolloClient, ApolloLink, InMemoryCache, concat } from 'apollo-boost';
import { createUploadLink } from "apollo-upload-client";

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        "x-auth-token": localStorage.getItem('jwtToken') || ""
      }
    });
    return forward(operation);
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_URL,
  headers: {
    "x-auth-token": localStorage.getItem('jwtToken') || ""
  }
});

const uploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, uploadLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default uploadClient;