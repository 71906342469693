import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SignatureCanvas from 'react-signature-canvas';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  Button,
  Tooltip,
  Checkbox,
  TextField,
  Typography,
  IconButton,
  FormControlLabel,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import ReplayIcon from '@material-ui/icons/Replay';
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "../../../shared/DatePicker/index";;
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { removeKey, formatDate } from "../../../../utils/helpers";
import { pipPlanNavigationKeys, pipPlanSteps } from '../../../../resources/constants';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    mt: 3, mb: 2, width: "auto", marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9
    }
  }
}));

const checkAccessForPublishing = (pipPlan) => {
  const pipPlanNavigationSteps = [...pipPlanSteps].filter((item) => (
    item.key !== pipPlanNavigationKeys.consentForm
  ));
  const hasAccess = pipPlanNavigationSteps.every((step) => (
    pipPlan?.[step.key]
  ));
  return hasAccess;
};

function ConsentForm({ pipPlan, setPipPlanPage }) {
  const params = useParams();
  const navigate = useNavigate();
  const { refugeeId } = params;
  const data = pipPlan?.consentForm;
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState("")
  const hasAccessForPublishing = checkAccessForPublishing(pipPlan)
  const [signature, setSignature] = useState();
  const [signatureSRC, setSignatureSRC] = useState();
  const [retrySignature, setRetrySignature] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();

  useEffect(
    () => {
      if (data?.base64Signature) {
        const src = data?.base64Signature ? `data:image/png;base64,${data.base64Signature}` : '';
        setSignatureSRC(src);
        setRetrySignature(false);
      }
    },
    [data]
  );

  const clearCanvas = () => signature.clear();

  const showCanvas = () => setRetrySignature(true);

  const hideCanvas = () => {
    signature.clear();
    setRetrySignature(false);
  };

  const onSubmit = (values, helpers) => {
    const base64Signature = signature
      ?.getTrimmedCanvas()
      ?.toDataURL('image/png')
      ?.split(';base64,')
      ?.[1] || data?.base64Signature || '';
    const isDirty = formik.dirty;
    if (refugeeId) {
      const queryString = JSON
        .stringify(removeKey({ ...values, base64Signature, date: formatDate(moment()) }, '__typename'), null, 2)
        .replace(/"([^"]+)":/g, '$1:');
      const consentFormQuery = `consentForm: ${queryString}`;
      createOrUpdateDraftPipPlan({ refugeeId, section: consentFormQuery })
        .then(() => {
          if (selectedPage === "saveAndPrevious") {
            setOpenAlert(true);
            dispatch(getPipPlanByRefugee(refugeeId))
            setPipPlanPage(pipPlanNavigationKeys.goalsAndAgreements)
          } else {
            setOpenAlert(true);
            if (values.publish) {
              navigate('/dashboard');
            }
            dispatch(getPipPlanByRefugee(refugeeId))
          }
        })
        .finally(() => {
          formik.setFieldValue('publish', false);
          helpers.setSubmitting(false);
        })
    }
  }

  const formik = useFormik({
    onSubmit: onSubmit,
    initialValues: {
      supervisor: data?.supervisor ?? false,
      supervisorAnswer: data?.supervisorAnswer || null,

      integrationSchool: data?.integrationSchool ?? false,
      integrationSchoolAnswer: data?.integrationSchoolAnswer || null,

      socialWork: data?.socialWork ?? false,
      socialWorkAnswer: data?.socialWorkAnswer || null,

      employersServicePoint: data?.employersServicePoint ?? false,
      employersServicePointAnswer: data?.employersServicePointAnswer || null,

      other: data?.other ?? false,
      otherAnswer: data?.otherAnswer || null,

      date: data?.date || '',
      publish: false
    },
    enableReinitialize: true,
  });

  const onCheckboxChange = (answerName) => (event) => {
    const { name, checked } = event.target;
    if (!checked) {
      formik.setFieldValue(answerName, '');
    }
    formik.setFieldValue(name, checked);
  }

  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [openToolTip, setOpenToolTip] = React.useState(false);

  const handleCloseToolTip = () => {
    setOpenToolTip(false);
  };

  const handleOpenToolTip = () => {
    setOpenToolTip(true);
  };

  return (
    <Grid container>
      <form
        noValidate
        style={{ width: "100%", paddingTop: 40, paddingBottom: 40 }}
        onSubmit={formik.handleSubmit}
      >
        {
          <Snackbar
            open={openAlert}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {formik.values.publish ?
              <Alert severity="success" variant="filled">{t('Successfully published')}</Alert> :
              <Alert severity="success" variant="filled">{t('Successfully saved')}</Alert>}
          </Snackbar>
        }
        <Box pl={10}>
          <Grid container spacing={1}>
            <Typography style={{ paddingTop: 20 }}>
              {t("With this form I give my integration director from the integration team of the municipality of Echt-Susteren permission to share the information from this plan with the collaborating partners below")}
            </Typography>
            <Typography style={{ paddingTop: 20 }}>
              {`${t("I give permission under these conditions")}:`}
            </Typography>
            <ul>
              <li>
                <Typography>
                  {`${t("My consent only applies to the reasons, data and persons or organizations described below. For other reasons, data, persons or organizations, the integration team will ask me for permission again")}.`}
                </Typography>
              </li>
              <li>
                <Typography>
                  {`${t("The integration team informs me about the data that is exchanged about me and the data that is registered about me. This means, for example, that the integration team explains to me which specific data it concerns and why this information is necessary to be able to help me")}.`}
                </Typography>
              </li>
              <li>
                <Typography>
                  {`${t("I can choose not to give permission or to only give permission for certain parts. The integration team explains the consequences for my guidance if I do not give permission (for certain) data or persons")}.`}
                </Typography>
              </li>
              <li>
                <Typography>
                  {`${t("I may withdraw my consent at any time. In some cases, withdrawing consent will have consequences for my guidance. The integration team will inform me about this on a case-by-case basis. However, the municipality will always share the data with other organizations if the Civic Integration Act 2022 obliges us to do so")}.`}
                </Typography>
              </li>
            </ul>

            <Grid item xs={12}>
              <Typography style={{ paddingTop: 20 }}>
                {`${t("This permission is valid for the entire integration period")}.`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ paddingTop: 40 }}>
                {`${t("I consent to this plan being shared with")}:`}
              </Typography>
            </Grid>

            <Grid container item xs={12} spacing={1} style={{ paddingTop: 20 }}>
              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <FormControlLabel
                  label={t("my supervisor from")}
                  control={
                    <Checkbox
                      required
                      color="primary"
                      checked={formik.values.supervisor}
                      onChange={onCheckboxChange("supervisorAnswer")}
                      name="supervisor"
                    />
                  }
                />
              </Grid>
              {formik.values.supervisor && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ maxWidth: 300 }}
                    name='supervisorAnswer'
                    onBlur={formik.handleBlur}
                    value={formik.values?.supervisorAnswer ?? ''}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <FormControlLabel
                  label={t("my integration school")}
                  control={
                    <Checkbox
                      required
                      color="primary"
                      checked={formik.values.integrationSchool}
                      onChange={onCheckboxChange("integrationSchoolAnswer")}
                      name="integrationSchool"
                    />
                  }
                />
              </Grid>
              {formik.values.integrationSchool && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ maxWidth: 300 }}
                    name='integrationSchoolAnswer'
                    onBlur={formik.handleBlur}
                    value={formik.values.integrationSchoolAnswer}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <FormControlLabel
                  label={t("social work")}
                  control={
                    <Checkbox
                      required
                      color="primary"
                      checked={formik.values.socialWork}
                      onChange={onCheckboxChange("socialWorkAnswer")}
                      name="socialWork"
                    />
                  }
                />
              </Grid>
              {formik.values.socialWork && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ maxWidth: 300 }}
                    name='socialWorkAnswer'
                    onBlur={formik.handleBlur}
                    value={formik.values.socialWorkAnswer}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <FormControlLabel
                  label={t("employers service point / reintegration agency")}
                  control={
                    <Checkbox
                      required
                      color="primary"
                      checked={formik.values.employersServicePoint}
                      onChange={onCheckboxChange("employersServicePointAnswer")}
                      name="employersServicePoint"
                    />
                  }
                />
              </Grid>
              {formik.values.employersServicePoint && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ maxWidth: 300 }}
                    name='employersServicePointAnswer'
                    onBlur={formik.handleBlur}
                    value={formik.values.employersServicePointAnswer}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <FormControlLabel
                  label={t("other, namely")}
                  control={
                    <Checkbox
                      required
                      color="primary"
                      checked={formik.values.other}
                      onChange={onCheckboxChange("otherAnswer")}
                      name="other"
                    />
                  }
                />
              </Grid>
              {formik.values.other && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ maxWidth: 300 }}
                    name='otherAnswer'
                    onBlur={formik.handleBlur}
                    value={formik.values.otherAnswer}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <DatePicker
                  fullWidth
                  required
                  name="date"
                  label={t("Date")}
                  value={formik.values.date ? formatDate(formik.values.date) : null}
                  // disabled={formik.isSubmitting}
                  disabled={true}
                  onChange={formik.handleChange}
                />
              </Grid>


              <Typography style={{ paddingTop: 40 }}>
                {t("Sign your signature below using your mouse pointer")}
              </Typography>

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Grid
                  style={{
                    width: 500,
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: "1px solid black",
                    position: 'relative'
                  }}
                >
                  {retrySignature || !signatureSRC ? (
                    <>
                      <SignatureCanvas
                        ref={data => setSignature(data)}
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                      />

                      <Grid
                        container
                        style={{
                          top: 10,
                          right: 10,
                          flex: 1,
                          width: 60,
                          padding: 0,
                          height: 20,
                          display: "flex",
                          position: 'absolute',
                          justifyContent: 'end',
                        }}
                      >
                        <Tooltip title="Retry">
                          <IconButton onClick={clearCanvas} style={{ padding: 0 }}>
                            <ReplayIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>

                        {signatureSRC && (
                          <Tooltip title="Cancel">
                            <IconButton onClick={hideCanvas} style={{ padding: 0, marginLeft: 5 }}>
                              <ClearOutlinedIcon fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>

                    </>
                  ) : (
                    <>
                      <img src={signatureSRC} />
                      <Tooltip title="Retry">
                        <IconButton
                          onClick={showCanvas}
                          style={{
                            top: 10,
                            right: 10,
                            padding: 0,
                            position: 'absolute',
                          }}
                        >
                          <ReplayIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Tooltip
                  open={openToolTip}
                  onClose={handleCloseToolTip}
                  onOpen={handleOpenToolTip}
                  title={t('You cannot make a publication until you have filled out the previous forms')}
                >
                  <FormControlLabel
                    disabled={!hasAccessForPublishing}
                    label={t("Publish PIP version")}
                    control={
                      <Checkbox
                        disabled={!hasAccessForPublishing}
                        required
                        color="primary"
                        checked={formik.values.publish}
                        onChange={formik.handleChange}
                        name="publish"
                      />
                    }
                  />
                </Tooltip>
                <Typography style={{ paddingTop: 40 }}>
                  {t("NB! When publishing this form, a new version will be created")}
                  <br />
                  {`${t('A maximum of 3 versions may be created. After this, the plan becomes final and no changes are possible')}.`}
                  <br />
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "20px",
                  minWidth: "100%",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  isSubmitting={true}
                  disabled={formik.isSubmitting}
                  className={classes.buttonStyle}
                  onClick={() => setSelectedPage("saveAndPrevious")}
                >
                  {t("SAVE & PREVIOUS")}
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  isSubmitting={true}
                  disabled={formik.isSubmitting}
                  className={classes.buttonStyle}
                  endIcon={formik.isSubmitting && (
                    <CircularProgress size={18} color="inherit" />
                  )}
                >
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Grid>
  )
}

export default ConsentForm;
