import { TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    maxWidth: 300,
  },
}));

function DatePicker(props) {

  const {
    error,
    label,
    value,
    disabled,
    onChange,
    fullWidth,
    helperText,
    name,
    id
  } = props;

  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id={id}
        label={label}
        type="date"
        value={value}
        name={name}
        onChange={onChange}
        className={classes.textField}
        InputLabelProps={{ shrink: true }}
        error={error}
        fullWidth={fullWidth}
        disabled={disabled}
        helperText={helperText}
      />
    </form>
  );
}

export default DatePicker;

DatePicker.defaultProps = {
  error: false,
  disabled: false,
  helperText: undefined,
  label: undefined,
  style: undefined,
  fullWidth: false,
  id: "date"
};
