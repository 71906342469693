import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import StyledCheckbox from "../../shared/StyledCheckbox";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
}));

function AssignMunicipalityToSupplierMunicipalitiesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [municipalities, setMunicipalities] = useState({});

  const SearchedMunicipalities = useSelector(
    ({ AssignMunicipalityToSupplierMunicipalitiesDataReducer }) =>
      AssignMunicipalityToSupplierMunicipalitiesDataReducer
        ?.allMiniSearchedMunicipalitiesReducer?.data
  );
  const loading = useSelector(
    ({ AssignMunicipalityToSupplierMunicipalitiesDataReducer }) =>
      AssignMunicipalityToSupplierMunicipalitiesDataReducer
        ?.allMiniSearchedMunicipalitiesReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMunicipalityToSupplierMunicipalitiesDataReducer }) =>
      AssignMunicipalityToSupplierMunicipalitiesDataReducer
        ?.allMiniSearchedMunicipalitiesReducer?.errMsg
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedMunicipalities(true));
    };
  }, []);

  useEffect(() => {
    dispatch(
      Actions.getAllMiniSearchedMunicipalities(
        page + 1,
        rowsPerPage,
        props.searchText,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const newMunicipalitiesObj = { ...municipalities };
    newMunicipalitiesObj.docs[index].isSelected =
      !municipalities.docs[index].isSelected;
    if (newMunicipalitiesObj.docs[index].isSelected) {
      const selectedMunicipality = {
        id: newMunicipalitiesObj.docs[index]?.id,
        name: newMunicipalitiesObj.docs[index].name,
        province: newMunicipalitiesObj.docs[index].province,
        contactPerson: newMunicipalitiesObj.docs[index].contactPerson,
      };
      props.setSelectedMunicipalities([selectedMunicipality]);
    } else {
      props.setSelectedMunicipalities(
        props.selectedMunicipalities.filter(
          (selectedMunicipality) =>
            selectedMunicipality?.id !== newMunicipalitiesObj.docs[index]?.id
        )
      );
    }
    setMunicipalities(newMunicipalitiesObj);
  };

  useEffect(() => {
    const municipalitiesObj =
      SearchedMunicipalities?.miniSearchAllMunicipalities;
    if (municipalitiesObj) {
      const newMunicipalitiesObj = { ...municipalitiesObj };
      if (newMunicipalitiesObj.docs && newMunicipalitiesObj.docs.length) {
        for (let i = 0; i < newMunicipalitiesObj.docs.length; i++) {
          newMunicipalitiesObj.docs[i].isSelected = false;
          if (props.selectedMunicipalities.length) {
            for (let j = 0; j < props.selectedMunicipalities.length; j++) {
              if (
                newMunicipalitiesObj.docs[i]?.id ===
                props.selectedMunicipalities[j]?.id
              ) {
                newMunicipalitiesObj.docs[i].isSelected = true;
              }
            }
          }
        }
      }
      setMunicipalities(newMunicipalitiesObj);
    }
  }, [SearchedMunicipalities, props.selectedMunicipalities]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div className="system-search-filter-div">
        <div className="system-search-filter-total-div">
          {t("Total Municipalities")}:{" "}
          {municipalities && municipalities.totalDocs
            ? municipalities.totalDocs
            : "0"}
        </div>
      </div>

      <div className="edit-mun-table-div">
        <table
          className="system-search-table-tag"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <tr>
            <th>{t("Land")}</th>
            <th>{t("Municipality Name")}</th>
            <th>{t("Manager Name")}</th>
            <th>{t("Contact Person")}</th>
            <th>{t("Assign")}</th>
          </tr>

          {municipalities.docs &&
            municipalities.docs.length > 0 &&
            municipalities.docs.map((doc, index) => (
              <tr key={doc?.id}>
                <td>{doc.province}</td>
                <td>{doc.name}</td>
                <td>
                  {doc.manager === null ? (
                    <span className="unassigned-td">{t("Unassigned")}</span>
                  ) : (
                    doc.manager.name
                  )}
                </td>
                <td>
                  {doc.contactPerson === null ? (
                    <span className="unassigned-td">{t("N/A")}</span>
                  ) : (
                    doc.contactPerson.name
                  )}
                </td>
                <td>
                  <StyledCheckbox
                    checked={doc.isSelected}
                    onClick={() => handleChangeSelected(index)}
                  />
                </td>
              </tr>
            ))}
        </table>
      </div>

      <div className="assign-mentor-to-municipality-pagination-div">
        <TablePagination
          colSpan={3}
          component="div"
          count={
            municipalities && municipalities.totalDocs
              ? municipalities.totalDocs
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          classes={{
            root: classes.tablePagination,
          }}
        />
      </div>
    </div>
  );
}

export default withReducer(
  "AssignMunicipalityToSupplierMunicipalitiesDataReducer",
  reducer
)(AssignMunicipalityToSupplierMunicipalitiesData);
