import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import jwt from "jsonwebtoken";
import { makeStyles } from "@material-ui/core/styles";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "black",
  },
  textField: {
    width: "100%",
    margin: 0,
    backgroundColor: "#daeff0",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div
          style={{
            backgroundColor: "#ececec",
            // overflowY:"auto",
            height: "400px",
            borderRadius: "5px",
          }}
        >
          {children}
        </div>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root1: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  rootStyle: {
    borderRadius: 15,
    backgroundColor: "white",
    padding: "15px",
  },
  headingaddform: {
    color: "black",
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "1px solid #8fcccd",
    width: "240px",
    paddingBottom: "2px",
    marginLeft: "10px",
    paddingRight: "110px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  headingaddform1: {
    color: "black",
    fontSize: "14px",
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
    margin: 0,
    backgroundColor: "#daeff0",
  },
  textField1: {
    width: "15%",
    margin: 0,
    backgroundColor: "#daeff0",
  },
  textField3: {
    width: "40%",
    margin: 0,
    backgroundColor: "#daeff0",
  },
  input1: {
    height: "5px",
    border: 0,
  },
  notchedOutline: {
    borderWidth: "0px",
  },
  lable: {
    fontStyle: "italic",
    textAlign: "left",
  },
  cornerbtn: {
    textAlign: "right",
  },
  cornerbtn1: {
    margin: theme.spacing(1),
    fontSize: "13px",
    backgroundColor: "#454a92",
    width: "160px",
    opacity: 1,
  },
  btnupload: {
    backgroundColor: "#454a92",
  },
  input: {
    display: "none",
  },
  tabLabel: {
    fontSize: "11px",
    fontWeight: "600",
    textTransform: "none",
  },
  default_tabStyle: {
    backgroundColor: "white",
    border: "2px solid #ececec",
    borderBottom: "none",
    borderRadius: "5px 5px 0px 0px",
    height: "30px",
    minHeight: "30px",
    minWidth: "170px",
    width: "170px",
    maxWidth: "130px",
    fontSize: "x-small",
    marginLeft: "10px",
    textTransform: "none",
  },
  active_tabStyle: {
    backgroundColor: "#ececec",
    borderRadius: "5px 5px 0px 0px",
    height: "30px",
    minHeight: "30px",
    minWidth: "170px",
    width: "170px",
    maxWidth: "130px",
    fontSize: "x-small",
    marginLeft: "10px",
    textTransform: "none",
  },
  send_btn: {
    float: "right",
  },
  head: {
    height: "fit-content",
    width: "fit-content",
    boxShadow: "none",
    backgroundColor: "white",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
}));

let hasMoreState;
let pageState = 1;
let valueState = 0;
export default function Chatmodal(props) {
  const classes = useStyles();
  const [referenceNode, setReferenceNode] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [t] = useTranslation();
  const [value, setValue] = React.useState(0);
  const [flag, setFlag] = React.useState(false);
  const [typing, setTyping] = React.useState(false);
  const [messages, setMessages] = React.useState([]); // Sent and received messages
  const [newMessage, setNewMessage] = React.useState(""); // Message to be sent
  const [room, setRoom] = React.useState("");
  const [length, setLength] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [messagesEnd, setMessagesEnd] = React.useState("");
  const [messagesTop, setMessagesTop] = React.useState(false);
  const [scrollTop, setScrollTop] = React.useState(false);
  const [defaultChat, setDefaultChat] = React.useState({
    [props.data.name]: 0,
    [props.data.municipality.manager.name]: 1,
  });
  React.useEffect(() => {
    const suppliers = props.data.suppliers.school || [];
    const suppliersWork = props.data.suppliers.work || [];

    const updatedDefaultChat = {
      ...defaultChat,
      ...suppliersWork.reduce((acc, supplier, index) => {
        let name = supplier.split("!")[1];
        acc[`${name}`] = index + 2;
        return acc;
      }, {}),
      ...suppliers.reduce((acc, supplier, index) => {
        let name = supplier.split("!")[1];
        acc[`${name}`] = index + suppliersWork.length + 2;
        return acc;
      }, {}),
    };

    setDefaultChat(updatedDefaultChat);
  }, [props.data.suppliers]);

  const socket = React.useRef();
  hasMoreState = hasMore;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    valueState = newValue;
  };

  React.useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
      path: "/chat",
      query: {
        token: localStorage.jwtToken,
      },
    });
    socket.current.on("connect", () => {
      join();
    });
    socket.current.on("connect_error", (err) => {});
    socket.current.on("connect_timeout", () => {});
    socket.current.on("reconnect", (attemptNumber) => {});
    socket.current.on("reconnect_error", (err) => {});
    socket.current.on("reconnect_failed", () => {});
    socket.current.on("disconnect", (reason) => {});
    socket.current.on("clientError", (data) => {});
    socket.current.on("error", (err) => {});
    socket.current.on("receiveMessage", (res) => {
      join();
    });
    socket.current.on("typing", () => {
      setTyping(true);
    });
    socket.current.on("stopTyping", () => {
      setTyping(false);
    });
    socket.current.on("seenMessages", (res) => {
      join();
    });
  }, []);
  React.useEffect(() => {
    if (newMessage === "") {
      socket.current.emit("stopTyping", {
        room: room,
      });
    }
  }, [newMessage]);
  React.useEffect(() => {
    setTyping(false);
    valueState = value;
    join();
  }, [value]);

  React.useEffect(() => {
    let Valid = sessionStorage.getItem("defaultValue");

    if (Valid) {
      if (defaultChat.hasOwnProperty(Valid)) {
        const value = defaultChat[Valid];
        setValue(value);
        sessionStorage.removeItem("defaultValue");
      }
    }
  }, [defaultChat]);
  function join() {
    socket.current.emit(
      "join",
      {
        receiverId:
          valueState === 0 && props.data?.id
            ? props.data?.id
            : valueState === 1 &&
              props.data.municipality &&
              props.data.municipality.manager
            ? props.data.municipality.manager._id
            : sessionStorage.getItem("school"),
        dataId: props.data.id,
      },
      (res) => {
        const msgs = res.messages.docs.reverse();
        setMessages(msgs);
        setRoom(res.room);
        setHasMore(res.messages.hasNextPage);
        hasMoreState = res.messages.hasNextPage;
        setScrollTop(false);
        setLength(res.messages.totalDocs);
      }
    );
  }
  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
    socket.current.emit("typing", {
      room: room,
    });
  };
  const handleFocus = (event) => {
    let seenData = {
      room: room,
      refugee: props.data.id,
    };
    socket.current.emit("seenMessages", seenData);
  };

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      //Do stuff in here
      event.preventDefault();
      handleSendMessage();
    }
  };
  const handleSendMessage = () => {
    socket.current.emit("sendMessage", {
      type: "text",
      body: newMessage,
      room: room,
      refugee: props?.data.id,
    });
    join();
    setNewMessage("");
    setMessagesTop(false);
  };

  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    if (messagesEnd && !messagesTop) {
      scrollToBottom();
      let seenData = {
        room: room,
        refugee: props.data.id,
      };
      socket.current.emit("seenMessages", seenData);
    }
  }, [scrollToBottom]);

  React.useEffect(() => {
    if (flag && page && limit && hasMore && hasMoreState) {
      socket.current.emit(
        "getAllChatMessages",
        {
          room: room,
          page: pageState,
          limit: limit,
        },
        (res) => {
          setMessagesTop(true);
          setHasMore(res.messages.hasNextPage);
          hasMoreState = res.messages.hasNextPage;
          setScrollTop(false);
          setLength(res.messages.totalDocs);
          const msgs = res.messages.docs.reverse();
          setMessages((messages) => msgs.concat(messages));
          setFlag(false);
        }
      );
    }
  }, [page, limit, flag, hasMore, hasMoreState, pageState]);

  const handleClose = (status) => {
    setOpen(status);
    props.methodd(false);
  };
  const handleScroll = (event) => {
    var node = event.target;
    if (node.scrollTop === 0) {
      if (hasMoreState) {
        pageState = pageState + 1;
        setPage(page + 1);
        setScrollTop(true);
        setFlag(true);
        node.scrollTop = node.clientHeight;
      }
    }
  };
  const paneDidMount = (node) => {
    if (node) {
      node.addEventListener("scroll", handleScroll);
      setReferenceNode(node);
    }
  };
  return (
    <div>
      <Dialog
        classes={{
          paper: classes.rootStyle,
        }}
        onClose={() => handleClose(false)}
        aria-labelledby="customized-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.root1,
          },
        }}
        open={props.status}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose(false)}
        >
          <p className="citemsf">
            <i
              className="fa fa-weixin"
              style={{
                marginRight: "10px",
                color: "#8fcccd",
                fontSize: "28px",
              }}
            >
              {" "}
            </i>

            <span className={classes.headingaddform}>{props.data.name}</span>
          </p>
        </DialogTitle>
        <DialogContent>
          <div className="chat-div">
            <AppBar position="static" className={classes.head}>
              <div>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "transparent",
                    },
                  }}
                  style={{ minHeight: "0px" }}
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Refugee"
                    className={
                      value === 0
                        ? classes.active_tabStyle
                        : classes.default_tabStyle
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Manager"
                    className={
                      value === 1
                        ? classes.active_tabStyle
                        : classes.default_tabStyle
                    }
                    {...a11yProps(1)}
                  />
                  {(props.data.suppliers.work ?? []).map(
                    (schoolSupplier, originalIndex) => {
                      const index = originalIndex + 2;

                      // Find the index of the exclamation mark
                      const indexOfExclamation = schoolSupplier.indexOf("!");

                      // Extract the text after the exclamation mark
                      const textAfterExclamation =
                        indexOfExclamation !== -1
                          ? schoolSupplier.substring(indexOfExclamation + 1)
                          : "";

                      const textBeforeExclamation =
                        indexOfExclamation !== -1
                          ? schoolSupplier.substring(0, indexOfExclamation)
                          : "";

                      if (value === index) {
                        sessionStorage.setItem("school", textBeforeExclamation);
                      }
                      return (
                        <Tab
                          key={index}
                          className={
                            value === index
                              ? classes.active_tabStyle
                              : classes.default_tabStyle
                          }
                          label={
                            <span className={classes.tabLabel}>
                              {`Supplier-${textAfterExclamation}`}
                            </span>
                          }
                          {...a11yProps(index)}
                        />
                      );
                    }
                  )}
                  {(props.data.suppliers.school ?? []).map(
                    (schoolSupplier, originalIndex) => {
                      let index;
                      if (props?.data?.suppliers?.work) {
                        index =
                          originalIndex +
                          props?.data?.suppliers?.work?.length +
                          2;
                      } else {
                        index = originalIndex + 2;
                      }

                      // Find the index of the exclamation mark
                      const indexOfExclamation = schoolSupplier.indexOf("!");

                      // Extract the text after the exclamation mark
                      const textAfterExclamation =
                        indexOfExclamation !== -1
                          ? schoolSupplier.substring(indexOfExclamation + 1)
                          : "";

                      const textBeforeExclamation =
                        indexOfExclamation !== -1
                          ? schoolSupplier.substring(0, indexOfExclamation)
                          : "";

                      if (value === index) {
                        sessionStorage.setItem("school", textBeforeExclamation);
                      }
                      return (
                        <Tab
                          key={index}
                          className={
                            value === index
                              ? classes.active_tabStyle
                              : classes.default_tabStyle
                          }
                          label={
                            <span className={classes.tabLabel}>
                              {`Supplier-${textAfterExclamation}`}
                            </span>
                          }
                          {...a11yProps(index)}
                        />
                      );
                    }
                  )}
                </Tabs>
              </div>
            </AppBar>
            <TabPanel value={value} index={0}>
              {props.data?.id && (
                <div className="chat-tab-div">
                  {hasMore && scrollTop ? (
                    <span className="typing">Loading...</span>
                  ) : null}
                  <div
                    id="scrollableDiv"
                    ref={paneDidMount}
                    className="msg-wrapper"
                  >
                    {messages &&
                      messages.map((msg) =>
                        msg.type === "text" ? (
                          <div
                            className={
                              msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? "chat-receiver sb12"
                                : "chat-sender sb11"
                            }
                          >
                            <h5 className="msg-head">
                              {msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? props.data?.mentor?.name
                                : props.data?.name}{" "}
                              {new Date(msg.sentTime).toLocaleString()}
                            </h5>
                            <p className="msg-text">{msg.body}</p>
                            <div
                              className={
                                msg.senderId ===
                                  jwt.decode(localStorage.jwtToken)?.id &&
                                msg.status === "seen"
                                  ? "seen-msg-div"
                                  : "seen-msg-div-none"
                              }
                            >
                              <span
                                style={{
                                  margin: "3px",
                                  float: "right",
                                  fontSize: "12px",
                                }}
                              >
                                {new Date(msg.sentTime).toLocaleString()}
                              </span>
                              <DoneAllIcon
                                style={{
                                  color: "#3f51b5",
                                  margin: 0,
                                  float: "right",
                                }}
                              />
                            </div>
                          </div>
                        ) : msg.type === "location" ? (
                          <div
                            className={
                              msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? "chat-receiver-map-div"
                                : "chat-sender-map-div"
                            }
                          >
                            <img
                              src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=1&size=300x250&maptype=terrian&visual_refresh=true&markers=icon:https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/location-24-32.png%7C+${msg.body}&key=AIzaSyBthhfafE1UaPQDCVzzoX9avMbpmVL__OQ`}
                              alt="Map"
                              className={
                                msg.senderId ===
                                jwt.decode(localStorage.jwtToken)?.id
                                  ? "chat-receiver-map"
                                  : "chat-sender-map"
                              }
                            />
                          </div>
                        ) : msg.type === "image" ? (
                          <div
                            className={
                              msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? "chat-receiver-map-div"
                                : "chat-sender-map-div"
                            }
                          >
                            <img
                              src={msg.body}
                              alt="Image"
                              className={
                                msg.senderId ===
                                jwt.decode(localStorage.jwtToken)?.id
                                  ? "chat-receiver-map"
                                  : "chat-sender-map"
                              }
                            />
                          </div>
                        ) : null
                      )}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        setMessagesEnd(el);
                      }}
                    ></div>
                  </div>

                  <div className="send-msg-wrapper">
                    <div className="input-msg-div">
                      <textarea
                        className="input-msg-box"
                        rows="2"
                        cols="50"
                        name="comment"
                        placeholder={t("Enter message here...")}
                        value={newMessage}
                        onChange={handleNewMessageChange}
                        onClick={handleFocus}
                        onKeyPress={enterPressed}
                      />
                    </div>
                    <div className="send-btn-div">
                      <Button
                        variant="contained"
                        className={classes.send_btn}
                        size="small"
                        onClick={() => handleSendMessage()}
                        color="primary"
                        disabled={newMessage ? false : true}
                      >
                        {t("Send")}
                      </Button>
                    </div>
                  </div>
                  {typing ? <span className="typing">typing...</span> : null}
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {props.data.municipality && props.data.municipality.manager && (
                <div className="chat-tab-div">
                  {hasMore && scrollTop ? (
                    <span className="typing">Loading...</span>
                  ) : null}
                  <div
                    id="scrollableDiv"
                    ref={paneDidMount}
                    className="msg-wrapper"
                  >
                    {messages &&
                      messages.map((msg) =>
                        msg.type === "text" ? (
                          <div
                            className={
                              msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? "chat-receiver sb12"
                                : "chat-sender sb11"
                            }
                          >
                            <h5 className="msg-head">
                              {msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? props.data?.mentor?.name
                                : props.data?.municipality?.manager?.name}{" "}
                              {new Date(msg.sentTime).toLocaleString()}
                            </h5>
                            <p className="msg-text">{msg.body}</p>
                            <div
                              className={
                                msg.senderId ===
                                  jwt.decode(localStorage.jwtToken)?.id &&
                                msg.status === "seen"
                                  ? "seen-msg-div"
                                  : "seen-msg-div-none"
                              }
                            >
                              <span
                                style={{
                                  margin: "3px",
                                  float: "right",
                                  fontSize: "12px",
                                }}
                              >
                                {new Date(msg.sentTime).toLocaleString()}
                              </span>
                              <DoneAllIcon
                                style={{
                                  color: "#3f51b5",
                                  margin: 0,
                                  float: "right",
                                }}
                              />
                            </div>
                          </div>
                        ) : msg.type === "location" ? (
                          <div
                            className={
                              msg.senderId ===
                              jwt.decode(localStorage.jwtToken)?.id
                                ? "chat-receiver-map-div"
                                : "chat-sender-map-div"
                            }
                          >
                            <img
                              src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=1&size=300x250&maptype=terrian&visual_refresh=true&markers=icon:https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/location-24-32.png%7C+${msg.body}&key=AIzaSyBthhfafE1UaPQDCVzzoX9avMbpmVL__OQ`}
                              alt="Map"
                              className={
                                msg.senderId ===
                                jwt.decode(localStorage.jwtToken)?.id
                                  ? "chat-receiver-map"
                                  : "chat-sender-map"
                              }
                            />
                          </div>
                        ) : null
                      )}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        setMessagesEnd(el);
                      }}
                    ></div>
                  </div>

                  <div className="send-msg-wrapper">
                    <div className="input-msg-div">
                      <textarea
                        className="input-msg-box"
                        rows="2"
                        cols="50"
                        name="comment"
                        placeholder={t("Enter message here...")}
                        value={newMessage}
                        onChange={handleNewMessageChange}
                        onClick={handleFocus}
                        onKeyPress={enterPressed}
                      />
                    </div>
                    <div className="send-btn-div">
                      <Button
                        variant="contained"
                        className={classes.send_btn}
                        size="small"
                        onClick={() => handleSendMessage()}
                        color="primary"
                        disabled={newMessage ? false : true}
                      >
                        {t("Send")}
                      </Button>
                    </div>
                  </div>
                  {typing ? <span className="typing">typing...</span> : null}
                </div>
              )}
              {(!props.data.municipality ||
                !props.data.municipality.manager) && (
                <div style={{ padding: "100px" }}>
                  {t("Manager is not assign to this refugee")}
                </div>
              )}
            </TabPanel>
            {props.data.suppliers.work &&
              props.data.suppliers.work.map((work, index) => {
                const indexOfExclamation = work.indexOf("!");
                
                // Extract the text after the exclamation mark
                const textAfterExclamation =
                  indexOfExclamation !== -1
                    ? work.substring(indexOfExclamation + 1)
                    : "";

                return (
                  <TabPanel key={index} value={value} index={index + 2}>
                    {work && (
                      <div className="chat-tab-div">
                        {hasMore && scrollTop ? (
                          <span className="typing">Loading...</span>
                        ) : null}
                        <div
                          id="scrollableDiv"
                          ref={paneDidMount}
                          className="msg-wrapper"
                        >
                          {messages &&
                            messages.map((msg) =>
                              msg.type === "text" ? (
                                <div
                                  className={
                                    msg.senderId ===
                                    jwt.decode(localStorage.jwtToken)?.id
                                      ? "chat-receiver sb12"
                                      : "chat-sender sb11"
                                  }
                                >
                                  <h5 className="msg-head">
                                    {msg.senderId ===
                                    jwt.decode(localStorage.jwtToken)?.id
                                      ? props.data?.mentor?.name
                                      : textAfterExclamation?.name}{" "}
                                    {new Date(msg.sentTime).toLocaleString()}
                                  </h5>
                                  <p className="msg-text">{msg.body}</p>
                                  <div
                                    className={
                                      msg.senderId ===
                                        jwt.decode(localStorage.jwtToken)?.id &&
                                      msg.status === "seen"
                                        ? "seen-msg-div"
                                        : "seen-msg-div-none"
                                    }
                                  >
                                    <span
                                      style={{
                                        margin: "3px",
                                        float: "right",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(msg.sentTime).toLocaleString()}
                                    </span>
                                    <DoneAllIcon
                                      style={{
                                        color: "#3f51b5",
                                        margin: 0,
                                        float: "right",
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : msg.type === "location" ? (
                                <div
                                  className={
                                    msg.senderId ===
                                    jwt.decode(localStorage.jwtToken)?.id
                                      ? "chat-receiver-map-div"
                                      : "chat-sender-map-div"
                                  }
                                >
                                  <img
                                    src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=1&size=300x250&maptype=terrian&visual_refresh=true&markers=icon:https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/location-24-32.png%7C+${msg.body}&key=YOUR_GOOGLE_MAPS_API_KEY`}
                                    alt="Map"
                                    className={
                                      msg.senderId ===
                                      jwt.decode(localStorage.jwtToken)?.id
                                        ? "chat-receiver-map"
                                        : "chat-sender-map"
                                    }
                                  />
                                </div>
                              ) : null
                            )}
                          <div
                            style={{ float: "left", clear: "both" }}
                            ref={(el) => {
                              setMessagesEnd(el);
                            }}
                          ></div>
                        </div>

                        <div className="send-msg-wrapper">
                          <div className="input-msg-div">
                            <textarea
                              className="input-msg-box"
                              rows="2"
                              cols="50"
                              name="comment"
                              placeholder={t("Enter message here...")}
                              value={newMessage}
                              onChange={handleNewMessageChange}
                              onClick={handleFocus}
                              onKeyPress={enterPressed}
                            />
                          </div>
                          <div className="send-btn-div">
                            <Button
                              variant="contained"
                              className={classes.send_btn}
                              size="small"
                              onClick={() => handleSendMessage()}
                              color="primary"
                              disabled={newMessage ? false : true}
                            >
                              {t("Send")}
                            </Button>
                          </div>
                        </div>
                        {typing ? (
                          <span className="typing">typing...</span>
                        ) : null}
                      </div>
                    )}
                    {!work && (
                      <div style={{ padding: "100px" }}>
                        {t("School Supplier is not assigned to this refugee")}
                      </div>
                    )}
                  </TabPanel>
                );
              })}
{props.data.suppliers.school &&
  props.data.suppliers.school.map((school, index) => {
    const indexOfExclamation = school.indexOf("!");
                
    // Extract the text after the exclamation mark
    const textAfterExclamation =
      indexOfExclamation !== -1
        ? school.substring(indexOfExclamation + 1)
        : "";

    return (
      <TabPanel
        key={index}
        value={value}
        index={
          props?.data?.suppliers?.work
            ? index + props?.data?.suppliers?.work?.length + 2
            : index + 2
        }
      >
        {school && (
          <div className="chat-tab-div">
            {hasMore && scrollTop ? (
              <span className="typing">Loading...</span>
            ) : null}
            <div
              id="scrollableDiv"
              ref={paneDidMount}
              className="msg-wrapper"
            >
              {messages &&
                messages.map((msg) =>
                  msg.type === "text" ? (
                    <div
                      className={
                        msg.senderId ===
                        jwt.decode(localStorage.jwtToken)?.id
                          ? "chat-receiver sb12"
                          : "chat-sender sb11"
                      }
                    >
                      <h5 className="msg-head">
                        {msg.senderId ===
                        jwt.decode(localStorage.jwtToken)?.id
                          ? props.data?.mentor?.name
                          : textAfterExclamation}{" "}
                        {new Date(msg.sentTime).toLocaleString()}
                      </h5>
                      <p className="msg-text">{msg.body}</p>
                      <div
                        className={
                          msg.senderId ===
                            jwt.decode(localStorage.jwtToken)?.id &&
                          msg.status === "seen"
                            ? "seen-msg-div"
                            : "seen-msg-div-none"
                        }
                      >
                        <span
                          style={{
                            margin: "3px",
                            float: "right",
                            fontSize: "12px",
                          }}
                        >
                          {new Date(msg.sentTime).toLocaleString()}
                        </span>
                        <DoneAllIcon
                          style={{
                            color: "#3f51b5",
                            margin: 0,
                            float: "right",
                          }}
                        />
                      </div>
                    </div>
                  ) : msg.type === "location" ? (
                    <div
                      className={
                        msg.senderId ===
                        jwt.decode(localStorage.jwtToken)?.id
                          ? "chat-receiver-map-div"
                          : "chat-sender-map-div"
                      }
                    >
                      <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=1&size=300x250&maptype=terrian&visual_refresh=true&markers=icon:https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/location-24-32.png%7C+${msg.body}&key=YOUR_GOOGLE_MAPS_API_KEY`}
                        alt="Map"
                        className={
                          msg.senderId ===
                          jwt.decode(localStorage.jwtToken)?.id
                            ? "chat-receiver-map"
                            : "chat-sender-map"
                        }
                      />
                    </div>
                  ) : null
                )}
              <div
                style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  setMessagesEnd(el);
                }}
              ></div>
            </div>

            <div className="send-msg-wrapper">
              <div className="input-msg-div">
                <textarea
                  className="input-msg-box"
                  rows="2"
                  cols="50"
                  name="comment"
                  placeholder={t("Enter message here...")}
                  value={newMessage}
                  onChange={handleNewMessageChange}
                  onClick={handleFocus}
                  onKeyPress={enterPressed}
                />
              </div>
              <div className="send-btn-div">
                <Button
                  variant="contained"
                  className={classes.send_btn}
                  size="small"
                  onClick={() => handleSendMessage()}
                  color="primary"
                  disabled={newMessage ? false : true}
                >
                  {t("Send")}
                </Button>
              </div>
            </div>
            {typing ? <span className="typing">typing...</span> : null}
          </div>
        )}
        {!school && (
          <div style={{ padding: "100px" }}>
            {t("School Supplier is not assigned to this refugee")}
          </div>
        )}
      </TabPanel>
    );
  })}

          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
