import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import RefugeesData from "./sub_components/AssignRefugeeToSupplierRefugeesData";
import AssignedRefugeesData from "./sub_components/AssignedRefugeeToSupplierRefugeesData";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignRefugeeToSupplier(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [assignedRefugeesState, setAssignedRefugeesState] = useState([]);
  const [selectedRefugees, setSelectedRefugees] = useState([]);
  const [unSelectedRefugees, setUnSelectedRefugees] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);

  const assignedRefugees = useSelector(
    ({ AssignRefugeeToSupplierReducer }) =>
      AssignRefugeeToSupplierReducer?.AllAssignedRefugeesReducer?.data
  );
  const loading = useSelector(
    ({ AssignRefugeeToSupplierReducer }) =>
      AssignRefugeeToSupplierReducer?.AllAssignedRefugeesReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignRefugeeToSupplierReducer }) =>
      AssignRefugeeToSupplierReducer?.AllAssignedRefugeesReducer?.errMsg
  );
  const add_confirmation = useSelector(
    ({ AssignRefugeeToSupplierReducer }) =>
      AssignRefugeeToSupplierReducer?.assignUnassignRolesToSupplierReducer
  );

  useEffect(() => {
    dispatch(
      Actions.getAllAssignedRefugees(
        page + 1,
        10000,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
    return () => {
      dispatch(Actions.resetAllAssignedRefugees(true));
      dispatch(Actions.resetAssignUnassignRolesToSupplier(true));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
    }
  }, [setSelectedRefugees]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedRefugees")) {
      setUnSelectedRefugees(
        JSON.parse(sessionStorage.getItem("unAssignedRefugees"))
      );
    }
  }, [setUnSelectedRefugees]);

  const fetchMoreData = () => {
    dispatch(
      Actions.getAllAssignedRefugees(
        page + 1,
        1000,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
  };

  useEffect(() => {
    if (
      assignedRefugees?.listAssignedRefugees?.docs &&
      assignedRefugees.listAssignedRefugees.docs.length
    ) {
      setPage(page + 1);
      setTotalDocs(assignedRefugees.listAssignedRefugees.totalDocs);
      setHasNextPage(assignedRefugees.listAssignedRefugees.hasNextPage);
      if (assignedRefugees.listAssignedRefugees.docs.length) {
        if (unSelectedRefugees.length) {
          setAssignedRefugeesState((assignedRefugeesState) => {
            const newAssignedRefugeesState = [
              ...assignedRefugeesState,
              ...assignedRefugees.listAssignedRefugees.docs,
            ];
            const unSelectedRefugeeIds = unSelectedRefugees.map(
              (unSelectedRefugee) => unSelectedRefugee?.id
            );
            return newAssignedRefugeesState.filter(
              (newAssignedRefugeeState) =>
                !unSelectedRefugeeIds.includes(newAssignedRefugeeState?.id)
            );
          });
        } else {
          setAssignedRefugeesState((assignedRefugeesState) => [
            ...assignedRefugeesState,
            ...assignedRefugees.listAssignedRefugees.docs,
          ]);
        }
      }
    }
  }, [assignedRefugees]);

  const createRefugee = () => {
    const values = {
      supplier_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      arefugeeIds: [],
      umunicipalityIds: [],
      urefugeeIds: [],
    };
    if (selectedRefugees.length > 0) {
      for (let i = 0; i < selectedRefugees.length; i++) {
        const element = selectedRefugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
    if (unSelectedRefugees.length > 0) {
      for (let i = 0; i < unSelectedRefugees.length; i++) {
        const element = unSelectedRefugees[i];
        values.urefugeeIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToSupplier(values));
  };

  useEffect(() => {
    if (add_confirmation?.data?.data?.assignUnassignRolesToSupplier) {
      // sessionStorage.clear();
      // sessionStorage.setItem("user_type", "refugee");
      // setLeaveScreenDialog(false);
      // navigate("/createUserByManager");
    }
  }, [add_confirmation]);

  const unAssignRefugee = (data) => {
    const unSelectedRefugee = {
      id: data?.id,
    };
    setSelectedRefugees((selectedRefugees) =>
      selectedRefugees.filter(
        (selectedRefugee) => selectedRefugee?.id !== unSelectedRefugee?.id
      )
    );
  };

  const unAssignRefugeeBackend = (data) => {
    const unSelectedRefugee = {
      id: data?.id,
    };
    setUnSelectedRefugees((unSelectedRefugees) => [
      ...unSelectedRefugees,
      unSelectedRefugee,
    ]);
    setAssignedRefugeesState((assignedRefugeesState) =>
      assignedRefugeesState.filter(
        (assignedRefugeeState) =>
          assignedRefugeeState?.id !== unSelectedRefugee?.id
      )
    );
  };

  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };

  const handleClickLeaveScreenDialogopen = () => {
    setLeaveScreenDialog(true);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <div className="main">
      {leaveScreenDialogOpen === true && (
        <LeaveScreenDialog
          status={leaveScreenDialogOpen}
          methodd={handleClickLeaveScreenDialogclose}
          role="Supplier"
          continueClick={createRefugee}
          loading={add_confirmation.isLoading}
          errMsg={add_confirmation.errMsg}
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <Grid container spacing={4} className={classes.con}>
            <Grid item md={7} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  <div className="assign-mentor-to-municipality-title-div">
                    <h3 className="assign-mentor-to-municipality-title">
                      {t("Choose Refugees from the list")}
                    </h3>
                  </div>
                  <Grid container spacing={4}>
                    <Grid item sm={8}>
                      <div className="assign-mentor-to-municipality-search-div">
                        <div className="assign-mentor-to-municipality-search-div-search-field-wrapper">
                          <TextField
                            id="search"
                            onChange={(e) => handleChangeSearch(e.target.value)}
                            value={searchText}
                            name="search"
                            className={classes.textFieldSearch}
                            type="text"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ClearIcon
                                    style={{
                                      fontSize: "24px",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleClearSearch()}
                                  />
                                </InputAdornment>
                              ),
                              classes: {
                                notchedOutline: classes.notchedOutline,
                                input: classes.input1,
                              },
                            }}
                            placeholder={t("Search by name or BSN number")}
                            margin="normal"
                            variant="outlined"
                            size="small"
                          />
                        </div>
                        <div className="assign-mentor-to-municipality-search-div-search-btn-wrapper">
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "0 auto",
                              marginTop: "5px",
                            }}
                          >
                            <SearchIcon
                              style={{
                                color: "white",
                                fontSize: "30px",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={4}>
                      <div className="assign-mentor-to-municipality-btn-div">
                        <Button
                          variant="contained"
                          className={classes.createButton}
                          color="primary"
                          onClick={() => handleClickLeaveScreenDialogopen()}
                        >
                          {t("Create New")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <RefugeesData
                  searchText={searchText}
                  selectedRefugees={selectedRefugees}
                  unSelectedRefugees={unSelectedRefugees}
                  setSelectedRefugees={setSelectedRefugees}
                  setUnSelectedRefugees={setUnSelectedRefugees}
                  setAssignedRefugeesState={setAssignedRefugeesState}
                  assignedRefugeesState={assignedRefugeesState}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  <div className="assign-mentor-to-municipality-title-div">
                    <h3 className="assign-mentor-to-municipality-title">
                      {t("Supplier")}: {sessionStorage.getItem("entity_name")}
                    </h3>
                    <p className="assigned-mentors-to-municipality-total-text">
                      {t("Total Assigned Refugees")}:{" "}
                      {totalDocs +
                        selectedRefugees.length -
                        unSelectedRefugees.length}
                    </p>
                  </div>
                </div>
                <AssignedRefugeesData
                  selectedRefugees={selectedRefugees}
                  unSelectedRefugees={unSelectedRefugees}
                  unAssignRefugee={unAssignRefugee}
                  unAssignRefugeeBackend={unAssignRefugeeBackend}
                  assignedRefugeesState={assignedRefugeesState}
                  totalDocs={totalDocs}
                  hasNextPage={hasNextPage}
                  fetchMoreData={fetchMoreData}
                  isButtonDisabled={loading}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withReducer(
  "AssignRefugeeToSupplierReducer",
  reducer
)(AssignRefugeeToSupplier);
