import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import UploadIcon from "@material-ui/icons/CloudUpload";
import {
  Checkbox,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  IconButton
} from "@material-ui/core";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import moment from "moment";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { pipSocialPageSchema } from "../../../../resources/scheme";
import { removeKey, formatDate } from "../../../../utils/helpers";
import DatePicker from "../../../shared/DatePicker";
import { pipPlanNavigationKeys } from '../../../../resources/constants';
import { fromFileToBase64 } from '../../../../utils/helpers';
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { DATE_FORMAT } from '../../../../resources/constants';


const useStyles = makeStyles({
  title: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "start",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "start",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "fit-content"
  },
  marginTopAndBottom: {
    margin: "20px 0"
  },
  buttonStyle: {
    mt: 3, mb: 2, width: "auto", marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9
    }
  },
  input: {
    display: "none",
  },
  btnupload: {
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    width: "450px",
    height: "90px",
    textTransform: "capitalize",
    boxShadow: "none",
  },
  customInput: {
    "& .MuiOutlinedInput-input": {
      padding: "5px",
    },
  },
});

function SocialGuidanceForm({ data, setPipPlanPage }) {
  const [t] = useTranslation();
  const [selectedPage , setSelectedPage] = useState("")
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const [scannedPDFName, setScannedPDFName] = useState('');
  const { refugeeId } = params;

  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.healthAndLeisure)
  };
  const navigatePreviousPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.details)
  }
  const formik = useFormik({
    initialValues: {
      dateLastModified: data?.dateLastModified ?? '',
      guidedBy: {
        from: data?.guidedBy?.from ?? '',
        to: data?.guidedBy?.to ?? '',
      },
      agreementsAboutGuidance: data?.agreementsAboutGuidance ?? '',
      scannedPDF: data?.scannedPDF ?? '',
      lifeStory: {
        from: data?.lifeStory?.from ?? '',
        livedIn: data?.lifeStory?.livedIn ?? [],
        normalDayForMe: data?.lifeStory?.normalDayForMe ?? '',
        leftMyCountryOn: data?.lifeStory?.leftMyCountryOn ?? '',
        willBeInTheNetherlands: data?.lifeStory?.willBeInTheNetherlands ?? '',
        oftenHaveContactWith: data?.lifeStory?.oftenHaveContactWith ?? [],
        familyLocation: data?.lifeStory?.familyLocation ?? '',
        friendsLocation: data?.lifeStory?.friendsLocation ?? '',
        missMostIs: data?.lifeStory?.missMostIs ?? '',
        concernedAbout: data?.lifeStory?.concernedAbout ?? '',
        moreAbout: data?.lifeStory?.moreAbout ?? '',
      },
    },
    enableReinitialize: true,
    validationSchema: pipSocialPageSchema,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        const queryString = JSON
          .stringify(removeKey({ ...values, dateLastModified: moment().format(DATE_FORMAT) }, '__typename'), null, 2)
          .replace(/"([^"]+)":/g, '$1:');
        const socialGuidanceQuery = `socialGuidance: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: socialGuidanceQuery })
          .then(() => {
            dispatch(getPipPlanByRefugee(refugeeId))
            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.details)
            } else{
              setPipPlanPage(pipPlanNavigationKeys.healthAndLeisure)
            }
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      } else {
        helpers.setSubmitting(false);
        navigateNextPage();
        if (selectedPage === "saveAndPrevious") {
          navigatePreviousPage()
        } else{
          navigateNextPage()
        }
      }
    },
  });

  const handleFileChange = async (event) => {
    const [file] = event.target.files;
    if (file) {
      setScannedPDFName(file.name);
      const base64String = await fromFileToBase64(file);
      const scannedPDF = base64String?.split(';base64,')?.[1] || data?.scannedPDF || '';
      formik.setFieldValue("scannedPDF", scannedPDF);
      event.target.value = '';
    }
  };

  const onLivedInChange = (event) => {
    const { name, checked } = event.target;
    const livedIn = [...formik.values?.lifeStory?.livedIn] || [];
    if (checked) {
      livedIn.push(name)
      formik.setFieldValue('lifeStory.livedIn', livedIn)
    } else {
      formik.setFieldValue('lifeStory.livedIn', livedIn.filter((place) => place !== name))
    }
  }

  const onContactWithChange = (event) => {
    const { name, checked } = event.target;
    const contacts = [...formik.values?.lifeStory?.oftenHaveContactWith] || [];
    if (checked) {
      contacts.push(name)
      formik.setFieldValue('lifeStory.oftenHaveContactWith', contacts)
    } else {
      formik.setFieldValue(
        'lifeStory.oftenHaveContactWith', contacts.filter((contact) => contact !== name)
      )
    }
  }

  return (
    <Grid container>
      <form
        noValidate
        style={{ width: "100%" }}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container item
        >
          <Grid
            style={{ padding: "0 60px" }}
            container
            item
            display="flex"
            direction="row"
          >
            <Grid container>
              <Typography variant="h2" className={classes.subTitle}>{t('My guidance')}</Typography>
            </Grid>

            <Box mb={4} mt={4} width="inherit">
              <DatePicker
                id="socialGuidance_lastModified"
                label={t('Date (last modified)')}
                name={'dateLastModified'}
                value={formatDate(formik.values?.dateLastModified)}
                disabled={true}
                fullWidth={true}
              />
            </Box>

            <Grid container>
              <Box mb={4} mt={2} mr={6}>
                <DatePicker
                  id="guidedBy_from"
                  name="guidedBy.from"
                  label={
                    <Grid style={{width: "max-content"}}>
                      {t('I am guided by')}
                    </Grid>
                  }
                  value={formatDate(formik.values?.guidedBy?.from)}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  fullWidth={true}
                  helperText={Boolean(formik.touched?.guidedBy?.from) && t(formik?.errors?.guidedBy?.from)}
                  error={Boolean(formik.touched?.guidedBy?.from) && Boolean(formik.errors?.guidedBy?.from)}
                />
              </Box>
              <Box mb={4} mt={2}>
                <DatePicker
                  id="guidedBy_to"
                  name="guidedBy.to"
                  label={t('until')}
                  value={formatDate(formik.values?.guidedBy?.to)}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  fullWidth={true}
                  helperText={Boolean(formik.touched?.guidedBy?.to) && t(formik?.errors?.guidedBy?.to)}
                  error={Boolean(formik.touched?.guidedBy?.to) && Boolean(formik.errors?.guidedBy?.to)}
                />
              </Box>
            </Grid>

            <TextField
              required
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              name="agreementsAboutGuidance"
              label={t("Agreements about guidance")}
              onBlur={formik.handleBlur}
              value={formik.values.agreementsAboutGuidance}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              helperText={Boolean(formik.touched?.agreementsAboutGuidance) && t(formik?.errors?.agreementsAboutGuidance)}
              error={Boolean(formik.touched?.agreementsAboutGuidance) && Boolean(formik.errors?.agreementsAboutGuidance)}
            />


            <Grid container className={classes.marginTopAndBottom}>
              <Typography variant="h2" className={classes.title}>{t('Brede intake')}</Typography>
            </Grid>

            <Grid container className={classes.marginTopAndBottom}>
              <Typography variant="h2" className={classes.subTitle}>
                {`${t('Upload the scanned PDF file')} ${scannedPDFName}`}
              </Typography>
            </Grid>

            {data?.scannedPDF && (
              <Grid container>
                <PictureAsPdfIcon href={data?.scannedPDF} />
                <a target="_blank" href={data?.scannedPDF}>{t('Uploaded PDF file')}</a>
              </Grid>
            )}

            <Grid>
              <input
                accept="application/pdf"
                className={classes.input}
                id="scannedPDF"
                name="scannedPDF"
                type="file"
                onChange={handleFileChange}
                disabled={formik.isSubmitting}
              />
              <label htmlFor="scannedPDF">
                <Button
                  variant="contained"
                  className={classes.btnupload}
                  disabled={formik.isSubmitting}
                  size="small"
                  component="span"
                  startIcon={<UploadIcon />}
                >
                  {t("Selecting")}
                </Button>
              </label>
              <p style={{ color: "red" }}>
                {formik.errors?.scannedPDF && (
                  <>
                    {formik.errors.scannedPDF}
                    <br />
                  </>
                )}
              </p>
            </Grid>

            <Grid container className={classes.marginTopAndBottom}>
              <Typography variant="h2" className={classes.subTitle}>{t('Life story')}</Typography>
            </Grid>

            <Grid container>
              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "300px" }}
                fullWidth
                name="lifeStory.from"
                label={t("I'm from")}
                onBlur={formik.handleBlur}
                value={formik.values.lifeStory?.from}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={Boolean(formik.touched?.lifeStory?.from) && t(formik?.errors?.lifeStory?.from)}
                error={Boolean(formik.touched?.lifeStory?.from) && Boolean(formik.errors?.lifeStory?.from)}
              />
            </Grid>

            <Grid container className={classes.marginTopAndBottom}>
              <Typography variant="h2" className={classes.subTitle}>{t('I lived in')}</Typography>
            </Grid>
            <FormControl required component="fieldset">
              <FormGroup label>
                <FormControlLabel
                  key={"city"}
                  control={
                    <Checkbox
                      checked={
                        formik.values?.lifeStory?.livedIn?.includes("city")
                      }
                      color='primary'
                      onChange={onLivedInChange}
                      name="city"
                    />
                  }
                  label={t('the city')}
                />
                <FormControlLabel
                  key={"village"}
                  control={
                    <Checkbox
                      checked={
                        formik.values?.lifeStory?.livedIn?.includes("village")
                      }
                      color='primary'
                      onChange={onLivedInChange}
                      name="village"
                    />
                  }
                  label={t('a village')}
                />
              </FormGroup>
            </FormControl>

            <TextField
              required
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              name="lifeStory.normalDayForMe"
              label={t("A normal day looked like this for me...")}
              onBlur={formik.handleBlur}
              value={formik.values?.lifeStory?.normalDayForMe}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              helperText={
                Boolean(formik.touched?.lifeStory?.normalDayForMe) &&
                t(formik?.errors?.lifeStory?.normalDayForMe)
              }
              error={
                Boolean(formik.touched?.lifeStory?.normalDayForMe) &&
                Boolean(formik.errors?.lifeStory?.normalDayForMe)
              }
            />

            <Box mb={4} mt={4} width="inherit">
              <DatePicker
                id="lifeStory_leftMyCountryOn"
                name="lifeStory.leftMyCountryOn"
                label={t('I left my country on')}
                value={formatDate(formik.values?.lifeStory?.leftMyCountryOn)}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                fullWidth={true}
                helperText={
                  Boolean(formik.touched?.lifeStory?.leftMyCountryOn) &&
                  t(formik?.errors?.lifeStory?.leftMyCountryOn)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.leftMyCountryOn) &&
                  Boolean(formik.errors?.lifeStory?.leftMyCountryOn)
                }
              />
            </Box>

            <Box mb={4} mt={4} width="inherit">
              <DatePicker
                id="lifeStory_willBeInTheNetherlands"
                name="lifeStory.willBeInTheNetherlands"
                label={t('I will be in the Netherlands from')}
                value={formatDate(formik.values?.lifeStory?.willBeInTheNetherlands)}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                fullWidth={true}
                helperText={
                  Boolean(formik.touched?.lifeStory?.willBeInTheNetherlands) &&
                  t(formik?.errors?.lifeStory?.willBeInTheNetherlands)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.willBeInTheNetherlands) &&
                  Boolean(formik.errors?.lifeStory?.willBeInTheNetherlands)
                }
              />
            </Box>


            <Grid container className={classes.marginTopAndBottom}>
              <Typography variant="h2" className={classes.subTitle}>{t('I often have contact with')}</Typography>
            </Grid>
            <FormControl required component="fieldset">
              <FormGroup label>
                <FormControlLabel
                  key={"family"}
                  control={
                    <Checkbox
                      checked={
                        formik.values?.lifeStory?.oftenHaveContactWith?.includes("family")
                      }
                      color='primary'
                      onChange={onContactWithChange}
                      name={"family"}
                    />
                  }
                  label={t('family')}
                />
                <FormControlLabel
                  key={"friends"}
                  control={
                    <Checkbox
                      checked={
                        formik.values?.lifeStory?.oftenHaveContactWith?.includes("friends")
                      }
                      color='primary'
                      onChange={onContactWithChange}
                      name="friends"
                    />
                  }
                  label={t('friends')}
                />
                <FormControlLabel
                  key={"neighbors"}
                  control={
                    <Checkbox
                      checked={
                        formik.values?.lifeStory?.oftenHaveContactWith?.includes("neighbors")
                      }
                      color='primary'
                      onChange={onContactWithChange}
                      name="neighbors"
                    />
                  }
                  label={t('neighbors')}
                />
              </FormGroup>
            </FormControl>

            <Box mt={2} width="inherit">
              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "300px" }}
                fullWidth
                name="lifeStory.familyLocation"
                label={t("My family lives in")}
                onBlur={formik.handleBlur}
                value={formik.values.lifeStory?.familyLocation}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.lifeStory?.familyLocation) &&
                  t(formik?.errors?.lifeStory?.familyLocation)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.familyLocation) &&
                  Boolean(formik.errors?.lifeStory?.familyLocation)
                }
              />
            </Box>

            <Box mt={2} mb={2} width="inherit">
              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "300px" }}
                fullWidth
                name="lifeStory.friendsLocation"
                label={t("My friends live in")}
                onBlur={formik.handleBlur}
                value={formik.values.lifeStory?.friendsLocation}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.lifeStory?.friendsLocation) &&
                  t(formik?.errors?.lifeStory?.friendsLocation)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.friendsLocation) &&
                  Boolean(formik.errors?.lifeStory?.friendsLocation)
                }
              />
            </Box>


            <Box mt={2} mb={2} width="inherit">
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="lifeStory.missMostIs"
                label={t('What I miss most is')}
                onBlur={formik.handleBlur}
                value={formik.values?.lifeStory?.missMostIs}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.lifeStory?.missMostIs) &&
                  t(formik?.errors?.lifeStory?.missMostIs)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.missMostIs) &&
                  Boolean(formik.errors?.lifeStory?.missMostIs)
                }
              />
            </Box>

            <Box mt={2} mb={2} width="inherit">
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="lifeStory.concernedAbout"
                label={t('I am concerned about')}
                onBlur={formik.handleBlur}
                value={formik.values?.lifeStory?.concernedAbout}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.lifeStory?.concernedAbout) &&
                  t(formik?.errors?.lifeStory?.concernedAbout)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.concernedAbout) &&
                  Boolean(formik.errors?.lifeStory?.concernedAbout)
                }
              />
            </Box>

            <Box mt={2} mb={6} width="inherit">
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="lifeStory.moreAbout"
                label={t('I would like to tell you more about')}
                onBlur={formik.handleBlur}
                value={formik.values?.lifeStory?.moreAbout}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.lifeStory?.moreAbout) &&
                  t(formik?.errors?.lifeStory?.moreAbout)
                }
                error={
                  Boolean(formik.touched?.lifeStory?.moreAbout) &&
                  Boolean(formik.errors?.lifeStory?.moreAbout)
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 60px"
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              onClick={() => setSelectedPage("saveAndPrevious")}

            >
              {t("SAVE & PREVIOUS")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              endIcon={formik.isSubmitting && (
                <CircularProgress size={18} color="inherit" />
              )}
            >
              {t("Save and Next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid >
  );
}

export default SocialGuidanceForm;
