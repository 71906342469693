import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import checkIcon from '../../assets/images/Tick_Mark_Icon.svg';

const useStyles = makeStyles((theme) => ({
    chkroot: {
      padding: 2
    },
    icon: {
      border: "1px solid #454A92",
      borderRadius: 0,
      width: "18px",
      height: "18px",
    },
    checkedIcon: {
      backgroundColor: '#ffffff',
      '&:before': {
        display: 'block',
        width: "18px",
        height: "18px",
        backgroundImage: `url(${checkIcon})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "14px 14px",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#ffffff',
      },
    },
}));

export default function StyledCheckbox(props) {
    const classes = useStyles();
  
    return (
      <Checkbox
        className={classes.chkroot}
        icon={<span className={classes.icon} />}
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        color="primary"
        {...props}
      />
    );
  }