import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { Grid, FormLabel } from "@material-ui/core";
function PhoneNumberInput(props) {
  const {
    country,
    value,
    onChange,
    id,
    name,
    disabled,
    onBlur,
    errorMessage,
    error,
    label,
  } = props;

  const [t] = useTranslation();

  return (
    <Grid item sm={10} xs={8}>
      <FormLabel>
        {t(label)}
      </FormLabel>
      <PhoneInput
        disabled={disabled}
        country={country}
        value={value}
        name={name}
        onChange={phone => onChange(phone, name)}
        onBlur={onBlur}
        inputProps={{
          name: 'phone',
          required: true,
        }}
        id={id}
        excludeCountries={["bg"]}
        defaultErrorMessage={errorMessage}
        isValid={() => {
          if (error) {
            return `${errorMessage}`;
          } else if (error) {
            return false;
          } else {
            return true;
          }
        }}
      />
    </Grid>
  );
};

export default PhoneNumberInput;

PhoneNumberInput.defaultProps = {
  country: 'nl',
  value: null,
  id: 'phoneNumber'
};
