import Grid from "@material-ui/core/Grid";
import { pipPlanNavigationKeys } from '../../../resources/constants';
import FrontPage from "./FrontPage";
import DetailsPage from "./Details";
import HealthAndLeisure from "./HealthAndLeisure";
import WorkAndActivities from './WorkAndActivities';
import TrainingAndDigitalSkills from "./TrainingAndDigitalSkills";
import Integration from "./IntegrationPage";
import SocialGuidance from "./SocialGuidance";
import GoalsAgreements from "./GoalsAndAgreements";
import ConsentFormPage from './ConsentForm';

function getPipPlanForm(formState, pipPlan, setPipPlanPage) {
  switch (formState) {
    case pipPlanNavigationKeys.frontPage:
      return <FrontPage data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.details:
      return <DetailsPage data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.socialGuidance:
      return <SocialGuidance data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.healthAndLeisure:
      return <HealthAndLeisure data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.trainingAndDigitalSkills:
      return <TrainingAndDigitalSkills data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.workAndActivities:
      return <WorkAndActivities data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.integration:
      return <Integration data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
    case pipPlanNavigationKeys.goalsAndAgreements:
      return <GoalsAgreements data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
      case pipPlanNavigationKeys.consentForm:
        return <ConsentFormPage data={pipPlan} setPipPlanPage={setPipPlanPage}/>;
    default:
      return <FrontPage data={pipPlan?.[formState]} setPipPlanPage={setPipPlanPage}/>;
  }
};

function PipPlanForm(props) {

  const { formState, pipPlan, setPipPlanPage } = props;

  return (
    <Grid container item>
      {getPipPlanForm(formState, pipPlan, setPipPlanPage)}
    </Grid>
  );
};

export default PipPlanForm;