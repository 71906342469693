import { useTranslation } from 'react-i18next';
import { MenuList, Paper, MenuItem } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { pipPlanSteps } from '../../resources/constants';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: "none",
    width: "fit-content",
  },
  menuList: {
    "& .Mui-selected": {
      backgroundColor: "#454a92",
      "&:hover": {
        backgroundColor: "#454a92",
        opacity: 0.7,
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "grey",
    },
  },
  menuItem: {
    backgroundColor: "#e84e0e",
    fontSize: "9pt",
    color: "white",
    maxHeight: "39px",
    minHeight: "39px",
    margin: "8px",
    borderRadius: "4px",
    paddingLeft: "6px",
    "&:hover": {
      backgroundColor: "#e84e0e",
      opacity: 0.7,
    }
  },
}));


function NavigationPanel(props) {
  const { handleClick, selectedMenuItemKey, pipPlan } = props;
  const [t] = useTranslation();
  const classes = useStyles();


  const handleClickMenuItem = (menuItemKey) => (
    handleClick(menuItemKey)
  );

  return (
    <Paper className={classes.paper}>
      <MenuList
        className={classes.menuList}
      >
        {pipPlanSteps?.map((pipInfo) => (
          <MenuItem
            name={pipInfo.name}
            key={pipInfo.key}
            className={classes.menuItem}
            style={{
              backgroundColor: pipPlan?.[pipInfo.key] ? "#454a92" : "none"
            }}
            selected={selectedMenuItemKey && pipInfo.key === selectedMenuItemKey}
            onClick={() => handleClickMenuItem(pipInfo.key)}
          >
            <DoneIcon
              style={{
                marginRight: "4px",
                color: pipPlan?.[pipInfo.key] ? "white" : "none",
                visibility: pipPlan?.[pipInfo.key] ? "visible" : "hidden"
              }}
            />
            {t(pipInfo.title)}
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
}
export default NavigationPanel;
