import React from "react";
import Barchart from "./Barchart";
import graphb from "../../../assets/images/graphb.svg";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

function GraphData({
  classes,
  details,
  handleClose,
  setTotalProgress,
  totalProgress,
  t,
}) {
  if (details) {
    return (
      <React.Fragment>
        <Grid item sm={9} md={9} lg={9} xs={9}>
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <Typography variant="h5" gutterBottom className={classes.heading1}>
              {t("Statistics")}
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.subheading1}
            >
              {details.name}
            </Typography>

            <Barchart data={details} setTotalProgress={setTotalProgress} />
          </div>
        </Grid>

        <Grid item sm={3} md={3} lg={3} xs={3}>
          <IconButton
            aria-label="cross"
            className={classes.crossIconBtnGraph}
            onClick={() => handleClose()}
          >
            <ClearIcon className={classes.crossIcon} />
          </IconButton>
          <div className="graph-total">
            <img className="graph-total-image" src={graphb} alt="grpahb"></img>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "-55px",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  margin: "0",
                  color: "#454a92",
                  fontFamily: "opensans-bold",
                  fontSize: "32pt",
                }}
              >
                {parseInt(totalProgress)}%
              </p>
              <p
                style={{
                  margin: "0",
                  marginTop: "-5px",
                  color: "#454a92",
                  fontFamily: "opensans-bold",
                  fontSize: "10pt",
                }}
              >
                {t("Total progress")}
              </p>
              <p
                style={{
                  margin: "0",
                  color: "#8fcccd",
                  fontFamily: "opensans-bold",
                  fontSize: "9pt",
                }}
              >
                {t("Self-Sustainability")}
              </p>
            </div>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
  return null;
}

export default GraphData;
