import { Text, View, Page, Image } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { generateDownloadFileLink } from "../../../utils/helpers";

const FRONT_PAGE_LABELS = {
  "name": "Name",
  "integrationDirector": "Integration Director",
  "phoneNumber": "Phone number",
  "emailAddress": "E-mail address"
};

function FrontPagePdf({ frontPageData, headerText, municipality }) {
  const pdfContent = { ...frontPageData }
  delete pdfContent.__typename;
  const { logoUrl, name } = municipality;
  const downloadFileLink = generateDownloadFileLink("logoUrl", logoUrl);

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text style={styles.subBodyTitle}>
            {i18next.t("In the municipality of")} {name}
          </Text>
        </View>
        <View
          style={{
            padding: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image
            style={{
              width: "120px",
              height: "120px"
            }}
            src={
              {
                uri: downloadFileLink,
                method: 'GET',
                headers: {
                  authorization: localStorage.getItem('jwtToken') || "",
                  "x-auth-token": localStorage.getItem('jwtToken') || ""
                }
              }
            }
          />
        </View>
        <View style={{display: "flex", alignItems: "center", padding: 10, justifyContent: "center"}}>
          <Text style={styles.subBodyTitle}>
            {i18next.t("Integration and participation plan (PIP)")}
          </Text>
        </View>
        <View style={styles.viewRowContainer}>
          {Object.entries(pdfContent)?.map((content) => {
            const [key, value] = content;
            return (
              <View
                key={key}
                style={styles.viewRow}>
                <View style={{ width: "50%", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(FRONT_PAGE_LABELS[key])}
                  </Text>
                </View>
                <View style={styles.viewRowInput}>
                  <Text> {value}</Text>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    </Page>
  );
};

export default FrontPagePdf;
