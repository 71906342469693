export const roles = {
  admin: {
    id: "admin",
    title: "Admin",
  },
  manager: {
    id: "manager",
    title: "Manager",
  },
  mentor: {
    id: "mentor",
    title: "Mentor",
  },
  school_supplier: {
    id: "school_supplier",
    title: "School Supplier",
  },
  work_supplier: {
    id: "work_supplier",
    title: "Work Supplier",
  },
  refugee: {
    id: "refugee",
    title: "Refugee",
  },
  "N/A": {
    id: "N/A",
    title: "N/A",
  },
};
