import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import StyledCheckbox from "../../shared/StyledCheckbox";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
}));

function AssignMunicipalityToRefugeeMunicipalitiesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectIndex, setSelectIndex] = useState(1000)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [municipalities, setMunicipalities] = useState({});

  const SearchedMunicipalities = useSelector(
    ({ AssignMunicipalityToRefugeeMunicipalitiesDataReducer }) =>
      AssignMunicipalityToRefugeeMunicipalitiesDataReducer
        ?.allMiniSearchedMunicipalitiesReducer?.data
  );
  const loading = useSelector(
    ({ AssignMunicipalityToRefugeeMunicipalitiesDataReducer }) =>
      AssignMunicipalityToRefugeeMunicipalitiesDataReducer
        ?.allMiniSearchedMunicipalitiesReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMunicipalityToRefugeeMunicipalitiesDataReducer }) =>
      AssignMunicipalityToRefugeeMunicipalitiesDataReducer
        ?.allMiniSearchedMunicipalitiesReducer?.errMsg
  );
 
  
  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedMunicipalities(true));
    };
  }, []);

  useEffect(() => {
    dispatch(
      Actions.getAllMiniSearchedMunicipalities(
        page + 1,
        10000,
        props.searchText,
        "",
        sessionStorage.getItem("user_id")
      )
    );
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (event) => {
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    props.setChangeMunicipality(randomNumber)
    props.setUserState(randomNumber)
    const index = event.target.value;
    
    
    const newMunicipalitiesObj = { ...municipalities };
    newMunicipalitiesObj.docs[index].isSelected =
      !municipalities.docs[index].isSelected;
    if (newMunicipalitiesObj.docs[index].isSelected) {
      const selectedMunicipality = {
        id: newMunicipalitiesObj.docs[index]?.id,
        name: newMunicipalitiesObj.docs[index].name,
        province: newMunicipalitiesObj.docs[index].province,
        contactPerson: newMunicipalitiesObj.docs[index].contactPerson,
      };
      props.setSelectedMunicipalities([selectedMunicipality]);
    } else {
      props.setSelectedMunicipalities(
        props.selectedMunicipalities.filter(
          (selectedMunicipality) =>
            selectedMunicipality?.id !== newMunicipalitiesObj.docs[index]?.id
        )
      );
    }
    setMunicipalities(newMunicipalitiesObj);
    props.setState(newMunicipalitiesObj)
    
  };

  useEffect(() => {
    const municipalitiesObj =
      SearchedMunicipalities?.miniSearchAllMunicipalities;
    
    if (municipalitiesObj) {
      const newMunicipalitiesObj = { ...municipalitiesObj };
      if (newMunicipalitiesObj.docs && newMunicipalitiesObj.docs.length) {
        for (let i = 0; i < newMunicipalitiesObj.docs.length; i++) {
          newMunicipalitiesObj.docs[i].isSelected = false;
          if (props.selectedMunicipalities.length) {
            for (let j = 0; j < props.selectedMunicipalities.length; j++) {
              if (
                newMunicipalitiesObj.docs[i]?.id ===
                props.selectedMunicipalities[j]?.id
              ) {
                newMunicipalitiesObj.docs[i].isSelected = true;
              }
            }
          }
        }
      }
      setMunicipalities(newMunicipalitiesObj);
    }
  }, [SearchedMunicipalities, props.selectedMunicipalities]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  props.setState(props.selectedMunicipalities)

  useEffect(() => {
    const municipalitiesNow = JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
  
    if(municipalitiesNow && props?.isEdit === "true") {
     
        
        const municipalitiesNow = JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
        municipalities?.docs?.forEach((value,index) => {
          if(value.id === municipalitiesNow[0]?.id) {
            setSelectIndex(index)
          }
        })  
     
    }
    else {
      
      municipalities?.docs?.forEach((value,index) => {
      
        
        if(value.id === props.selectedMunicipalities[0]?.id) {
          setSelectIndex(index)
        }
      })  
    }
  },[props.selectedMunicipalities,municipalities,props?.isEdit])
  
  return (
    <div>
    <h3>Select the Municipality</h3>
    <Select
      value={selectIndex}
      onChange={handleChangeSelected}
      className={classes.select}
      disabled={props?.disabled}
    >
      <MenuItem value="1000" disabled>
        Please make a selection
      </MenuItem>
      {municipalities &&
        municipalities.docs !== null &&
        municipalities.docs &&
        municipalities.docs.length > 0 &&
        municipalities.docs.map((doc, index) => (
          <MenuItem key={doc?.id} value={index} name={doc?.name}>
            {doc.name}
          </MenuItem>
        ))}
    </Select>
  </div>
  );
}

export default withReducer(
  "AssignMunicipalityToRefugeeMunicipalitiesDataReducer",
  reducer
)(AssignMunicipalityToRefugeeMunicipalitiesData);
