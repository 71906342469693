import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import SuccessDialog from "./sub_components/AddMunicipalityDetailSuccessDialog";
import { useTranslation } from "react-i18next";
import AssignMentorToMunicipality from "./AssignMentorToMunicipality";
import AssignRefugeeToMunicipality from "./AssignRefugeeToMunicipality"
import AssignSupplierToMunicipality from "./AssignSupplierToMunicipality"
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  editButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginLeft: "auto",
    marginTop: "50px",
    boxShadow: "none",
  },
  btnsCon: {
    marginTop: "20px",
  },
  button: {
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "150px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
  },
}));
function PostMunicipalityDetails(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedRefugees, setSelectedRefugees] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const add_confirmation = useSelector(
    ({ PostMunicipalityDetailsReducer }) =>
      PostMunicipalityDetailsReducer?.assignUnassignRolesToMunicipalityReducer
  );
  useEffect(() => {
    dispatch(Actions.getMunicipalitySummary(sessionStorage.getItem("user_id")));
    return () => {
      dispatch(Actions.resetMunicipalitySummary(true));
      dispatch(Actions.resetAssignUnassignRolesToMunicipality(true));
    };
  }, []);
  const municipalitySummary = useSelector(
    ({ PostMunicipalityDetailsReducer }) =>
      PostMunicipalityDetailsReducer?.getMunicipalitySummaryReducer?.data
  );
  const loading = useSelector(
    ({ PostMunicipalityDetailsReducer }) =>
      PostMunicipalityDetailsReducer?.getMunicipalitySummaryReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ PostMunicipalityDetailsReducer }) =>
      PostMunicipalityDetailsReducer?.getMunicipalitySummaryReducer?.errMsg
  );
  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
    }
  }, [setSelectedRefugees]);

  const handleClickSuccessDialogclose = () => {
    setSuccessOpen(false);
  };
  const handleClickSuccessDialogopen = () => {
    setSuccessOpen(true);
  };
  const handleClickEdit = (path) => {
    navigate(path);
  };
  const handleClickEditUser = () => {
    sessionStorage.removeItem("assignedRefugees")
    sessionStorage.removeItem("assignedMentors")
    sessionStorage.removeItem("assignedSuppliers")

    navigate("/ViewEditMunicipalitySummary");
  };

  useEffect(() => {
    let refugees = JSON.parse(sessionStorage.getItem("assignedRefugees"))
    let mentors = JSON.parse(sessionStorage.getItem("assignedMentors"))
    let suppliers = JSON.parse(sessionStorage.getItem("assignedSuppliers"))
    let values = {
      municipality_id: sessionStorage.getItem("user_id"),
      amentorIds: [],
      asupplierIds: [],
      arefugeeIds: [],
      umentorIds: [],
      usupplierIds: [],
      urefugeeIds: [],
    };
    if (mentors?.length > 0) {
      for (let i = 0; i < mentors?.length; i++) {
        const element = mentors[i];
        values.amentorIds.push(element?.id);
      }
    }
    if (suppliers?.length > 0) {
      for (let i = 0; i < suppliers?.length; i++) {
        const element = suppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (refugees?.length > 0) {
      for (let i = 0; i < refugees.length; i++) {
        const element = refugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
   
    dispatch(Actions.assignUnassignRolesToMunicipality(values));
  },[])
 
  useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignRolesToMunicipality
    ) {
      
    }
  }, [add_confirmation]);
  const handleNavigateToDashboard = () => {
    sessionStorage.clear();
    navigate("/dashboard");
  };
  return (
    <div className="main">
      {successOpen === true && (
        <SuccessDialog
          status={successOpen}
          methodd={handleClickSuccessDialogclose}
          role="Municipality"
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <div className="edit-municipality-detail-view-div">
            <p className="edit-municipality-detail-view-title">
              {t("Municipality")}
            </p>
            {municipalitySummary &&
              municipalitySummary.getMunicipalitySummary && (
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("Name")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {municipalitySummary.getMunicipalitySummary.name}
                      </span>
                    </p>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("Land")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {municipalitySummary.getMunicipalitySummary.province}
                      </span>
                    </p>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("Manager")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {
                          municipalitySummary.getMunicipalitySummary.manager
                            .name
                        }
                      </span>
                    </p>
                  </Grid>
                  <Grid item sm={3}>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("Contact Person")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {
                          municipalitySummary.getMunicipalitySummary
                            .contactPerson.name
                        }
                      </span>
                    </p>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("Email")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {
                          municipalitySummary.getMunicipalitySummary.manager
                            .email
                        }
                      </span>
                    </p>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("Phone")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {
                          municipalitySummary.getMunicipalitySummary.manager
                            .phoneNumber
                        }
                      </span>
                    </p>
                  </Grid>
                  <Grid item sm={6}>
                    <p className="edit-municipality-detail-view-data">
                      <span className="edit-municipality-detail-view-label">
                        {t("POI (DISK) Link")}:{" "}
                      </span>{" "}
                      <span className="edit-municipality-detail-view-text">
                        {municipalitySummary.getMunicipalitySummary.poiLink ? (
                          <a
                            target="_blank"
                            href={
                              municipalitySummary.getMunicipalitySummary.poiLink
                            }
                          >
                            {municipalitySummary.getMunicipalitySummary.poiLink}
                          </a>
                        ) : (
                          "N/A"
                        )}{" "}
                      </span>
                    </p>

                    <div className="edit-municipality-detail-view-image-div">
                      <p className="edit-municipality-detail-view-logo-label">
                        {t("Logo")}:&nbsp;
                      </p>
                      <p className="edit-municipality-detail-view-logo-text">
                        {municipalitySummary.getMunicipalitySummary.logoUrl &&
                          municipalitySummary.getMunicipalitySummary.logoUrl.slice(
                            municipalitySummary.getMunicipalitySummary.logoUrl
                              .length - 12
                          )}
                      </p>
                      <div className="edit-municipality-detail-view-image-span">
                        <img
                          alt="logo"
                          className="edit-municipality-detail-view-image"
                          src={
                            municipalitySummary.getMunicipalitySummary.logoUrl
                          }
                        ></img>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            <Grid container spacing={2}>
            
            </Grid>
          </div>
          <div className="edit-municipality-detail-view-div">
           
           
              <Grid container style={{width: "100%"}}>
   
      <Grid item xs={6}>
      <AssignRefugeeToMunicipality disabled={true}/>
      </Grid>
    
    </Grid>
       
              
             
          </div>
          <Grid container spacing={2} className={classes.btnsCon}>
            <Grid item sm={8} xs={12}></Grid>
            <Grid item sm={2} xs={12}>
              
            </Grid>
            <Grid item sm={2} xs={12}>
            <Button
                variant="contained"
                className={classes.button}
                onClick={() => handleClickEditUser()}
                color="primary"
              >
                {t("Edit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
export default withReducer(
  "PostMunicipalityDetailsReducer",
  reducer
)(PostMunicipalityDetails);
