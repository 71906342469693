import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import SuppliersData from "./sub_components/AssignSupplierToRefugeeSuppliersData";
import AssignedSuppliersData from "./sub_components/AssignedSupplierToRefugeeSuppliersData";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import { useTranslation } from "react-i18next";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    // borderWidth: "0px",
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignSupplierToRefugee(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);
  const [unAssignId, setUnAssignId] = useState(false);
  const [editCheck, setEditCheck] = useState(false);
  const [state, setState] = useState(1);
  const add_confirmation = useSelector(
    ({ AssignSupplierToRefugeeReducer }) =>
      AssignSupplierToRefugeeReducer?.assignUnassignRolesToRefugeeReducer
  );
  React.useEffect(() => {
    return () => {
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
      setEditCheck(true);
    }
  }, [setSelectedMunicipalities]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [add_confirmation]);

  const handleChangeSearch = (value) => {
    setSearchText(value);
  };

  const unAssignSupplier = (data) => {
    setUnAssignId(true);
    setSelectedSuppliers(
      selectedSuppliers.filter((item) => item?.id !== data?.id)
    );
  };

  useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignUnassignRolesToRefugee
    ) {
   
      
    
    }
  }, [add_confirmation]);

  const clickBack = () => {
    navigate(-1);
  };
  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };
  const handleClickLeaveScreenDialogopen = () => {
    setLeaveScreenDialog(true);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <Container fixed>
      <Grid container spacing={4} className={classes.con}>
        <Grid item md={7} sm={12} xs={12}>
          <div className="assign-mentor-to-municipality-left-grid-div">
            <div className="assign-mentor-to-municipality-left-grid-child-div"></div>
            <SuppliersData
              searchText={searchText}
              selectedSuppliers={selectedSuppliers}
              setSelectedSuppliers={setSelectedSuppliers}
              unAssignId={unAssignId}
              setUnAssignId={setUnAssignId}
              editCheck={editCheck}
              setEditCheck={setEditCheck}
              setState={setState}
              disabled={props?.disabled}
            />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <div className="assign-mentor-to-municipality-right-grid-div">
            <AssignedSuppliersData
              selectedSuppliers={selectedSuppliers}
              unAssignSupplier={unAssignSupplier}
              state={state}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
export default withReducer(
  "AssignSupplierToRefugeeReducer",
  reducer
)(AssignSupplierToRefugee);
