import { Text, View, Page } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate } from "../../../utils/helpers";

function GoalsAndAgreementsPdf({ goalsAndAgreementsPageData, headerText }) {
  delete goalsAndAgreementsPageData.__typename;

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View><Text style={styles.subBodyTitle}>{i18next.t(headerText)}</Text></View>
        <View style={styles.viewRowContainer}>
          {Object.entries(goalsAndAgreementsPageData)?.map((goalsAndAgreements, index) => {
            const [key, value] = goalsAndAgreements;
            const { by, until, objective } = value
            return (
              <View key={`goalAndAgreements${key}`} style={styles.viewRowContainer}>
                <View style={styles.viewRow}>
                  <View style={{ width: "55%", fontSize: 10 }}>
                    <Text style={styles.label}>
                      {`${i18next.t('Objective')} ${index + 1}`}
                    </Text>
                  </View>

                  <View style={styles.viewRowInput}>
                    <Text>{`${objective}`.split('')}</Text>
                  </View>
                </View>

                <View style={styles.viewRow}>
                  <View style={{ width: "55%", fontSize: 10 }}>
                    <Text style={styles.label}>
                      {i18next.t("by")}
                    </Text>
                  </View>

                  <View style={styles.viewRowInput}>
                    <Text>{formatDate(by)}</Text>
                  </View>
                </View>
                <View style={styles.viewRow}>
                  <View style={{ width: "55%", fontSize: 10 }}>
                    <Text style={styles.label}>
                      {i18next.t("until")}
                    </Text>
                  </View>

                  <View style={styles.viewRowInput}>
                    <Text>{formatDate(until)}</Text>
                  </View>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    </Page>
  );
};

export default GoalsAndAgreementsPdf;
