import React from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "./ROUTES";
import NoMatch from "../components/NotFound/NoMatch";
import Auth from "../components/auth/index";

function Index() {
  return (
    <Routes>
      {ROUTES.map((route) => (
        <Route
          key={route?.id}
          path={route.path}
          element={
            route.isMultiple ? (
              route.element
            ) : (
              <Auth
                children={route.element}
                isAdmin={route.isAdmin}
                rolesAssigned={route?.roles}
                path={route.path}
              />
            )
          }
        />
      ))}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Index;
