import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import MentorsData from "./sub_components/AssignMentorToRefugeeMentorsData";
import AssignedMentorsData from "./sub_components/AssignedMentorToRefugeeMentorsData";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  con: {
    width: "calc(100% + 170px) !important",
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignMentorToRefugee(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [assignedMentorsState, setAssignedMentorsState] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [unSelectedMentors, setUnSelectedMentors] = useState([]);
  const [unSelectedSuppliers, setUnSelectedSuppliers] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);

  const assignedMentors = useSelector(
    ({ AssignMentorToRefugeeReducer }) =>
      AssignMentorToRefugeeReducer?.AllAssignedMentorsReducer?.data
  );
  const loading = useSelector(
    ({ AssignMentorToRefugeeReducer }) =>
      AssignMentorToRefugeeReducer?.AllAssignedMentorsReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMentorToRefugeeReducer }) =>
      AssignMentorToRefugeeReducer?.AllAssignedMentorsReducer?.errMsg
  );
  const add_confirmation = useSelector(
    ({ AssignMentorToRefugeeReducer }) =>
      AssignMentorToRefugeeReducer?.assignUnassignRolesToRefugeeReducer
  );

  useEffect(() => {
    dispatch(
      Actions.getAllAssignedMentors(
        page + 1,
        rowsPerPage,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
    return () => {
      dispatch(Actions.resetAllAssignedMentors(true));
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, [props?.clickState]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedMentors")) {
      setUnSelectedMentors(
        JSON.parse(sessionStorage.getItem("unAssignedMentors"))
      );
    }
  }, [setUnSelectedMentors]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedSuppliers")) {
      setUnSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("unAssignedSuppliers"))
      );
    }
  }, [setUnSelectedSuppliers]);

  const fetchMoreData = () => {
    dispatch(
      Actions.getAllAssignedMentors(
        page + 1,
        rowsPerPage,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
  };

  // useEffect(() => {
  //   if (
  //     assignedMentors?.listAssignedMentors?.docs &&
  //     assignedMentors.listAssignedMentors.docs.length
  //   ) {
  //     setPage(page + 1);
  //     setTotalDocs(assignedMentors.listAssignedMentors.totalDocs);
  //     setHasNextPage(assignedMentors.listAssignedMentors.hasNextPage);
  //     if (unSelectedMentors.length) setAssignedMentorsState([]);
  //     else {
  //       setAssignedMentorsState((assignedMentorsState) => [
  //         ...assignedMentorsState,
  //         ...assignedMentors.listAssignedMentors.docs,
  //       ]);
  //     }
  //   }
  // }, [assignedMentors]);

  
  useEffect(() => {
    if (add_confirmation?.data?.data?.assignUnassignRolesToRefugee) {
      // sessionStorage.clear();
      // sessionStorage.setItem("user_type", "mentor");
      // setLeaveScreenDialog(false);
      
    }
  }, [add_confirmation]);

  const unAssignMentor = (data) => {
    setSelectedMentors([]);
  };

  const unAssignMentorBackend = (data) => {
    const unSelectedMentor = {
      id: data?.id,
    };
    setUnSelectedMentors([unSelectedMentor]);
    setAssignedMentorsState([]);
  };

  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };

  const handleClickLeaveScreenDialogopen = () => {
    setLeaveScreenDialog(true);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };
  
  return (
  
        <Container fixed>
          <Grid container spacing={4} className={classes.con}>
            <Grid item md={7} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  
                  
                </div>
                <MentorsData
                  searchText={searchText}
                  selectedMentors={selectedMentors}
                  unSelectedMentors={unSelectedMentors}
                  setSelectedMentors={setSelectedMentors}
                  setUnSelectedMentors={setUnSelectedMentors}
                  assignedMentorsState={assignedMentorsState}
                  setAssignedMentorsState={setAssignedMentorsState}
                  setClickState={props.setClickState}
                  disabled={props?.disabled}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  
                </div>
                <AssignedMentorsData
                  selectedMentors={selectedMentors}
                  unSelectedMentors={unSelectedMentors}
                  unAssignMentor={unAssignMentor}
                  unAssignMentorBackend={unAssignMentorBackend}
                  assignedMentorsState={assignedMentorsState}
                  totalDocs={totalDocs}
                  hasNextPage={hasNextPage}
                  fetchMoreData={fetchMoreData}
                  isButtonDisabled={loading}
                  clickState={props.clickState}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
     
  );
}

export default withReducer(
  "AssignMentorToRefugeeReducer",
  reducer
)(AssignMentorToRefugee);
