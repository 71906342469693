import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MunicipalitiesData from "./sub_components/AssignMunicipalityToSupplierMunicipalitiesData";
import AssignedMunicipalitiesData from "./sub_components/AssignedMunicipalityToSupplierMunicipalitiesData";
import LimitWarningDialog from "./sub_components/LimitWarningDialog";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignMunicipalityToSupplier(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [assignedMunicipalitiesState, setAssignedMunicipalitiesState] =
    useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedRefugees, setSelectedRefugees] = useState([]);
  const [unSelectedMunicipalities, setUnSelectedMunicipalities] = useState([]);
  const [unSelectedRefugees, setUnSelectedRefugees] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);

  const assignedMunicipalities = useSelector(
    ({ AssignMunicipalityToSupplierReducer }) =>
      AssignMunicipalityToSupplierReducer?.AllAssignedMunicipalitiesReducer
        ?.data
  );
  const loading = useSelector(
    ({ AssignMunicipalityToSupplierReducer }) =>
      AssignMunicipalityToSupplierReducer?.AllAssignedMunicipalitiesReducer
        ?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMunicipalityToSupplierReducer }) =>
      AssignMunicipalityToSupplierReducer?.AllAssignedMunicipalitiesReducer
        ?.errMsg
  );
  const add_confirmation = useSelector(
    ({ AssignMunicipalityToSupplierReducer }) =>
      AssignMunicipalityToSupplierReducer?.assignUnassignRolesToSupplierReducer
  );

  useEffect(() => {
    dispatch(
      Actions.getAllAssignedMunicipalities(
        page + 1,
        rowsPerPage,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
    return () => {
      dispatch(Actions.resetAssignedMunicipality(true));
      dispatch(Actions.resetAssignUnassignRolesToSupplier(true));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
    }
  }, [setSelectedMunicipalities]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
    }
  }, [setSelectedRefugees]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedMunicipalities")) {
      setUnSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("unAssignedMunicipalities"))
      );
    }
  }, [setUnSelectedMunicipalities]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedRefugees")) {
      setUnSelectedRefugees(
        JSON.parse(sessionStorage.getItem("unAssignedRefugees"))
      );
    }
  }, [setUnSelectedRefugees]);

  const fetchMoreData = () => {
    dispatch(
      Actions.getAllAssignedMunicipalities(
        page + 1,
        rowsPerPage,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
  };

  useEffect(() => {
    if (
      assignedMunicipalities?.listAssignedMunicipalities?.docs &&
      assignedMunicipalities.listAssignedMunicipalities.docs.length
    ) {
      setPage(page + 1);
      setTotalDocs(assignedMunicipalities.listAssignedMunicipalities.totalDocs);
      setHasNextPage(
        assignedMunicipalities.listAssignedMunicipalities.hasNextPage
      );
      if (!selectedMunicipalities.length)
        setSelectedMunicipalities(
          assignedMunicipalities.listAssignedMunicipalities.docs
        );
    }
  }, [assignedMunicipalities]);

  const createMunicipality = () => {
    const values = {
      supplier_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      arefugeeIds: [],
      umunicipalityIds: [],
      urefugeeIds: [],
    };
    if (selectedMunicipalities.length > 0) {
      for (let i = 0; i < selectedMunicipalities.length; i++) {
        const element = selectedMunicipalities[i];
        values.amunicipalityIds.push(element?.id);
      }
    }
    if (selectedRefugees.length > 0) {
      for (let i = 0; i < selectedRefugees.length; i++) {
        const element = selectedRefugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
    if (unSelectedMunicipalities.length > 0) {
      for (let i = 0; i < unSelectedMunicipalities.length; i++) {
        const element = unSelectedMunicipalities[i];
        values.umunicipalityIds.push(element?.id);
      }
    }
    if (unSelectedRefugees.length > 0) {
      for (let i = 0; i < unSelectedRefugees.length; i++) {
        const element = unSelectedRefugees[i];
        values.urefugeeIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToSupplier(values));
  };

  useEffect(() => {
    if (add_confirmation?.data?.data?.assignUnassignRolesToSupplier) {
      // sessionStorage.clear();
      // sessionStorage.setItem("user_type", "municipality");
      // setLeaveScreenDialog(false);
      // navigate("/createUserByAdmin");
    }
  }, [add_confirmation]);

  const unAssignMunicipality = (data) => {
    setSelectedMunicipalities(
      selectedMunicipalities.filter(
        (selectedMunicipality) => selectedMunicipality?.id !== data?.id
      )
    );
  };

  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };

  const handleClickLeaveScreenDialogopen = () => {
    if (!selectedMunicipalities.length) setWarningOpen(true);
    else setLeaveScreenDialog(true);
  };

  const handleClickWarningDialogclose = () => {
    setWarningOpen(false);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <div className="main">
      {leaveScreenDialogOpen === true && (
        <LeaveScreenDialog
          status={leaveScreenDialogOpen}
          methodd={handleClickLeaveScreenDialogclose}
          role="Supplier"
          continueClick={createMunicipality}
          loading={add_confirmation.isLoading}
          errMsg={add_confirmation.errMsg}
        />
      )}
      {warningOpen === true && (
        <LimitWarningDialog
          status={warningOpen}
          methodd={handleClickWarningDialogclose}
          role="Municipality"
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <Grid container spacing={4} className={classes.con}>
            <Grid item md={7} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  <div className="assign-mentor-to-municipality-title-div">
                    <h3 className="assign-mentor-to-municipality-title">
                      {t("Choose Municipalities from the list")}
                    </h3>
                  </div>
                  <Grid container spacing={4}>
                    <Grid item sm={8}>
                      <div className="assign-mentor-to-municipality-search-div">
                        <div className="assign-mentor-to-municipality-search-div-search-field-wrapper">
                          <TextField
                            id="search"
                            onChange={(e) => handleChangeSearch(e.target.value)}
                            value={searchText}
                            name="search"
                            className={classes.textFieldSearch}
                            type="text"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ClearIcon
                                    style={{
                                      fontSize: "24px",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleClearSearch()}
                                  />
                                </InputAdornment>
                              ),
                              classes: {
                                notchedOutline: classes.notchedOutline,
                                input: classes.input1,
                              },
                            }}
                            placeholder={t(
                              "Search by land, name or contact person"
                            )}
                            margin="normal"
                            variant="outlined"
                            size="small"
                          />
                        </div>
                        <div className="assign-mentor-to-municipality-search-div-search-btn-wrapper">
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "0 auto",
                              marginTop: "5px",
                            }}
                          >
                            <SearchIcon
                              style={{
                                color: "white",
                                fontSize: "30px",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={4}>
                      <div className="assign-mentor-to-municipality-btn-div">
                        <Button
                          variant="contained"
                          className={classes.createButton}
                          color="primary"
                          onClick={() => handleClickLeaveScreenDialogopen()}
                        >
                          {t("Create New")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <MunicipalitiesData
                  searchText={searchText}
                  selectedMunicipalities={selectedMunicipalities}
                  setSelectedMunicipalities={setSelectedMunicipalities}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  <div className="assign-mentor-to-municipality-title-div">
                    <h3 className="assign-mentor-to-municipality-title">
                      {t("Supplier")}: {sessionStorage.getItem("entity_name")}
                    </h3>
                    <p className="assigned-mentors-to-municipality-total-text">
                      {t("Total Assigned Municipalities")}:{" "}
                      {selectedMunicipalities.length}
                    </p>
                  </div>
                </div>
                <AssignedMunicipalitiesData
                  selectedMunicipalities={selectedMunicipalities}
                  unAssignMunicipality={unAssignMunicipality}
                  totalDocs={totalDocs}
                  hasNextPage={hasNextPage}
                  fetchMoreData={fetchMoreData}
                  isButtonDisabled={loading}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withReducer(
  "AssignMunicipalityToSupplierReducer",
  reducer
)(AssignMunicipalityToSupplier);
