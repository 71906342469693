import React, { useState, useEffect, useRef } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import checkIcon from "../../../assets/images/Tick_Mark_Icon.svg";
import { useTranslation } from "react-i18next";
import CustomScrollbar from "../../shared/CustomScrollbar/index"

// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";
const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
  },
  listItem: {
    '&.MuiListItem-gutters': {
      marginLeft: '-26px',
      marginRight: '16px',
    },
  },
  filterContinueButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    float: "right",
    marginRight: "15px",
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  dropdown: {
    position: "absolute",
    // width: "155px",
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    height: "226px",
  },
  chkroot: {
    padding: 2,
  },
  icon: {
    border: "1px solid #454A92",
    borderRadius: 0,
    width: "18px",
    height: "18px",
  },
  checkedIcon: {
    backgroundColor: "#ffffff",
    "&:before": {
      display: "block",
      width: "18px",
      height: "18px",
      backgroundImage: `url(${checkIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "14px 14px",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffffff",
    },
  },
  container: {
    height: "18rem",
    overflow: "hidden",
    position: "relative",
  },
  container__content: {
    height: "100%",
    marginRight: "-1rem",
    overflow: "auto",
    padding: "0.5rem",
    paddingRight: "1rem",
  },
  scrollbar: {
    backgroundColor: "rgb(243, 244, 246)",
    borderLeftWidth: 0,
    position: "absolute",
    top: 0,
    right: 0,
    height: "60%",
    width: "0.75rem",
  },
  scrollbar__thumb: {
    backgroundColor: "rgb(156, 163, 175)",
    borderRadius: "9999px",
    cursor: "grab",
    left: 0,
    position: "absolute",
    width: "100%",
  },
  scrollbar__track: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
}));
// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.chkroot}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      color="primary"
      {...props}
    />
  );
}
function AssignRefugeeToMunicipalityRefugeesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [refugees, setRefugees] = useState({});
  const [selectedRefugeeIds, setSelectedRefugeeIds] = React.useState([]);
  const trackRef = useRef();
  const thumbRef = useRef();
  const contentRef = useRef();

  const handleScrollContent = () => {
    const thumbEle = thumbRef.current;
    const contentEle = contentRef.current;
    if (!thumbEle || !contentEle) {
      return;
    }
    thumbEle.style.top = `${(contentEle.scrollTop * 100) /
      contentEle.scrollHeight}%`;
  };

  const handleClickTrack = (e) => {
    const trackEle = trackRef.current;
    const contentEle = contentRef.current;
    if (!trackEle || !contentEle) {
      return;
    }
    const bound = trackEle.getBoundingClientRect();
    const percentage = (e.clientY - bound.top) / bound.height;
    contentEle.scrollTop =
      percentage * (contentEle.scrollHeight - contentEle.clientHeight);
  };
  useEffect(() => {
    const thumbEle = thumbRef.current;
    const contentEle = contentRef.current;
    if (!thumbEle || !contentEle) {
      return;
    }
    const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
    thumbEle.style.height = `${scrollRatio * 100}%`;
  }, []);
  const handleMouseDown = (e) => {
    const ele = thumbRef.current;
    const contentEle = contentRef.current;
    if (!ele || !contentEle) {
        return;
    }
    const startPos = {
        top: contentEle.scrollTop,
        x: e.clientX,
        y: e.clientY,
    };
    const updateCursor = (ele) => {
      ele.style.cursor = "grabbing";
      ele.style.userSelect = "none";
      document.body.style.cursor = "grabbing";
      document.body.style.userSelect = "none";
    };
  
    // Function to reset cursor style
    const resetCursor = (ele) => {
      ele.style.cursor = "grab";
      ele.style.userSelect = "";
      document.body.style.cursor = "";
      document.body.style.userSelect = "";
    };
    const handleMouseMove = (e) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
        contentEle.scrollTop = startPos.top + dy / scrollRatio;
        updateCursor(ele);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor(ele);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
};
const handleTouchStart = (e) => {
  const ele = thumbRef.current;
  const contentEle = contentRef.current;
  if (!ele || !contentEle) {
    return;
  }
  const touch = e.touches[0];
  const startPos = {
    top: contentEle.scrollTop,
    x: touch.clientX,
    y: touch.clientY,
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const dy = touch.clientY - startPos.y;
    const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
    contentEle.scrollTop = startPos.top + dy / scrollRatio;
    updateCursor(ele);
};


  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
    resetCursor(ele);
  };

  document.addEventListener("touchmove", handleTouchMove);
  document.addEventListener("touchend", handleTouchEnd);
};
useEffect(() => {
  return () => {
    dispatch(Actions.resetMiniSearchedRefugees(true));
  };
}, []);
  useEffect(() => {
    const storedRefugeeIds = JSON.parse(
      localStorage.getItem("selectedRefugeeIds")
    );
    dispatch(
      Actions.getAllMiniSearchedRefugees(
        page + 1,
        10000,
        props.searchText,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
    setSelectedRefugeeIds(storedRefugeeIds || []);
  }, [rowsPerPage, page, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const existingSelectedIds =
      JSON.parse(localStorage.getItem("selectedRefugeeIds")) || [];
    const randomNumber = Math.floor(Math.random() * 15) + 1;

    const newString = "hi".replace(/hi/, randomNumber);

    props.setState(newString);
    let newObj = Object.assign({}, refugees);
    newObj.docs[index].isSelected = !refugees.docs[index].isSelected;
    if (newObj.docs[index].isSelected) {
      let newElement = {
        bsn: newObj.docs[index].bsn,
        id: newObj.docs[index]?.id,
        name: newObj.docs[index].name,
      };
      props.setSelectedRefugees(() => [...props.selectedRefugees, newElement]);
    }
    if (!newObj.docs[index].isSelected) {
      props.setSelectedRefugees(
        props.selectedRefugees.filter(
          (item) => item?.id !== newObj.docs[index]?.id
        )
      );
    }
    const newSelectedIds = newObj.docs
      .filter((refugee) => refugee.isSelected)
      .map((refugee) => refugee.id);
    const combinedIds = Array.from(
      new Set([...existingSelectedIds, ...newSelectedIds])
    );
    localStorage.setItem("selectedRefugeeIds", JSON.stringify(combinedIds));
    setSelectedRefugeeIds(combinedIds);
    setRefugees(newObj);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const SearchedRefugees = useSelector(
    ({ AssignRefugeeToMunicipalityRefugeesDataReducer }) =>
      AssignRefugeeToMunicipalityRefugeesDataReducer
        ?.allMiniSearchedRefugeesReducer?.data
  );
  const loading = useSelector(
    ({ AssignRefugeeToMunicipalityRefugeesDataReducer }) =>
      AssignRefugeeToMunicipalityRefugeesDataReducer
        ?.allMiniSearchedRefugeesReducer?.isLoading
  );

  const errMsg = useSelector(
    ({ AssignRefugeeToMunicipalityRefugeesDataReducer }) =>
      AssignRefugeeToMunicipalityRefugeesDataReducer
        ?.allMiniSearchedRefugeesReducer?.errMsg
  );
  useEffect(() => {
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.selectedRefugees.length === 0
    ) {
      let refugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (refugeesObj.docs !== null && refugeesObj.docs.length > 0) {
        for (let i = 0; i < refugeesObj.docs.length; i++) {
          const doc = refugeesObj.docs[i];
          doc["isSelected"] = false;
        }
      }
      setRefugees(refugeesObj);
    }
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.selectedRefugees.length > 0
    ) {
      let selrefugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (selrefugeesObj.docs !== null && selrefugeesObj.docs.length > 0) {
        for (let i = 0; i < selrefugeesObj.docs.length; i++) {
          const seldoc = selrefugeesObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedRefugees.length; j++) {
            const selRefugee = props.selectedRefugees[j];
            if (seldoc?.id === selRefugee?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setRefugees(selrefugeesObj);
    }
  }, [SearchedRefugees]);
  useEffect(() => {
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.selectedRefugees.length > 0 &&
      props.editCheck
    ) {
      let selrefugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (selrefugeesObj.docs !== null && selrefugeesObj.docs.length > 0) {
        for (let i = 0; i < selrefugeesObj.docs.length; i++) {
          const seldoc = selrefugeesObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedRefugees.length; j++) {
            const selRefugee = props.selectedRefugees[j];
            if (seldoc?.id === selRefugee?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setRefugees(selrefugeesObj);
      props.setEditCheck(false);
    }
  }, [props.editCheck, SearchedRefugees]);
  useEffect(() => {
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.unAssignId
    ) {
      let selrefugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (selrefugeesObj.docs !== null && selrefugeesObj.docs.length > 0) {
        for (let i = 0; i < selrefugeesObj.docs.length; i++) {
          const seldoc = selrefugeesObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedRefugees.length; j++) {
            const selRefugee = props.selectedRefugees[j];
            if (seldoc?.id === selRefugee?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setRefugees(selrefugeesObj);
      props.setUnAssignId(false);
    }
  }, [props.unAssignId, props.selectedRefugees]);




  return (
    <>
      <h3 style={{marginRight: "9px"}}>Select the Refugee(s)</h3>
     
    
     
      <CustomScrollbar>
      <List>
          {refugees && refugees.docs && refugees.docs.length > 0 && (
            <>
              {refugees.docs
                .sort((a, b) => {
                  if (a.isSelected && !b.isSelected) return -1;
                  if (!a.isSelected && b.isSelected) return 1;
                  const isSelectedA = selectedRefugeeIds?.includes(a.id);
                  const isSelectedB = selectedRefugeeIds?.includes(b.id);
                  if (isSelectedA && !isSelectedB) return -1;
                  if (!isSelectedA && isSelectedB) return 1;

                  if (isSelectedA === isSelectedB) {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  }
                  return 0;
                })
                .map((doc, index) => (
                  <ListItem
                    key={doc.id}
                    button
                    onClick={() => handleChangeSelected(index)}
                    disabled={props?.disabled}
                    className={classes.listItem} 
                  >
                    <Checkbox checked={doc.isSelected} />
                    <ListItemText primary={doc.name} />
                  </ListItem>
                ))}
            </>
          )}
        </List>
      </CustomScrollbar>

       
    </>
  );
}
export default withReducer(
  "AssignRefugeeToMunicipalityRefugeesDataReducer",
  reducer
)(AssignRefugeeToMunicipalityRefugeesData);
