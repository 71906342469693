export const pipPlanNavigationKeys = {
  frontPage: "frontPage",
  details: "details",
  socialGuidance: "socialGuidance",
  healthAndLeisure: "healthAndLeisure",
  trainingAndDigitalSkills: "trainingAndDigitalSkills",
  workAndActivities: "workAndActivities",
  integration: "integration",
  goalsAndAgreements: "goalsAndAgreements",
  consentForm: "consentForm"
};

export const pipPlanSteps = [
  {
    title: "Front page PIP",
    key: pipPlanNavigationKeys.frontPage,
    name: pipPlanNavigationKeys.frontPage,
  },
  {
    title: "My details",
    key: pipPlanNavigationKeys.details,
    name: pipPlanNavigationKeys.details,
  },
  {
    title: "Social guidance +LV",
    key: pipPlanNavigationKeys.socialGuidance,
    name: pipPlanNavigationKeys.socialGuidance,
  },
  {
    title: "Health and leisure",
    key: pipPlanNavigationKeys.healthAndLeisure,
    name: pipPlanNavigationKeys.healthAndLeisure,
  },
  {
    title: "Education & digital skills",
    key: pipPlanNavigationKeys.trainingAndDigitalSkills,
    name: pipPlanNavigationKeys.trainingAndDigitalSkills,
  },
  {
    title: "Work and activities",
    key: pipPlanNavigationKeys.workAndActivities,
    name: pipPlanNavigationKeys.workAndActivities,
  },
  {
    title: "Integration",
    key: pipPlanNavigationKeys.integration,
    name: pipPlanNavigationKeys.integration,
  },
  {
    title: "Goals and agreements",
    key: pipPlanNavigationKeys.goalsAndAgreements,
    name: pipPlanNavigationKeys.goalsAndAgreements,
  },
  {
    title: "Permission document",
    key: pipPlanNavigationKeys.consentForm,
    name: pipPlanNavigationKeys.consentForm,
  }
];

export const DATE_FORMAT = "YYYY-MM-DD";

export const MAX_DATE_OF_BIRTH = "2004-12-31";

export const COUNT_CHILDRENS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];


// Auto

// Vrachtwagen

// 2-as trekker

// Heftruck

// Scooter/brommer

// Personenbus

// Bestelbus
export const TRANSPORTS_TYPES = ["Car", "Bicycle", "Moped", "Scooter"];

export const TYPE_OF_DRIVING_LICENSE = ["Car", "Truck", "2-axle tractor", "Forklift truck", "Scooter/moped", "Passenger bus", "Van"];

export const LEARNING_PATHS = {
  B1_ROUTE: "B1 route",
  EDUCATION_ROUTE: "Education route",
  Z_ROUTE: "Z route"
};

export const LANGUAGE_LEVELS = {
  A0: "A0",
  A1: "A1",
  A2: "A2",
  B1: "B1",
  B2: "B2",
  C1: "C1",
  C2: "C2",
  Illiterate: "Illiterate"
};

export const LANGUAGE_LEVELS_BY_LEARNING_PATH = {
  "B1 route": [LANGUAGE_LEVELS.A0, LANGUAGE_LEVELS.A1, LANGUAGE_LEVELS.A2, LANGUAGE_LEVELS.B1],
  "Education route": Object.values(LANGUAGE_LEVELS),
  "Z route": [LANGUAGE_LEVELS.A0, LANGUAGE_LEVELS.A1, LANGUAGE_LEVELS.A2, LANGUAGE_LEVELS.Illiterate]
};

export const WEEKLY_TEACHING_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

export const GENDER = ["Male", "Female", "Other"];
