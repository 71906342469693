import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import LimitWarningDialog from "./LimitWarningDialog";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  clearIcon: {
    verticalAlign: "middle",
    padding: "2px",
    height: "18px",
    color: "454A92",
    fontSize: "18px",
    cursor: "pointer",
  },
  continueButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginRight: "30px",
    boxShadow: "none",
  },
  cancelButton: {
    fontSize: "16px",
    backgroundColor: "white",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginRight: "30px",
    border: "1px solid #454A92",
    color: "#454A92",
    boxShadow: "none",
    "&:hover": {
      color: "#ffffff",
    },
  },
}));

function AssignedMunicipalityToSupplierMunicipalitiesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [warningOpen, setWarningOpen] = useState(false);

  const continueClick = () => {
    if (!props.selectedMunicipalities.length) {
      setWarningOpen(true);
    } else {
      if (
        props.selectedMunicipalities[0]?.id !==
        sessionStorage.getItem("previousMunicipalityId")
      ) {
        sessionStorage.removeItem("assignedRefugees");
        sessionStorage.removeItem("unAssignedRefugees");
        sessionStorage.setItem(
          "previousMunicipalityId",
          props.selectedMunicipalities[0]?.id
        );
      }
      sessionStorage.setItem(
        "assignedMunicipalities",
        JSON.stringify(props.selectedMunicipalities)
      );
      sessionStorage.setItem("unAssignedMunicipalities", []);
      navigate("/editAssignRefugeeToSupplier");
    }
  };

  const handleClickWarningDialogclose = () => {
    setWarningOpen(false);
  };

  const cancelClick = () => {
    navigate("/viewEditSupplierSummary");
  };

  return (
    <div>
      {warningOpen === true && (
        <LimitWarningDialog
          status={warningOpen}
          methodd={handleClickWarningDialogclose}
          role="Municipality"
        />
      )}
      <div style={{ marginTop: "103px" }} className="edit-mun-table-div">
        <table
          className="system-search-table-tag"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <tr>
            <th>{t("Land")}</th>
            <th>{t("Municipality Name")}</th>
            <th>{t("Contact Person")}</th>
            <th></th>
          </tr>

          {props.selectedMunicipalities &&
            props.selectedMunicipalities.length > 0 &&
            props.selectedMunicipalities.map((doc, index) => (
              <tr key={doc?.id}>
                <td>{doc.province}</td>
                <td>{doc.name}</td>
                <td>
                  {!doc.contactPerson ? (
                    <span className="unassigned-td">{t("N/A")}</span>
                  ) : (
                    doc.contactPerson.name
                  )}
                </td>
                <td style={{ width: "110px" }}>
                  <ClearIcon
                    onClick={() => {
                      props.unAssignMunicipality(doc);
                    }}
                    className={classes.clearIcon}
                  />
                </td>
              </tr>
            ))}
        </table>
      </div>

      <div className="assigned-mentor-to-municipality-btn-div">
        <Button
          variant="contained"
          className={classes.continueButton}
          onClick={() => continueClick()}
          color="primary"
          disabled={props.isButtonDisabled}
        >
          {t("Continue")}
        </Button>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={() => cancelClick()}
          color="primary"
          disabled={props.isButtonDisabled}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}

export default AssignedMunicipalityToSupplierMunicipalitiesData;
