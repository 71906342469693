import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import store from "../../store/index";
import { roles } from "../../utils/roles";
import jwt from "jsonwebtoken";
import * as Actions from "../login/store/actions";
import * as ActionsC from "../userapp/login/store/actions";

import HeaderAdmin from "../home/sub_components/Header";
import FooterAdmin from "../home/sub_components/Footer";
import HeaderManager from "../userapp/manager/home/sub_components/Header";
import FooterManager from "../userapp/manager/home/sub_components/Footer";
import HeaderMentor from "../userapp/mentor/home/sub_components/Header";
import FooterMentor from "../userapp/mentor/home/sub_components/Footer";
import HeaderSupplier from "../userapp/supplier/home/sub_components/Header";
import FooterSupplier from "../userapp/supplier/home/sub_components/Footer";

function Index({ children, isAdmin, rolesAssigned, path }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { refugeeId, municipalityId } = params;


  useEffect(() => {
    const jwtToken = localStorage.jwtToken;

    const redirectToPath = (redirectPath) => {
      navigate(redirectPath);
    };

    const setCurrentUserAndNavigate = (role, navigatePath) => {
      const decodedToken = jwt.decode(jwtToken);
      store.dispatch(ActionsC.setCurrentUser(decodedToken));
      navigate(navigatePath);
    };

    if (!jwtToken) {
      if (isAdmin) {
        if (path.includes("resetPasswordByAdmin"))
          redirectToPath(path + location.search);
        else redirectToPath("/");
      } else {
        if (path.includes("resetPassword"))
          redirectToPath(path + location.search);
        else redirectToPath("/userlogin");
      }
    } else {
      const decodedRole = jwt.decode(jwtToken)?.role;

      if (
        decodedRole === roles?.admin?.id &&
        !rolesAssigned.includes(roles?.admin?.id)
      ) {
        store.dispatch(Actions.setCurrentUser(jwt.decode(jwtToken)));
        redirectToPath("/dashboard");
      } else if (
        decodedRole === roles?.manager?.id &&
        !rolesAssigned.includes(roles?.manager?.id)
      ) {
        setCurrentUserAndNavigate(decodedRole, "/managerdashboard");
      } else if (
        decodedRole === roles?.mentor?.id &&
        !rolesAssigned.includes(roles?.mentor?.id)
      ) {
        setCurrentUserAndNavigate(decodedRole, "/mentordashboard");
      } else if (
        (decodedRole === roles?.work_supplier?.id ||
          decodedRole === roles?.school_supplier?.id) &&
        !rolesAssigned.includes(roles?.work_supplier?.id)
      ) {
        setCurrentUserAndNavigate(decodedRole, "/supplierdashboard");
      }else if(!refugeeId && !municipalityId) {
        navigate(path);
      }
    }
  }, []);

  return (
    <>
      <div>
      {localStorage.jwtToken &&
        jwt.decode(localStorage.jwtToken)?.role === roles.admin.id && (
          <HeaderAdmin />
        )}
        {localStorage.jwtToken &&
          jwt.decode(localStorage.jwtToken)?.role === roles.manager.id && (
            <HeaderManager />
          )}
        {localStorage.jwtToken  &&
          jwt.decode(localStorage.jwtToken)?.role === roles.mentor.id && (
            <HeaderMentor />
          )}
        {localStorage.jwtToken  &&
          jwt.decode(localStorage.jwtToken)?.role ===
            roles.work_supplier.id && <HeaderSupplier />}
        {localStorage.jwtToken  &&
          jwt.decode(localStorage.jwtToken)?.role ===
            roles.school_supplier.id && <HeaderSupplier />}
      </div>
      <div>{children}</div>
      <div>
        {localStorage.jwtToken &&
          jwt.decode(localStorage.jwtToken)?.role === roles.admin.id && (
            <FooterAdmin />
          )}
        {localStorage.jwtToken &&
          jwt.decode(localStorage.jwtToken)?.role === roles.manager.id && (
            <FooterManager />
          )}
        {localStorage.jwtToken &&
          jwt.decode(localStorage.jwtToken)?.role === roles.mentor.id && (
            <FooterMentor />
          )}
        {localStorage.jwtToken &&
          jwt.decode(localStorage.jwtToken)?.role ===
            roles.work_supplier.id && <FooterSupplier />}
        {localStorage.jwtToken &&
          jwt.decode(localStorage.jwtToken)?.role ===
            roles.school_supplier.id && <FooterSupplier />}
      </div>
    </>
  );
}

export default Index;
