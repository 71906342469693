import React from "react";

import { useTranslation } from "react-i18next";
import SystemSearchComponent from "../SearchComponent/index";
function SystemSearch(props) {
  const [t] = useTranslation();
  let roles = [
    {
      id: "all",
      name: "All",
      default: false,
    },
    {
      id: "municipalities",
      name: "Municipalities",
      default: true,
    },
    {
      id: "managers",
      name: "Managers",
      default: false,
    },
    {
      id: "mentors",
      name: "Mentors",
      default: false,
    },
    {
      id: "suppliers",
      name: "Suppliers",
      default: false,
    },
    {
      id: "refugees",
      name: "Refugees",
      default: false,
    },
  ];
  let tabs = [
    {
      id: "municipalities",
      name: "Municipalities",
      default: true,
    },
    {
      id: "managers",
      name: "Managers",
      default: false,
    },
    {
      id: "mentors",
      name: "Mentors",
      default: false,
    },
    {
      id: "suppliers",
      name: "Suppliers",
      default: false,
    },
    {
      id: "refugees",
      name: "Refugees",
      default: false,
    },
  ];
  return(
    <SystemSearchComponent roles={roles} tabs={tabs} rang={"admin"}/>
  )
}
export default SystemSearch;
