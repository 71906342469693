import { Text, View, Page, Link } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate } from "../../../utils/helpers";

function isPrimitive(value) {
  return value !== Object(value);
}


const HEALTH_PAGE_LABELS = {
  "dateLastModified": "Date (last modified)",
  "physicalComplaints": "I have physical complaints",
  "psychologicalComplaints": "I have psychological complaints",
  "emotionalProblems": "I suffer from stress or emotional problems",
  "shockingEventsOrExperiences": "I am bothered by shocking events and/or experiences from the past",
  "sufferFromAnAddiction": "I suffer from an addiction",
  "underMedicalTreatment": "I am under medical treatment",
  "medicalFacilitiesOrAdjustmentsInWork": "I need medical facilities or adjustments in my work or living situation",
  "sportsAndLeisure": "Sports and leisure"
};

const getHealthAndLeisureData = (content) => {
  const [key, value] = content;
  return (
    <View>
      <View style={[styles.viewRow, { justifyContent: "start"}]}>
        <View style={{ width: "70%", fontSize: 10, display: "flex" }}>
          <Text style={styles.label}>
            {i18next.t(HEALTH_PAGE_LABELS?.[key])}
          </Text>
        </View>
        <View style={[styles.viewRowInput, { width: "20%", marginLeft: 20 }]}>
          <Text>{value?.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      {value?.answer === true && (
        <View
          style={{
            display: "flex",
            width: "100%",
            padding: "10px 0"
          }}
        >
          <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
            <Text style={styles.viewRowInput}>
              <Text>{`${value.problem}`.split('')}</Text>
            </Text>
          </View>
        </View>
      )}
    </View>
  )
};

const getSportsAndLeisureData = (content) => {
  const { exerciseEveryDay, doYouSports } = content;
  return (
    <View>
      <View style={styles.viewRow}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t("I walk/cycle/exercise every day")}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{exerciseEveryDay.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      {exerciseEveryDay?.answer === true && (
        <View style={styles.viewRow}>
          <View style={{ width: "50%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t("Exercise every day")}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            <Text>{exerciseEveryDay.whatDoYouDo}</Text>
          </View>
        </View>
      )}
      <View style={styles.viewRow}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t("I sport")}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{doYouSports.answer === true ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      {doYouSports?.answer === true && (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "10px 0"
          }}
        >
          <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
            <Text style={[styles.label, { width: "100%" }]}>
              {i18next.t("Which sport do you practice")}
            </Text>
          </View>
          <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
            <Text style={styles.viewRowInput}>
              <Text>{`${doYouSports.whichSport}`.split('')}</Text>
            </Text>
          </View>
        </View>
      )}
      {doYouSports?.answer === false && (
        <View style={styles.viewRow}>
          <View style={{ width: "50%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t("I like to exercise")}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            <Text>{doYouSports.youLikeToExercise ? i18next.t("Yes") : i18next.t("No")}</Text>
          </View>
        </View>
      )}
      <View style={styles.viewRow}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t("Want to participate in the language café/samensp")}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{content.languageCafeSamensp ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      <View style={styles.viewRow}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t("I want to become a member of the library")}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{content.becomeMemberOfLibrary ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      <View style={styles.viewRow}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t("I can swim")}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{content.canSwim ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      <View style={styles.viewRow}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t("My children can swim")}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{content?.canSwimChildren}</Text>
        </View>
      </View>
    </View>
  )
}

function HealthAndLeisure({ healthAndLeisurePageData, headerText }) {
  const { sportsAndLeisure } = healthAndLeisurePageData;

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
      </View>
      <View style={styles.viewRowContainer}>
        <View style={styles.viewRow}>
          <View style={{ width: "55%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(HEALTH_PAGE_LABELS["dateLastModified"])}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            <Text>{formatDate(healthAndLeisurePageData.dateLastModified)}</Text>
          </View>
        </View>
      </View>
      <View><Text style={styles.subtitle}>{i18next.t('Health')}</Text></View>
      <View style={styles.viewRowContainer}>
        {Object.entries(healthAndLeisurePageData)?.map((content) => {
          const [key, value] = content;
          if (value !== null && !isPrimitive(value)) {
            if (key !== "sportsAndLeisure") {
              return (
                <View key={`healthAndLeisurePageData_${key}`}>
                  {getHealthAndLeisureData(content)}
                </View>
              )
            }
          }
        })}
      </View>
      <View><Text style={styles.subtitle}>{i18next.t("Sports and leisure")}</Text></View>
      <View style={styles.viewRowContainer}>
        {getSportsAndLeisureData(sportsAndLeisure)}
      </View>
    </Page>
  );
};

export default HealthAndLeisure;
