import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from "moment"
import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from 'formik';
import DatePicker from "../../../shared/DatePicker";
import { getBooleanValue, removeKey, formatDate } from "../../../../utils/helpers";
import { pipIntegrationPageSchema } from "../../../../resources/scheme";
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { pipPlanNavigationKeys } from "../../../../resources/constants";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
  DATE_FORMAT,
  LEARNING_PATHS,
  LANGUAGE_LEVELS,
  LANGUAGE_LEVELS_BY_LEARNING_PATH,
  WEEKLY_TEACHING_DAYS
} from "../../../../resources/constants";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "start",
  },
  subTitle: {
    fontSize: "10px",
    color: "#253c8c"
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "fit-content"
  },
  marginTopAndBottom: {
    margin: "10px 0"
  },
  teachingDaysMargin: {
    margin: "30px 0"
  },
  teachingFormControl: {
    margin: "10px"
  },
  buttonStyle: {
    mt: 3, mb: 2, width: "auto", marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9
    }
  }
}));

function IntegrationForm({ data, setPipPlanPage }) {
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState("")
  const classes = useStyles();

  const params = useParams();
  const { refugeeId } = params;

  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.goalsAndAgreements)
  };
  const navigatePreviousPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.workAndActivities)
  }
  const formik = useFormik({
    dirty: false,
    initialValues: {
      dateLastModified: data?.dateLastModified ?? '',
      learningPath: data?.learningPath ?? null,
      languageLevel: data?.languageLevel ?? null,
      additionToMyNativeLanguageIAlsoSpeak: data?.additionToMyNativeLanguageIAlsoSpeak ?? null,
      nameOfIntegrationSchool: data?.nameOfIntegrationSchool ?? null,
      locationOfIntegrationSchool: data?.locationOfIntegrationSchool ?? null,
      contactPersonIntegrationSchool: data?.contactPersonIntegrationSchool ?? null,
      myTeacherNameIs: data?.myTeacherNameIs ?? null,
      teachingDaysAreAsFollows: {
        monday: {
          haveTeaching: Boolean(data?.teachingDaysAreAsFollows?.monday?.haveTeaching) ?? null,
          morning: Boolean(data?.teachingDaysAreAsFollows?.monday?.morning) ?? null,
          afternoon: Boolean(data?.teachingDaysAreAsFollows?.monday?.afternoon) ?? null,
        },
        tuesday: {
          haveTeaching: Boolean(data?.teachingDaysAreAsFollows?.tuesday?.haveTeaching) ?? null,
          morning: Boolean(data?.teachingDaysAreAsFollows?.tuesday?.morning) ?? null,
          afternoon: Boolean(data?.teachingDaysAreAsFollows?.tuesday?.afternoon) ?? null,
        },
        wednesday: {
          haveTeaching: Boolean(data?.teachingDaysAreAsFollows?.wednesday?.haveTeaching) ?? null,
          morning: Boolean(data?.teachingDaysAreAsFollows?.wednesday?.morning) ?? null,
          afternoon: Boolean(data?.teachingDaysAreAsFollows?.wednesday?.afternoon) ?? null,
        },
        thursday: {
          haveTeaching: Boolean(data?.teachingDaysAreAsFollows?.thursday?.haveTeaching) ?? null,
          morning: Boolean(data?.teachingDaysAreAsFollows?.thursday?.morning) ?? null,
          afternoon: Boolean(data?.teachingDaysAreAsFollows?.thursday?.afternoon) ?? null,
        },
        friday: {
          haveTeaching: Boolean(data?.teachingDaysAreAsFollows?.friday?.haveTeaching) ?? null,
          morning: Boolean(data?.teachingDaysAreAsFollows?.friday?.morning) ?? null,
          afternoon: Boolean(data?.teachingDaysAreAsFollows?.friday?.afternoon) ?? null,
        },
      },
      haveLanguageBuddy: data?.haveLanguageBuddy ?? null,
      necessary: data?.necessary ?? null,
      participationDeclarationProcess: {
        haveCompletedThePVT: data?.participationDeclarationProcess?.haveCompletedThePVT ?? null,
        by: data?.participationDeclarationProcess?.by ?? null,
        until: data?.participationDeclarationProcess?.until ?? null,
        roundingFor: data?.participationDeclarationProcess?.roundingFor ?? null,
      },
      completedTheMAP: {
        completed: data?.completedTheMAP?.completed ?? null,
        theory: data?.completedTheMAP?.theory ?? null,
        practice: data?.completedTheMAP?.practice ?? null,
        completedPractice: {
          from: data?.completedTheMAP?.completedPractice?.from ?? null,
          to: data?.completedTheMAP?.completedPractice?.to ?? null,
        },
        completedTheory: {
          from: data?.completedTheMAP?.completedTheory?.from ?? null,
          to: data?.completedTheMAP?.completedTheory?.to ?? null,
        },
        completing: {
          practice: data?.completedTheMAP?.completing?.practice ?? null,
          theory: data?.completedTheMAP?.completing?.theory ?? null,
        }
      },
      completedTheKNM: {
        completed: data?.completedTheKNM?.completed ?? null,
        by: data?.completedTheKNM?.by ?? null,
        until: data?.completedTheKNM?.until ?? null,
        roundingFor: data?.completedTheKNM?.roundingFor ?? null,
      },
    },
    enableReinitialize: true,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        const queryString = JSON
          .stringify(removeKey({ ...values, dateLastModified: moment().format(DATE_FORMAT) }, '__typename'), null, 2)
          .replace(/"([^"]+)":/g, '$1:');
        const integrationQuery = `integration: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: integrationQuery })
          .then(() => {
            dispatch(getPipPlanByRefugee(refugeeId))
            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.workAndActivities)
            } else {
              setPipPlanPage(pipPlanNavigationKeys.goalsAndAgreements)
            }
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      } else {
        helpers.setSubmitting(false);
        navigateNextPage()
        if (selectedPage === "saveAndPrevious") {
          navigatePreviousPage()
        } else {
          navigateNextPage()
        }
      }
    },
    validationSchema: pipIntegrationPageSchema,
  });

  const onRadioChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    formik.setFieldValue(name, booleanValue)
  }

  const onLearningPathChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue("languageLevel", null);
    formik.setFieldValue(name, value);
  }

  const onLanguageBuddyChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    formik.setFieldValue("necessary", null);
    formik.setFieldValue(name, booleanValue);
  }

  const onPvtChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (booleanValue) {
      formik.setFieldValue("participationDeclarationProcess.roundingFor", null);
    } else {
      formik.setFieldValue("participationDeclarationProcess.by", null);
      formik.setFieldValue("participationDeclarationProcess.until", null);
    }
    formik.setFieldValue(name, booleanValue);
  }

  const onCompletedTheMAPChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (!booleanValue) {
      formik.setFieldValue("completedTheMAP.completedPractice", null);
      formik.setFieldValue("completedTheMAP.completedTheory", null);
      formik.setFieldValue("completedTheMAP.practice", false);
      formik.setFieldValue("completedTheMAP.theory", false);
    } else {
      formik.setFieldValue("completedTheMAP.practice", true);
      formik.setFieldValue("completedTheMAP.theory", true);
      formik.setFieldValue("completedTheMAP.completedPractice", { from: null, to: null });
      formik.setFieldValue("completedTheMAP.completedTheory", { from: null, to: null });
    }
    formik.setFieldValue(name, booleanValue);
  }

  const onByUntilDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "by") {
      formik.setFieldValue("completedTheMAP.completedPractice.from", value);
      formik.setFieldValue("completedTheMAP.completedTheory.from", value);
    } else {
      formik.setFieldValue("completedTheMAP.completedPractice.to", value);
      formik.setFieldValue("completedTheMAP.completedTheory.to", value);
    }
    formik.setFieldValue("completedTheMAP.completing", null);
  };

  const resetCompletedRangeDates = (atCheckedNow) => {
    if (atCheckedNow === "theory") {
      formik.setFieldValue("completedTheMAP.completedTheory", null);
      formik.setFieldValue("completedTheMAP.completing.theory", null);
    } else {
      formik.setFieldValue("completedTheMAP.completedPractice", null);
      formik.setFieldValue("completedTheMAP.completing.practice", null);
    }
  };

  const onPracticeTheoryChange = (event) => {
    const { name, checked } = event.target;
    const [_, atCheckedNow] = name.split('.');
    const allChecked = formik.values?.completedTheMAP?.theory && formik.values?.completedTheMAP?.practice;
    const atLeastOneChecked = formik.values?.completedTheMAP?.theory || formik.values?.completedTheMAP?.practice;
    const checkedBefore = atLeastOneChecked && formik.values?.completedTheMAP?.theory ? "theory" : "practice";

    if (allChecked) {
      formik.setFieldValue("completedTheMAP.completed", false);
      resetCompletedRangeDates(atCheckedNow);
    } else if (atLeastOneChecked) {
      if (checkedBefore !== atCheckedNow) {
        formik.setFieldValue("completedTheMAP.completed", true);
        formik.setFieldValue("completedTheMAP.completing", null);
        formik.setFieldValue("completedTheMAP.completedPractice", { from: null, to: null });
        formik.setFieldValue("completedTheMAP.completedTheory", { from: null, to: null });
      } else {
        formik.setFieldValue("completedTheMAP.completing", {
          practice: null,
          theory: null
        });
        formik.setFieldValue("completedTheMAP.completedPractice", null);
        formik.setFieldValue("completedTheMAP.completedTheory", null);
        resetCompletedRangeDates(atCheckedNow);
      }
    } else {
      if (atCheckedNow === "theory") {
        formik.setFieldValue("completedTheMAP.completing.theory", null);
        formik.setFieldValue("completedTheMAP.completedTheory", { from: null, to: null });
      } else {
        formik.setFieldValue("completedTheMAP.completing.practice", null);
        formik.setFieldValue("completedTheMAP.completedPractice", { from: null, to: null });
      }
      formik.setFieldValue("completedTheMAP.completed", false);
    }

    formik.setFieldValue(name, checked);
  };


  const onCompletedTheKNMChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (!booleanValue) {
      formik.setFieldValue("completedTheKNM.by", null);
      formik.setFieldValue("completedTheKNM.until", null);
    } else {
      formik.setFieldValue("completedTheKNM.roundingFor", null);
    }

    formik.setFieldValue(name, booleanValue);
  }

  return (
    <Grid container>
      <form
        noValidate
        style={{ width: "100%" }}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container item
        >
          <Grid
            style={{ padding: "0 60px" }}
            container
            item
            display="flex"
            direction="row"
          >
            <Typography variant="h2" className={classes.title}>{t('Language and learning route')}</Typography>
            <Box mb={2} mt={2} width="inherit">
              <DatePicker
                id="integration_lastModified"
                label={t('Date (last modified)')}
                name={'dateLastModified'}
                value={formatDate(formik.values?.dateLastModified)}
                disabled={true}
                fullWidth={true}
              />
            </Box>

            <FormControl
              fullWidth
              className={classes.marginTopAndBottom}
              component="fieldset"
              error={
                Boolean(formik.touched?.learningPath) &&
                Boolean(formik.errors?.learningPath)
              }
            >
              <FormLabel component="legend">{t("My learning path is")}</FormLabel>
              <RadioGroup
                aria-label="My learning path is"
                name="learningPath"
                value={`${formik.values.learningPath}`}
                onChange={onLearningPathChange}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value={LEARNING_PATHS.B1_ROUTE}
                  control={<Radio color="primary" />}
                  label={LEARNING_PATHS.B1_ROUTE}
                />
                <FormControlLabel
                  value={LEARNING_PATHS.EDUCATION_ROUTE}
                  control={<Radio color="primary" />}
                  label={t(LEARNING_PATHS.EDUCATION_ROUTE)}
                />
                <FormControlLabel
                  value={LEARNING_PATHS.Z_ROUTE}
                  control={<Radio color="primary" />}
                  label={LEARNING_PATHS.Z_ROUTE}
                />
              </RadioGroup>
              <FormLabel component="legend" style={{ marginTop: "6px" }}>
                {Boolean(formik.errors?.learningPath) && t(formik?.errors?.learningPath)}
              </FormLabel>
            </FormControl>

            {formik.values?.learningPath && (
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={
                  Boolean(formik.touched?.languageLevel) &&
                  Boolean(formik.errors?.languageLevel)
                }
              >
                <FormLabel component="legend">{t("My language level is (learnability test)")}</FormLabel>
                <RadioGroup
                  aria-label="My language level is (learnability test)"
                  name="languageLevel"
                  value={`${formik.values?.languageLevel}`}
                  onChange={formik.handleChange}
                  className={classes.radioGroup}
                >
                  {LANGUAGE_LEVELS_BY_LEARNING_PATH?.[formik.values?.learningPath]?.map((languageLevel) => {
                    return (
                      <FormControlLabel
                        key={languageLevel}
                        value={LANGUAGE_LEVELS?.[languageLevel]}
                        control={<Radio color="primary" />}
                        label={
                          languageLevel === LANGUAGE_LEVELS.C1 ||
                            languageLevel === LANGUAGE_LEVELS.C2 ?
                            `${t(languageLevel)}${t("(optional)")}` :
                            t(languageLevel)
                        }
                      />
                    )
                  })}
                </RadioGroup>
                <FormLabel component="legend" style={{ marginTop: "6px" }}>
                  {Boolean(formik.errors?.languageLevel) && t(formik?.errors?.languageLevel)}
                </FormLabel>
              </FormControl>)}

            <Grid container direction="column">
              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "400px" }}
                fullWidth
                name="additionToMyNativeLanguageIAlsoSpeak"
                label={t("In addition to my native language, I also speak")}
                onBlur={formik.handleBlur}
                value={formik.values.additionToMyNativeLanguageIAlsoSpeak}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                InputLabelProps={formik.values.additionToMyNativeLanguageIAlsoSpeak ? { shrink: true } : {}}
                helperText={
                  Boolean(formik.errors?.additionToMyNativeLanguageIAlsoSpeak) &&
                  t(formik?.errors?.additionToMyNativeLanguageIAlsoSpeak)
                }
                error={
                  Boolean(formik.touched?.additionToMyNativeLanguageIAlsoSpeak) &&
                  Boolean(formik.errors?.additionToMyNativeLanguageIAlsoSpeak)
                }
              />


              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "400px" }}
                fullWidth
                name="nameOfIntegrationSchool"
                label={t("Name of integration school")}
                onBlur={formik.handleBlur}
                value={formik.values.nameOfIntegrationSchool}
                InputLabelProps={formik.values.nameOfIntegrationSchool ? { shrink: true } : {}}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.errors?.nameOfIntegrationSchool) &&
                  formik?.errors?.nameOfIntegrationSchool
                }
                error={
                  Boolean(formik.touched?.nameOfIntegrationSchool) &&
                  Boolean(formik.errors?.nameOfIntegrationSchool)
                }
              />


              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "400px" }}
                fullWidth
                name="locationOfIntegrationSchool"
                label={t("Location of integration school")}
                onBlur={formik.handleBlur}
                value={formik.values.locationOfIntegrationSchool}
                InputLabelProps={formik.values.locationOfIntegrationSchool ? { shrink: true } : {}}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.errors?.locationOfIntegrationSchool) &&
                  t(formik?.errors?.locationOfIntegrationSchool)
                }
                error={
                  Boolean(formik.touched?.locationOfIntegrationSchool) &&
                  Boolean(formik.errors?.locationOfIntegrationSchool)
                }
              />


              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "400px" }}
                fullWidth
                name="contactPersonIntegrationSchool"
                label={t("Contact person integration school")}
                onBlur={formik.handleBlur}
                value={formik.values.contactPersonIntegrationSchool}
                InputLabelProps={formik.values.contactPersonIntegrationSchool ? { shrink: true } : {}}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.errors?.contactPersonIntegrationSchool) &&
                  t(formik?.errors?.contactPersonIntegrationSchool)
                }
                error={
                  Boolean(formik.touched?.contactPersonIntegrationSchool) &&
                  Boolean(formik.errors?.contactPersonIntegrationSchool)
                }
              />

              <TextField
                required
                style={{ marginBottom: "20px", maxWidth: "400px" }}
                fullWidth
                name="myTeacherNameIs"
                label={t("My teacher's name is")}
                InputLabelProps={formik.values.myTeacherNameIs ? { shrink: true } : {}}
                onBlur={formik.handleBlur}
                value={formik.values.myTeacherNameIs}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.errors?.myTeacherNameIs) &&
                  t(formik?.errors?.myTeacherNameIs)
                }
                error={
                  Boolean(formik.touched?.myTeacherNameIs) &&
                  Boolean(formik.errors?.myTeacherNameIs)
                }
              />
            </Grid>

            <Grid container>
              <Grid container className={classes.teachingDaysMargin}>
                <FormLabel component="legend">{t('My teaching days are as follows')}</FormLabel>
              </Grid>
              {WEEKLY_TEACHING_DAYS.map((weeklyDay) => {
                return (
                  <FormControl component="fieldset" key={weeklyDay} className={classes.teachingFormControl}>
                    <FormGroup>
                      <FormControlLabel
                        color='primary'
                        control={
                          <Checkbox
                            name={`teachingDaysAreAsFollows.${weeklyDay.toLowerCase()}.haveTeaching`}
                            checked={
                              formik.values?.teachingDaysAreAsFollows?.[weeklyDay.toLowerCase()]?.haveTeaching
                            }
                            color='primary'
                            onChange={formik.handleChange}
                          />
                        }
                        label={t(weeklyDay)}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`teachingDaysAreAsFollows.${weeklyDay.toLowerCase()}.morning`}
                            color='primary'
                            onChange={formik.handleChange}
                            checked={
                              formik.values?.teachingDaysAreAsFollows?.[weeklyDay.toLowerCase()]?.morning
                            }
                          />
                        }
                        label={t('morning')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`teachingDaysAreAsFollows.${weeklyDay.toLowerCase()}.afternoon`}
                            color='primary'
                            onChange={formik.handleChange}
                            checked={
                              formik.values?.teachingDaysAreAsFollows?.[weeklyDay.toLowerCase()]?.afternoon
                            }
                          />
                        }
                        label={t('afternoon')}
                      />
                    </FormGroup>
                  </FormControl>
                )
              })}
            </Grid>

            <Box mt={4} mb={1} style={{ width: "inherit" }}>
              <FormControl
                className={classes.marginTopAndBottom}
                component="fieldset"
                fullWidth
                error={Boolean(formik.touched?.haveLanguageBuddy)
                  && Boolean(formik.errors?.haveLanguageBuddy)
                }
              >
                <FormLabel component="legend">{t("I have a language buddy")}</FormLabel>
                <RadioGroup
                  aria-label="I have a language buddy"
                  name="haveLanguageBuddy"
                  value={`${formik.values?.haveLanguageBuddy}`}
                  onChange={onLanguageBuddyChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.haveLanguageBuddy) &&
                  Boolean(formik.errors?.haveLanguageBuddy) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.haveLanguageBuddy)}
                    </FormLabel>
                  )}
              </FormControl>
            </Box>


            {formik.values?.haveLanguageBuddy === false && (
              <Box mb={4}>
                <FormControl
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  fullWidth
                  error={
                    Boolean(formik.touched?.necessary) &&
                    Boolean(formik.errors?.necessary)
                  }
                >
                  <FormLabel component="legend">{`${t("Necessary")}?`}</FormLabel>
                  <RadioGroup
                    aria-label="Necessary?"
                    name="necessary"
                    value={`${formik.values?.necessary}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {Boolean(formik.errors?.necessary) && t(formik?.errors?.necessary)}
                  </FormLabel>
                </FormControl>
              </Box>
            )}


            <Box mb={4} mt={2} width="inherit">
              <Typography variant="h2" className={classes.title}>
                {t('Participation Declaration Process (PVT)')}
              </Typography>
            </Box>

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={Boolean(formik.touched?.participationDeclarationProcess?.haveCompletedThePVT)
                && Boolean(formik.errors?.participationDeclarationProcess?.haveCompletedThePVT)
              }
            >
              <FormLabel component="legend">{t("I have completed the PVT")}</FormLabel>
              <RadioGroup
                aria-label="I have completed the PVT"
                name="participationDeclarationProcess.haveCompletedThePVT"
                value={`${formik.values?.participationDeclarationProcess?.haveCompletedThePVT}`}
                onChange={onPvtChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.participationDeclarationProcess?.haveCompletedThePVT) &&
                Boolean(formik.errors?.participationDeclarationProcess?.haveCompletedThePVT) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.participationDeclarationProcess.haveCompletedThePVT)}
                  </FormLabel>
                )}
            </FormControl>


            {formik.values?.participationDeclarationProcess?.haveCompletedThePVT && (
              <Grid container>
                <Box mb={2} mr={6}>
                  <DatePicker
                    id="participationDeclarationProcess_by"
                    name="participationDeclarationProcess.by"
                    label={t('by')}
                    value={formatDate(formik.values?.participationDeclarationProcess?.by)}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    helperText={
                      Boolean(formik.touched?.participationDeclarationProcess?.by) &&
                      t(formik?.errors?.participationDeclarationProcess?.by)
                    }
                    error={
                      Boolean(formik.touched?.participationDeclarationProcess?.by) &&
                      Boolean(formik.errors?.participationDeclarationProcess?.by)
                    }
                  />
                </Box>
                <Box mb={2}>
                  <DatePicker
                    id="participationDeclarationProcess_until"
                    name="participationDeclarationProcess.until"
                    label={t('until')}
                    value={formatDate(formik.values?.participationDeclarationProcess?.until)}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    helperText={
                      Boolean(formik.touched?.participationDeclarationProcess?.until) &&
                      t(formik?.errors?.participationDeclarationProcess?.until)
                    }
                    error={
                      Boolean(formik.touched?.participationDeclarationProcess?.until) &&
                      Boolean(formik.errors?.participationDeclarationProcess?.until)
                    }
                  />
                </Box>
              </Grid>

            )}

            {formik.values?.participationDeclarationProcess?.haveCompletedThePVT === false && (
              <Grid item xs={12} alignContent="left" mb={2}>
                <Box mb={2}>
                  <DatePicker
                    id="participationDeclarationProcess_roundingFor"
                    name="participationDeclarationProcess.roundingFor"
                    label={t('Rounding for')}
                    value={formatDate(formik.values?.participationDeclarationProcess?.roundingFor)}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    helperText={
                      Boolean(formik.touched?.participationDeclarationProcess?.roundingFor) &&
                      t(formik?.errors?.participationDeclarationProcess?.roundingFor)
                    }
                    error={
                      Boolean(formik.touched?.participationDeclarationProcess?.roundingFor) &&
                      Boolean(formik.errors?.participationDeclarationProcess?.roundingFor)
                    }
                  />
                </Box>
              </Grid>)}

            <Box mb={4} mt={6} width="inherit">
              <Typography variant="h2" className={classes.title}>
                {t('FOLDER')}
              </Typography>
            </Box>


            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={Boolean(formik.touched?.participationDeclarationProcess?.haveCompletedThePVT)
                && Boolean(formik.errors?.participationDeclarationProcess?.haveCompletedThePVT)
              }
            >
              <FormLabel component="legend">{t("I completed the MAP")}</FormLabel>
              <RadioGroup
                aria-label="I completed the MAP"
                name="completedTheMAP.completed"
                value={`${formik.values?.completedTheMAP?.completed}`}
                onChange={onCompletedTheMAPChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.participationDeclarationProcess?.haveCompletedThePVT) &&
                Boolean(formik.errors?.participationDeclarationProcess?.haveCompletedThePVT) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.participationDeclarationProcess.haveCompletedThePVT)}
                  </FormLabel>
                )}
            </FormControl>

            <Grid container>

              {formik.values?.completedTheMAP?.completed === true && (
                <Grid container>
                  <Box mb={2} mr={6}>
                    <DatePicker
                      name="by"
                      id="completedTheMAP_completed_by"
                      label={t('by')}
                      value={formatDate(formik.values?.completedTheMAP?.completedPractice?.from)}
                      disabled={formik.isSubmitting}
                      onChange={onByUntilDateChange}
                      fullWidth={true}
                      helperText={
                        Boolean(formik.touched?.completedTheMAP?.completedPractice?.from) &&
                        t(formik?.errors?.completedTheMAP?.completedPractice?.from)
                      }
                      error={
                        Boolean(formik.touched?.completedTheMAP?.completedPractice?.from) &&
                        Boolean(formik.errors?.completedTheMAP?.completedPractice?.from)
                      }
                    />
                  </Box>
                  <Box mb={2}>
                    <DatePicker
                      id="completedTheMAP_completed_until"
                      name="until"
                      label={t('until')}
                      value={formatDate(formik.values?.completedTheMAP?.completedPractice?.to)}
                      disabled={formik.isSubmitting}
                      onChange={onByUntilDateChange}
                      fullWidth={true}
                      helperText={
                        Boolean(formik.touched?.completedTheMAP?.completedPractice?.to) &&
                        t(formik?.errors?.completedTheMAP?.completedPractice?.to)
                      }
                      error={
                        Boolean(formik.touched?.completedTheMAP?.completedPractice?.to) &&
                        Boolean(formik.errors?.completedTheMAP?.completedPractice?.to)
                      }
                    />
                  </Box>
                </Grid>
              )}

              {formik.values?.completedTheMAP?.completed === false && (
                <Grid container >
                  <Grid container>
                    {formik.values?.completedTheMAP?.theory === true ? (
                      <Grid container>
                        <FormLabel component="legend" fullWidth>{t("Theory completed from to")}</FormLabel>
                        <Grid container>
                          <Box mr={6}>
                            <DatePicker
                              id="completedTheMAP_completedTheory_from"
                              name="completedTheMAP.completedTheory.from"
                              value={formatDate(formik.values?.completedTheMAP?.completedTheory?.from)}
                              disabled={formik.isSubmitting}
                              onChange={formik.handleChange}
                              fullWidth={true}
                              helperText={
                                Boolean(formik.touched?.completedTheMAP?.completedTheory?.from) &&
                                t(formik?.errors?.completedTheMAP?.completedTheory?.from)
                              }
                              error={
                                Boolean(formik.touched?.completedTheMAP?.completedTheory?.from) &&
                                Boolean(formik.errors?.completedTheMAP?.completedTheory?.from)
                              }
                            />
                          </Box>
                          <Box>
                            <DatePicker
                              id="completedTheMAP_completedTheory_to"
                              name="completedTheMAP.completedTheory.to"
                              value={formatDate(formik.values?.completedTheMAP?.completedTheory?.to)}
                              disabled={formik.isSubmitting}
                              onChange={formik.handleChange}
                              fullWidth={true}
                              helperText={
                                Boolean(formik.errors?.completedTheMAP?.completedTheory?.to) &&
                                t(formik?.errors?.completedTheMAP?.completedTheory?.to)
                              }
                              error={
                                Boolean(formik.touched?.completedTheMAP?.completedTheory?.to) &&
                                Boolean(formik.errors?.completedTheMAP?.completedTheory?.to)
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box width="inherit" className={classes.marginTopAndBottom}>
                        <DatePicker
                          id="completedTheMAP_completing_theory"
                          name="completedTheMAP.completing.theory"
                          value={formatDate(formik.values?.completedTheMAP?.completing?.theory)}
                          label={t('Completing theory for')}
                          disabled={formik.isSubmitting}
                          onChange={formik.handleChange}
                          fullWidth={true}
                          helperText={
                            Boolean(formik.errors?.completedTheMAP?.completing?.theory) &&
                            t(formik?.errors?.completedTheMAP?.completing?.theory)
                          }
                          error={
                            Boolean(formik.errors?.completedTheMAP?.completing?.theory)
                          }
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid container>
                    {formik.values?.completedTheMAP?.practice === true ? (
                      <Grid container className={classes.marginTopAndBottom}>
                        <FormLabel component="legend" fullWidth>{t("Practice completed from to")}</FormLabel>
                        <Grid container>
                          <Box mr={6}>
                            <DatePicker
                              id="completedTheMAP_completedPractice_from"
                              name="completedTheMAP.completedPractice.from"
                              value={formatDate(formik.values?.completedTheMAP?.completedPractice?.from)}
                              disabled={formik.isSubmitting}
                              onChange={formik.handleChange}
                              fullWidth={true}
                              helperText={
                                Boolean(formik.touched?.completedTheMAP?.completedPractice?.from) &&
                                t(formik?.errors?.completedTheMAP?.completedPractice?.from)
                              }
                              error={
                                Boolean(formik.touched?.completedTheMAP?.completedPractice?.from) &&
                                Boolean(formik.errors?.completedTheMAP?.completedPractice?.from)
                              }
                            />
                          </Box>
                          <Box>
                            <DatePicker
                              id="completedTheMAP_completedPractice_to"
                              name="completedTheMAP.completedPractice.to"
                              value={formatDate(formik.values?.completedTheMAP?.completedPractice?.to)}
                              disabled={formik.isSubmitting}
                              onChange={formik.handleChange}
                              helperText={
                                Boolean(formik.touched?.completedTheMAP?.completedPractice?.to) &&
                                t(formik?.errors?.completedTheMAP?.completedPractice?.to)
                              }
                              error={
                                Boolean(formik.touched?.completedTheMAP?.completedPractice?.to) &&
                                Boolean(formik.errors?.completedTheMAP?.completedPractice?.to)
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box width="inherit" className={classes.marginTopAndBottom}>
                        <DatePicker
                          id="completedTheMAP_completing_practice"
                          name="completedTheMAP.completing.practice"
                          value={formatDate(formik.values?.completedTheMAP?.completing?.practice)}
                          label={t('Completing practice for')}
                          disabled={formik.isSubmitting}
                          onChange={formik.handleChange}
                          fullWidth={true}
                          helperText={
                            Boolean(formik.errors?.completedTheMAP?.completing?.practice) &&
                            t(formik?.errors?.completedTheMAP?.completing?.practice)
                          }
                          error={
                            Boolean(formik.errors?.completedTheMAP?.completing?.practice)
                          }
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}

              <FormControl component="fieldset" className={classes.teachingFormControl}>
                <FormGroup onChange={onPracticeTheoryChange}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"completedTheMAP.theory"}
                        color='primary'
                        checked={formik.values?.completedTheMAP?.theory}
                      />
                    }
                    label={t('Theory')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"completedTheMAP.practice"}
                        color='primary'
                        checked={formik.values?.completedTheMAP?.practice}
                      />
                    }
                    label={t('Practice')}
                  />
                </FormGroup>
              </FormControl>



              <Box mb={4} mt={6} width="inherit">
                <Typography variant="h2" className={classes.title}>
                  {t('KNM')}
                </Typography>
              </Box>

              <FormControl
                className={classes.marginTopAndBottom}
                component="fieldset"
                fullWidth
                error={Boolean(formik.touched?.completedTheKNM?.completed)
                  && Boolean(formik.errors?.completedTheKNM?.completed)
                }
              >
                <FormLabel component="legend">{t("I completed the KNM")}</FormLabel>
                <RadioGroup
                  aria-label="I completed the KNM"
                  name="completedTheKNM.completed"
                  value={`${formik.values?.completedTheKNM?.completed}`}
                  onChange={onCompletedTheKNMChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.completedTheKNM?.completed) &&
                  Boolean(formik.errors?.completedTheKNM?.completed) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.completedTheKNM?.completed)}
                    </FormLabel>
                  )}
              </FormControl>

              {formik.values?.completedTheKNM?.completed && (
                <Grid container>
                  <Box mb={2} mr={6}>
                    <DatePicker
                      id="completedTheKNM_by"
                      name="completedTheKNM.by"
                      label={t('by')}
                      value={formatDate(formik.values?.completedTheKNM?.by)}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      helperText={
                        Boolean(formik.errors?.completedTheKNM?.by) &&
                        t(formik?.errors?.completedTheKNM?.by)
                      }
                      error={
                        Boolean(formik.touched?.completedTheKNM?.by) &&
                        Boolean(formik.errors?.completedTheKNM?.by)
                      }
                    />
                  </Box>
                  <Box mb={2}>
                    <DatePicker
                      name="completedTheKNM.until"
                      id="completedTheKNM_until"
                      label={t('until')}
                      value={formatDate(formik.values?.completedTheKNM?.until)}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      helperText={
                        Boolean(formik.errors?.completedTheKNM?.until) &&
                        t(formik?.errors?.completedTheKNM?.until)
                      }
                      error={
                        Boolean(formik.touched?.completedTheKNM?.until) &&
                        Boolean(formik.errors?.completedTheKNM?.until)
                      }
                    />
                  </Box>
                </Grid>
              )}
              {formik.values?.completedTheKNM?.completed === false && (
                <Box mb={2} width="inherit">
                  <DatePicker
                    name="completedTheKNM.roundingFor"
                    id="completedTheKNM_roundingFor"
                    label={t('Rounding for')}
                    value={formatDate(formik.values?.completedTheKNM?.roundingFor)}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    fullWidth={true}
                    helperText={
                      Boolean(formik.errors?.completedTheKNM?.roundingFor) &&
                      t(formik?.errors?.completedTheKNM?.roundingFor)
                    }
                    error={
                      Boolean(formik.touched?.completedTheKNM?.roundingFor) &&
                      Boolean(formik.errors?.completedTheKNM?.roundingFor)
                    }
                  />
                </Box>
              )}
            </Grid>

          </Grid>
          <Grid
            item
            style={{
              marginTop: "20px",
              minWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 60px"
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              onClick={() => setSelectedPage("saveAndPrevious")}
            >
              {t("SAVE & PREVIOUS")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              endIcon={formik.isSubmitting && (
                <CircularProgress size={18} color="inherit" />
              )}
            >
              {t("Save and Next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid >
  );
};

export default IntegrationForm;
