import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  clearIcon: {
    verticalAlign: "middle",
    padding: "2px",
    height: "18px",
    color: "454A92",
    fontSize: "18px",
    cursor: "pointer",
  },
  continueButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginRight: "30px",
    boxShadow: "none",
  },
  cancelButton: {
    fontSize: "16px",
    backgroundColor: "white",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginRight: "30px",
    border: "1px solid #454A92",
    color: "#454A92",
    boxShadow: "none",
    "&:hover": {
      color: "#ffffff",
    },
  },
}));

function AssignedRefugeeToSupplierRefugeesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const continueClick = () => {
    sessionStorage.setItem(
      "assignedRefugees",
      JSON.stringify(props.selectedRefugees)
    );
    sessionStorage.setItem(
      "unAssignedRefugees",
      JSON.stringify(props.unSelectedRefugees)
    );
    navigate("/viewEditSupplierSummaryByManager");
  };
  const cancelClick = () => {
    navigate("/viewEditSupplierSummaryByManager");
  };

  return (
    <div>
      <div style={{ marginTop: "103px" }} className="edit-mun-table-div">
        <InfiniteScroll
          dataLength={props.selectedRefugees.length + props.totalDocs}
          next={props.fetchMoreData}
          hasMore={props.hasNextPage}
          loader={<h4>Loading...</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b></b>
            </p>
          }
        >
          <table
            className="system-search-table-tag"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
              backgroundColor: "white",
            }}
          >
            <tr>
              <th>{t("BSN")}</th>
              <th>{t("Name")}</th>
              <th></th>
            </tr>

            {props.selectedRefugees &&
              props.selectedRefugees.length > 0 &&
              props.selectedRefugees.map((doc, index) => (
                <tr key={doc?.id}>
                  <td>{doc.bsn}</td>
                  <td>{doc.name}</td>
                  <td style={{ width: "110px" }}>
                    <ClearIcon
                      onClick={() => {
                        props.unAssignRefugee(doc);
                      }}
                      className={classes.clearIcon}
                    />
                  </td>
                </tr>
              ))}

            {props.assignedRefugeesState &&
              props.assignedRefugeesState.length > 0 &&
              props.assignedRefugeesState.map((doc, index) => (
                <tr key={doc?.id}>
                  <td>{doc.bsn}</td>
                  <td>{doc.name}</td>
                  <td style={{ width: "110px" }}>
                    <ClearIcon
                      onClick={() => {
                        props.unAssignRefugeeBackend(doc);
                      }}
                      className={classes.clearIcon}
                    />
                  </td>
                </tr>
              ))}
          </table>
        </InfiniteScroll>
      </div>

      <div className="assigned-mentor-to-municipality-btn-div">
        <Button
          variant="contained"
          className={classes.continueButton}
          onClick={() => continueClick()}
          color="primary"
          disabled={props.isButtonDisabled}
        >
          {t("Continue")}
        </Button>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={() => cancelClick()}
          color="primary"
          disabled={props.isButtonDisabled}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}

export default AssignedRefugeeToSupplierRefugeesData;
