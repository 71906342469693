import { Text, View, Page } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate } from "../../../utils/helpers";
import {
  WEEKLY_TEACHING_DAYS
} from "../../../resources/constants";

function isPrimitive(value) {
  return value !== Object(value);
}


const INTEGRATION_LABELS = {
  "dateLastModified": "Date (last modified)",
  "learningPath": "My learning path is",
  "languageLevel": "My language level is (learnability test)",
  "additionToMyNativeLanguageIAlsoSpeak": "In addition to my native language, I also speak",
  "nameOfIntegrationSchool": "Name of integration school",
  "locationOfIntegrationSchool": "Location of integration school",
  "contactPersonIntegrationSchool": "Contact person integration school",
  "myTeacherNameIs": "My teacher's name is",
  "haveLanguageBuddy": "I have a language buddy",
  "necessary": "Necessary",
  "participationDeclarationProcess": "Participation Declaration Process (PVT)",
  "haveCompletedThePVT": "I have completed the PVT",
  "teachingDaysAreAsFollows": "My teaching days are as follows",
  "completedTheMAP": "Folder",
  "completedTheKNM": "I completed the KNM",
  "by": "by",
  "until": "until",
  "roundingFor": "Rounding for",
  "completed": "I completed the MAP",
  "theory": "Theory",
  "practice": "Practice",
  "completedPracticeFrom": "From",
  "completedPracticeTo": "To",
  "completedTheoryFrom": "From",
  "completedTheoryTo": "To",
};

const getTeachingDaysAreAsFollows = (dataKey, dataValue) => {
  return (
    <View>
      <View>
        <Text style={[styles.subtitle, { margin: 0, padding: "20px 0 20px 0" }]}>
          {i18next.t(INTEGRATION_LABELS[dataKey])}
        </Text>
      </View>
      {WEEKLY_TEACHING_DAYS.map((day) => {
        const wekDayData = dataValue[day.toLowerCase()];
        return (
          <View key={day} style={{ padding: "5px 10px 5px 10px" }}>
            <View style={[styles.viewRow]}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(day)}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{wekDayData.haveTeaching ? i18next.t("Yes") : i18next.t("No")}</Text>
              </View>
            </View>
            <View style={styles.viewRow}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t("morning")}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{wekDayData.morning ? i18next.t("Yes") : i18next.t("No")}</Text>
              </View>
            </View>
            <View style={styles.viewRow}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t("afternoon")}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{wekDayData.afternoon ? i18next.t("Yes") : i18next.t("No")}</Text>
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
};

const getDeclarationProcess = (dataKey, dataValue) => {
  delete dataValue.__typename
  return (
    <View>
      <View style={{ padding: "20px 0 20px 0" }}>
        <Text style={[styles.subtitle, { margin: 0 }]}>
          {i18next.t(INTEGRATION_LABELS[dataKey])}
        </Text>
      </View>
      {Object.entries(dataValue).map((mapData) => {
        const [key, value] = mapData;
        if (value !== null) {
          return (
            <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]} key={`${dataKey}_${key}`}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(INTEGRATION_LABELS?.[key])}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                {typeof value === 'boolean' ? (
                  <Text>{value ? i18next.t("Yes") : i18next.t("No")}</Text>
                ) : (
                  <Text>{formatDate(value)}</Text>
                )}
              </View>
            </View>
          )
        }
      })}
    </View>
  )
};

const getCompletedTheMAPNonePrimitive = (dataKey, dataValue) => {
  const {
    completed,
    practice,
    theory,
    completedPractice,
    completedTheory,
    completing
  } = dataValue;

  if (completed) {
    return (
      <View>
        <View style={[styles.viewRow,{ padding: "5px 10px 5px 10px" }]}>
          <View style={{ width: "50%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(INTEGRATION_LABELS?.["by"])}
            </Text>
          </View>

          <View style={styles.viewRowInput}>
            <Text>{formatDate(completedPractice.from)}</Text>
          </View>
        </View>
        <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]}>
          <View style={{ width: "50%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(INTEGRATION_LABELS?.["until"])}
            </Text>
          </View>

          <View style={styles.viewRowInput}>
            <Text>{formatDate(completedPractice.to)}</Text>
          </View>
        </View>
      </View>
    )
  } else {
    if (theory) {
      return (
        <View>
          <View style={[styles.viewRow,{ padding: "5px 10px 5px 10px" }]}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t("Theory completed from to")}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{`${formatDate(completedTheory.from)}-${formatDate(completedTheory.to)}`}</Text>
            </View>
          </View>
          <View style={[styles.viewRow,{ padding: "5px 10px 5px 10px" }]}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t("Completing practice for")}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(completing?.practice)}</Text>
            </View>
          </View>
        </View>
      )
    }
    if (practice) {
      return (
        <View>
          <View style={[styles.viewRow,{ padding: "5px 10px 5px 10px" }]}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t("Practice completed from to")}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{`${formatDate(completedPractice.from)} - ${formatDate(completedPractice.to)}`}</Text>
            </View>
          </View>
          <View style={[styles.viewRow,{ padding: "5px 10px 5px 10px" }]}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t("Completing theory for")}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(completing?.theory)}</Text>
            </View>
          </View>
        </View>
      )
    }
  }
};

const getCompletedTheMAPPrimitive = (dataKey, dataValue) => {
  delete dataValue.__typename
  if (dataValue !== null) {
    delete dataValue.__typename
    return Object.entries(dataValue).map((dataMap) => {
      const [key, value] = dataMap;
      if (value !== null && isPrimitive(value)) {
        if (isPrimitive(value) && value !== null) {
          return (
            <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]} key={`${dataKey}_${key}`}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(INTEGRATION_LABELS?.[key])}
                </Text>
              </View>

              <View style={styles.viewRowInput}>
                {typeof value === 'boolean' ? (
                  <Text>{value ? i18next.t("Yes") : i18next.t("No")}</Text>
                ) : (
                  <Text>{formatDate(value)}</Text>
                )}
              </View>
            </View>
          )
        }
      }
    })
  }
};

const getCompletedTheKNM = (dataValue) => {
  const { completed, roundingFor, by, until } = dataValue

  return (
    <View>
      <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]}>
        <View style={{ width: "50%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(INTEGRATION_LABELS["completedTheKNM"])}
          </Text>
        </View>

        <View style={styles.viewRowInput}>
          <Text>{completed ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>
      {completed ? (
        <View>
          <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t("by")}
              </Text>
            </View>

            <View style={styles.viewRowInput}>
              <Text>{formatDate(by)}</Text>
            </View>
          </View>
          <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t("until")}
              </Text>
            </View>

            <View style={styles.viewRowInput}>
              <Text>{formatDate(until)}</Text>
            </View>
          </View>
        </View>
      ) : (
        <View style={[styles.viewRow, { padding: "5px 10px 5px 10px" }]}>
          <View style={{ width: "50%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(INTEGRATION_LABELS["roundingFor"])}
            </Text>
          </View>

          <View style={styles.viewRowInput}>
            <Text>{formatDate(roundingFor)}</Text>
          </View>
        </View>
      )}
    </View>
  )
}

const getIntegrationData = (dataKey, dataValue) => {
  if (isPrimitive(dataValue)) {
    return (
      <View>
        <View style={[styles.viewRow]}>
          <View style={{ width: "50%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(INTEGRATION_LABELS?.[dataKey])}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            {typeof dataValue === 'boolean' ? (
              <Text>{dataValue ? i18next.t("Yes") : i18next.t("No")}</Text>
            ) : (
              <Text>{`${dataValue}`.split('')}</Text>
            )}
          </View>
        </View>
      </View>
    )
  }
  switch (dataKey) {
    case "teachingDaysAreAsFollows":
      return getTeachingDaysAreAsFollows(dataKey, dataValue);
    case "participationDeclarationProcess":
      return getDeclarationProcess(dataKey, dataValue);
    case "completedTheMAP":
      return (
        <View>
          <Text style={[styles.subtitle, { margin: 0, padding: "20px 0 20px 0" }]}>
            {i18next.t(INTEGRATION_LABELS[dataKey])}
          </Text>
          {getCompletedTheMAPPrimitive(dataKey, dataValue)}
          {getCompletedTheMAPNonePrimitive(dataKey, dataValue)}
        </View>
      )
    case "completedTheKNM":
      return (
        <View>
          <Text style={[styles.subtitle, { margin: 0, padding: "20px 0 20px 0"}]}>
            {i18next.t(INTEGRATION_LABELS[dataKey])}
          </Text>
          {getCompletedTheKNM(dataValue)}
        </View>
      )
  }
};

function IntegrationPdf({ integrationPageData, headerText }) {
  delete integrationPageData.__typename

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View style={styles.viewRowContainer}>
          <View style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t(INTEGRATION_LABELS["dateLastModified"])}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(integrationPageData.dateLastModified)}</Text>
            </View>
          </View>
        </View>

        <View style={{ padding: "20px 0 20px 0" }}>
          <Text style={[styles.subtitle, { margin: 0 }]}>
            {i18next.t('Language and learning route')}
          </Text>
        </View>
        <View>
          {Object.entries(integrationPageData)?.map((integration) => {
            const [key, value] = integration;
            return (
              <View key={key}>
                {value !== null && key !== "dateLastModified" && getIntegrationData(key, value)}
              </View>
            )
          })}
        </View>
      </View>
    </Page>
  );
};

export default IntegrationPdf;
