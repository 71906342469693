import React, { useState, useEffect} from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import checkIcon from "../../../assets/images/Tick_Mark_Icon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CustomScrollbar from "../../shared/CustomScrollbar/index"
import ListItemText from '@material-ui/core/ListItemText';

// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";
const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
  },
  customScrollbar: {
    maxHeight: "200px",
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
    },
    /* For Firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5)',
    /* For Edge */
    '-ms-overflow-style': 'none',
    'scrollbar-3dlight-color': 'rgba(255, 255, 255, 0.5)',
    'scrollbar-arrow-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-base-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-darkshadow-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-face-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-highlight-color': 'rgba(255, 255, 255, 0.5)',
    'scrollbar-shadow-color': 'rgba(0, 0, 0, 0.5)',
  },
  listItem: {
    '&.MuiListItem-gutters': {
      marginLeft: '-26px',
      marginRight: '16px',
    },
  },
  filterContinueButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    float: "right",
    marginRight: "15px",
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  dropdown: {
    position: "absolute",
    // width: "155px",
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    height: "226px",
  },
  chkroot: {
    padding: 2,
  },
  icon: {
    border: "1px solid #454A92",
    borderRadius: 0,
    width: "18px",
    height: "18px",
  },
  checkedIcon: {
    backgroundColor: "#ffffff",
    "&:before": {
      display: "block",
      width: "18px",
      height: "18px",
      backgroundImage: `url(${checkIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "14px 14px",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffffff",
    },
  },
}));
// Inspired by blueprintjs
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.chkroot}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      color="primary"
      {...props}
    />
  );
}
function AssignRefugeeToSupplierRefugeesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [refugees, setRefugees] = useState({});
  const [selectedRefugeeIds, setSelectedRefugeeIds] = useState([]);
 

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedRefugees(true));
    };
  }, []);
  useEffect(() => {
   
  
    let municipalities = JSON.parse(sessionStorage.getItem("assignedMunicipalities"));
    let municipalityId = municipalities && municipalities.length > 0 ? municipalities[0]?.id : undefined;
    
    const storedRefugeeIds = JSON.parse(
      localStorage.getItem("selectedRefugeeIds")
    );

      dispatch(
        Actions.getAllMiniSearchedRefugees(
          page + 1,
          rowsPerPage,
          props.searchText,
          "",
          "SS-20",
          municipalityId
        )
      );
      setSelectedRefugeeIds(storedRefugeeIds || []);
  }, [rowsPerPage, page, props.searchText, dispatch]);
  const handleChangeSelected = (index) => {
    const existingSelectedIds =
    JSON.parse(localStorage.getItem("selectedRefugeeIds")) || [];
    const randomNumber = Math.floor(Math.random() * 15) + 1;
    const newString = "hi".replace(/hi/, randomNumber);
    props.setStateRefugee(newString)
    
    const randomNumberTwo = Math.floor(Math.random() * 15) + 1;
    const newStringTwo = "hi".replace(/hi/, randomNumberTwo);
    props.setStatePost(newStringTwo);
    if(props.setClickState){
      props?.setClickState(newStringTwo)
    }
    let newObj = Object.assign({}, refugees);
    newObj.docs[index].isSelected = !refugees.docs[index].isSelected;
    if (newObj.docs[index].isSelected) {
      let newElement = {
        bsn: newObj.docs[index].bsn,
        id: newObj.docs[index]?.id,
        name: newObj.docs[index].name,
      };
      props.setSelectedRefugees(() => [...props.selectedRefugees, newElement]);
    }
    if (!newObj.docs[index].isSelected) {
      props.setSelectedRefugees(
        props.selectedRefugees.filter(
          (item) => item?.id !== newObj.docs[index]?.id
        )
      );
    }
    const newSelectedIds = newObj.docs
    .filter((refugee) => refugee.isSelected)
    .map((refugee) => refugee.id);
  const combinedIds = Array.from(
    new Set([...existingSelectedIds, ...newSelectedIds])
  );
  localStorage.setItem("selectedRefugeeIds", JSON.stringify(combinedIds));
  setSelectedRefugeeIds(combinedIds);;
    setRefugees(newObj);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const SearchedRefugees = useSelector(
    ({ AssignRefugeeToSupplierRefugeesDataReducer }) =>
      AssignRefugeeToSupplierRefugeesDataReducer?.allMiniSearchedRefugeesReducer
        ?.data
  );
  const loading = useSelector(
    ({ AssignRefugeeToSupplierRefugeesDataReducer }) =>
      AssignRefugeeToSupplierRefugeesDataReducer?.allMiniSearchedRefugeesReducer
        ?.isLoading
  );

  const errMsg = useSelector(
    ({ AssignRefugeeToSupplierRefugeesDataReducer }) =>
      AssignRefugeeToSupplierRefugeesDataReducer?.allMiniSearchedRefugeesReducer
        ?.errMsg
  );

  useEffect(() => {
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.selectedRefugees.length === 0
    ) {
      let refugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (refugeesObj.docs !== null && refugeesObj.docs.length > 0) {
        for (let i = 0; i < refugeesObj.docs.length; i++) {
          const doc = refugeesObj.docs[i];
          doc["isSelected"] = false;
        }
      }
      setRefugees(refugeesObj);
    }
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.selectedRefugees.length > 0
    ) {
      let selrefugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (selrefugeesObj.docs !== null && selrefugeesObj.docs.length > 0) {
        for (let i = 0; i < selrefugeesObj.docs.length; i++) {
          const seldoc = selrefugeesObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedRefugees.length; j++) {
            const selRefugee = props.selectedRefugees[j];
            if (seldoc?.id === selRefugee?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      if(selrefugeesObj.totalDocs !== 0) {
        setRefugees(selrefugeesObj);
      }
    }
  }, [SearchedRefugees]);
  useEffect(() => {
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.selectedRefugees.length > 0 &&
      props.editCheck
    ) {
      let selrefugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (selrefugeesObj.docs !== null && selrefugeesObj.docs.length > 0) {
        for (let i = 0; i < selrefugeesObj.docs.length; i++) {
          const seldoc = selrefugeesObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedRefugees.length; j++) {
            const selRefugee = props.selectedRefugees[j];
            if (seldoc?.id === selRefugee?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setRefugees(selrefugeesObj);
      props.setEditCheck(false);
    }
  }, [props.editCheck, SearchedRefugees]);
  
  useEffect(() => {
    if (
      SearchedRefugees &&
      SearchedRefugees.miniSearchAllRefugees &&
      props.unAssignId
    ) {
      let selrefugeesObj = SearchedRefugees.miniSearchAllRefugees;
      if (selrefugeesObj.docs !== null && selrefugeesObj.docs.length > 0) {
        for (let i = 0; i < selrefugeesObj.docs.length; i++) {
          const seldoc = selrefugeesObj.docs[i];
          seldoc["isSelected"] = false;
          for (let j = 0; j < props.selectedRefugees.length; j++) {
            const selRefugee = props.selectedRefugees[j];
            if (seldoc?.id === selRefugee?.id) {
              seldoc["isSelected"] = true;
            }
          }
        }
      }
      setRefugees(selrefugeesObj);
      props.setUnAssignId(false);
    }
  }, [props.unAssignId, props.selectedRefugees]);


  let municipalities = JSON.parse(sessionStorage.getItem("assignedMunicipalities"));

  return (
    <div>
       <h3>Select the Refugee(s)</h3>
    <div >
    {municipalities?.length === undefined ? (
      <>
         
      <List>
         <ListItem>
                    
                    <ListItemText  />
          </ListItem>
      </List>  
      </>
    ) : (
      
      <>
       <CustomScrollbar>
       <List>
          {refugees && refugees.docs && refugees.docs.length > 0 && (
            <>
              
              {refugees.docs
               .sort((a, b) => {
                if (a.isSelected && !b.isSelected) return -1;
                if (!a.isSelected && b.isSelected) return 1;
                const isSelectedA = selectedRefugeeIds?.includes(a.id);
                const isSelectedB = selectedRefugeeIds?.includes(b.id);
                if (isSelectedA && !isSelectedB) return -1;
                if (!isSelectedA && isSelectedB) return 1;
              
                if (isSelectedA === isSelectedB) {
                  const nameA = a.name.toLowerCase();
                  const nameB = b.name.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                }
                return 0;
              })
                .map((doc, index) => (
                  <ListItem className={classes.listItem}  key={doc.id} button onClick={() => handleChangeSelected(index)} disabled={props?.disabled}>
                    <Checkbox checked={doc.isSelected} />
                    <ListItemText primary={doc.name} />
                  </ListItem>
                ))
              }
            </>
          )}
        </List>
       </CustomScrollbar>
       
      </>
    )}
  </div>
  </div>
  );
}
export default withReducer(
  "AssignRefugeeToSupplierRefugeesDataReducer",
  reducer
)(AssignRefugeeToSupplierRefugeesData);
