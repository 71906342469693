import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { withStyles } from "@material-ui/core/styles";

import {
  Grid,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Skeleton from '@material-ui/lab/Skeleton';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import PipPlanPDF from "../../PDFDocuments/PipPlanPDF";
// store

import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";
import moment from "moment";
import { getNumberInString } from "../../../resources/constants/regExp"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "black",
  },
  textField: {
    width: "100%",
    margin: 0,
    backgroundColor: "#daeff0",
  },
});

const getVersionNumber = (versionString) => {
  const [version] = versionString.split('.');
  return Number(version.match(getNumberInString))
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root1: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  rootStyle: {
    borderRadius: 15,
    backgroundColor: "white",
    paddingTop: "25px",
    paddinBottom: "25px",
    paddingLeft: "45px",
    paddingRight: "25px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
      paddinBottom: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  headingaddform: {
    color: "#8fcccd",
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "1px solid #8fcccd",
    width: "270px",
    paddingBottom: "2px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headingaddform1: {
    color: "black",
    fontSize: "14px",
    fontWeight: "bold",
  },
  dividerColor: {
    marginTop: "45px",
    backgroundColor: "black",
  },
  textField: {
    // paddingTop: "20.5px",
    width: "100%",
    margin: 0,
    backgroundColor: "#daeff0",

    // height:"2%px",
    // padding: 0
  },
  textField1: {
    // paddingTop: "20.5px",
    width: "15%",
    margin: 0,
    backgroundColor: "#daeff0",

    // height:"2%px",
    // padding: 0
  },
  input1: {
    height: "5px",
    border: 0,
  },
  notchedOutline: {
    borderWidth: "0px",
    // borderColor: "yellow !important"
  },
  lable: {
    fontStyle: "italic",
    textAlign: "left",
  },
  cornerbtn: {
    textAlign: "right",
  },
  cornerbtn1: {
    margin: theme.spacing(1),
    fontSize: "13px",
    backgroundColor: "#454a92",
    width: "160px",
    // height: "45px",
    // border: "1px solid white",
    opacity: 1,
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#454a92",
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

function Pipversionsmodal(props) {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) {
      batch(() => {
        dispatch(
          Actions.getAllPipplanVersions(props.data?.id,1, 10)
        )
        dispatch(
          Actions.getPipPlanByRefugee(props.data?.id)
        )
      })
    }
  }, [dispatch, props.data]);

  const AllVersions = useSelector(
    ({ PipversionsmodalReducer }) =>
      PipversionsmodalReducer?.AllPipplanVersionsReducer?.data
  );

  const loading = useSelector(
    ({ PipversionsmodalReducer }) =>
      PipversionsmodalReducer?.AllPipplanVersionsReducer?.isLoading
  );

  const errMsg = useSelector(
    ({ PipversionsmodalReducer }) =>
      PipversionsmodalReducer?.AllPipplanVersionsReducer?.errMsg
  );

  const allPipPlanInfo = useSelector(
    ({ PipversionsmodalReducer }) =>
      PipversionsmodalReducer?.AllPipPlanInfo
  );

  const allPipPlanData = allPipPlanInfo.data;

  const Addpip = () => {
    navigate(`/addpipreport/${props.data.id}/${props.data?.municipality?._id}`, {
      state: props.data ? props.data : "",
      mid: props.mid ? props.mid : "",
    });
  };

  const allVersionsDocs = AllVersions?.listAllPIPPlanVersions?.docs;
  const hasOneVersion = allVersionsDocs?.some(doc => doc.version);
  const publishedPipVersions = allVersionsDocs?.filter(doc => doc.version);

  const getPipPlanDataById = (pipPlanId) => {
    return allPipPlanData.find((pipData) => pipData?._id === pipPlanId)
  };

  return (
    <div>
      <Dialog
        classes={{
          paper: classes.rootStyle,
        }}
        onClose={() => props.methodd(false)}
        aria-labelledby="customized-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.root1,
          },
        }}
        open={props.status}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => props.methodd(false)}
        >
          <Typography
            variant="h6"
            gutterBottom
            className={classes.headingaddform1}
          >
            {props.data ? props.data.name : "N/A"}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.headingaddform}
          >
            {t("Plan Inburgering en Participatie")} (PIP)
          </Typography>
        </DialogTitle>
        {allPipPlanInfo?.isLoading ?
          (<Skeleton variant="rect" width="100%" height="500px" />) :
          (
            <DialogContent style={{ overflow: "hidden" }}>
              <div style={{ width: "100%", height: "500px", textAlign: "center" }}>
                {publishedPipVersions?.length && allPipPlanData?.length ? (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead className={classes.TableHead}>
                          <TableRow>
                            <StyledTableCell align="center">
                              {t("Version")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t("Made on")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t("Adjusted to")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t("End Date")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t("Actions")}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {hasOneVersion && publishedPipVersions?.map(
                            (doc) => (
                              <TableRow key={doc?.id}>
                                <TableCell align="center">
                                  {doc.version}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(doc.createdAt).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(doc.updatedAt).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(doc.updatedAt).add(3, "year").format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell
                                  align={"center"}
                                >
                                  {/* {refugee name}-{version} */}
                                  <PDFDownloadLink
                                    fileName={`${props.data?.name}-${doc.version}.pdf`}
                                    document={
                                      <PipPlanPDF
                                        pipData={getPipPlanDataById(doc.id)}
                                        municipality={props.municipalitySummary}
                                      />
                                    }
                                  >
                                    {({ blob, url, loading, error }) => {
                                      return (
                                          <IconButton
                                            disabled={loading}
                                            aria-label="Download Pip PDF"
                                            style={{
                                              marginRight: publishedPipVersions.length !== 3 &&
                                                getVersionNumber(doc.version) === publishedPipVersions.length ?
                                                0 : "48px"
                                            }}
                                          >
                                            <GetAppIcon
                                              disabled={loading}
                                              color="primary"
                                              fontSize="small"
                                            />
                                          </IconButton>
                                      )
                                    }}
                                  </PDFDownloadLink>
                                  {publishedPipVersions.length !== 3 &&
                                    getVersionNumber(doc.version) === publishedPipVersions.length && (
                                      <IconButton
                                        color="primary"
                                        fontSize="small"
                                      >
                                        <EditIcon onClick={() => Addpip()} />
                                      </IconButton>)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Grid item container xs={12} style={{ marginTop: 80 }}>
                      <EmojiObjectsIcon fontSize="medium" style={{ marginRight: 10 }} />
                      <Typography style={{ textAlign: 'start' }}>
                        {t('The PIP plan can be adjusted up to 12 months after the start of the')}
                        <br />
                        {t('process, after these 12 months the PIP plan is fixed and no changes can be')}
                        <br />
                        {`${t('applied')}.`}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid container style={{ width: '100%' }}>
                    <Grid item container xs={12}>
                      <Typography variant="h6">
                        {`${t('PIP form(s) from')} ${props.data?.name}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container style={{ paddingTop: 40 }}>
                      <Button
                        onClick={() => Addpip()}
                        variant="contained"
                        style={{
                          mt: 3, mb: 2, width: "auto", marginBottom: "20px",
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "opensans-semibold",
                          backgroundColor: "#454a92",
                          color: "#fff"
                        }}
                      >
                        {allVersionsDocs?.length ? t('Continue filling in') : t('Start filling out')}
                      </Button>
                    </Grid>
                    <Grid item container xs={12} style={{ marginTop: 50 }}>
                      <EmojiObjectsIcon fontSize="medium" style={{ marginRight: 10 }} />
                      <Typography style={{ textAlign: 'start' }}>
                        {t('The PIP plan can be adjusted up to 12 months after the start of the')}
                        <br />
                        {t('process, after these 12 months the PIP plan is fixed and no changes can be')}
                        <br />
                        {`${t('applied')}.`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
            </DialogContent>
          )
        }
      </Dialog>
    </div >
  );
}
export default withReducer(
  "PipversionsmodalReducer",
  reducer
)(Pipversionsmodal);
