import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from "moment"
import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from 'formik';
import DatePicker from "../../../shared/DatePicker";
import { formatDate, getBooleanValue, removeKey } from "../../../../utils/helpers";
import { pipHealthAndLeisurePageSchema } from "../../../../resources/scheme";
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { DATE_FORMAT, pipPlanNavigationKeys } from "../../../../resources/constants";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "start",
  },
  subTitle: {
    fontSize: "10px",
    color: "#253c8c"
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "fit-content"
  },
  marginTopAndBottom: {
    margin: "10px 0"
  },
  buttonStyle: {
    mt: 3, mb: 2, width: "auto", marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9
    }
  }
}));

function HealthAndLeisureForm({ data, setPipPlanPage }) {
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState("")
  const params = useParams();
  const { refugeeId } = params;

  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.trainingAndDigitalSkills)
  }
  const navigatePreviousPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.socialGuidance)
  }

  const formik = useFormik({
    initialValues: {
      dateLastModified: data?.dateLastModified ?? '',
      physicalComplaints: {
        answer: data?.physicalComplaints?.answer ?? null,
        problem: data?.physicalComplaints?.problem ?? null
      },
      psychologicalComplaints: {
        answer: data?.psychologicalComplaints?.answer ?? null,
        problem: data?.psychologicalComplaints?.problem ?? null,
      },
      emotionalProblems: {
        answer: data?.emotionalProblems?.answer ?? null,
        problem: data?.emotionalProblems?.problem ?? null
      },
      shockingEventsOrExperiences: {
        answer: data?.shockingEventsOrExperiences?.answer ?? null,
        problem: data?.shockingEventsOrExperiences?.problem ?? null
      },
      sufferFromAnAddiction: {
        answer: data?.sufferFromAnAddiction?.answer ?? null,
        problem: data?.sufferFromAnAddiction?.problem ?? null
      },
      underMedicalTreatment: {
        answer: data?.underMedicalTreatment?.answer ?? null,
        problem: data?.underMedicalTreatment?.problem ?? null
      },
      medicalFacilitiesOrAdjustmentsInWork: {
        answer: data?.medicalFacilitiesOrAdjustmentsInWork?.answer ?? null,
        problem: data?.medicalFacilitiesOrAdjustmentsInWork?.problem ?? null
      },
      sportsAndLeisure: {
        exerciseEveryDay: {
          answer: data?.sportsAndLeisure?.exerciseEveryDay?.answer ?? null,
          whatDoYouDo: data?.sportsAndLeisure?.exerciseEveryDay?.whatDoYouDo ?? null
        },
        doYouSports: {
          answer: data?.sportsAndLeisure?.doYouSports?.answer ?? null,
          whichSport: data?.sportsAndLeisure?.doYouSports?.whichSport ?? '',
          youLikeToExercise: data?.sportsAndLeisure?.doYouSports?.youLikeToExercise ?? null
        },
        languageCafeSamensp: data?.sportsAndLeisure?.languageCafeSamensp ?? null,
        becomeMemberOfLibrary: data?.sportsAndLeisure?.becomeMemberOfLibrary ?? null,
        canSwim: data?.sportsAndLeisure?.canSwim ?? null,
        canSwimChildren: data?.sportsAndLeisure?.canSwimChildren ?? ''
      }
    },
    enableReinitialize: true,
    validationSchema: pipHealthAndLeisurePageSchema,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        helpers.setSubmitting(true);
        const queryString = JSON
          .stringify(removeKey({ ...values, dateLastModified: moment().format(DATE_FORMAT) }, '__typename'), null, 2)
          .replace(/"([^"]+)":/g, '$1:');
        const healthAndLeisureQuery = `healthAndLeisure: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: healthAndLeisureQuery })
          .then(() => {
            dispatch(getPipPlanByRefugee(refugeeId))

            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.socialGuidance)
            } else {
              setPipPlanPage(pipPlanNavigationKeys.trainingAndDigitalSkills)
            }
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      } else {
        helpers.setSubmitting(false);
        if (selectedPage === "saveAndPrevious") {
          navigatePreviousPage()
        } else {
          navigateNextPage()
        }
      }
    },
  });

  const resetProblem = (name) => {
    const [healthName] = name.split(".");
    formik.setFieldValue(`${healthName}.problem`, '')
  }

  const onAnswerAndProblemsChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (!booleanValue) {
      resetProblem(name)
    }
    formik.setFieldValue(name, booleanValue)
  }

  const onExerciseEveryDayChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (!booleanValue) {
      formik.setFieldValue('sportsAndLeisure.exerciseEveryDay.whatDoYouDo', '')
    } else {

    }
    formik.setFieldValue(name, booleanValue)
  }

  const onSportChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (!booleanValue) {
      formik.setFieldValue('sportsAndLeisure.doYouSports.whichSport', '')
    } else {
      formik.setFieldValue('sportsAndLeisure.doYouSports.youLikeToExercise', undefined)
    }
    formik.setFieldValue(name, booleanValue)
  }

  const onRadioChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    formik.setFieldValue(name, booleanValue)
  }

  return (
    <Grid container style={{ padding: "0 60px" }}>
      <form
        noValidate
        style={{ width: "100%" }}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container item
        >
          <Grid
            container
            item
            display="flex"
            direction="row"
          >
            <Box mb={2} mt={2} width="inherit">
              <Typography variant="h2" className={classes.title}>{t("Health")}</Typography>
              <DatePicker
                id="healthAndLeisure_lastModified"
                label={t("Date (last modified)")}
                name={'dateLastModified'}
                value={formatDate(formik.values?.dateLastModified)}
                disabled={true}
                fullWidth={true}
              />
            </Box>

            <FormControl
              fullWidth
              className={classes.marginTopAndBottom}
              component="fieldset"
              error={
                Boolean(formik.touched?.physicalComplaints?.answer) &&
                Boolean(formik.errors?.physicalComplaints?.answer)
              }
            >
              <FormLabel component="legend">{t("I have physical complaints")}
              </FormLabel>
              <RadioGroup
                aria-label="Physical complaints"
                name="physicalComplaints.answer"
                value={`${formik.values.physicalComplaints?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.physicalComplaints?.answer) &&
                Boolean(formik.errors?.physicalComplaints?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.physicalComplaints?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.physicalComplaints?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="physicalComplaints.problem"
                label={Boolean(formik.touched?.physicalComplaints?.problem) &&
                  formik.errors?.physicalComplaints?.problem ?
                  t(formik.errors?.physicalComplaints?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.physicalComplaints?.problem)
                  && Boolean(formik.errors?.physicalComplaints?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.physicalComplaints?.problem ?? ''}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)
            }

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.psychologicalComplaints?.answer) &&
                Boolean(formik.errors?.psychologicalComplaints?.answer)
              }
            >
              <FormLabel component="legend">{t("I have psychological complaints")}
              </FormLabel>
              <RadioGroup
                aria-label="Psychological complaints"
                name="psychologicalComplaints.answer"
                value={`${formik.values.psychologicalComplaints?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.psychologicalComplaints?.answer) &&
                Boolean(formik.errors?.psychologicalComplaints?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.psychologicalComplaints?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.psychologicalComplaints?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="psychologicalComplaints.problem"
                label={Boolean(formik.touched?.psychologicalComplaints?.problem) &&
                  formik.errors?.psychologicalComplaints?.problem ?
                  t(formik.errors?.psychologicalComplaints?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.psychologicalComplaints?.problem)
                  && Boolean(formik.errors?.psychologicalComplaints?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.psychologicalComplaints?.problem}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)}

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.emotionalProblems?.answer) &&
                Boolean(formik.errors?.emotionalProblems?.answer)
              }
            >
              <FormLabel component="legend">
                {t("I suffer from stress or emotional problems")}
              </FormLabel>
              <RadioGroup
                aria-label="Emotional problems"
                name="emotionalProblems.answer"
                value={`${formik.values.emotionalProblems?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.emotionalProblems?.answer) &&
                Boolean(formik.errors?.emotionalProblems?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.emotionalProblems?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.emotionalProblems?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="emotionalProblems.problem"
                label={Boolean(formik.touched?.emotionalProblems?.problem) &&
                  formik.errors?.emotionalProblems?.problem ?
                  t(formik.errors?.emotionalProblems?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.emotionalProblems?.problem)
                  && Boolean(formik.errors?.emotionalProblems?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.emotionalProblems?.problem ?? ''}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)}

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.shockingEventsOrExperiences?.answer) &&
                Boolean(formik.errors?.shockingEventsOrExperiences?.answer)
              }
            >
              <FormLabel component="legend">{t("I am bothered by shocking events and/or experiences from the past")}
              </FormLabel>
              <RadioGroup
                aria-label="Shocking events or experiences"
                name="shockingEventsOrExperiences.answer"
                value={`${formik.values.shockingEventsOrExperiences?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.shockingEventsOrExperiences?.answer) &&
                Boolean(formik.errors?.shockingEventsOrExperiences?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.shockingEventsOrExperiences?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.shockingEventsOrExperiences?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="shockingEventsOrExperiences.problem"
                label={Boolean(formik.touched?.shockingEventsOrExperiences?.problem) &&
                  formik.errors?.shockingEventsOrExperiences?.problem ?
                  t(formik.errors?.shockingEventsOrExperiences?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.shockingEventsOrExperiences?.problem)
                  && Boolean(formik.errors?.shockingEventsOrExperiences?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.shockingEventsOrExperiences?.problem ?? ''}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)}

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.sufferFromAnAddiction?.answer) &&
                Boolean(formik.errors?.sufferFromAnAddiction?.answer)
              }
            >
              <FormLabel component="legend">{t("I suffer from an addiction")}
              </FormLabel>
              <RadioGroup
                aria-label="Suffer from an addiction"
                name="sufferFromAnAddiction.answer"
                value={`${formik.values?.sufferFromAnAddiction?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.sufferFromAnAddiction?.answer) &&
                Boolean(formik.errors?.sufferFromAnAddiction?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sufferFromAnAddiction?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.sufferFromAnAddiction?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="sufferFromAnAddiction.problem"
                label={Boolean(formik.touched?.sufferFromAnAddiction?.problem) &&
                  formik.errors?.sufferFromAnAddiction?.problem ?
                  t(formik.errors?.sufferFromAnAddiction?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.sufferFromAnAddiction?.problem)
                  && Boolean(formik.errors?.sufferFromAnAddiction?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.sufferFromAnAddiction?.problem ?? ''}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)}

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.underMedicalTreatment?.answer) &&
                Boolean(formik.errors?.underMedicalTreatment?.answer)
              }
            >
              <FormLabel component="legend">
                {t("I am under medical treatment")}
              </FormLabel>
              <RadioGroup
                aria-label="Medical treatment"
                name="underMedicalTreatment.answer"
                value={`${formik.values.underMedicalTreatment?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.underMedicalTreatment?.answer) &&
                Boolean(formik.errors?.underMedicalTreatment?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.underMedicalTreatment?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values?.underMedicalTreatment?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="underMedicalTreatment.problem"
                label={Boolean(formik.touched?.underMedicalTreatment?.problem) &&
                  formik.errors?.underMedicalTreatment?.problem ?
                  t(formik.errors?.underMedicalTreatment?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.underMedicalTreatment?.problem)
                  && Boolean(formik.errors?.underMedicalTreatment?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.underMedicalTreatment?.problem ?? ''}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)}


            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={Boolean(formik.touched?.medicalFacilitiesOrAdjustmentsInWork?.answer)
                && Boolean(formik.errors?.medicalFacilitiesOrAdjustmentsInWork?.answer)
              }
            >
              <FormLabel component="legend">
                {t("I need medical facilities or adjustments in my work or living situation")}
              </FormLabel>
              <RadioGroup
                aria-label="Adjustments"
                name="medicalFacilitiesOrAdjustmentsInWork.answer"
                value={`${formik.values.medicalFacilitiesOrAdjustmentsInWork?.answer}`}
                onChange={onAnswerAndProblemsChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.medicalFacilitiesOrAdjustmentsInWork?.answer) &&
                Boolean(formik.errors?.medicalFacilitiesOrAdjustmentsInWork?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.medicalFacilitiesOrAdjustmentsInWork?.answer)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.medicalFacilitiesOrAdjustmentsInWork?.answer === true && (
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="medicalFacilitiesOrAdjustmentsInWork.problem"
                label={Boolean(formik.touched?.medicalFacilitiesOrAdjustmentsInWork?.problem) &&
                  formik.errors?.medicalFacilitiesOrAdjustmentsInWork?.problem ?
                  t(formik.errors?.medicalFacilitiesOrAdjustmentsInWork?.problem) :
                  t('Type here...')
                }
                error={Boolean(formik.touched?.medicalFacilitiesOrAdjustmentsInWork?.problem)
                  && Boolean(formik.errors?.medicalFacilitiesOrAdjustmentsInWork?.problem)
                }
                onBlur={formik.handleBlur}
                value={formik.values.medicalFacilitiesOrAdjustmentsInWork?.problem ?? ''}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />)}

            <Box mt={4} mb={4}>
              <Typography fullWidth variant="h2" className={classes.title}>{t('Sports and leisure')}</Typography>
            </Box>

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={Boolean(formik.touched?.sportsAndLeisure?.exerciseEveryDay?.answer)
                && Boolean(formik.errors?.sportsAndLeisure?.exerciseEveryDay?.answer)
              }
            >
              <FormLabel component="legend">{t("I walk/cycle/exercise every day")}</FormLabel>
              <RadioGroup
                aria-label="walk/cycle/exercise"
                name="sportsAndLeisure.exerciseEveryDay.answer"
                value={`${formik.values.sportsAndLeisure?.exerciseEveryDay?.answer}`}
                onChange={onExerciseEveryDayChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.sportsAndLeisure?.exerciseEveryDay?.answer) &&
                Boolean(formik.errors?.sportsAndLeisure?.exerciseEveryDay?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sportsAndLeisure?.exerciseEveryDay?.answer)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values.sportsAndLeisure.exerciseEveryDay?.answer === true && (
              <Grid item xs={12} container>
                <FormControl
                  className={classes.margin}
                  fullWidth
                  style={{
                    marginBottom: "20px",
                    maxWidth: "300px"
                  }}
                  error={
                    Boolean(formik.touched.sportsAndLeisure?.exerciseEveryDay?.whatDoYouDo) &&
                    Boolean(formik.errors.sportsAndLeisure?.exerciseEveryDay?.whatDoYouDo)}
                >
                  <Select
                    name='sportsAndLeisure.exerciseEveryDay.whatDoYouDo'
                    labelId="sportsAndLeisure.exerciseEveryDay.whatDoYouDo"
                    id="demo-customized-select"
                    value={formik.values.sportsAndLeisure.exerciseEveryDay?.whatDoYouDo}
                    onChange={formik.handleChange}
                    selected={formik.values.sportsAndLeisure.exerciseEveryDay?.whatDoYouDo}
                  >
                    <MenuItem
                      value={t('To walk')}
                      key={"toWalk"}
                    >
                      {t('To walk')}
                    </MenuItem>
                    <MenuItem
                      value={t('Misuse')}
                      key={"misuse"}
                    >
                      {t('Misuse')}
                    </MenuItem>
                    <MenuItem
                      value={t('To move')}
                      key={"toMove"}
                    >
                      {t('To move')}
                    </MenuItem>
                  </Select>
                  {Boolean(formik.touched?.sportsAndLeisure?.exerciseEveryDay?.whatDoYouDo) &&
                    Boolean(formik.errors?.sportsAndLeisure?.exerciseEveryDay?.whatDoYouDo) && (
                      <FormLabel component="legend">
                        {t(formik.errors?.sportsAndLeisure?.exerciseEveryDay?.whatDoYouDo)}
                      </FormLabel>
                    )}
                </FormControl>
              </Grid>
            )}

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={Boolean(formik.touched?.sportsAndLeisure?.doYouSports?.answer)
                && Boolean(formik.errors?.sportsAndLeisure?.doYouSports?.answer)
              }
            >
              <FormLabel component="legend">{t("I sport")}</FormLabel>
              <RadioGroup
                aria-label="I sport"
                name="sportsAndLeisure.doYouSports.answer"
                value={`${formik.values.sportsAndLeisure?.doYouSports?.answer}`}
                onChange={onSportChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.sportsAndLeisure?.doYouSports?.answer) &&
                Boolean(formik.errors?.sportsAndLeisure?.doYouSports?.answer) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sportsAndLeisure?.doYouSports?.answer)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values.sportsAndLeisure?.doYouSports?.answer === true && (
              <TextField
                style={{paddingBottom: 20}}
                fullWidth
                name='sportsAndLeisure.doYouSports.whichSport'
                value={formik.values.sportsAndLeisure?.doYouSports?.whichSport}
                label={t('Which sport do you practice?')}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={
                  Boolean(formik.touched?.sportsAndLeisure?.doYouSports?.whichSport) &&
                  t(formik?.errors?.sportsAndLeisure?.doYouSports?.whichSport)
                }
                error={
                  Boolean(formik.touched?.sportsAndLeisure?.doYouSports?.whichSport) &&
                  Boolean(formik.errors?.sportsAndLeisure?.doYouSports?.whichSport)
                }
              />
            )}

            {formik.values.sportsAndLeisure?.doYouSports?.answer === false && (
              <FormControl
                className={classes.marginTopAndBottom}
                component="fieldset"
                fullWidth
                error={
                  Boolean(formik.touched?.sportsAndLeisure?.doYouSports?.youLikeToExercise) &&
                  Boolean(formik.errors?.sportsAndLeisure?.doYouSports?.youLikeToExercise)
                }
              >
                <FormLabel component="legend">{t("I like to exercise")}</FormLabel>
                <RadioGroup
                  aria-label="I like to exercise"
                  name="sportsAndLeisure.doYouSports.youLikeToExercise"
                  value={`${formik.values.sportsAndLeisure?.doYouSports?.youLikeToExercise}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.sportsAndLeisure?.doYouSports?.youLikeToExercise) &&
                  Boolean(formik.errors?.sportsAndLeisure?.doYouSports?.youLikeToExercise) && (
                    <FormLabel component="legend">
                      {t(formik.errors?.sportsAndLeisure?.doYouSports?.youLikeToExercise)}
                    </FormLabel>
                  )}
              </FormControl>

            )}

            < FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.sportsAndLeisure?.languageCafeSamensp) &&
                Boolean(formik.errors?.sportsAndLeisure?.languageCafeSamensp)
              }
            >
              <FormLabel component="legend">{t("Want to participate in the language café/samensp")}</FormLabel>
              <RadioGroup
                aria-label="Want to participate in the language café/samensp"
                name="sportsAndLeisure.languageCafeSamensp"
                value={`${formik.values.sportsAndLeisure?.languageCafeSamensp}`}
                onChange={onRadioChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.sportsAndLeisure?.languageCafeSamensp) &&
                Boolean(formik.errors?.sportsAndLeisure?.languageCafeSamensp) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sportsAndLeisure?.languageCafeSamensp)}
                  </FormLabel>
                )}
            </FormControl>

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.sportsAndLeisure?.becomeMemberOfLibrary) &&
                Boolean(formik.errors?.sportsAndLeisure?.becomeMemberOfLibrary)
              }
            >
              <FormLabel component="legend">{t("I want to become a member of the library")}</FormLabel>
              <RadioGroup
                aria-label="I want to become a member of the library"
                name="sportsAndLeisure.becomeMemberOfLibrary"
                value={`${formik.values.sportsAndLeisure?.becomeMemberOfLibrary}`}
                onChange={onRadioChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.sportsAndLeisure?.becomeMemberOfLibrary) &&
                Boolean(formik.errors?.sportsAndLeisure?.becomeMemberOfLibrary) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sportsAndLeisure?.becomeMemberOfLibrary)}
                  </FormLabel>
                )}
            </FormControl>

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.sportsAndLeisure?.canSwim) &&
                Boolean(formik.errors?.sportsAndLeisure?.canSwim)
              }
            >
              <FormLabel component="legend">{t("I can swim")}</FormLabel>
              <RadioGroup
                aria-label="I can swim"
                name="sportsAndLeisure.canSwim"
                value={`${formik.values.sportsAndLeisure?.canSwim}`}
                onChange={onRadioChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.sportsAndLeisure?.canSwim) &&
                Boolean(formik.errors?.sportsAndLeisure?.canSwim) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sportsAndLeisure?.canSwim)}
                  </FormLabel>
                )}
            </FormControl>

            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.sportsAndLeisure?.canSwimChildren) &&
                Boolean(formik.errors?.sportsAndLeisure?.canSwimChildren)
              }
            >
              <FormLabel component="legend">{t("My children can swim")}</FormLabel>
              <RadioGroup
                aria-label="My children can swim"
                name="sportsAndLeisure.canSwimChildren"
                value={formik.values.sportsAndLeisure?.canSwimChildren}
                onChange={formik.handleChange}
                className={classes.radioGroup}
                error={
                  Boolean(formik.touched?.sportsAndLeisure?.canSwimChildren) &&
                  Boolean(formik.errors?.sportsAndLeisure?.canSwimChildren)
                }
              >
                <FormControlLabel value={"yes"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"no"} control={<Radio color="primary" />} label={t("No")} />
                <FormControlLabel value={"n/a"} control={<Radio color="primary" />} label={t("N/A")} />
              </RadioGroup>
              {Boolean(formik.touched?.sportsAndLeisure?.canSwimChildren) &&
                Boolean(formik.errors?.sportsAndLeisure?.canSwimChildren) && (
                  <FormLabel component="legend">
                    {t(formik.errors?.sportsAndLeisure?.canSwimChildren)}
                  </FormLabel>
                )}
            </FormControl>
          </Grid>
          <Grid
            item
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              onClick={() => setSelectedPage("saveAndPrevious")}
            >
              {t("SAVE & PREVIOUS")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              endIcon={formik.isSubmitting && (
                <CircularProgress size={18} color="inherit" />
              )}
            >
              {t("Save and Next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid >
  );
};

export default HealthAndLeisureForm;