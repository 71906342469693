import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import checkIcon from "../../../assets/images/Tick_Mark_Icon.svg";
import StyledCheckbox from "../../shared/StyledCheckbox";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  alreadyChecked: {
    marginLeft: "5px",
    width: "14px",
  },
}));

function AssignRefugeeToMunicipalityRefugeesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refugees, setRefugees] = useState({});

  const SearchedRefugees = useSelector(
    ({ AssignRefugeeToMunicipalityRefugeesDataReducer }) =>
      AssignRefugeeToMunicipalityRefugeesDataReducer
        ?.allMiniSearchedRefugeesReducer?.data
  );
  const loading = useSelector(
    ({ AssignRefugeeToMunicipalityRefugeesDataReducer }) =>
      AssignRefugeeToMunicipalityRefugeesDataReducer
        ?.allMiniSearchedRefugeesReducer?.isLoading
  );

  const errMsg = useSelector(
    ({ AssignRefugeeToMunicipalityRefugeesDataReducer }) =>
      AssignRefugeeToMunicipalityRefugeesDataReducer
        ?.allMiniSearchedRefugeesReducer?.errMsg
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedRefugees(true));
    };
  }, []);

  useEffect(() => {
    dispatch(
      Actions.getAllMiniSearchedRefugees(
        page + 1,
        rowsPerPage,
        props.searchText,
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const newSuppliersObj = { ...refugees };
    newSuppliersObj.docs[index].isSelected = !refugees.docs[index].isSelected;
    if (newSuppliersObj.docs[index].isSelected) {
      const selectedRefugee = {
        id: newSuppliersObj.docs[index]?.id,
        bsn: newSuppliersObj.docs[index].bsn,
        name: newSuppliersObj.docs[index].name,
      };
      props.setSelectedRefugees(() => [
        ...props.selectedRefugees,
        selectedRefugee,
      ]);
    }
    if (!newSuppliersObj.docs[index].isSelected) {
      props.setSelectedRefugees(
        props.selectedRefugees.filter(
          (selectedRefugee) =>
            selectedRefugee?.id !== newSuppliersObj.docs[index]?.id
        )
      );
    }
    setRefugees(newSuppliersObj);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const refugeesObj = SearchedRefugees?.miniSearchAllRefugees;
    if (refugeesObj) {
      const newRefugeesObj = { ...refugeesObj };
      if (newRefugeesObj.docs && newRefugeesObj.docs.length) {
        for (let i = 0; i < newRefugeesObj.docs.length; i++) {
          newRefugeesObj.docs[i].isSelected = false;
          if (props.selectedRefugees.length) {
            for (let j = 0; j < props.selectedRefugees.length; j++) {
              if (
                newRefugeesObj.docs[i]?.id === props.selectedRefugees[j]?.id
              ) {
                newRefugeesObj.docs[i].isSelected = true;
              }
            }
          }
        }
      }
      setRefugees(newRefugeesObj);
    }
  }, [SearchedRefugees, props.selectedRefugees]);

  return (
    <div>
      <div className="system-search-filter-div">
        <div className="system-search-filter-total-div">
          {t("Total Refugees")}:{" "}
          {refugees && refugees.totalDocs ? refugees.totalDocs : 0}
        </div>
      </div>

      <div className="edit-mun-table-div">
        <table
          className="system-search-table-tag"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <tr>
            <th>{t("BSN")}</th>
            <th>{t("Name")}</th>
            <th>{t("Assigned Municipality")}</th>
            <th>{t("Assign")}</th>
          </tr>

          {refugees.docs &&
            refugees.docs.length > 0 &&
            refugees.docs.map((doc, index) => (
              <tr key={doc?.id}>
                <td>{doc.bsn}</td>
                <td>{doc.name}</td>
                <td>
                  {doc.municipality ? (
                    doc.municipality.name
                  ) : (
                    <span className="unassigned-td">{t("Unassigned")}</span>
                  )}
                </td>
                <td>
                  {doc.municipality &&
                  doc.municipality._id === sessionStorage.getItem("user_id") ? (
                    <img
                      src={checkIcon}
                      className={classes.alreadyChecked}
                    ></img>
                  ) : (
                    <StyledCheckbox
                      checked={!!doc.isSelected}
                      onClick={() => handleChangeSelected(index)}
                    />
                  )}
                </td>
              </tr>
            ))}
        </table>
      </div>

      <div className="assign-mentor-to-municipality-pagination-div">
        <TablePagination
          colSpan={3}
          component="div"
          count={refugees && refugees.totalDocs ? refugees.totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          classes={{
            root: classes.tablePagination,
          }}
        />
      </div>
    </div>
  );
}

export default withReducer(
  "AssignRefugeeToMunicipalityRefugeesDataReducer",
  reducer
)(AssignRefugeeToMunicipalityRefugeesData);
