import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import React from "react";

function AppointmentComponent({ children, style, ...restProps }) {
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        borderRadius: "8px",
      }}
    >
      {children}
    </Appointments.Appointment>
  );
}
export default AppointmentComponent;
