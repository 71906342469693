import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { useFormik } from "formik";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "../../css/login.css";
import nl from "../../assets/images/nl.svg";
import en from "../../assets/images/en.svg";
import logob from "../../assets/images/logob.png";
import Otpmodalfinal from "./sub_components/Otpmodalfinal";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import { useLocation, useNavigate } from "react-router-dom";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";
import { useEffect } from "react";
import { useState } from "react";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "transparent",
    color: "white",
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: "white",
  },
  button: {
    margin: theme.spacing(1),
    fontSize: "15.6pt",
    fontFamily: "Calibri",
    backgroundColor: "#283583",
    width: "35%",
    height: "45px",
    border: "1px solid white",
    opacity: 1,
    color: "white",
    paddingTop: "10px",
    "&:hover": {
      background: "#222a5d",
    },
  },
  LoginPageHeading: {
    fontFamily: "opensans-semibold",
    fontSize: "48px",
    [theme.breakpoints.between("xs", "md")]: {
      fontFamily: "opensans-semibold",
      fontSize: "30px",
    },
  },
}));

function LoginPage(props) {
  // getting path name from url to check if user is admin or not
  const pathName = useLocation().pathname;

  //all useState hooks
  const [otpopen, setOtpopen] = useState(false);
  const [check, setCheck] = useState(true);
  const [selectedFlag, setSelectedFlag] = useState({});
  const [resend, setResend] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [firstLogin, setFirstLogin] = useState(true);
  const [flags, setFlags] = useState([
    { id: "en", url: en, label: "EN", selected: true },
    { id: "nl", url: nl, label: "NL", selected: false },
    // { id: "arb", url: arb, label: "ARB", selected: false },
    // { id: "fr", url: fr, label: "FR", selected: false },
    // { id: "be", url: be, label: "BE", selected: false }
  ]);

  //all custom hooks
  const classes = useStyles();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  //validation schema for login form
  const validationSchema = Yup.object({
    email: Yup.string().email().required(t("Email is required")),
    password: isAdmin
      ? Yup.string().required(t("Password is required"))
      : Yup.string(3).required(t("Password is required")),
    check: firstLogin
      ? Yup.bool().oneOf([true], t("You must accept the Terms of Use"))
      : Yup.bool(),
  });

  //all useSelector hooks
  const confirmation = useSelector(
    ({ AuthReducer }) => AuthReducer?.AuthenticateUserReducer
  );
  const verifyOTPByAdminReducer = useSelector(
    ({ AuthReducer }) => AuthReducer?.VerifyOtpReducer
  );
  const ResendOtpReducer = useSelector(
    ({ AuthReducer }) => AuthReducer?.ResendOtpReducer
  );

  //all useEffect hooks
  useEffect(() => {
    setIsAdmin(pathName === "/" ? true : false);
    if (localStorage.getItem("language")) {
      let newData = [...flags];
      for (let i = 0; i < newData.length; i++) {
        const data = newData[i];
        if (data?.id === localStorage.getItem("language")) {
          newData[i].selected = true;
        } else {
          newData[i].selected = false;
        }
      }
      setFlags(newData);
    }
  }, []);
  useEffect(() => {
    if (confirmation.isAuthenticated) {
      setOtpopen(true);
      if (isAdmin) dispatch(Actions.verifyOTPByAdmin("1234"));
      else dispatch(Actions.verifyOtp("1234"));
    }
  }, [confirmation]);

  useEffect(() => {
    const hasUserLoggedInBefore = localStorage.getItem("hasUserLoggedIn");
    if (hasUserLoggedInBefore) {
      setFirstLogin(false);
    } else {
      localStorage.setItem("hasUserLoggedIn", "true");
    }
  }, []);
  useEffect(() => {
    if (
      confirmation.isAuthenticated &&
      confirmation.isOtpVerified &&
      confirmation.user
    ) {
      if (isAdmin) {
        navigate("/dashboard");
        localStorage.setItem("name", confirmation?.user?.name);

        localStorage.setItem("email", confirmation?.user?.email);
      } else {
        if (confirmation.user?.role === "manager") {
          navigate("/managerdashboard");
          localStorage.setItem("manager", confirmation?.user?.id);
          localStorage.setItem("name", confirmation?.user?.name);
          localStorage.setItem("email", confirmation?.user?.email);
          localStorage.removeItem("mentor");
          localStorage.removeItem("admin");
          localStorage.removeItem("supplier");
        }
        if (confirmation.user?.role === "mentor") {
          navigate("/mentordashboard");
          localStorage.setItem("mentor", confirmation?.user?.id);
          localStorage.setItem("name", confirmation?.user?.name);
          localStorage.setItem("id", confirmation?.user?.id);
          localStorage.removeItem("manager");
          localStorage.removeItem("admin");
          localStorage.removeItem("supplier");

          localStorage.setItem("email", confirmation?.user?.email);
        }
        if (
          confirmation.user?.role === "work_supplier" ||
          confirmation.user?.role === "school_supplier"
        ) {
          navigate("/supplierdashboard");
          localStorage.setItem("supplier", confirmation?.user?.id);
          localStorage.setItem("name", confirmation?.user?.name);
          localStorage.removeItem("mentor");
          localStorage.removeItem("manager");
          localStorage.removeItem("admin");
          localStorage.setItem("email", confirmation?.user?.email);
        }
      }
    }
  }, [confirmation]);
  console.log(confirmation.user);
  useEffect(() => {
    if (selectedFlag && selectedFlag?.id) {
      if (selectedFlag?.id === "en" || selectedFlag?.id === "nl") {
        i18n.changeLanguage(selectedFlag?.id);
        localStorage.setItem("language", selectedFlag?.id);
      }
    }
  }, [selectedFlag]);

  //all handlers
  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
      check: "",
    },
    validationSchema,
    onSubmit(values) {
      if (firstLogin && !values.check) {
        return;
      }
      isAdmin
        ? dispatch(Actions.authenticateadmin(values))
        : dispatch(Actions.authenticateuser(values));
    },
  });

  const OTPresend = () => {
    dispatch(Actions.resetAuthentiacateUser(true));
    if (isAdmin) {
      dispatch(Actions.authenticateadmin(values));
    } else {
      dispatch(Actions.authenticateuser(values));
    }
    setCheck(true);
  };

  const handleClickFlag = (flag, index) => {
    let newData = [...flags];
    for (let i = 0; i < newData.length; i++) {
      if (i === index) {
        newData[i].selected = true;
      } else {
        newData[i].selected = false;
      }
    }

    setFlags(newData);
    setSelectedFlag(flag);
  };
  const OTPverification = (otp, num) => {
    if (isAdmin) dispatch(Actions.verifyOTPByAdmin(otp));
    else dispatch(Actions.verifyOtp(otp));
  };

  const handleOTPmodal = (s) => {};

  const handleClickOtpclose = () => {
    dispatch(Actions.setCurrentUser({}));
    setOtpopen(false);
  };

  return (
    <div>
      {/* {otpopen === true && (
        <Otpmodalfinal
          status={otpopen}
          methodd={handleClickOtpclose}
          number="+923034754096"
          handleOTPmodal={handleOTPmodal}
          OTPverification={OTPverification}
          OTPresend={OTPresend}
          isLoading={verifyOTPByAdminReducer?.isLoading}
          errMsg={verifyOTPByAdminReducer?.errMsg}
          isLoadingResend={confirmation.isLoading}
          errMsgResend={confirmation.errMsg}
          dataResend={confirmation.data}
          resend={resend}
          setResend={setResend}
          setCheck={setCheck}
          check={check}
        />
      )} */}
      <header className="headerl">
        <div>
          <img className="block-logob" src={logob} alt="logob"></img>
        </div>
      </header>
      <div className="parentdiv">
        <Container
          style={{
            backgroundColor: "transparent",
          }}
          component="main"
          maxWidth="xs"
        >
          <CssBaseline />
          <div className={classes.paper}>
            <Typography
              className={classes.LoginPageHeading}
              component="h4"
              variant="h4"
            >
              {isAdmin ? t("ADMIN LOGIN") : t("USER LOGIN")}
            </Typography>
            <Typography
              style={{ fontFamily: "opensans-semibold", fontSize: "22.4px" }}
              component="h5"
              variant="h5"
            >
              {t("Backoffice")}
            </Typography>
            <form
              onSubmit={handleSubmit}
              style={{ width: "100%", marginTop: "40px" }}
            >
              <ul className="f-list">
                {flags &&
                  flags.map((flag, index) => (
                    <li
                      key={index}
                      className="li"
                      onClick={() => handleClickFlag(flag, index)}
                    >
                      <img
                        className={
                          flag.selected ? "selected-flag" : "unselected-flag"
                        }
                        src={flag.url}
                        alt="flag"
                      />
                      <p
                        className={
                          flag.selected
                            ? "selected-flag-label"
                            : "unselected-flag-label"
                        }
                      >
                        {flag.label}
                      </p>
                    </li>
                  ))}
              </ul>

              <div className="form-inside-div">
                {confirmation.errMsg && (
                  <div className="input-container">
                    {" "}
                    <Alert style={{ width: "100%" }} severity="error">
                      {t(confirmation.errMsg)}
                    </Alert>
                  </div>
                )}
                <div className="input-container">
                  <input
                    className="input-field"
                    type="email"
                    placeholder={t("Email Address")}
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  <i className="fa fa-user fa-2x icon"></i>
                </div>
                {touched.email && errors.email && (
                  <span className="error-msg">{errors.email}</span>
                )}

                <div className="input-container">
                  <input
                    className="input-field"
                    type="password"
                    placeholder={t("Password")}
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <i className="fa fa-lock fa-2x icon"></i>
                </div>
                {touched.password && errors.password && (
                  <span className="error-msg">{errors.password}</span>
                )}

                {firstLogin && (
                  <div className="input-container login-chkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={values.check}
                          labelStyle={{ color: "black" }}
                          iconStyle={{ fill: "white" }}
                          inputStyle={{ color: "white" }}
                          style={{ color: "white" }}
                          name="check"
                          onChange={handleChange}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: "13.842pt",
                            fontFamily: "Calibri",
                          }}
                        >
                          {t("I accept the Terms of Use")}
                        </span>
                      }
                    />
                  </div>
                )}
                {touched.check && errors.check && (
                  <span className="error-msg">{errors.check}</span>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                >
                  {t("LOGIN")}
                </Button>
                <Link
                  className={classes.link}
                  onClick={() => {
                    navigate({
                      pathname: isAdmin
                        ? "/requestResetPasswordForAdmin"
                        : "/requestResetPasswordForUser",
                    });
                  }}
                >
                  <p
                    style={{
                      marginTop: "2px",
                      fontSize: "13pt",
                      fontFamily: "Calibri",
                    }}
                  >
                    {t("Forgot Password")}?
                  </p>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      </div>
      <div id="footer"></div>
    </div>
  );
}

export default withReducer("AuthReducer", reducer)(LoginPage);
