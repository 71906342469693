import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "../../css/home.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// store
import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";
import { useState } from "react";
import { useEffect } from "react";
import jwt from "jsonwebtoken";
import { roles } from "../../utils/roles";
import TableRow from "./sub_components/TableRow";

//styling
const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    width: "127px",
    height: "35px",
    opacity: 1,
    color: "white",
    marginLeft: "15px",
    marginRight: "10px",
    borderRadius: "2px",
    float: "right",
  },
  addBtn: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    width: "127px",
    height: "35px",
    opacity: 1,
    color: "white",
    borderRadius: "2px",
  },
  paginationstyle: {
    "& > li > button": {
      color: "white",
    },
  },
  conform: {
    padding: "30px",
    marginTop: "3px",
    backgroundColor: "white",
    borderRadius: "12px",
    webkitboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    mozboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    boxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
  },
  con1: {
    backgroundColor: "white",
    marginTop: "99px",
    textAlign: "center",
    marginLeft: "3px",
    borderRadius: "12px",
    height: "435px",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      overflowY: "hidden",
      marginTop: "9px",
    },

    webkitboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    mozboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    boxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
  },
  con2: {
    backgroundColor: "white",
    marginTop: "99px",
    textAlign: "left",
    marginLeft: "3px",
    borderRadius: "14px",
    height: "264px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "9px",
    },

    webkitboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    mozboxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
    boxShadow: "-2px 2px 11px -3px rgba(0,0,0,0.75)",
  },
  heading: {
    color: "white",
    fontSize: "36pt",
    fontFamily: "opensans-semibold",
    [theme.breakpoints.down("lg")]: {
      fontSize: "26pt",
    },
  },
  headingaddform: {
    color: "#454a92",
    fontSize: "16px",
    fontWeight: "bold",
    borderBottom: "2px solid black",
    width: "220px",
    paddingBottom: "2px",
  },
  heading1: {
    color: "#454a92",
    fontFamily: "opensans-bold",
    fontSize: "12pt",
    textAlign: "left",
  },
  subheading: {
    color: "white",
    fontSize: "16.8pt",
    fontFamily: "opensans-semibold",
    marginTop: "-10px",
    fontWeight: "500",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12.8pt",
    },
  },
  subheading1: {
    color: "#454a92",
    fontFamily: "opensans-italic",
    fontSize: "10pt",
    textAlign: "left",
    marginTop: "-5px",
  },
  lable: {
    textAlign: "left",
  },
  cornerbtn: {
    textAlign: "right",
  },
  cornerbtn1: {
    margin: theme.spacing(1),
    fontSize: "13px",
    backgroundColor: "#454a92",
    width: "160px",
    opacity: 1,
  },
  row1: {
    textAlign: "center",
  },
  btnrow1: {
    marginTop: "13px",
    display: "flex",
  },

  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  papergraph: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "395px",
  },
  paper1: {
    cursor: "pointer",
    textAlign: "center",
    color: "white",
    backgroundColor: "#e84e0e",
    overflow: "hidden",
    height: "39px",
    maxHeight: "39px",
    minHeight: "39px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: "12pt",
    fontFamily: "opensans-semibold",
    [theme.breakpoints.down("lg")]: {
      fontSize: "9pt",
    },
  },
  paperico: {
    backgroundColor: "#72B0B4",
    height: "39px",
    maxHeight: "39px",
    minHeight: "39px",
  },
  button: {
    fontSize: "12pt",
    backgroundColor: "transparent",
    width: "100%",
    height: "38px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "none",
    fontFamily: "opensans-semibold",
  },
  buttonmenu: {
    fontSize: "12px",
    backgroundColor: "transparent",
    width: "100%",
    height: "38px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "lowercase",
    marginRight: "3px",
    padding: "1px",
  },
  btnupload: {
    backgroundColor: "#454a92",
  },
  textField: {
    width: "100%",
    margin: 0,
    backgroundColor: "#daeff0",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    backgroundColor: "#ffffff",
    borderRadius: "5px",
  },
  notchedOutline: {
    borderWidth: "0px",
  },
  input1: {
    height: "5px",
    border: 0,
    "&::placeholder": {
      fontSize: "12px",
      color: "#909090",
      opacity: "1",
      fontFamily: "opensans-semibold",
    },
  },
  input2: {
    height: "7px",
    border: 0,
  },
  input: {
    display: "none",
  },
  crossIcon: {
    color: "black",
    fontSize: 23,
  },
  crossIconBtn: {
    float: "right",
    marginTop: "-15px",
    marginRight: "-10px",
  },
  crossIconBtnGraph: {
    float: "right",
    marginTop: "-18px",
    marginRight: "-18px",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
  },
  rowContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
}));

function HomePage(props) {
  //all custom hooks
  const classes = useStyles();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //all useState hooks
  const [totalProgress, setTotalProgress] = useState(0);
  const [details, setDetails] = useState(undefined);
  const [contact, setContact] = useState(undefined);
  const [bg, setBg] = useState("bg");
  const [page, setPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(null);

  //all useEffect hooks
  useEffect(() => {
    if (
      jwt.decode(localStorage.getItem("jwtToken"))?.role === roles?.admin?.id
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(Actions.getAllAssignedMunicipalities(page, 10, isAdmin));
    }
  }, [page, dispatch, isAdmin]);

  useEffect(() => {
    dispatch(Actions.getAdminProfile(isAdmin));
  }, [isAdmin, dispatch]);

  //all useSelector hooks
  const profile_data = useSelector(
    ({ HomePageReducer }) => HomePageReducer?.adminProfileReducer?.data
  );

  //all other handler and methods
  const handleClickOpen = (id) => {
    setDetails(id);
    setContact(undefined);
  };

  const handleClickOpenContact = (doc) => {
    setContact(doc);
    setDetails(undefined);
  };

  const handleClickAddNewMunicipality = () => {
    sessionStorage.clear();
    sessionStorage.setItem("user_type", "municipality");
    navigate("/createUserByAdmin");
  };

  const handleClose = () => {
    setDetails(undefined);
    setContact(undefined);
  };

  const handleEditContact = (data) => {
    setDetails(undefined);
    setContact(undefined);
    navigate("/editMunicipalityContact", { state: data });
  };

  const Openmunipage = (id, logo) => {
    isAdmin
      ? navigate("/municipalitypip", { state: id, logo: logo })
      : navigate("/managerMunicipalitypip", { state: id });
  };

  function MainComponent() {
    return (
      <Container fixed className={classes.con}>
        <Grid container spacing={3}>
          <Grid
            item
            sm={12}
            md={4}
            xs={12}
            spacing={3}
            className={classes.row1}
          ></Grid>
          <Grid
            item
            sm={12}
            md={4}
            xs={12}
            spacing={3}
            className={classes.row1}
          >
            <Typography variant="h4" gutterBottom className={classes.heading}>
              {t("DASHBOARD")}
            </Typography>
            {isAdmin ? (
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subheading}
              >
                {t("Welcome")}{" "}
                {profile_data &&
                profile_data.getAdminProfile &&
                profile_data.getAdminProfile.name
                  ? profile_data.getAdminProfile.name
                  : "Admin"}
              </Typography>
            ) : (
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subheading}
              >
                {t("Welcome")}{" "}
                {profile_data &&
                profile_data.getManagerProfile &&
                profile_data.getManagerProfile.name
                  ? profile_data.getManagerProfile.name
                  : "Manager"}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
            xs={12}
            spacing={3}
            className={classes.row1}
          ></Grid>
          <Grid
            container
            item
            sm={12}
            xs={12}
            spacing={3}
            className={classes.rowContainer}
          >
            <TableRow
              isAdmin={isAdmin}
              contact={contact}
              handleClose={handleClose}
              handleEditContact={handleEditContact}
              handleClickOpen={handleClickOpen}
              handleClickOpenContact={handleClickOpenContact}
              handleClickAddNewMunicipality={handleClickAddNewMunicipality}
              classes={classes}
              t={t}
              details={details}
              setTotalProgress={setTotalProgress}
              totalProgress={totalProgress}
              Openmunipage={Openmunipage}
              page={page}
              setPage={setPage}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
  return (
    <div className="main">
      <div className={bg}>
        <MainComponent />
      </div>
    </div>
  );
}

export default withReducer("HomePageReducer", reducer)(HomePage);
