import { Grid } from "@material-ui/core";
import React from "react";
import ContactData from "./ContactData";

function ContactCont({
  isAdmin,
  contact,
  handleClose,
  handleEditContact,
  classes,
  t,
}) {
  if (contact) {
    return (
      <Grid
        container
        spacing={2}
        item
        lg={4}
        md={8}
        sm={12}
        xs={12}
        className={classes.con2}
      >
        <ContactData
          isAdmin={isAdmin}
          contact={contact}
          handleClose={handleClose}
          handleEditContact={handleEditContact}
          classes={classes}
          t={t}
        />
      </Grid>
    );
  }
  return null;
}

export default ContactCont;
