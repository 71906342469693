import * as Actions from "../actions";

//getMunicipalitySummaryReducer
export const municipalityInfo = function (
  state = {
    isLoading: false,
    errMsg: null,
    data: null,
    success: "",
  },
  action
) {

  switch (action.type) {
    case Actions.GET_MUNICIPALITY_SUMMARY:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload?.getMunicipalitySummary,
        success: true,
      };
    case Actions.MUNICIPALITY_SUMMARY_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.MUNICIPALITY_SUMMARY_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    case Actions.RESET_MUNICIPALITY_SUMMARY:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: [],
        success: "",
      };
    default:
      return state;
  }
};

export const pipPlanInfo = function (
  state = {
    isLoading: false,
    errMsg: null,
    data: null,
    success: "",
  },
  action
) {

  switch (action.type) {
    case Actions.GET_PIP_PLAN:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: action.payload?.getPIPPlanByRefugee?.pipPlan,
        success: true,
      };
    case Actions.PIP_PLAN_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMsg: null,
        data: null,
        success: false,
      };
    case Actions.PIP_PLAN_FAILED:
      return {
        ...state,
        isLoading: false,
        errMsg: action.payload,
        data: null,
        success: "failed",
      };
    case Actions.RESET_PIP_PLAN:
      return {
        ...state,
        isLoading: false,
        errMsg: null,
        data: [],
        success: "",
      };
    default:
      return state;
  }
};

