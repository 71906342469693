import React from "react";
import MomentUtils from "@date-io/moment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccessAlarm from "@material-ui/icons/AccessAlarm";
import Notes from "@material-ui/icons/Notes";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import CalendarToday from "@material-ui/icons/CalendarToday";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Create from "@material-ui/icons/Create";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import jwt from "jsonwebtoken";

const containerStyles = makeStyles((theme) => ({
  container: {
    width: theme.spacing(68),
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  header: {
    overflow: "hidden",
    paddingTop: theme.spacing(0.5),
    "@media (max-width: 500px)": {
      width: "70%",
    },
  },
  closeButton: {
    float: "right",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    paddingBottom: "10px",
    "@media (max-width: 500px)": {
      width: "60%",
    },
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  picker: {
    marginRight: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
    width: "50%",

    "@media (max-width: 500px)": {
      width: "66%",
      marginBottom: "10px",
      marginTop: "15px",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
    "@media (max-width: 500px)": {
      display: "inline-block",
    },
  },
  reminderWrapper: {
    display: "flex",
    padding: theme.spacing(1, 0),
    "@media (max-width: 500px)": {
      display: "inline-block",
    },
  },
  reminderField: {
    width: "48%",
    "@media (max-width: 670px)": {
      width: "92%",
    },
  },
  wrapperCheck: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 0),
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2),
    "@media (max-width: 500px)": {
      display: "none",
    },
  },
  textField: {
    width: "100%",
    "@media (max-width: 500px)": {
      width: "80%",
    },
  },
  textArea: {
    width: "100%",
    "@media (max-width: 500px)": {
      width: "92%",
    },
  },
  reminderTextField: {
    width: "22%",
    "@media (max-width: 500px)": {
      width: "79%",
    },
  },
}));

function AppointmentFormContainerBasic(props) {
  const classes = containerStyles();
  const [t] = useTranslation();
  const [appointmentChanges, setAppointmentChanges] = React.useState({});

  const getAppointmentData = () => {
    const { appointmentData } = props;
    return appointmentData;
  };
  // console.log(appointmentChanges)
  const getAppointmentChanges = () => {
    const appointmentChangest = appointmentChanges;
    return appointmentChangest;
  };

  function changeAppointment({ field, changes }) {
    const nextChanges = {
      ...getAppointmentChanges(),
      [field]: changes,
    };
    setAppointmentChanges(nextChanges);
  }

  function commitAppointment(type) {
    const { commitChanges } = props;
    const appointment = {
      ...getAppointmentData(),
      ...getAppointmentChanges(),
    };

    if (["added", "changed"].includes(type)) {
      if (!appointment.title) {
        alert("Title must not be empty");
        return;
      } else if (
        !(appointment.mentor || appointment.school || appointment.work)
      ) {
        alert("One Particpant must be selected");
        return;
      }
      if (type === "added") {
        commitChanges({ [type]: appointment });
      } else {
        commitChanges({ [type]: { ["data"]: appointment } });
      }
    } else if (type === "deleted") {
      commitChanges({ [type]: appointment.event_id });
    }
    setAppointmentChanges({});
  }

  const {
    visible,
    visibleChange,
    appointmentData,
    cancelAppointment,
    target,
    onHide,
  } = props;
  const appointmentChangess = appointmentChanges;
  
  const displayAppointmentData = {
    ...appointmentData,
    ...appointmentChangess,
  };
  const isNewAppointment = appointmentData.event_id === undefined;
  const applyChanges = isNewAppointment
    ? () => commitAppointment("added")
    : () => commitAppointment("changed");
  let note = "";
  if (displayAppointmentData.notes) {
    for (let i = 0; i < displayAppointmentData.notes.length; i++) {
      const element = displayAppointmentData.notes[i];
      
        note = element.note;
      
    }
  }

  const textEditorProps = (field) => ({
    variant: "outlined",
    onChange: ({ target: change }) => {
      var reg = /^0+/gi;
      if (field === "reminderBefore" && change.value.match(reg)) {
        changeAppointment({
          field: [field],
          changes: "",
        });
      } else {
        changeAppointment({
          field: [field],
          changes: change.value,
        });
      }
    },
    value: displayAppointmentData[field] || "",
    // label: field[0].toUpperCase() + field.slice(1),
    className:
      field === "reminderBefore"
        ? classes.reminderTextField
        : classes.textField,
  });

  const textEditorNotesProps = (field) => ({
    variant: "outlined",
    onChange: ({ target: change }) => {
      changeAppointment({
        field: [field],
        changes: change.value,
      });
    },
    value: note || displayAppointmentData[field] || "",
    // label: field[0].toUpperCase() + field.slice(1),
    className: classes.textArea,
  });

  const checkEditorProps = (field) => {
    return {
      onChange: (event) => {
        changeAppointment({
          field: [field],
          changes: event.target.checked,
        });
      },
      checked:
        field === "school"
          ? selectedSuppliersSchool.length > 0
          : field === "work"
          ? selectedSuppliersWork.length > 0
          : displayAppointmentData[field] || false,
      name: field,
      color: "primary",
    };
  };
  const pickerEditorProps = (field) => ({
    className: classes.picker,
    ampm: false,
    value: displayAppointmentData[field],
    onChange: (date) =>
      changeAppointment({
        field: [field],
        changes: date ? date.toDate() : new Date(displayAppointmentData[field]),
      }),
    inputVariant: "outlined",
    format: "DD/MM/YYYY HH:mm",
    onError: () => null,
  });

  const cancelChanges = () => {
    setAppointmentChanges({});
    visibleChange();
    cancelAppointment();
    localStorage.removeItem("selected");
  };
  const [dataSchool, setDataSchool] = React.useState(() => {
    const test = displayAppointmentData["school"];
    if (test !== undefined) {
      // Remove duplicates based on userId
      const uniqueData = test.reduce((acc, current) => {
        const existingItem = acc.find((item) => item.userId === current.userId);
        if (!existingItem) {
          acc.push(current);
        }
        return acc;
      }, []);

      return uniqueData;
    } else {
      return [];
    }
  });

  const [dataWork, setDataWork] = React.useState(() => {
    const test = displayAppointmentData["work"];
    if (test !== undefined) {
      // Remove duplicates based on userId
      const uniqueData = test.reduce((acc, current) => {
        const existingItem = acc.find((item) => item.userId === current.userId);
        if (!existingItem) {
          acc.push(current);
        }
        return acc;
      }, []);

      return uniqueData;
    } else {
      return [];
    }
  });

  sessionStorage.setItem("SelectedSchool", JSON.stringify(dataSchool));
  sessionStorage.setItem("SelectedWork", JSON.stringify(dataWork));

  const [selectedSuppliersSchool, setSelectedSuppliersSchool] = React.useState(
    () => {
      const storedData = sessionStorage.getItem("selectedSuppliersSchool");
      let EditSchool = JSON.parse(sessionStorage.getItem("SelectedSchool"));

      try {
        if (EditSchool) {
          EditSchool = EditSchool.map((item) => {
            // Update userId to _id for each object
            return { ...item, _id: item.userId, userId: undefined };
          });
        }

        return EditSchool ? EditSchool : JSON.parse(storedData) || [];
      } catch (error) {
        console.error("Error parsing data:", error);
        return [];
      }
    }
  );
  const [selectedSuppliersWork, setSelectedSuppliersWork] = React.useState(
    () => {
      const storedData = sessionStorage.getItem("selectedSuppliersWork");
      let EditWork = JSON.parse(sessionStorage.getItem("SelectedWork"));

      try {
        if (EditWork) {
          EditWork = EditWork.map((item) => {
            // Update userId to _id for each object
            return { ...item, _id: item.userId, userId: undefined };
          });
        }

        return EditWork ? EditWork : JSON.parse(storedData) || [];
      } catch (error) {
        console.error("Error parsing data:", error);
        return [];
      }
    }
  );
    console.log(textEditorNotesProps("notes"))
  return (
    <AppointmentForm.Overlay
      visible={visible}
      target={target}
      fullSize
      onHide={onHide}
    >
      <div>
        <Grid className={classes.header}>
          <IconButton
            className={classes.closeButton}
            onClick={() => cancelChanges()}
          >
            <Close color="action" />
          </IconButton>
        </Grid>
        <div className={classes.content}>
          <Grid className={classes.wrapper}>
            <Create className={classes.icon} color="action" />
            <TextField {...textEditorProps("title")} label={t("Title")} />
          </Grid>
          <div className={classes.wrapper}>
            <CalendarToday className={classes.icon} color="action" />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                label={t("Start Date")}
                {...pickerEditorProps("startDate")}
              />
              <KeyboardDateTimePicker
                label={t("End Date")}
                {...pickerEditorProps("endDate")}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.reminderWrapper}>
            <AccessAlarm className={classes.icon} color="action" />
            <TextField
              {...textEditorProps("reminderBefore")}
              label={t("Reminder Before in Minutes")}
              type="number"
              className={classes.reminderField}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </div>
          <div className={classes.wrapper}>
            <Notes className={classes.icon} color="action" />
            <TextField
              {...textEditorNotesProps("notes")}
              label={t("Notes")}
              multiline
              rows="6"
            />
          </div>
          {props?.refugeeData?.mentor && (
            <div className={classes.wrapperCheck}>
              <SupervisorAccountIcon className={classes.icon} color="action" />
              <FormControlLabel
                control={<Checkbox {...checkEditorProps("mentor")} />}
                label={t("Mentor")}
                className={classes.textField}
              />
            </div>
          )}
          <div style={{ display: "flex", "align-items": "center" }}>
            <SchoolIcon className={classes.icon} color="action" />
            <span>School</span>
          </div>
          <List>
            {props.refugeeData?.suppliers?.school &&
              props.refugeeData.suppliers.school.map((school, index) => {
                const textAfterExclamation = school.split("!")[1];
                const textBeforeExclamation = school.split("!")[0];
                const isSelectedSchool = selectedSuppliersSchool.some(
                  (supplier) => supplier._id === textBeforeExclamation
                );

                console.log(selectedSuppliersSchool);

                return (
                  <ListItem key={index}>
                    <div className={classes.wrapperCheck}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...checkEditorProps("school")}
                            checked={isSelectedSchool}
                            onChange={() => {
                              setSelectedSuppliersSchool((prevState) => {
                                const updatedSuppliers = isSelectedSchool
                                  ? prevState.filter(
                                      (supplier) =>
                                        supplier._id !== textBeforeExclamation
                                    )
                                  : [
                                      ...prevState,
                                      {
                                        _id: textBeforeExclamation,
                                        name: textAfterExclamation,
                                      },
                                    ];

                                sessionStorage.setItem(
                                  "selectedSuppliersSchool",
                                  JSON.stringify(updatedSuppliers)
                                );

                                return updatedSuppliers;
                              });
                            }}
                          />
                        }
                        label={textAfterExclamation}
                        className={classes.textField}
                      />
                    </div>
                  </ListItem>
                );
              })}
          </List>

          <div style={{ display: "flex", "align-items": "center" }}>
            <WorkIcon className={classes.icon} color="action" />
            <span>Work</span>
          </div>
          <List>
            {props.refugeeData?.suppliers?.work &&
              props.refugeeData.suppliers.work.map((work, index) => {
                const textAfterExclamation = work.split("!")[1];
                const textBeforeExclamation = work.split("!")[0];

                let isSelectedWork = selectedSuppliersWork.some(
                  (supplier) => supplier._id === textBeforeExclamation
                );

                return (
                  <ListItem key={index}>
                    <div className={classes.wrapperCheck}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...checkEditorProps("work")}
                            checked={isSelectedWork}
                            onChange={() => {
                              setSelectedSuppliersWork((prevState) => {
                                const updatedSuppliers = isSelectedWork
                                  ? prevState.filter(
                                      (supplier) =>
                                        supplier._id !== textBeforeExclamation
                                    )
                                  : [
                                      ...prevState,
                                      {
                                        _id: textBeforeExclamation,
                                        name: textAfterExclamation,
                                      },
                                    ];

                                sessionStorage.setItem(
                                  "selectedSuppliersWork",
                                  JSON.stringify(updatedSuppliers)
                                );

                                return updatedSuppliers;
                              });
                            }}
                          />
                        }
                        label={textAfterExclamation}
                        className={classes.textField}
                      />
                    </div>
                  </ListItem>
                );
              })}
          </List>
        </div>
        <div className={classes.buttonGroup}>
          {!isNewAppointment && (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => {
                visibleChange();
                commitAppointment("deleted");
              }}
            >
              {t("Delete")}
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              visibleChange();
              applyChanges();
            }}
          >
            {isNewAppointment ? t("Create") : t("Update")}
          </Button>
        </div>
      </div>
    </AppointmentForm.Overlay>
  );
}

export default AppointmentFormContainerBasic;
