import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import checkIcon from "../../../assets/images/Tick_Mark_Icon.svg";
import StyledCheckbox from "../../shared/StyledCheckbox";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  alreadyChecked: {
    marginLeft: "5px",
    width: "14px",
  },
}));

const AssignMentorToMunicipalityMentorsData = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mentors, setMentors] = useState({});

  const SearchedMentors = useSelector(
    ({ AssignMentorToMunicipalityMentorsDataReducer }) =>
      AssignMentorToMunicipalityMentorsDataReducer
        ?.allMiniSearchedMentorsReducer?.data
  );
  const loading = useSelector(
    ({ AssignMentorToMunicipalityMentorsDataReducer }) =>
      AssignMentorToMunicipalityMentorsDataReducer
        ?.allMiniSearchedMentorsReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMentorToMunicipalityMentorsDataReducer }) =>
      AssignMentorToMunicipalityMentorsDataReducer
        ?.allMiniSearchedMentorsReducer?.errMsg
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedMentors(true));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("user_id")) {
      dispatch(
        Actions.getAllMiniSearchedMentors(
          page + 1,
          rowsPerPage,
          props.searchText,
          "",
          sessionStorage.getItem("user_id")
        )
      );
    }
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const newMentorsObj = { ...mentors };
    newMentorsObj.docs[index].isSelected = !mentors.docs[index].isSelected;
    if (newMentorsObj.docs[index].isSelected) {
      const selectedMentor = {
        id: newMentorsObj.docs[index]?.id,
        bsn: newMentorsObj.docs[index].bsn,
        name: newMentorsObj.docs[index].name,
      };
      props.setSelectedMentors(() => [
        ...props.selectedMentors,
        selectedMentor,
      ]);
    } else {
      props.setSelectedMentors(
        props.selectedMentors.filter(
          (selectedMentor) =>
            selectedMentor?.id !== newMentorsObj.docs[index]?.id
        )
      );
    }
    setMentors(newMentorsObj);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const mentorsObj = SearchedMentors?.miniSearchAllMentors;
    if (mentorsObj) {
      const newMentorsObj = { ...mentorsObj };
      if (newMentorsObj.docs && newMentorsObj.docs.length) {
        for (let i = 0; i < newMentorsObj.docs.length; i++) {
          newMentorsObj.docs[i].isSelected = false;
          if (props.selectedMentors.length) {
            for (let j = 0; j < props.selectedMentors.length; j++) {
              if (newMentorsObj.docs[i]?.id === props.selectedMentors[j]?.id) {
                newMentorsObj.docs[i].isSelected = true;
              }
            }
          }
        }
      }
      setMentors(newMentorsObj);
    }
  }, [SearchedMentors, props.selectedMentors]);

  return (
    <div>
      <div className="system-search-filter-div">
        <div className="system-search-filter-total-div">
          {t("Total Mentors")}:{" "}
          {mentors && mentors.totalDocs ? mentors.totalDocs : "0"}
        </div>
      </div>

      <div className="edit-mun-table-div">
        <table
          className="system-search-table-tag"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <tr>
            <th>{t("BSN")}</th>
            <th>{t("Name")}</th>
            <th>{t("Assigned Municipality")}</th>
            <th>{t("Assign")}</th>
          </tr>

          {mentors.docs &&
            mentors.docs.length > 0 &&
            mentors.docs.map((doc, index) => (
              <tr key={doc?.id}>
                <td>{doc.bsn}</td>
                <td>{doc.name}</td>
                <td>
                  {doc.municipality ? (
                    doc.municipality.name
                  ) : (
                    <span className="unassigned-td">{t("Unassigned")}</span>
                  )}
                </td>
                <td>
                  {doc.municipality &&
                  doc.municipality._id === sessionStorage.getItem("user_id") ? (
                    <img
                      src={checkIcon}
                      className={classes.alreadyChecked}
                    ></img>
                  ) : (
                    <StyledCheckbox
                      checked={!!doc.isSelected}
                      onClick={() => handleChangeSelected(index)}
                    />
                  )}
                </td>
              </tr>
            ))}
        </table>
      </div>

      <div className="assign-mentor-to-municipality-pagination-div">
        <TablePagination
          colSpan={3}
          component="div"
          count={mentors && mentors.totalDocs ? mentors.totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          classes={{
            root: classes.tablePagination,
          }}
        />
      </div>
    </div>
  );
};

export default withReducer(
  "AssignMentorToMunicipalityMentorsDataReducer",
  reducer
)(AssignMentorToMunicipalityMentorsData);
