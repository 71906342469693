import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import StyledCheckbox from "../../../../shared/StyledCheckbox";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";
import * as Actions from "../store/actions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import CustomScrollbar from "../../../../shared/CustomScrollbar/index"
const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  customScrollbar: {
    maxHeight: "200px",
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
    },
    /* For Firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5)',
    /* For Edge */
    '-ms-overflow-style': 'none',
    'scrollbar-3dlight-color': 'rgba(255, 255, 255, 0.5)',
    'scrollbar-arrow-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-base-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-darkshadow-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-face-color': 'rgba(0, 0, 0, 0.5)',
    'scrollbar-highlight-color': 'rgba(255, 255, 255, 0.5)',
    'scrollbar-shadow-color': 'rgba(0, 0, 0, 0.5)',
  },
  listItem: {
    "&.MuiListItem-gutters": {
      marginLeft: "-26px",
      marginRight: "16px",
    },
  },


}));

function AssignMentorToRefugeeMentorsData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [mentors, setMentors] = useState({});
  const [selectedMentorIds, setSelectedMentorIds] = useState([]);
  const SearchedMentors = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.data
  );
  const loading = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMentorToRefugeeMentorsDataReducer }) =>
      AssignMentorToRefugeeMentorsDataReducer?.allMiniSearchedMentorsReducer
        ?.errMsg
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedMentors(true));
    };
  }, []);

  useEffect(() => {
    const storedMentorIds = JSON.parse(
      localStorage.getItem("selectedMentorIds")
    );
    dispatch(
      Actions.getAllMiniSearchedMentors(
        page + 1,
        rowsPerPage,
        props.searchText,
        sessionStorage.getItem("user_id")
      )
    );
    setSelectedMentorIds(storedMentorIds || []);
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const existingSelectedIds =
    JSON.parse(localStorage.getItem("selectedMentorIds")) || [];
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    props.setClickState(randomNumber);
    const newMentorsObj = { ...mentors };
    newMentorsObj.docs[index].isSelected = !mentors.docs[index].isSelected;
    if (newMentorsObj.docs[index].isSelected) {
      const selectedMentor = {
        id: newMentorsObj.docs[index]?.id,
        bsn: newMentorsObj.docs[index].bsn,
        name: newMentorsObj.docs[index].name,
      };
      if (newMentorsObj.docs[index].isAssigned) {
        props.setSelectedMentors([]);
        props.setUnSelectedMentors([]);
        props.setAssignedMentorsState([selectedMentor]);
      } else {
        props.setSelectedMentors([selectedMentor]);
        if (props.assignedMentorsState.length) {
          const unSelectedMentor = {
            id: props.assignedMentorsState[0]?.id,
          };
          props.setUnSelectedMentors([unSelectedMentor]);
          props.setAssignedMentorsState([]);
        }
      }
    } else {
      const unSelectedMentor = {
        id: newMentorsObj.docs[index]?.id,
      };
      if (newMentorsObj.docs[index].isAssigned) {
        props.setUnSelectedMentors([unSelectedMentor]);
        props.setAssignedMentorsState([]);
      } else {
        props.setSelectedMentors([]);
      }
    }
    const newSelectedIds = newMentorsObj.docs
    .filter((mentor) => mentor.isSelected)
    .map((mentor) => mentor.id);
  const combinedIds = Array.from(
    new Set([...existingSelectedIds, ...newSelectedIds])
  );
  localStorage.setItem("selectedMentorIds", JSON.stringify(combinedIds));
  setSelectedMentorIds(combinedIds);
  };

  useEffect(() => {
    
    const mentorsObj = SearchedMentors?.miniSearchAllMentors;
    if (mentorsObj) {
      const newMentorsObj = { ...mentorsObj };
      if (newMentorsObj.docs && newMentorsObj.docs.length) {
        for (let i = 0; i < newMentorsObj.docs.length; i++) {
          if (newMentorsObj.docs[i].isAssigned)
            newMentorsObj.docs[i].isSelected = true;
          else newMentorsObj.docs[i].isSelected = false;
          if (props.selectedMentors.length) {
            for (let j = 0; j < props.selectedMentors.length; j++) {
              if (newMentorsObj.docs[i]?.id === props.selectedMentors[j]?.id) {
                newMentorsObj.docs[i].isSelected = true;
              } else {
                newMentorsObj.docs[i].isSelected = false;
              }
            }
          }
          if (props.unSelectedMentors.length) {
            for (let j = 0; j < props.unSelectedMentors.length; j++) {
              if (
                newMentorsObj.docs[i]?.id === props.unSelectedMentors[j]?.id
              ) {
                newMentorsObj.docs[i].isSelected = false;
              }
            }
          }
        }
      }
      setMentors(newMentorsObj);
    }
  }, [SearchedMentors, props.selectedMentors, props.unSelectedMentors]);

  console.log(mentors);
  return (
    <div>
       <h3>Select the Mentor</h3>
  <div>
      <CustomScrollbar>
      <List>
        {mentors && mentors.docs && mentors.docs.length > 0 && (
          <>
            {mentors.docs
               .sort((a, b) => {
                if (a.isSelected && !b.isSelected) return -1;
                if (!a.isSelected && b.isSelected) return 1;
                const isSelectedA = selectedMentorIds?.includes(a.id);
                const isSelectedB = selectedMentorIds?.includes(b.id);
                if (isSelectedA && !isSelectedB) return -1;
                if (!isSelectedA && isSelectedB) return 1;

                if (isSelectedA === isSelectedB) {
                  const nameA = a.name.toLowerCase();
                  const nameB = b.name.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                }
                return 0;
              })
              .map((doc, index) => (
                <ListItem
                  key={doc.id}
                  button
                  disabled={props?.disabled}
                  className={classes.listItem}
                  onClick={() => handleChangeSelected(index)}
                >
                  <Radio
                    checked={doc.isSelected}
                    onSelect={() => handleChangeSelected(index)}
                    value={doc.id}
                    name="MentorsRadio"
                  />
                  <ListItemText primary={doc.name} />
                </ListItem>
              ))}
          </>
        )}
      </List>
      </CustomScrollbar>
     
    </div>
    </div>
  );
}

export default withReducer(
  "AssignMentorToRefugeeMentorsDataReducer",
  reducer
)(AssignMentorToRefugeeMentorsData);
