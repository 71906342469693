import { Grid, Paper } from "@material-ui/core";
import React from "react";
import graphIcon from "../../../assets/images/graph_icon.svg";
import phoneIcon from "../../../assets/images/phone_icon.svg";

function FormRowInside({
  classes,
  doc,
  handleClickOpen,
  handleClickOpenContact,
  Openmunipage,
}) {
  return (
    <React.Fragment>
      <Grid item sm={8} xs={8}>
        <Paper
          onClick={() => Openmunipage(doc?.id, doc.logoUrl)}
          className={classes.paper1}
        >
          {doc.name}
        </Paper>
      </Grid>
      <Grid item sm={2} xs={2}>
        <Paper className={classes.paperico}>
          <button className="btnico" onClick={() => handleClickOpen(doc)}>
            <img
              src={graphIcon}
              className="home-page-grid-icon"
              alt="Smiley face"
            />
          </button>
        </Paper>
      </Grid>
      <Grid item sm={2} xs={2}>
        <Paper className={classes.paperico}>
          <button
            className="btnico"
            onClick={() => handleClickOpenContact(doc)}
          >
            <img
              src={phoneIcon}
              className="home-page-grid-phone-icon"
              alt="Smiley face"
            />
          </button>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default FormRowInside;
