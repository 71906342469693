import { Text, View, Page, Image } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate, generateDownloadFileLink } from "../../../utils/helpers";

const PERMISSION_DOCUMENT_LABELS = {
  "supervisor": "my supervisor from",
  "integrationSchool": "my integration school",
  "socialWork": "social work",
  "employersServicePoint": "employers service point / reintegration agency",
  "other": "other, namely",
  "date": "Date",
  "publish": "Publish",
}

function PermissionDocumentPdf({ permissionDocumentPageData, headerText, pipPlanId }) {
  delete permissionDocumentPageData.__typename;
  delete permissionDocumentPageData.base64Signature;
  const {
    signatureImageUrl,
    supervisor, supervisorAnswer,
    integrationSchool, integrationSchoolAnswer,
    socialWork, socialWorkAnswer,
    employersServicePoint, employersServicePointAnswer,
    other, otherAnswer,
    date
  } = permissionDocumentPageData;

  const downloadFileLink = generateDownloadFileLink("signature.png", signatureImageUrl, pipPlanId);

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View><Text style={styles.subtitle}>{i18next.t(headerText)}</Text></View>
        <View style={styles.viewRowContainer}>
          {supervisor !== null && (
            <View style={{ padding: "10px 0" }}>
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", justifyContent: "start", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(PERMISSION_DOCUMENT_LABELS?.["supervisor"])}
                  </Text>
                </View>
              </View>
              {supervisor && (
                <View>
                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
                      <Text style={styles.viewRowInput}>
                        <Text>{`${supervisorAnswer}`.split('')}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", fontSize: 10 }}>
                  <View style={styles.viewRowInput}>
                    <Text>{supervisor ? i18next.t("Yes") : i18next.t("No")}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {integrationSchool !== null && (
            <View style={{ padding: "10px 0" }}>
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", justifyContent: "start", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(PERMISSION_DOCUMENT_LABELS?.["integrationSchool"])}
                  </Text>
                </View>
              </View>
              {integrationSchool && (
                <View>
                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
                      <Text style={styles.viewRowInput}>
                        <Text>{`${integrationSchoolAnswer}`.split('')}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", fontSize: 10 }}>
                  <View style={styles.viewRowInput}>
                    <Text>{integrationSchool ? i18next.t("Yes") : i18next.t("No")}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {socialWork !== null && (
            <View style={{ padding: "10px 0" }}>
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", justifyContent: "start", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(PERMISSION_DOCUMENT_LABELS?.["socialWork"])}
                  </Text>
                </View>
              </View>
              {socialWork && (
                <View>
                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
                      <Text style={styles.viewRowInput}>
                        <Text>{`${socialWorkAnswer}`.split('')}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", fontSize: 10 }}>
                  <View style={styles.viewRowInput}>
                    <Text>{socialWork ? i18next.t("Yes") : i18next.t("No")}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {employersServicePoint !== null && (
            <View style={{ padding: "10px 0" }}>
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", justifyContent: "start", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(PERMISSION_DOCUMENT_LABELS?.["employersServicePoint"])}
                  </Text>
                </View>
              </View>
              {employersServicePoint && (
                <View>
                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
                      <Text style={styles.viewRowInput}>
                        <Text>{`${employersServicePointAnswer}`.split('')}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", fontSize: 10 }}>
                  <View style={styles.viewRowInput}>
                    <Text>{employersServicePoint ? i18next.t("Yes") : i18next.t("No")}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {other !== null && (
            <View style={{ padding: "10px 0" }}>
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", justifyContent: "start", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(PERMISSION_DOCUMENT_LABELS?.["other"])}
                  </Text>
                </View>
              </View>
              {other && (
                <View>
                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
                      <Text style={styles.viewRowInput}>
                        <Text>{`${otherAnswer}`.split('')}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={[styles.viewRow, { justifyContent: "start" }]}>
                <View style={{ width: "100%", fontSize: 10 }}>
                  <View style={styles.viewRowInput}>
                    <Text>{other ? i18next.t("Yes") : i18next.t("No")}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          <View
            style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t(PERMISSION_DOCUMENT_LABELS?.["date"])}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(date)}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            paddingTop: 40,
            margin: 0,
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <Image
            style={{
              width: "200px",
              height: "80px"
            }}
            src={
              {
                uri: downloadFileLink,
                method: 'GET',
                headers: {
                  authorization: localStorage.getItem('jwtToken') || "",
                  "x-auth-token": localStorage.getItem('jwtToken') || ""
                }
              }
            }
          />
        </View>
      </View>
    </Page>
  );
};

export default PermissionDocumentPdf;
