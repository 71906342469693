import { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MentorsData from "./sub_components/AssignMentorToMunicipalityMentorsData";
import AssignedMentorsData from "./sub_components/AssignedMentorToMunicipalityMentorsData";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import { useTranslation } from "react-i18next";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    // borderWidth: "0px",
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    // width: "203px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignMentorToMunicipality(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedRefugees, setSelectedRefugees] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);
  const [unAssignId, setUnAssignId] = useState(false);
  const [editCheck, setEditCheck] = useState(false);
  const [state, setState] = useState(1)
  const add_confirmation = useSelector(
    ({ AssignMentorToMunicipalityReducer }) =>
      AssignMentorToMunicipalityReducer?.assignUnassignRolesToMunicipalityReducer
  );
  useEffect(() => {
    return () => {
      dispatch(Actions.resetAssignUnassignRolesToMunicipality(true));
    };
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
      setEditCheck(true);
    }
  }, [setSelectedMentors]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
    }
  }, [setSelectedRefugees]);
  const handleChangeSearch = (value) => {
    setSearchText(value);
  };
  const createMentor = () => {
    let values = {
      municipality_id: sessionStorage.getItem("user_id"),
      amentorIds: [],
      asupplierIds: [],
      arefugeeIds: [],
      umentorIds: [],
      usupplierIds: [],
      urefugeeIds: [],
    };
    if (selectedMentors.length > 0) {
      for (let i = 0; i < selectedMentors.length; i++) {
        const element = selectedMentors[i];
        values.amentorIds.push(element?.id);
      }
    }
    if (selectedSuppliers.length > 0) {
      for (let i = 0; i < selectedSuppliers.length; i++) {
        const element = selectedSuppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (selectedRefugees.length > 0) {
      for (let i = 0; i < selectedRefugees.length; i++) {
        const element = selectedRefugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToMunicipality(values));
  };
  useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignRolesToMunicipality
    ) {
      // sessionStorage.clear();
      // sessionStorage.setItem("user_type", "mentor");
      // setLeaveScreenDialog(false);
      // navigate("/createUserByAdmin");
    }
  }, [add_confirmation]);
  const unAssignMentor = (data) => {
    setUnAssignId(true);
    setSelectedMentors(selectedMentors.filter((item) => item?.id !== data?.id));
  };
  const clickBack = () => {
    navigate(-1);
  };
  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };
  const handleClickLeaveScreenDialogopen = () => {
    setLeaveScreenDialog(true);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  return (
   
        <Container fixed>
         
          <Grid container spacing={4} className={classes.con}>
            <Grid item md={7} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                
                <MentorsData
                  searchText={searchText}
                  selectedMentors={selectedMentors}
                  setSelectedMentors={setSelectedMentors}
                  unAssignId={unAssignId}
                  setUnAssignId={setUnAssignId}
                  editCheck={editCheck}
                  setEditCheck={setEditCheck}
                  setState={setState}
                  disabled={props?.disabled}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
                
                <AssignedMentorsData
                  selectedMentors={selectedMentors}
                  unAssignMentor={unAssignMentor}
                  state={state}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
     
  );
}
export default withReducer(
  "AssignMentorToMunicipalityReducer",
  reducer
)(AssignMentorToMunicipality);
