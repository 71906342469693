import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Grid, TextField, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from 'formik';

import { pipFrontPageSchema } from "../../../../resources/scheme";
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { pipPlanNavigationKeys } from '../../../../resources/constants';
import PhoneInputNumber from "../../../shared/PhoneInputNumber";

function FrontPageForm({ data, setPipPlanPage }) {
  const [t] = useTranslation()
  const dispatch = useDispatch();

  const useStyles = makeStyles(() => ({
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#253c8c",
      textAlign: "center"
    },
    subTitle: {
      fontSize: "10px",
      color: "#253c8c"
    },
  }));

  const classes = useStyles();

  const params = useParams();
  const { refugeeId } = params;

  const municipalityInfo = useSelector(
    ({ PipPlanState }) => PipPlanState?.municipalityInfo?.data
  );

  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.details)
  };

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      phoneNumber: data?.phoneNumber || '',
      integrationDirector: data?.integrationDirector || '',
      emailAddress: data?.emailAddress || '',
    },
    enableReinitialize: true,
    validationSchema: pipFrontPageSchema,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        if (refugeeId) {
          const { name, integrationDirector, phoneNumber, emailAddress } = values;
          const frontPage = `
          frontPage: {
            name: "${name}"
            phoneNumber: "${phoneNumber}"
            integrationDirector: "${integrationDirector}"
            emailAddress: "${emailAddress}"
          }
          `
          createOrUpdateDraftPipPlan({ refugeeId, section: frontPage })
            .then(() => {
              dispatch(getPipPlanByRefugee(refugeeId))
              setPipPlanPage(pipPlanNavigationKeys.details)
            })
            .finally(() => {
              helpers.setSubmitting(false);
            })
        }
      } else {
        helpers.setSubmitting(false);
        navigateNextPage()
      }
    },
  });

  const onPhoneNumberChange = (phone, name) => {
    formik.setFieldValue(name, `+${phone}`)
  }

  return (
    <Grid container>
      <form
        noValidate
        style={{ width: "100%" }}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container item
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            container
            style={{ marginBottom: "40px", display: "flex", justifyContent: "center" }}
          >
            <Grid item container xs={4} style={{ marginTop: '20px', minWidth: "300px" }}>
              <TextField
                required
                style={{ marginBottom: "20px", minWidth: "300px" }}
                fullWidth
                name="name"
                label={t("Name")}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                helperText={formik.touched.name && t(formik.errors.name)}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </Grid>
            <Grid
              item
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {municipalityInfo &&
                (
                  <Grid item>
                    <h2 className={classes.title}>
                      {t("Welcome to municipality")} {municipalityInfo.name}
                    </h2>
                    <h2 className={classes.subTitle}>
                      {t("You will complete your Future Plan together with your integration director")}
                    </h2>
                  </Grid>
                )}
              <Grid
                item
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Grid item>
                  <TextField
                    required
                    style={{ marginBottom: "20px", minWidth: "300px" }}
                    fullWidth
                    name="integrationDirector"
                    label={t("Integration Director")}
                    onBlur={formik.handleBlur}
                    value={formik.values.integrationDirector}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    helperText={formik.touched.integrationDirector && t(formik.errors.integrationDirector)}
                    error={formik.touched.integrationDirector && Boolean(formik.errors.integrationDirector)}
                  />
                </Grid>
                <Grid item style={{ marginBottom: '20px', minWidth: "300px" }}>
                  <PhoneInputNumber
                    name="phoneNumber"
                    label={t("Phone number")}
                    id={"phoneNumberFrontPage"}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    onChange={onPhoneNumberChange}
                    disabled={formik.isSubmitting}
                    error={
                      Boolean(formik.touched?.phoneNumber) &&
                      Boolean(formik.errors?.phoneNumber)
                    }
                    errorMessage={
                      Boolean(formik.touched?.phoneNumber) &&
                      t(formik?.errors?.phoneNumber)
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    style={{ marginBottom: "20px", minWidth: "300px" }}
                    fullWidth
                    name="emailAddress"
                    label={t("E-mail address")}
                    onBlur={formik.handleBlur}
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    helperText={formik.touched.emailAddress && t(formik.errors.emailAddress)}
                    error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              marginTop: "20px",
              minWidth: "80%",
              display: "flex",
              justifyContent: "right"
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              style={{
                mt: 3, mb: 2, width: "auto", marginBottom: "20px",
                height: "40px",
                fontSize: "16px",
                fontFamily: "opensans-semibold",
                backgroundColor: "#454a92",
                color: "#fff"
              }}
              endIcon={formik.isSubmitting && (
                <CircularProgress size={18} color="inherit" />
              )}
            >
              {t("Save and Next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid >
  );
};

export default FrontPageForm;