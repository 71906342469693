import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import LimitWarningDialog from "./LimitWarningDialog";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  clearIcon: {
    verticalAlign: "middle",
    padding: "2px",
    height: "18px",
    color: "454A92",
    fontSize: "18px",
    cursor: "pointer",
  },
  continueButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginRight: "30px",
    boxShadow: "none",
  },
  cancelButton: {
    fontSize: "16px",
    backgroundColor: "white",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginRight: "30px",
    border: "1px solid #454A92",
    color: "#454A92",
    boxShadow: "none",
    "&:hover": {
      color: "#ffffff",
    },
  },
}));

function AssignedMunicipalityToRefugeeMunicipalitiesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [warningOpen, setWarningOpen] = useState(false);

 

  useEffect(() => {
    if(props.userState !== 1){
      
      sessionStorage.setItem(
        "previousMunicipalityId",
        props.selectedMunicipalities[0]?.id
      );
      sessionStorage.setItem(
        "assignedMunicipalities",
        JSON.stringify(props.selectedMunicipalities)
      );
      sessionStorage.setItem("unAssignedMunicipalities", []);
    }

  },[props.userState])

  const handleClickWarningDialogclose = () => {
    setWarningOpen(false);
  };

  const cancelClick = () => {
    navigate("/viewEditRefugeeSummary");
  };

  return (
    <div>
     
    </div>
  );
}
export default AssignedMunicipalityToRefugeeMunicipalitiesData;
