import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from "moment";
import React, { useState } from 'react';

import {
  Grid,
  Button,
  Radio,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  InputLabel,
  RadioGroup,
  IconButton,
  FormControl,
  FormControlLabel,
  CircularProgress,
  Box
} from "@material-ui/core";
import { useFormik } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DatePicker from "../../../shared/DatePicker/index";
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { desiredWorkSectors } from './desiredWorkSectors';
import { removeKey } from "../../../../utils/helpers";
import { pipWorkAndActivitiesSchema } from "../../../../resources/scheme";
import { pipPlanNavigationKeys } from "../../../../resources/constants";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "start",
  },
  subTitle: {
    fontSize: "10px",
    color: "#253c8c"
  },
  radioGroup: {
    paddingTop: 12,
    display: "flex",
    flexDirection: "row"
  },
  buttonStyle: {
    mt: 3, mb: 2, width: "auto", marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9
    }
  }
}));

const blankWorkHistory = {
  whichFunctionAndWhere: "",
  from: "",
  to: ""
}

function WorkAndActivitiesForm({ data, setPipPlanPage }) {
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState("")
  const params = useParams();
  const { refugeeId } = params;

  const navigateNextPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.integration)
  }
  const navigatePreviousPage = () => {
    setPipPlanPage(pipPlanNavigationKeys.trainingAndDigitalSkills)
  }


  const formik = useFormik({
    initialValues: {
      workedInCountryOfOrigin: data?.workedInCountryOfOrigin ?? null,
      workedInCountryOfOriginAnswers: data?.workedInCountryOfOriginAnswers || null,

      workedDuringFlight: data?.workedDuringFlight ?? null,
      workedDuringFlightAnswers: data?.workedDuringFlightAnswers || null,

      workedInNetherlands: data?.workedInNetherlands ?? null,
      workedInNetherlandsAnswers: data?.workedInNetherlandsAnswers || null,

      doneVolunteerWorkInNetherlands: data?.doneVolunteerWorkInNetherlands ?? null,
      stillWorkVolunteer: data?.stillWorkVolunteer ?? null,
      stoppedVolunteerWorkBecause: data?.stoppedVolunteerWorkBecause || null,

      desiredWorkSector: data?.desiredWorkSector || null,
      dreamJob: data?.dreamJob || null,
      goodAt: data?.goodAt || null,

      needAdjustmentsForStartingWork: data?.needAdjustmentsForStartingWork ?? null,
      adjustmentsForStartingWork: data?.adjustmentsForStartingWork || null
    },
    enableReinitialize: true,
    validationSchema: pipWorkAndActivitiesSchema,
    onSubmit: (values, helpers) => {
      const isDirty = formik.dirty;
      if (isDirty) {
        const queryString = JSON
          .stringify(removeKey(values, '__typename'), null, 2)
          .replace(/"([^"]+)":/g, '$1:');
        const workAndActivitiesQuery = `workAndActivities: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: workAndActivitiesQuery })
          .then(() => {
            dispatch(getPipPlanByRefugee(refugeeId))
            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.trainingAndDigitalSkills)
            } else {
              setPipPlanPage(pipPlanNavigationKeys.integration)
            }

          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      } else {
        helpers.setSubmitting(false);

        if (selectedPage === "saveAndPrevious") {
          navigatePreviousPage()
        } else {
          navigateNextPage();
        }
      }
    },
  });

  const getBooleanValue = (value) => {
    if (value === true) return 'true';
    if (value === false) return 'false';
    return '';
  }

  const addRow = (name) => () => {
    const value = formik.values[name];
    formik.setFieldValue(name, [...value, { ...blankWorkHistory }])
  }

  const removeRow = (name, index) => () => {
    const value = formik.values[name];
    value?.splice(index, 1);
    formik.setFieldValue(name, value)
  }

  const onWorkHistoryValueChange = (answersKey) => (event) => {
    const { name, value } = event.target;
    const booleanValue = value === 'true';
    if (booleanValue) {
      formik.setFieldValue(answersKey, [{ ...blankWorkHistory }]);
    } else {
      formik.setFieldValue(answersKey, null);
    }
    formik.setFieldValue(name, booleanValue);
  }

  const onVolunteerWorkHistoryChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = value === 'true';
    formik.setFieldValue('stillWorkVolunteer', null);
    formik.setFieldValue('stoppedVolunteerWorkBecause', '');
    formik.setFieldValue(name, booleanValue);
  }

  const onVolunteerWorkStatusChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = value === 'true';
    formik.setFieldValue(name, booleanValue);
    formik.setFieldValue('stoppedVolunteerWorkBecause', '');
  }

  const onBarriersChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = value === 'true';
    formik.setFieldValue(name, booleanValue);
    formik.setFieldValue('adjustmentsForStartingWork', null);
  }

  return (
    <Grid container>
      <form
        noValidate
        style={{ width: "100%", paddingTop: 40, paddingBottom: 40 }}
        onSubmit={formik.handleSubmit}
      >
        <Box pl={10} mb={4} width="inherit">
          <FormLabel component="legend" className={classes.title}>{t('Work and activities')}</FormLabel>
        </Box>
        <Box pl={10}>
          <Grid container>

            <FormControl
              component="fieldset" fullWidth required
              error={Boolean(formik.touched?.workedInCountryOfOrigin)
                && Boolean(formik.errors?.workedInCountryOfOrigin)
              }
            >
              <FormLabel component="legend">{t('I have worked in my country of origin')}</FormLabel>
              <RadioGroup
                name="workedInCountryOfOrigin"
                className={classes.radioGroup}
                onChange={onWorkHistoryValueChange('workedInCountryOfOriginAnswers')}
                value={getBooleanValue(formik.values?.workedInCountryOfOrigin)}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.workedInCountryOfOrigin) &&
                Boolean(formik.errors?.workedInCountryOfOrigin) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.workedInCountryOfOrigin)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.workedInCountryOfOriginAnswers?.map((answer, index) => {
              return (
                <Grid container item xs={12} spacing={2} style={{paddingTop: 20}}>
                  <Grid item >
                    <TextField
                      required
                      fullWidth
                      name={`workedInCountryOfOriginAnswers[${index}].whichFunctionAndWhere`}
                      label={t("Which function and where?")}
                      onBlur={formik.handleBlur}
                      value={answer.whichFunctionAndWhere}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      InputLabelProps={formik.values.nameOfIntegrationSchool ? { shrink: true } : {}}
                      helperText={
                        Boolean(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.whichFunctionAndWhere) &&
                        Boolean(formik.touched?.workedInCountryOfOriginAnswers?.[index]?.whichFunctionAndWhere) &&
                        t(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.whichFunctionAndWhere)
                      }
                      error={
                        Boolean(formik.touched?.workedInCountryOfOriginAnswers?.[index]?.whichFunctionAndWhere) &&
                        Boolean(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.whichFunctionAndWhere)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      id={`workedInCountryOfOriginAnswers_${index}_from`}
                      fullWidth
                      required
                      name={`workedInCountryOfOriginAnswers[${index}].from`}
                      label={t("From")}
                      value={answer.from ? moment(answer.from).format('YYYY-MM-DD') : null}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.from) &&
                        Boolean(formik.touched?.workedInCountryOfOriginAnswers?.[index]?.from) &&
                        t(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.from)
                      }
                      error={
                        Boolean(formik.touched?.workedInCountryOfOriginAnswers?.[index]?.from) &&
                        Boolean(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.from)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      id={`workedInCountryOfOriginAnswers_${index}_to`}
                      fullWidth
                      required
                      name={`workedInCountryOfOriginAnswers[${index}].to`}
                      label={t("To")}
                      value={answer.to ? moment(answer.to).format('YYYY-MM-DD') : null}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.to) &&
                        Boolean(formik.touched?.workedInCountryOfOriginAnswers?.[index]?.to) &&
                        t(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.to)
                      }
                      error={
                        Boolean(formik.touched?.workedInCountryOfOriginAnswers?.[index]?.to) &&
                        Boolean(formik.errors?.workedInCountryOfOriginAnswers?.[index]?.to)
                      }
                    />
                  </Grid>
                  <Grid item container alignItems='center' xs={2}>
                    <IconButton
                      onClick={addRow('workedInCountryOfOriginAnswers')}
                      disabled={formik.values?.workedInCountryOfOriginAnswers?.length > 4}
                    >
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>

                    {index > 0 && (
                      <IconButton onClick={removeRow('workedInCountryOfOriginAnswers', index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )
            })}

            <FormControl
              component="fieldset"
              style={{ marginTop: 60 }}
              fullWidth
              required
              error={Boolean(formik.touched?.workedDuringFlight)
                && Boolean(formik.errors?.workedDuringFlight)
              }
            >
              <FormLabel component="legend">{t('I worked during my flight')}</FormLabel>
              <RadioGroup
                name="workedDuringFlight"
                className={classes.radioGroup}
                onChange={onWorkHistoryValueChange('workedDuringFlightAnswers')}
                value={getBooleanValue(formik.values?.workedDuringFlight)}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.workedDuringFlight) &&
                Boolean(formik.errors?.workedDuringFlight) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.workedDuringFlight)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.workedDuringFlightAnswers?.map((answer, index) => {
              return (
                <Grid container item xs={12} spacing={2}>
                  <Grid item >
                    <TextField
                      required
                      fullWidth
                      name={`workedDuringFlightAnswers[${index}].whichFunctionAndWhere`}
                      label={t("Which function and where?")}
                      onBlur={formik.handleBlur}
                      value={answer.whichFunctionAndWhere}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      InputLabelProps={formik.values.nameOfIntegrationSchool ? { shrink: true } : {}}
                      helperText={
                        Boolean(formik.errors?.workedDuringFlightAnswers?.[index]?.whichFunctionAndWhere) &&
                        Boolean(formik.touched?.workedDuringFlightAnswers?.[index]?.whichFunctionAndWhere) &&
                        t(formik.errors?.workedDuringFlightAnswers?.[index]?.whichFunctionAndWhere)
                      }
                      error={
                        Boolean(formik.touched?.workedDuringFlightAnswers?.[index]?.whichFunctionAndWhere) &&
                        Boolean(formik.errors?.workedDuringFlightAnswers?.[index]?.whichFunctionAndWhere)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      id={`workedDuringFlightAnswers_${index}_from`}
                      fullWidth
                      required
                      name={`workedDuringFlightAnswers[${index}].from`}
                      label={t("From")}
                      value={answer.from ? moment(answer.from).format('YYYY-MM-DD') : null}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedDuringFlightAnswers?.[index]?.from) &&
                        t(formik.errors?.workedDuringFlightAnswers?.[index]?.from)
                      }
                      error={
                        Boolean(formik.touched?.workedDuringFlightAnswers?.[index]?.from) &&
                        Boolean(formik.errors?.workedDuringFlightAnswers?.[index]?.from)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      fullWidth
                      required
                      id={`workedDuringFlightAnswers_${index}_to`}
                      name={`workedDuringFlightAnswers[${index}].to`}
                      label={t("To")}
                      value={answer.to ? moment(answer.to).format('YYYY-MM-DD') : null}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedDuringFlightAnswers?.[index]?.to) &&
                        t(formik.errors?.workedDuringFlightAnswers?.[index]?.to)
                      }
                      error={
                        Boolean(formik.touched?.workedDuringFlightAnswers?.[index]?.to) &&
                        Boolean(formik.errors?.workedDuringFlightAnswers?.[index]?.to)
                      }
                    />
                  </Grid>
                  <Grid item container alignItems='center' xs={1}>
                    <IconButton
                      onClick={addRow('workedDuringFlightAnswers')}
                      disabled={formik.values?.workedDuringFlightAnswers?.length > 4}
                    >
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>

                    {index > 0 && (
                      <IconButton onClick={removeRow('workedDuringFlightAnswers', index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )
            })}

            <FormControl
              component="fieldset"
              style={{ marginTop: 60 }}
              fullWidth required
              error={Boolean(formik.touched?.workedInNetherlands)
                && Boolean(formik.errors?.workedInNetherlands)
              }
            >
              <FormLabel component="legend">{t("I've worked in the Netherlands")}</FormLabel>
              <RadioGroup
                name="workedInNetherlands"
                className={classes.radioGroup}
                onChange={onWorkHistoryValueChange('workedInNetherlands')}
                value={getBooleanValue(formik.values?.workedInNetherlands)}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.workedInNetherlands) &&
                Boolean(formik.errors?.workedInNetherlands) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.workedInNetherlands)}
                  </FormLabel>
                )}
            </FormControl>
            {formik.values.workedInNetherlandsAnswers?.map((answer, index) => {
              return (
                <Grid container item xs={12} spacing={2}>
                  <Grid item >
                    <TextField
                      required
                      fullWidth
                      name={`workedInNetherlandsAnswers[${index}].whichFunctionAndWhere`}
                      label={t("Which function and where?")}
                      onBlur={formik.handleBlur}
                      value={answer.whichFunctionAndWhere}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedInNetherlandsAnswers?.[index]?.whichFunctionAndWhere) &&
                        Boolean(formik.touched?.workedInNetherlandsAnswers?.[index]?.whichFunctionAndWhere) &&
                        t(formik.errors?.workedInNetherlandsAnswers?.[index]?.whichFunctionAndWhere)
                      }
                      error={
                        Boolean(formik.touched?.workedInNetherlandsAnswers?.[index]?.whichFunctionAndWhere) &&
                        Boolean(formik.errors?.workedInNetherlandsAnswers?.[index]?.whichFunctionAndWhere)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      id={`workedInNetherlandsAnswers_${index}_from`}
                      fullWidth
                      required
                      name={`workedInNetherlandsAnswers[${index}].from`}
                      label={t("From")}
                      value={answer.from ? moment(answer.from).format('YYYY-MM-DD') : null}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedInNetherlandsAnswers?.[index]?.from) &&
                        Boolean(formik.touched?.workedInNetherlandsAnswers?.[index]?.from) &&
                        t(formik.errors?.workedInNetherlandsAnswers?.[index]?.from)
                      }
                      error={
                        Boolean(formik.touched?.workedInNetherlandsAnswers?.[index]?.from) &&
                        Boolean(formik.errors?.workedInNetherlandsAnswers?.[index]?.from)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      id={`workedInNetherlandsAnswers_${index}_to`}
                      fullWidth
                      required
                      name={`workedInNetherlandsAnswers[${index}].to`}
                      label={t("To")}
                      value={answer.to ? moment(answer.to).format('YYYY-MM-DD') : null}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      helperText={
                        Boolean(formik.errors?.workedInNetherlandsAnswers?.[index]?.to) &&
                        Boolean(formik.touched?.workedInNetherlandsAnswers?.[index]?.to) &&
                        t(formik.errors?.workedInNetherlandsAnswers?.[index]?.to)
                      }
                      error={
                        Boolean(formik.touched?.workedInNetherlandsAnswers?.[index]?.to) &&
                        Boolean(formik.errors?.workedInNetherlandsAnswers?.[index]?.to)
                      }
                    />
                  </Grid>
                  <Grid item container alignItems='center' xs={1}>
                    <IconButton
                      onClick={addRow('workedInNetherlandsAnswers')}
                      disabled={formik.values?.workedInNetherlandsAnswers?.length > 4}
                    >
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                    {index > 0 && (
                      <IconButton onClick={removeRow('workedInNetherlandsAnswers', index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )
            })}

            <FormControl
              component="fieldset"
              style={{ marginTop: 60 }}
              fullWidth required
              error={Boolean(formik.touched?.doneVolunteerWorkInNetherlands)
                && Boolean(formik.errors?.doneVolunteerWorkInNetherlands)
              }
            >
              <FormLabel component="legend">{t('I have done volunteer work in the Netherlands')}</FormLabel>
              <RadioGroup
                name="doneVolunteerWorkInNetherlands"
                className={classes.radioGroup}
                onChange={onVolunteerWorkHistoryChange}
                value={getBooleanValue(formik.values?.doneVolunteerWorkInNetherlands)}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.doneVolunteerWorkInNetherlands) &&
                Boolean(formik.errors?.doneVolunteerWorkInNetherlands) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.doneVolunteerWorkInNetherlands)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values.doneVolunteerWorkInNetherlands && (
              <FormControl
                component="fieldset"
                style={{ marginTop: 30 }}
                fullWidth
                required
                error={Boolean(formik.touched?.stillWorkVolunteer)
                  && Boolean(formik.errors?.stillWorkVolunteer)
                }
              >
                <FormLabel component="legend">{t('I still work here')}</FormLabel>
                <RadioGroup
                  name="stillWorkVolunteer"
                  className={classes.radioGroup}
                  onChange={onVolunteerWorkStatusChange}
                  value={getBooleanValue(formik.values?.stillWorkVolunteer)}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.stillWorkVolunteer) &&
                  Boolean(formik.errors?.stillWorkVolunteer) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.stillWorkVolunteer)}
                    </FormLabel>
                  )}
              </FormControl>
            )}

            {formik.values?.doneVolunteerWorkInNetherlands && formik.values.stillWorkVolunteer === false && (
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={6}
                  name="stoppedVolunteerWorkBecause"
                  label={t("I stopped here because")}
                  onBlur={formik.handleBlur}
                  value={formik.values.stoppedVolunteerWorkBecause}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  InputLabelProps={formik.values.nameOfIntegrationSchool ? { shrink: true } : {}}
                  helperText={
                    Boolean(formik.touched?.stoppedVolunteerWorkBecause) &&
                    Boolean(formik.errors?.stoppedVolunteerWorkBecause) &&
                    t(formik.errors?.stoppedVolunteerWorkBecause)
                  }
                  error={Boolean(formik.touched?.stoppedVolunteerWorkBecause)
                    && Boolean(formik.errors?.stoppedVolunteerWorkBecause)
                  }
                />
              </Grid>
            )}

            <Grid item xs={8} style={{ marginTop: 20 }}>
              <FormControl
                fullWidth required
                error={
                  Boolean(formik.touched?.desiredWorkSector) &&
                  Boolean(formik?.errors?.desiredWorkSector)
                }
              >
                <InputLabel shrink={true}>{t('My desired work sector is')}</InputLabel>
                <Select
                  required
                  name="desiredWorkSector"
                  label={t("My desired work sector is")}
                  onChange={formik.handleChange}
                   InputLabelProps={{ shrink: true }}
                  onBlur={formik.handleBlur}
                  value={formik.values.desiredWorkSector}
                >
                  {desiredWorkSectors.map((desiredWorkSector, index) => (
                    <MenuItem
                      key={desiredWorkSector}
                      value={desiredWorkSector}
                    >
                      {`${alphabet[index]}: ${t(desiredWorkSector)}`}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik.errors?.desiredWorkSector) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {Boolean(formik.errors?.desiredWorkSector) &&
                      t(formik?.errors?.desiredWorkSector)}
                  </FormLabel>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={8} style={{ marginTop: 20 }}>
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="dreamJob"
                label={t("My dream job is")}
                onBlur={formik.handleBlur}
                value={formik.values.dreamJob}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                 InputLabelProps={formik.values.dreamJob ? { shrink: true } : {}}
                helperText={
                  Boolean(formik.touched?.dreamJob) &&
                  Boolean(formik.errors?.dreamJob) &&
                  t(formik.errors?.dreamJob)
                }
                error={
                  Boolean(formik.touched?.dreamJob) &&
                  Boolean(formik.errors?.dreamJob)
                }
              />
            </Grid>

            <Grid item xs={8} style={{ marginTop: 20 }}>
              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="goodAt"
                label={t("This is what I am good at")}
                onBlur={formik.handleBlur}
                value={formik.values.goodAt}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
               InputLabelProps={formik.values.goodAt ? { shrink: true } : {}}
                helperText={
                  Boolean(formik.touched?.goodAt) &&
                  Boolean(formik.errors?.goodAt) &&
                  t(formik.errors?.goodAt)
                }
                error={
                  Boolean(formik.touched?.goodAt) &&
                  Boolean(formik.errors?.goodAt)
                }
              />
            </Grid>

            <Grid item xs={8} style={{ marginTop: 20 }}>
              <h1 className={classes.title}>{t('Barriers')}</h1>
            </Grid>

            <FormControl
              component="fieldset"
              style={{ marginTop: 60 }}
              fullWidth required
              error={
                Boolean(formik.touched?.needAdjustmentsForStartingWork) &&
                Boolean(formik?.errors?.needAdjustmentsForStartingWork)
              }
            >
              <FormLabel component="legend">{t('I need certain adjustments when I start work')}</FormLabel>
              <RadioGroup
                name="needAdjustmentsForStartingWork"
                className={classes.radioGroup}
                onChange={onBarriersChange}
                value={getBooleanValue(formik.values?.needAdjustmentsForStartingWork)}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.needAdjustmentsForStartingWork) &&
                Boolean(formik.errors?.needAdjustmentsForStartingWork) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.needAdjustmentsForStartingWork)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values.needAdjustmentsForStartingWork === true && (
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={6}
                  name="adjustmentsForStartingWork"
                  label={t("The adjustments I need are")}
                  onBlur={formik.handleBlur}
                  value={formik.values.adjustmentsForStartingWork}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  InputLabelProps={formik.values.nameOfIntegrationSchool ? { shrink: true } : {}}
                  helperText={
                    Boolean(formik.touched?.adjustmentsForStartingWork) &&
                    Boolean(formik.errors?.adjustmentsForStartingWork) &&
                    t(formik.errors?.adjustmentsForStartingWork)
                  }
                  error={
                    Boolean(formik.touched?.adjustmentsForStartingWork) &&
                    Boolean(formik.errors?.adjustmentsForStartingWork)
                  }
                />
              </Grid>
            )}

            <Grid
              item
              style={{
                marginTop: "20px",
                minWidth: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                fullWidth
                type="submit"
                variant="contained"
                isSubmitting={true}
                disabled={formik.isSubmitting}
                className={classes.buttonStyle}
                onClick={() => setSelectedPage("saveAndPrevious")}
              >
                {t("SAVE & PREVIOUS")}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                isSubmitting={true}
                disabled={formik.isSubmitting}
                className={classes.buttonStyle}
                endIcon={formik.isSubmitting && (
                  <CircularProgress size={18} color="inherit" />
                )}
              >
                {t("Save and Next")}
              </Button>
            </Grid>

          </Grid>
        </Box>
      </form>
    </Grid>
  )

}

export default WorkAndActivitiesForm;
