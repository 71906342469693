import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import i18n from "./i18n";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo";
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { createUploadLink } from "apollo-upload-client";
import { Provider } from "react-redux";
import store from "./store/index";
import { BrowserRouter } from "react-router-dom";

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_URL,
  fetchOptions: {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken") || "",
    },
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: uploadLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
