import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContactCont from "./ContactCont";
import RightCont from "./RightCont";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import FormRowInside from "./FormRowInside";
import * as Actions from "../store/actions";

function TableRow({
  isAdmin,
  contact,
  handleClose,
  handleEditContact,
  handleClickOpen,
  handleClickOpenContact,
  handleClickAddNewMunicipality,
  classes,
  t,
  details,
  setTotalProgress,
  totalProgress,
  Openmunipage,
  page,
  setPage,
}) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const AssignedMunicipalities = useSelector(
    ({ HomePageReducer }) =>
      HomePageReducer?.AllAssignedMunicipalitiesReducer?.data
  );
  const loading = useSelector(
    ({ HomePageReducer }) =>
      HomePageReducer?.AllAssignedMunicipalitiesReducer?.isLoading
  );

  const errMsg = useSelector(
    ({ HomePageReducer }) =>
      HomePageReducer?.AllAssignedMunicipalitiesReducer?.errMsg
  );

  const handleChangePagination = (event, value) => {
    setPage(value);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
    if (value) {
      dispatch(Actions.getAllAssignedMunicipalities(1, 10, isAdmin, value));
    } else {
      dispatch(Actions.getAllAssignedMunicipalities(page, 10, isAdmin, value));
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        item
        sm={12}
        md={12}
        lg={4}
        xs={12}
        className={classes.rowContainer}
      >
        {AssignedMunicipalities &&
          AssignedMunicipalities.listAllMunicipalities &&
          !AssignedMunicipalities.listAllMunicipalities.docs &&
          !searchText && (
            <Grid item sm={12} xs={12}>
              <p className="no-municipality-found">
                {t(
                  isAdmin === true
                    ? "There are no municipality available right now in the database.Continue by clicking on “Add new municipality” to create one"
                    : "There are no municipality available right now in the database"
                )}
                .
              </p>
            </Grid>
          )}

        {isAdmin && isAdmin === true && (
          <>
            <Grid item sm={8} xs={12}>
              <p className="search-heading">{t("Make a choice")}</p>
              <TextField
                id="search"
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={searchText}
                name="search"
                className={classes.textFieldSearch}
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input1,
                  },
                }}
                placeholder={t("Type municipality name...")}
                margin="normal"
                variant="outlined"
                size="small"
              />
            </Grid>
            {!AssignedMunicipalities?.listAllMunicipalities?.docs?.length &&
              searchText && (
                <Grid item sm={12} xs={12}>
                  <p className="no-municipality-found">
                    {t("There is no municipality available by that name")}
                  </p>
                </Grid>
              )}
            {AssignedMunicipalities?.listAllMunicipalities?.docs && (
              <>
                {AssignedMunicipalities.listAllMunicipalities.docs.map(
                  (doc, index) => (
                    <FormRowInside
                      key={index}
                      Openmunipage={Openmunipage}
                      handleClickOpen={handleClickOpen}
                      handleClickOpenContact={handleClickOpenContact}
                      classes={classes}
                      doc={doc}
                    />
                  )
                )}
                {AssignedMunicipalities &&
                  AssignedMunicipalities.listAllMunicipalities &&
                  AssignedMunicipalities.listAllMunicipalities.totalPages >
                    1 && (
                    <Grid item sm={12} xs={12}>
                      <div style={{ width: "100%" }}>
                        <Pagination
                          count={
                            AssignedMunicipalities &&
                            AssignedMunicipalities.listAllMunicipalities
                              ? AssignedMunicipalities.listAllMunicipalities
                                  .totalPages
                              : 0
                          }
                          page={page}
                          onChange={handleChangePagination}
                          color="primary"
                          classes={{
                            root: classes.paginationstyle,
                            ul: classes.paginationstyle,
                          }}
                          size="small"
                        />
                      </div>
                    </Grid>
                  )}
              </>
            )}
            {isAdmin && isAdmin === true && (
              <Grid item sm={8} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleClickAddNewMunicipality()}
                >
                  {t("Add new municiplity")}
                </Button>
              </Grid>
            )}
          </>
        )}
        {isAdmin && isAdmin === true ? (
          <>
            {AssignedMunicipalities &&
              AssignedMunicipalities.listAllMunicipalities &&
              AssignedMunicipalities.listAllMunicipalities.docs &&
              AssignedMunicipalities.listAllMunicipalities.docs.length < 5 && (
                <>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                  <Grid item sm={1} md={1} lg={12} xs={1}></Grid>
                </>
              )}
          </>
        ) : (
          <>
            {AssignedMunicipalities &&
              AssignedMunicipalities.listAllMunicipalities &&
              AssignedMunicipalities.listAllMunicipalities.docs && (
                <>
                  {AssignedMunicipalities &&
                    AssignedMunicipalities.listAllMunicipalities &&
                    AssignedMunicipalities.listAllMunicipalities.docs !==
                      null &&
                    AssignedMunicipalities.listAllMunicipalities.docs.map(
                      (doc, index) => (
                        <FormRowInside
                          key={index}
                          Openmunipage={Openmunipage}
                          handleClickOpen={handleClickOpen}
                          handleClickOpenContact={handleClickOpenContact}
                          classes={classes}
                          doc={doc}
                        />
                      )
                    )}
                </>
              )}
          </>
        )}
      </Grid>
      <RightCont
        classes={classes}
        details={details}
        handleClose={handleClose}
        setTotalProgress={setTotalProgress}
        totalProgress={totalProgress}
        t={t}
      />
      <ContactCont
        isAdmin={isAdmin}
        contact={contact}
        handleClose={handleClose}
        handleEditContact={handleEditContact}
        classes={classes}
        t={t}
      />
    </React.Fragment>
  );
}

export default TableRow;
