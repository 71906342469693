export function Truncate(str) {
  return str.length > 20 ? str.substring(0, 20) + "..." : str;
}
export function TruncateEmail(str) {
  return str.length > 20 ? str.substring(0, 20) + "..." : str;
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getMonday(date) {
  const currentDate = new Date(date);
  const day = currentDate.getDay();
  const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - day));
  startOfWeek.setHours(0, 0, 0, 0); 
  return startOfWeek;
}

export function endOfWeek(date) {
  const currentDate = new Date(date);
  const day = currentDate.getDay();
  const endOfWeek = new Date(currentDate.setDate(currentDate.getDate() - day + 6));
  endOfWeek.setHours(23, 59, 59, 999);
  return endOfWeek;
}