import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import StyledCheckbox from "../../shared/StyledCheckbox";
import reducer from "../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../store/actions";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
}));

function AssignRefugeeToMentorRefugeesData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refugees, setRefugees] = useState({});

  const SearchedRefugees = useSelector(
    ({ AssignRefugeeToMentorRefugeesDataReducer }) =>
      AssignRefugeeToMentorRefugeesDataReducer?.allMiniSearchedRefugeesReducer
        ?.data
  );
  const loading = useSelector(
    ({ AssignRefugeeToMentorRefugeesDataReducer }) =>
      AssignRefugeeToMentorRefugeesDataReducer?.allMiniSearchedRefugeesReducer
        ?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignRefugeeToMentorRefugeesDataReducer }) =>
      AssignRefugeeToMentorRefugeesDataReducer?.allMiniSearchedRefugeesReducer
        ?.errMsg
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedRefugees(true));
    };
  }, []);

  useEffect(() => {
    dispatch(
      Actions.getAllMiniSearchedRefugees(
        page + 1,
        rowsPerPage,
        props.searchText,
        sessionStorage.getItem("user_id"),
        "",
        props.municipalityId
      )
    );
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const newRefugeesObj = { ...refugees };
    newRefugeesObj.docs[index].isSelected = !refugees.docs[index].isSelected;
    if (newRefugeesObj.docs[index].isSelected) {
      const selectedRefugee = {
        bsn: newRefugeesObj.docs[index].bsn,
        id: newRefugeesObj.docs[index]?.id,
        name: newRefugeesObj.docs[index].name,
        isAssigned: newRefugeesObj.docs[index].isAssigned,
      };
      if (newRefugeesObj.docs[index].isAssigned) {
        props.setUnSelectedRefugees((unSelectedRefugees) =>
          unSelectedRefugees.filter(
            (unSelectedRefugee) => unSelectedRefugee?.id !== selectedRefugee?.id
          )
        );
        props.setAssignedRefugeesState((assignedRefugeesState) => [
          selectedRefugee,
          ...assignedRefugeesState,
        ]);
      } else {
        props.setSelectedRefugees((selectedRefugees) => [
          ...selectedRefugees,
          selectedRefugee,
        ]);
      }
    } else {
      const unSelectedRefugee = {
        id: newRefugeesObj.docs[index]?.id,
      };
      if (newRefugeesObj.docs[index].isAssigned) {
        props.setUnSelectedRefugees((unSelectedRefugees) => [
          ...unSelectedRefugees,
          unSelectedRefugee,
        ]);
        props.setAssignedRefugeesState((assignedRefugeesState) =>
          assignedRefugeesState.filter(
            (assignedRefugeeState) =>
              assignedRefugeeState?.id !== unSelectedRefugee?.id
          )
        );
      } else {
        props.setSelectedRefugees((selectedRefugees) =>
          selectedRefugees.filter(
            (selectedRefugee) => selectedRefugee?.id !== unSelectedRefugee?.id
          )
        );
      }
    }
  };

  useEffect(() => {
    const refugeesObj = SearchedRefugees?.miniSearchAllRefugees;
    if (refugeesObj) {
      const newRefugeesObj = { ...refugeesObj };
      if (newRefugeesObj.docs && newRefugeesObj.docs.length) {
        for (let i = 0; i < newRefugeesObj.docs.length; i++) {
          if (newRefugeesObj.docs[i].isAssigned)
            newRefugeesObj.docs[i].isSelected = true;
          else newRefugeesObj.docs[i].isSelected = false;
          if (props.selectedRefugees.length) {
            for (let j = 0; j < props.selectedRefugees.length; j++) {
              if (
                newRefugeesObj.docs[i]?.id === props.selectedRefugees[j]?.id
              ) {
                newRefugeesObj.docs[i].isSelected = true;
              }
            }
          }
          if (props.unSelectedRefugees.length) {
            for (let j = 0; j < props.unSelectedRefugees.length; j++) {
              if (
                newRefugeesObj.docs[i]?.id === props.unSelectedRefugees[j]?.id
              ) {
                newRefugeesObj.docs[i].isSelected = false;
              }
            }
          }
        }
      }
      setRefugees(newRefugeesObj);
    }
  }, [SearchedRefugees, props.selectedRefugees, props.unSelectedRefugees]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div className="system-search-filter-div">
        <div className="system-search-filter-total-div">
          {t("Total Refugees")}:{" "}
          {refugees && refugees.totalDocs ? refugees.totalDocs : "0"}
        </div>
      </div>

      <div className="edit-mun-table-div">
        <table
          className="system-search-table-tag"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <tr>
            <th>{t("BSN")}</th>
            <th>{t("Name")}</th>
            <th>{t("Assigned Municipality")}</th>
            <th>{t("Assign")}</th>
          </tr>

          {refugees.docs &&
            refugees.docs.length > 0 &&
            refugees.docs.map((doc, index) => (
              <tr key={doc?.id}>
                <td>{doc.bsn}</td>
                <td>{doc.name}</td>
                <td>
                  {doc.municipality ? (
                    doc.municipality.name
                  ) : (
                    <span className="unassigned-td">{t("Unassigned")}</span>
                  )}
                </td>
                <td>
                  <StyledCheckbox
                    checked={doc.isSelected}
                    onClick={() => handleChangeSelected(index)}
                  />
                </td>
              </tr>
            ))}
        </table>
      </div>

      <div className="assign-mentor-to-municipality-pagination-div">
        <TablePagination
          colSpan={3}
          component="div"
          count={refugees && refugees.totalDocs ? refugees.totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          classes={{
            root: classes.tablePagination,
          }}
        />
      </div>
    </div>
  );
}

export default withReducer(
  "AssignRefugeeToMentorRefugeesDataReducer",
  reducer
)(AssignRefugeeToMentorRefugeesData);
