import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IntegrationForm from "./IntegrationForm";

const useStyles = makeStyles(() => ({
  title: {
    color: "#253c8c",
    textAlign: "center"
  },
}));

function Integration({ data, setPipPlanPage }) {
  const [t] = useTranslation();

  const classes = useStyles();


  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <h1 className={classes.title}>{t("Integration")}</h1>
      <Grid
        container
        item
        style={{
          minWidth: "100%",
        }}
      >
        {<IntegrationForm data={data} setPipPlanPage={setPipPlanPage}/>}
      </Grid>
    </Grid>
  );
};

export default Integration;
