import { Text, View, Page } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";

function isPrimitive(value) {
  return value !== Object(value);
}

const COURSE_LABELS = {
  "schoolInMyCountry": "I went to school in my country of origin",
  "totalNumberOfYearEducation": "The total number of years that I have attended education is",
  "nameOfHighestLevelOfEducation": "The name of my highest level of education is",
  "haveHighestEducationDiploma": "I have completed my highest education with a diploma",
  "attendedISK": "I attended ISK",
  "wouldLikeToFollowACourse": "I would like to follow a course in the future",
  "haveOneOrMoreCertificates": "I have one or more certificates",
};

const DIGITAL_SKILLS_LABELS = {
  "haveComputerOrLaptopAtHome": "I have a computer/laptop at home",
  "doEverythingOnPhone": "I do everything on my phone",
  "canLookUpInformationOnInternet": "I can look up information on the internet",
  "canSendAnEmail": "I can send an email",
  "canSaveDownloadFiles": "I can save/download files",
  "useFacebookTwitterInstagram": "I use Facebook, Twitter, Instagram",
  "canPayOnline": "I can pay online",
  "useWhatsApp": "I use WhatsApp",
  "canUseWord": "I can use Word",
};

const TRANSPORT_LABELS = {
  "transportType": "Transport",
  "haveNnOVChipCard": "I have an OV chip card",
  "almostWalking": "I do almost everything walking",
  "canCycle": "I can cycle",
  "wantToLearnCycle": "I want to learn to cycle",
  "commentForBicycleQuestion": "Comment Cycle",
  "travelByBusOrTrain": "I travel by bus or train",
  "wouldLikeAnExplanation": "would like an explanation",
  "haveDrivingLicense": "I have a driver's license",
  "typeOfDrivingLicense": "Type of driving license",
  "busyWith": "I am busy with",
};

const getCourseData = (content) => {
  const {
    attendedISK,
    haveHighestEducationDiploma,
    haveOneOrMoreCertificates,
    schoolInMyCountry,
    wouldLikeToFollowACourse,
    nameOfHighestLevelOfEducation,
    totalNumberOfYearEducation
  } = content;

  return (
    <View style={styles.viewRowContainer}>
      <View
        style={
          [styles.viewRow,
          {
            justifyContent: "start",
            padding: schoolInMyCountry?.answer ? "10px 5px 5px 5px" : 5
          }]
        }
      >
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('I went to school in my country of origin')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{schoolInMyCountry?.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "10px"
        }}
      >
        <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
          <Text style={styles.viewRowInput}>
            <Text>{`${schoolInMyCountry.comment}`.split('')}</Text>
          </Text>
        </View>
      </View>

      <View style={[styles.viewRow, { justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('The total number of years that I have attended education is')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{totalNumberOfYearEducation}</Text>
        </View>
      </View>

      <View style={[styles.viewRow, { justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('The name of my highest level of education is')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{`${nameOfHighestLevelOfEducation}`.split('')}</Text>
        </View>
      </View>

      <View
        style={
          [styles.viewRow,
          {
            justifyContent: "start",
            padding: schoolInMyCountry?.answer ? "10px 5px 5px 5px" : 5
          }]
        }
      >
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('I have completed my highest education with a diploma')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{haveHighestEducationDiploma?.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "10px"
        }}
      >
        <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
          <Text style={styles.viewRowInput}>
            <Text>{`${haveHighestEducationDiploma.comment}`.split('')}</Text>
          </Text>
        </View>
      </View>

      <View
        style={
          [styles.viewRow,
          {
            justifyContent: "start",
            padding: schoolInMyCountry?.answer ? "10px 5px 5px 5px" : 5
          }]
        }
      >
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('I attended ISK')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{attendedISK?.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "10px"
        }}
      >
        <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
          <Text style={styles.viewRowInput}>
            <Text>{`${attendedISK.comment}`.split('')}</Text>
          </Text>
        </View>
      </View>

      <View
        style={
          [styles.viewRow,
          {
            justifyContent: "start",
            padding: schoolInMyCountry?.answer ? "10px 5px 5px 5px" : 5
          }]
        }
      >
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('I would like to follow a course in the future')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{wouldLikeToFollowACourse?.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "10px"
        }}
      >
        <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
          <Text style={styles.viewRowInput}>
            <Text>{`${wouldLikeToFollowACourse.comment}`.split('')}</Text>
          </Text>
        </View>
      </View>

      <View
        style={
          [styles.viewRow,
          {
            justifyContent: "start",
            padding: schoolInMyCountry?.answer ? "10px 5px 5px 5px" : 5
          }]
        }
      >
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t('I have one or more certificates')}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{haveOneOrMoreCertificates?.answer ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "10px"
        }}
      >
        <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
          <Text style={styles.viewRowInput}>
            <Text>{`${haveOneOrMoreCertificates.comment}`.split('')}</Text>
          </Text>
        </View>
      </View>

    </View>
  )
};

const getTransportData = (content) => {
  const {
    transportType,
    haveNnOVChipCard,
    almostWalking,
    canCycle,
    wantToLearnCycle,
    commentForBicycleQuestion,
    travelByBusOrTrain,
    wouldLikeAnExplanation,
    haveDrivingLicense,
    typeOfDrivingLicense,
    busyWith
  } = content;

  return (
    <View style={[styles.viewRowContainer]}>
      <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["transportType"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{`${transportType}`.toString()}</Text>
        </View>
      </View>

      <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["haveNnOVChipCard"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{haveNnOVChipCard ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["almostWalking"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{almostWalking ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["canCycle"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{canCycle ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      {canCycle === false && (
        <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
          <View style={{ width: "70%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(TRANSPORT_LABELS?.["wantToLearnCycle"])}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            <Text>{wantToLearnCycle ? i18next.t("Yes") : i18next.t("No")}</Text>
          </View>
        </View>
      )}
      <View
        style={{
          display: "flex",
          width: "100%",
          padding: "10px 0"
        }}
      >
        <View style={[styles.viewRow, { width: "100%", justifyContent: "start" }]}>
          <Text style={styles.viewRowInput}>
            <Text>{`${commentForBicycleQuestion}`?.split('')}</Text>
          </Text>
        </View>
      </View>

      <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["travelByBusOrTrain"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{travelByBusOrTrain ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

     {travelByBusOrTrain === false && (
     <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["wouldLikeAnExplanation"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{wouldLikeAnExplanation ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>)}

      <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
        <View style={{ width: "70%", fontSize: 10 }}>
          <Text style={styles.label}>
            {i18next.t(TRANSPORT_LABELS?.["haveDrivingLicense"])}
          </Text>
        </View>
        <View style={styles.viewRowInput}>
          <Text>{haveDrivingLicense ? i18next.t("Yes") : i18next.t("No")}</Text>
        </View>
      </View>

      {haveDrivingLicense ? (
        <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
          <View style={{ width: "70%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(TRANSPORT_LABELS?.["typeOfDrivingLicense"])}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            <Text>{typeOfDrivingLicense}</Text>
          </View>
        </View>
      ) : (
        <View style={[styles.viewRow, { display: "flex", justifyContent: "start" }]}>
          <View style={{ width: "70%", fontSize: 10 }}>
            <Text style={styles.label}>
              {i18next.t(TRANSPORT_LABELS?.["busyWith"])}
            </Text>
          </View>
          <View style={styles.viewRowInput}>
            <Text>{busyWith}</Text>
          </View>
        </View>
      )}
    </View>
  )
};

function TrainingAndDigitalSkillsPdf({ trainingAndDigitalSkillsData, headerText }) {
  const { course, digitalSkills, transport } = { ...trainingAndDigitalSkillsData };
  delete course.__typename
  delete digitalSkills.__typename
  delete transport.__typename

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View><Text style={styles.subtitle}>{i18next.t('Course')}</Text></View>
        {getCourseData(course)}
        <View><Text style={styles.subtitle}>{i18next.t('Digital skills')}</Text></View>
        <View style={styles.viewRowContainer}>
          {Object.entries(digitalSkills)?.map((skills) => {
            const [key, value] = skills;
            return (
              <View style={[styles.viewRow, { display: "flex", justifyContent: "start"}]} key={`digitalSkills_${key}`}>
                <View style={{ width: "55%", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(DIGITAL_SKILLS_LABELS?.[key])}
                  </Text>
                </View>
                <View style={styles.viewRowInput}>
                  <Text>{value ? i18next.t("Yes") : i18next.t("No")}</Text>
                </View>
              </View>
            )
          })}
        </View>
        <View><Text style={styles.subtitle}>{i18next.t('Transport')}</Text></View>
        {getTransportData(transport)}
      </View>
    </Page>
  );
};

export default TrainingAndDigitalSkillsPdf;
