import { Text, View, Page, Link } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate, generateDownloadFileLink } from "../../../utils/helpers";

function isPrimitive(value) {
  return value !== Object(value);
}


const SOCIAL_PAGE_LABELS = {
  "dateLastModified": "Date (last modified)",
  "from": "I'm from",
  "livedIn": "I lived in",
  "normalDayForMe": "A normal day looked like this for me...",
  "leftMyCountryOn": "I left my country on",
  "willBeInTheNetherlands": "I will be in the Netherlands from",
  "oftenHaveContactWith": "I often have contact with",
  "familyLocation": "My family lives in",
  "friendsLocation": "My friends live in",
  "missMostIs": "What I miss most is",
  "concernedAbout": "I am concerned about",
  "moreAbout": "I would like to tell you more about"
};

const DATE_KEYS = ["leftMyCountryOn", "willBeInTheNetherlands"];

function SocialGuidancePdf({ socialGuidancePageData, headerText }) {
  const { lifeStory } = { ...socialGuidancePageData };
  delete lifeStory.__typename

  return (
    <Page
      style={styles.body}
      size={{
        width: 595.28
      }}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View style={styles.viewRowContainer}>
          <View style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t(SOCIAL_PAGE_LABELS["dateLastModified"])}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(socialGuidancePageData.dateLastModified)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.viewRowContainer}>
          <View style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t('I am guided by')}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(socialGuidancePageData.guidedBy.from)}</Text>
            </View>
          </View>
          <View style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t('until')}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{formatDate(socialGuidancePageData.guidedBy.to)}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            padding: 20,
          }}
          wrap
        >
          <View style={{ width: "100%", fontSize: 10, padding: 10 }}>
            <Text style={styles.label}>
              {i18next.t('Agreements about guidance')}
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              fontSize: 10,
              padding: 10,
            }}
            wrap
          >
            <Text>{`${socialGuidancePageData.agreementsAboutGuidance}`.split('')}</Text>
          </View>
        </View>

        <View style={styles.viewRowContainer}>
          <View style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t('Upload the scanned PDF file')}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Link src={`${socialGuidancePageData.scannedPDF}`}>PDF_FILE</Link>
            </View>
          </View>
        </View>

        <View><Text style={styles.subtitle}>{i18next.t('Life story')}</Text></View>
        <View style={styles.viewRowContainer}>
          {Object.entries(lifeStory)?.map((content) => {
            const [key, value] = content;
            if (value) {
              return (
                <View
                  key={`lifeStory_${key}`}
                  style={styles.viewRow}>
                  <View style={{ width: "50%", fontSize: 10 }}>
                    <Text style={styles.label}>
                      {i18next.t(SOCIAL_PAGE_LABELS[key])}
                    </Text>
                  </View>
                  {isPrimitive(value) ? (
                    <View style={styles.viewRowInput}>
                      <Text>
                        {DATE_KEYS.includes(key) ? formatDate(value) : value}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.viewRowInput}>
                      <Text>
                        {value.toString()}
                      </Text>
                    </View>
                  )}
                </View>
              )
            }
          })}
        </View>
      </View>
    </Page>
  );
};

export default SocialGuidancePdf;
