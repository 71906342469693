import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import SocialGuidanceForm from "./SocialGuidanceForm";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  title: {
    color: "#253c8c",
    textAlign: "center",
    paddingBottom: "40px"
  },
}));

function SocialGuidance({ data, setPipPlanPage }) {

  const [t] = useTranslation();

  const classes = useStyles();

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: "100%",
        alignItems: "center",

        color: "#253c8c",
      }}
    >
      <h1 className={classes.title}>{t("Social guidance +LV")}</h1>
      <Grid
        container
        item
        style={{
          minWidth: "100%",
        }}
      >
        {<SocialGuidanceForm data={data} setPipPlanPage={setPipPlanPage}/>}
      </Grid>
    </Grid>
  );
}

export default SocialGuidance;
