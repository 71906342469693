//Components for Home
import EditMunicipalityContact from "../components/home/EditMunicipalityContact";
import HomePage from "../components/home/HomePage";
// Components for Municipality PIP
import Addrules from "../components/municipality_pip/Addrules";
import SystemSearchByAdmin from "../components/system_search/SystemSearch";
// Components for User Management
import CreateUserByAdmin from "../components/create_user/CreateUser";
import EditUserByAdmin from "../components/edit_user/EditUser";
import PostMunicipalityDetails from "../components/create_user/PostMunicipalityDetails";
import PostMentorDetails from "../components/create_user/PostMentorDetails";
import PostSupplierDetails from "../components/create_user/PostSupplierDetails";
import PostRefugeeDetails from "../components/create_user/PostRefugeeDetails";
import AssignMentorToMunicipality from "../components/create_user/AssignMentorToMunicipality";
import EditAssignMentorToMunicipality from "../components/edit_user/AssignMentorToMunicipality";
import AssignSupplierToMunicipality from "../components/create_user/AssignSupplierToMunicipality";
import EditAssignSupplierToMunicipality from "../components/edit_user/AssignSupplierToMunicipality";
import AssignRefugeeToMunicipality from "../components/create_user/AssignRefugeeToMunicipality";
import EditAssignRefugeeToMunicipality from "../components/edit_user/AssignRefugeeToMunicipality";
import AssignMunicipalityToMentor from "../components/create_user/AssignMunicipalityToMentor";
import EditAssignMunicipalityToMentor from "../components/edit_user/AssignMunicipalityToMentor";
import AssignRefugeeToMentor from "../components/create_user/AssignRefugeeToMentor";
import EditAssignRefugeeToMentor from "../components/edit_user/AssignRefugeeToMentor";
import AssignMunicipalityToSupplier from "../components/create_user/AssignMunicipalityToSupplier";
import EditAssignMunicipalityToSupplier from "../components/edit_user/AssignMunicipalityToSupplier";
import AssignRefugeeToSupplier from "../components/create_user/AssignRefugeeToSupplier";
import EditAssignRefugeeToSupplier from "../components/edit_user/AssignRefugeeToSupplier";
import AssignMunicipalityToRefugee from "../components/create_user/AssignMunicipalityToRefugee";
import EditAssignMunicipalityToRefugee from "../components/edit_user/AssignMunicipalityToRefugee";
import AssignMentorToRefugee from "../components/create_user/AssignMentorToRefugee";
import EditAssignMentorToRefugee from "../components/edit_user/AssignMentorToRefugee";
import AssignSupplierToRefugee from "../components/create_user/AssignSupplierToRefugee";
import EditAssignSupplierToRefugee from "../components/edit_user/AssignSupplierToRefugee";
import ViewEditMunicipalitySummary from "../components/edit_user/ViewEditMunicipalitySummary";
import ViewEditSupplierSummary from "../components/edit_user/ViewEditSupplierSummary";
import ViewEditMentorSummary from "../components/edit_user/ViewEditMentorSummary";
import ViewEditRefugeeSummary from "../components/edit_user/ViewEditRefugeeSummary";
// Components for Profiles and Authentication
import Profile from "../components/profile/Profile";
import UpdatePassword from "../components/update_password/UpdatePassword";
import MunicipalityPipPage from "../components/municipality_pip/MunicipalityPipPage";
import Pdfpipreport from "../components/municipality_pip/Pdfpipreport";
import Editpipreport from "../components/municipality_pip/Editpipreport";
import Addpipreport from "../components/municipality_pip/Addpipreport";
import PipPlan from "../components/PipPlan";
import LoginPage from "../components/login/LoginPage";
import PrivacyPage from "../components/terms_conditions/Privacy/PrivacyPage"
import ConditionsPage from "../components/terms_conditions/Conditions/ConditionsPage"
// Components for Manager User App
import SystemSearchByManager from "../components/userapp/manager/system_search/SystemSearch";
import SystemSearchByMentor from "../components/userapp/mentor/system_search/SystemSearch";
import EditUserByManager from "../components/userapp/manager/edit_user/EditUser";
import EditUserByMentor from "../components/userapp/mentor/edit_user/EditUser";
import CreateUserByManager from "../components/userapp/manager/create_user/CreateUser";
import CreateUserByMentor from "../components/userapp/mentor/create_user/CreateUser";
import PostMentorDetailsByManager from "../components/userapp/manager/create_user/PostMentorDetails";
import PostSupplierDetailsByManager from "../components/userapp/manager/create_user/PostSupplierDetails";
import PostSupplierDetailsByMentor from "../components/userapp/mentor/create_user/PostSupplierDetails";
import PostRefugeeDetailsByManager from "../components/userapp/manager/create_user/PostRefugeeDetails";
import PostRefugeeDetailsByMentor from "../components/userapp/mentor/create_user/PostRefugeeDetails";
import AssignRefugeeToMentorByManager from "../components/userapp/manager/create_user/AssignRefugeeToMentor";
import EditAssignRefugeeToMentorByManager from "../components/userapp/manager/edit_user/AssignRefugeeToMentor";
import ViewAssignRefugeeToMentorByMentor from "../components/userapp/mentor/edit_user/ViewAssignRefugeeToMentor";
import ViewAssignSupplierToRefugeeByMentor from "../components/userapp/mentor/edit_user/ViewAssignSupplierToRefugee";
import ViewAssignRefugeeToSupplierByMentor from "../components/userapp/mentor/edit_user/ViewAssignRefugeeToSupplier";
import AssignRefugeeToSupplierByManager from "../components/userapp/manager/create_user/AssignRefugeeToSupplier";
import AssignRefugeeToSupplierByMentor from "../components/userapp/mentor/create_user/AssignRefugeeToSupplier";
import EditAssignRefugeeToSupplierByManager from "../components/userapp/manager/edit_user/AssignRefugeeToSupplier";
import EditAssignRefugeeToSupplierByMentor from "../components/userapp/mentor/edit_user/AssignRefugeeToSupplier";
import AssignMentorToRefugeeByManager from "../components/userapp/manager/create_user/AssignMentorToRefugee";
import EditAssignMentorToRefugeeByManager from "../components/userapp/manager/edit_user/AssignMentorToRefugee";
import AssignSupplierToRefugeeByManager from "../components/userapp/manager/create_user/AssignSupplierToRefugee";
import AssignSupplierToRefugeeByMentor from "../components/userapp/mentor/create_user/AssignSupplierToRefugee";
import EditAssignSupplierToRefugeeByManager from "../components/userapp/manager/edit_user/AssignSupplierToRefugee";
import EditAssignSupplierToRefugeeByMentor from "../components/userapp/mentor/edit_user/AssignSupplierToRefugee";
import ViewEditSupplierSummaryByManager from "../components/userapp/manager/edit_user/ViewEditSupplierSummary";
// Components for Supplier User App
import ViewEditSupplierSummaryByMentor from "../components/userapp/mentor/edit_user/ViewEditSupplierSummary";
import ViewSupplierSummaryByMentor from "../components/userapp/mentor/edit_user/ViewSupplierSummary";
import ViewEditMentorSummaryByManager from "../components/userapp/manager/edit_user/ViewEditMentorSummary";
import ViewMentorSummaryByMentor from "../components/userapp/mentor/edit_user/ViewMentorSummary";
import ViewRefugeeSummaryByMentor from "../components/userapp/mentor/edit_user/ViewRefugeeSummary";
import ViewRefugeeSummaryBySupplier from "../components/userapp/supplier/home/ViewRefugeeSummary";
import ViewEditRefugeeSummaryByManager from "../components/userapp/manager/edit_user/ViewEditRefugeeSummary";
import ViewEditRefugeeSummaryByMentor from "../components/userapp/mentor/edit_user/ViewEditRefugeeSummary";
import MentorHomePage from "../components/userapp/mentor/home/HomePage";
import SupplierHomePage from "../components/userapp/supplier/home/HomePage";
import UserProfile from "../components/userapp/profile/Profile";
import SupplierProfile from "../components/userapp/supplier/profile/Profile";
import MentorProfile from "../components/userapp/mentor/profile/Profile";
import ManagerProfile from "../components/userapp/manager/profile/Profile";
import UserUpdatePassword from "../components/userapp/update_password/UpdatePassword";
import RequestResetPasswordForAdmin from "../components/forgot_password/RequestResetPassword";
import ResetPasswordForAdmin from "../components/forgot_password/ResetPassword";
import NewPassword from "../components/new_password/NewPassword";
import ManagerMunicipalityPipPage from "../components/userapp/manager/municipality_pip/MunicipalityPipPage";
import MentorMunicipalityPipPage from "../components/userapp/mentor/municipality_pip/MunicipalityPipPage";
import PdfpipreportByMentor from "../components/userapp/mentor/municipality_pip/Pdfpipreport";
import PdfpipreportByManager from "../components/userapp/manager/municipality_pip/Pdfpipreport";
import AddrulesByManager from "../components/userapp/manager/municipality_pip/Addrules";
import AddrulesByMentor from "../components/userapp/mentor/municipality_pip/Addrules";
import { roles } from "../utils/roles";

const ROUTES = [
  {
    id: "1",
    path: "/dashboard",
    element: <HomePage />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "2",
    path: "/editMunicipalityContact",
    element: <EditMunicipalityContact />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "3",
    path: "/addrules",
    element: <Addrules />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "4",
    path: "/systemSearchByAdmin",
    element: <SystemSearchByAdmin />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "5",
    path: "/createUserByAdmin",
    element: <CreateUserByAdmin />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "6",
    path: "/editUserByAdmin",
    element: <EditUserByAdmin />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "7",
    path: "/viewEditMunicipalitySummary",
    element: <ViewEditMunicipalitySummary />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "8",
    path: "/viewEditMentorSummary",
    element: <ViewEditMentorSummary />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "9",
    path: "/assignMentorToMunicipality",
    element: <AssignMentorToMunicipality />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "10",
    path: "/editAssignMentorToMunicipality",
    element: <EditAssignMentorToMunicipality />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "11",
    path: "/assignSupplierToMunicipality",
    element: <AssignSupplierToMunicipality />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "12",
    path: "/editAssignSupplierToMunicipality",
    element: <EditAssignSupplierToMunicipality />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "13",
    path: "/assignRefugeeToMunicipality",
    element: <AssignRefugeeToMunicipality />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "14",
    path: "/editAssignRefugeeToMunicipality",
    element: <EditAssignRefugeeToMunicipality />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "15",
    path: "/assignMunicipalityToMentor",
    element: <AssignMunicipalityToMentor />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "16",
    path: "/EditAssignMunicipalityToMentor",
    element: <EditAssignMunicipalityToMentor />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "17",
    path: "/assignRefugeeToMentor",
    element: <AssignRefugeeToMentor />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "18",
    path: "/postRefugeeDetails",
    element: <PostRefugeeDetails />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "19",
    path: "/postSupplierDetails",
    element: <PostSupplierDetails />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "20",
    path: "/postMunicipalityDetails",
    element: <PostMunicipalityDetails />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "21",
    path: "/postMentorDetails",
    element: <PostMentorDetails />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "22",
    path: "/ViewEditRefugeeSummary",
    element: <ViewEditRefugeeSummary />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "23",
    path: "/viewEditSupplierSummary",
    element: <ViewEditSupplierSummary />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "24",
    path: "/editAssignRefugeeToMentor",
    element: <EditAssignRefugeeToMentor />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "25",
    path: "/assignMunicipalityToSupplier",
    element: <AssignMunicipalityToSupplier />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "26",
    path: "/editAssignMunicipalityToSupplier",
    element: <EditAssignMunicipalityToSupplier />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "27",
    path: "/assignRefugeeToSupplier",
    element: <AssignRefugeeToSupplier />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "28",
    path: "/editAssignRefugeeToSupplier",
    element: <EditAssignRefugeeToSupplier />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "29",
    path: "/assignSupplierToRefugee",
    element: <AssignSupplierToRefugee />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "30",
    path: "/editAssignMentorToRefugee",
    element: <EditAssignMentorToRefugee />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "31",
    path: "/assignMunicipalityToRefugee",
    element: <AssignMunicipalityToRefugee />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "32",
    path: "/editAssignMunicipalityToRefugee",
    element: <EditAssignMunicipalityToRefugee />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "33",
    path: "/assignMentorToRefugee",
    element: <AssignMentorToRefugee />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "34",
    path: "/foradmin",
    element: <h1>ForAdmin Only</h1>,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "35",
    path: "/pdfpipreport",
    element: <Pdfpipreport />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "36",
    path: "/addpipreport/:refugeeId/:municipalityId",
    element: <PipPlan />,
    // element: <Addpipreport />,
    isPrivate: true,
    // isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "37",
    path: "/editpipreport",
    element: <Editpipreport />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "38",
    path: "/municipalitypip",
    element: <MunicipalityPipPage />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "39",
    path: "/updatepassword",
    element: <UpdatePassword />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "40",
    path: "/profile",
    element: <Profile />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "41",
    path: "/editAssignSupplierToRefugee",
    element: <EditAssignSupplierToRefugee />,
    isPrivate: true,
    isAdmin: true,
    roles: [roles?.admin?.id],
  },
  {
    id: "42",
    path: "/addRulesByManager",
    element: <AddrulesByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "43",
    path: "/systemSearchByManager",
    element: <SystemSearchByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "44",
    path: "/createUserByManager",
    element: <CreateUserByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "45",
    path: "/editUserByManager",
    element: <EditUserByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "46",
    path: "/postRefugeeDetailsByManager",
    element: <PostRefugeeDetailsByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "47",
    path: "/postMentorDetailsByManager",
    element: <PostMentorDetailsByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "48",
    path: "/postSupplierDetailsByManager",
    element: <PostSupplierDetailsByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "49",
    path: "/ViewEditRefugeeSummaryByManager",
    element: <ViewEditRefugeeSummaryByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "50",
    path: "/viewEditSupplierSummaryByManager",
    element: <ViewEditSupplierSummaryByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "51",
    path: "/viewEditMentorSummaryByManager",
    element: <ViewEditMentorSummaryByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "52",
    path: "/assignRefugeeToMentorByManager",
    element: <AssignRefugeeToMentorByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "53",
    path: "/editAssignRefugeeToMentorByManager",
    element: <EditAssignRefugeeToMentorByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "54",
    path: "/assignRefugeeToSupplierByManager",
    element: <AssignRefugeeToSupplierByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "55",
    path: "/addpipreport/:refugeeId/:municipalityId",
    element: <PipPlan />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "56",
    path: "/editpipreportbymanager",
    element: <Editpipreport />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "57",
    path: "/managerMunicipalitypip",
    element: <ManagerMunicipalityPipPage />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "58",
    path: "/managerdashboard",
    element: <HomePage />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "59",
    path: "/ManagerUserProfile",
    element: <ManagerProfile />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "60",
    path: "/editAssignSupplierToRefugeeByManager",
    element: <EditAssignSupplierToRefugeeByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "61",
    path: "/assignMentorToRefugeeByManager",
    element: <AssignMentorToRefugeeByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "62",
    path: "/editAssignMentorToRefugeeByManager",
    element: <EditAssignMentorToRefugeeByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "63",
    path: "/assignSupplierToRefugeeByManager",
    element: <AssignSupplierToRefugeeByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "64",
    path: "/pdfpipreportbymanager",
    element: <PdfpipreportByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "65",
    path: "/editAssignRefugeeToSupplierByManager",
    element: <EditAssignRefugeeToSupplierByManager />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.manager?.id],
  },
  {
    id: "66",
    path: "/addRulesByMentor",
    element: <AddrulesByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "67",
    path: "/systemSearchByMentor",
    element: <SystemSearchByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "68",
    path: "/createUserByMentor",
    element: <CreateUserByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "69",
    path: "/editUserByMentor",
    element: <EditUserByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "70",
    path: "/viewMentorSummaryByMentor",
    element: <ViewMentorSummaryByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "71",
    path: "/viewRefugeeSummaryByMentor",
    element: <ViewRefugeeSummaryByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "72",
    path: "/viewEditSupplierSummaryByMentor",
    element: <ViewEditSupplierSummaryByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "73",
    path: "/viewSupplierSummaryByMentor",
    element: <ViewSupplierSummaryByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "74",
    path: "/ViewEditRefugeeSummaryByMentor",
    element: <ViewEditRefugeeSummaryByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "75",
    path: "/postSupplierDetailsByMentor",
    element: <PostSupplierDetailsByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "76",
    path: "/postRefugeeDetailsByMentor",
    element: <PostRefugeeDetailsByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "77",
    path: "/viewAssignRefugeeToMentorByMentor",
    element: <ViewAssignRefugeeToMentorByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "78",
    path: "/viewAssignSupplierToRefugeeByMentor",
    element: <ViewAssignSupplierToRefugeeByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "79",
    path: "/viewAssignRefugeeToSupplierByMentor",
    element: <ViewAssignRefugeeToSupplierByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "80",
    path: "/assignRefugeeToSupplierByMentor",
    element: <AssignRefugeeToSupplierByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "81",
    path: "/editAssignRefugeeToSupplierByMentor",
    element: <EditAssignRefugeeToSupplierByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "82",
    path: "/assignSupplierToRefugeeByMentor",
    element: <AssignSupplierToRefugeeByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "83",
    path: "/editAssignSupplierToRefugeeByMentor",
    element: <EditAssignSupplierToRefugeeByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "84",
    path: "/mentordashboard",
    element: <MentorHomePage />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "85",
    path: "/MentorUserProfile",
    element: <MentorProfile />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "86",
    path: "/mentorMunicipalitypip",
    element: <MentorMunicipalityPipPage />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "87",
    path: "/editpipreportbymentor",
    element: <Editpipreport />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "88",
    path: "/addpipreport/:refugeeId/:municipalityId",
    element: <PipPlan />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "89",
    path: "/pdfpipreportbymentor",
    element: <PdfpipreportByMentor />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.mentor?.id],
  },
  {
    id: "90",
    path: "/viewRefugeeSummaryBySupplier",
    element: <ViewRefugeeSummaryBySupplier />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.work_supplier?.id],
  },
  {
    id: "91",
    path: "/SupplierUserProfile",
    element: <SupplierProfile />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.work_supplier?.id],
  },
  {
    id: "92",
    path: "/supplierdashboard",
    element: <SupplierHomePage />,
    isPrivate: true,
    isAdmin: false,
    roles: [roles?.work_supplier?.id, roles.school_supplier.id],
  },
  {
    id: "93",
    path: "/",
    element: <LoginPage />,
    isPrivate: false,
    isAdmin: true,
    roles: [],
  },
  {
    id: "94",
    path: "/resetPasswordByAdmin",
    element: <ResetPasswordForAdmin isAdmin={true} />,
    isPrivate: false,
    isAdmin: true,
    isMultiple: false,
    roles: [roles?.admin?.id],
  },
  {
    id: "95",
    path: "/requestResetPasswordForAdmin",
    element: <RequestResetPasswordForAdmin isAdmin={true} />,
    isMultiple: true,
    roles: [],
  },
  {
    id: "96",
    path: "/resetPassword",
    element: <ResetPasswordForAdmin isAdmin={false} />,
    isPrivate: false,
    isAdmin: false,
    isMultiple: false,
    roles: [],
  },
  {
    id: "97",
    path: "/requestResetPasswordForUser",
    element: <RequestResetPasswordForAdmin isAdmin={false} />,
    isPrivate: false,
    isAdmin: false,
    roles: [],
  },
  {
    id: "98",
    path: "/userlogin",
    // element: <UserLoginPage />,
    element: <LoginPage />,
    isPrivate: false,
    isAdmin: false,
    roles: [],
  },
  {
    id: "99",
    path: "/userProfile",
    element: <UserProfile />,
    isMultiple: true,
    roles: [
      roles.manager.id,
      roles.mentor.id,
      roles.school_supplier.id,
      roles.work_supplier.id,
      roles.refugee.id,
    ],
  },
  {
    id: "100",
    path: "/updateUserPassword",
    element: <UserUpdatePassword />,
    isPrivate: true,
    isAdmin: false,
    roles: [
      roles.manager.id,
      roles.mentor.id,
      roles.school_supplier.id,
      roles.work_supplier.id,
      roles.refugee.id,
    ],
  },
  {
    id: "101",
    path: "/formulti",
    element: <h1>For Multi user</h1>,
    isMultiple: true,
    roles: [],
  },
  {
    id: "102",
    path: "/resetPasswordActiveLink",
    element: <NewPassword isAdmin={false} />,
    isPrivate: false,
    isAdmin: false,
    roles: [],
  },
  {
    id: "103",
    path: "/privacyPolicy",
    element: <PrivacyPage />,
    roles: [],
  },
  {
    id: "104",
    path: "/TermsAndConditions",
    element: <ConditionsPage />,
    roles: [],
  },

];
export default ROUTES;
