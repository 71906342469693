import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import SuccessDialog from "./sub_components/AddMunicipalityDetailSuccessDialog";
import { useTranslation } from "react-i18next";
import AssignRefugeeToSupplier from "./AssignRefugeeToSupplier";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  editButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginLeft: "auto",
    marginTop: "50px",
    boxShadow: "none",
  },
  btnsCon: {
    marginTop: "20px",
  },
  button: {
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "150px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
  },
}));
function PostSupplierDetails(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedRefugees, setSelectedRefugees] = useState([]);
  
  const add_confirmation = useSelector(
    ({ PostSupplierDetailsReducer }) =>
      PostSupplierDetailsReducer?.assignUnassignRolesToSupplierReducer
  );
  useEffect(() => {
    dispatch(Actions.getSupplierSummary(sessionStorage.getItem("user_id")));
    return () => {
      dispatch(Actions.resetSupplierSummary(true));
      dispatch(Actions.resetAssignUnassignRolesToSupplier(true));
    };
  }, []);
  const supplierSummary = useSelector(
    ({ PostSupplierDetailsReducer }) =>
      PostSupplierDetailsReducer?.getSupplierSummaryReducer?.data
  );

  useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
    }
  }, [setSelectedMunicipalities]);
  useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
    }
  }, [setSelectedRefugees]);
  const handleClickSuccessDialogclose = () => {
    setSuccessOpen(false);
  };
  const handleClickSuccessDialogopen = (data) => {
    setSuccessOpen(true);
  };
  const handleClickEdit = (path) => {
    navigate(path);
  };
  const handleClickEditUser = () => {
    sessionStorage.removeItem("assignedRefugees")
    navigate("/ViewEditSupplierSummaryByMentor");
  };
  
  useEffect(() => {
    const refugees = JSON.parse(sessionStorage.getItem("assignedRefugees")) 
    let values = {
      supplier_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      arefugeeIds: [],
      umunicipalityIds: [],
      urefugeeIds: [],
    };
    if (selectedMunicipalities.length > 0) {
      for (let i = 0; i < selectedMunicipalities.length; i++) {
        const element = selectedMunicipalities[i];
        values.amunicipalityIds.push(element?.id);
      }
    }
    if (refugees?.length > 0) {
      for (let i = 0; i < refugees?.length; i++) {
        const element = refugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToSupplier(values));
  },[])
  
  useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignUnassignRolesToSupplier
    ) {
      
    }
  }, [add_confirmation]);
  const handleNavigateToDashboard = () => {
    sessionStorage.clear();
    navigate("/mentordashboard");
  };
  
  return (
    <div className="main">
      {successOpen === true && (
        <SuccessDialog
          status={successOpen}
          methodd={handleClickSuccessDialogclose}
          role="Supplier"
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <div className="edit-municipality-detail-view-div">
            <h3 className="edit-municipality-detail-view-title">
              {t("Supplier")}
            </h3>
            {supplierSummary && supplierSummary.getSupplierSummary && (
              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Name")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {supplierSummary.getSupplierSummary.name}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Type")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {supplierSummary.getSupplierSummary.type}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={4}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Contact Person")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {supplierSummary.getSupplierSummary.contactPerson.name}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Email")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {supplierSummary.getSupplierSummary.email}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Phone")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {supplierSummary.getSupplierSummary.phoneNumber}
                    </span>
                  </p>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
             
            </Grid>
          </div>
          <div className="edit-municipality-detail-view-div">
            <Grid container spacing={2}>
              <Grid item sm={7}>
             
                <AssignRefugeeToSupplier disabled={true} />
              </Grid>
              <Grid item sm={5}></Grid>
              {add_confirmation.errMsg && (
                <Grid item sm={12} xs={12}>
                  <Alert severity="error">{t(add_confirmation.errMsg)}</Alert>
                </Grid>
              )}
              {add_confirmation.isLoading && (
                <Grid item sm={12} xs={12}>
                  Loading.....
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container spacing={2} className={classes.btnsCon}>
            <Grid item sm={8} xs={12}></Grid>
            <Grid item sm={2} xs={12}>
             
            </Grid>
            <Grid item sm={2} xs={12}>
            <Button
                variant="contained"
                className={classes.button}
                onClick={() => handleClickEditUser()}
                color="primary"
              >
                {t("Edit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withReducer(
  "PostSupplierDetailsReducer",
  reducer
)(PostSupplierDetails);
