import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Container } from "@material-ui/core";
import NavigationPanel from "./NavigationPanel";
import PipPlanForm from "./PipPlanForm";
import { pipPlanNavigationKeys } from '../../resources/constants';
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

function PipPlan() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const pipPlanInfo = useSelector(
    ({ PipPlanState }) => PipPlanState?.pipPlanInfo
  );

  const params = useParams();

  const [pipPlanPage, setPipPlanPage] = useState(pipPlanNavigationKeys.frontPage);

  const pipId = searchParams.get('pipId') || '';
  const { refugeeId, municipalityId } = params;

  useEffect(
    () => {
      if (pipId) {
        dispatch(Actions.getPipPlan(pipId))
      } else {
        batch(() => {
          dispatch(Actions.getMunicipalitySummary(municipalityId))
          dispatch(Actions.getPipPlanByRefugee(refugeeId))
        })
      }
    },
    [refugeeId, pipId, municipalityId]
  )

  return (
    <Container style={{ minHeight: "80vh", marginTop: "50px", display: "flex", paddingBottom: "115px" }}>
      <Grid item xs={3} mr={2} style={{ marginRight: "6px" }}>
        <NavigationPanel
          handleClick={setPipPlanPage}
          selectedMenuItemKey={pipPlanPage}
          pipPlan={pipPlanInfo?.data}
        />
      </Grid>
      <Grid item container xs={9}>
        <PipPlanForm
          pipPlan={pipPlanInfo?.data}
          formState={pipPlanPage}
          setPipPlanPage={setPipPlanPage}
        />
      </Grid>
    </Container>
  );
}

export default withReducer("PipPlanState", reducer)(PipPlan);
