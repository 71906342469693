import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
}));

function AssignSupplierToRefugeeSuppliersData(props) {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <div className="system-search-filter-div">
        <div className="system-search-filter-total-div">
          {t("Total Suppliers")}:{" "}
          {props.assignedSuppliers?.listAssignedSuppliers
            ? props.assignedSuppliers.listAssignedSuppliers.totalDocs
            : "0"}
        </div>
      </div>

      <div className="edit-mun-table-div">
        <table
          className="system-search-table-tag"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <tr>
            <th>{t("Type")}</th>
            <th>{t("Name")}</th>
            <th>{t("Email")}</th>
            <th>{t("Phone Number")}</th>
            <th>{t("Assigned Municipality")}</th>
          </tr>

          {props.assignedSuppliers?.listAssignedSuppliers?.docs &&
            props.assignedSuppliers.listAssignedSuppliers.docs.length > 0 &&
            props.assignedSuppliers.listAssignedSuppliers.docs.map(
              (doc, index) => (
                <tr key={doc?.id}>
                  <td>{doc.type}</td>
                  <td>{doc.name}</td>
                  <td>{doc.email}</td>
                  <td>{doc.phoneNumber}</td>
                  <td>
                    {doc.municipality ? (
                      doc.municipality.name
                    ) : (
                      <span className="unassigned-td">{t("Unassigned")}</span>
                    )}
                  </td>
                </tr>
              )
            )}
        </table>
      </div>

      <div
        className="assign-mentor-to-municipality-pagination-div"
        style={{ marginTop: "40px" }}
      >
        <TablePagination
          colSpan={3}
          component="div"
          count={
            props.assignedSuppliers?.listAssignedSuppliers
              ? props.assignedSuppliers.listAssignedSuppliers.totalDocs
              : 0
          }
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handlePageChange}
          onRowsPerPageChange={props.handleRowsPerPageChange}
          labelRowsPerPage={t("Rows per page")}
          classes={{
            root: classes.tablePagination,
          }}
        />
      </div>
    </div>
  );
}

export default withReducer(
  "AssignSupplierToRefugeeSuppliersDataReducer",
  reducer
)(AssignSupplierToRefugeeSuppliersData);
