import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, concat } from 'apollo-boost';

const url = process.env.REACT_APP_URL;
const httpLink = new HttpLink({ uri: url });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "x-auth-token": localStorage.getItem('jwtToken') || ""
    }
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

export default client;
