import { Grid } from "@material-ui/core";
import GoalsAndAgreementsForm from "./GoalsAndAgreementsForm";
function GoalsAgreements({ data, setPipPlanPage }) {
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: "100%",
        alignItems: "center",

        color: "#253c8c",
      }}
    >
      <Grid
        container
        item
        style={{
          minWidth: "100%",
        }}
      >
        {<GoalsAndAgreementsForm data={data} setPipPlanPage={setPipPlanPage}/>}
      </Grid>
    </Grid>
  );
}

export default GoalsAgreements;
