import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import PhoneInput from "react-phone-input-2";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";
import * as yup from "yup";
import * as Actions from "../store/actions";
import "react-phone-input-2/lib/style.css";
import AssignSupplierToRefugee from "../AssignSupplierToRefugee";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    margin: 0,
    backgroundColor: "#93C7D2",
  },
  dateTextField: {
    margin: 0,
    backgroundColor: "#93C7D2",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dateInput: {
    height: "13px",
    minHeight: "13px",
    border: 0,
    fontSize: "16px",
    fontFamily: "opensans-Regular",
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-Regular",
      letterSpacing: "0px",
      textTransform: "capitalize",
    },
  },
  notchedOutline: {
    borderWidth: "0px",
  },
  input1: {
    height: "13px",
    minHeight: "13px",
    border: 0,
    fontSize: "16px",
    fontFamily: "opensans-Regular",
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
      letterSpacing: "0px",
    },
  },
  con: {
    marginTop: "30px",
  },
  btnupload: {
    backgroundColor: "#454a92",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    width: "150px",
    height: "40px",
    textTransform: "capitalize",
    boxShadow: "none",
  },
  input: {
    display: "none",
  },
  skipBtn: {
    backgroundColor: "#454a92",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    height: "40px",
    float: "right",
    paddingLeft: "30px",
    paddingRight: "30px",
    boxShadow: "none",
  },
}));

function RefugeeForm(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(undefined);
  const [loadingg, setLoadingg] = useState(false);
  const [thumbb, setThumbb] = useState(undefined);
  const [skip, setSkip] = useState(false);
  const [loadingBI, setLoadingBI] = useState(false);
  const [biFile, setBIFile] = useState(undefined);
  const [clickState, setClickState] = useState(1);
  const refugeeSummary = useSelector(
    ({ RefugeeFormReducer }) =>
      RefugeeFormReducer?.getRefugeeSummaryReducer?.data
  );
  const loadingRefugeeSummary = useSelector(
    ({ RefugeeFormReducer }) =>
      RefugeeFormReducer?.getRefugeeSummaryReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ RefugeeFormReducer }) =>
      RefugeeFormReducer?.getRefugeeSummaryReducer?.errMsg
  );
  const update_confirmation = useSelector(
    ({ RefugeeFormReducer }) => RefugeeFormReducer?.UpdateRefugeeReducer
  );

  useEffect(() => {
    dispatch(Actions.getRefugeeSummary(sessionStorage.getItem("user_id")));
    return () => {
      dispatch(Actions.resetRefugeeSummary(true));
      dispatch(Actions.resetUpdateRefugee(true));
    };
  }, []);

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const FILE_SIZE = 2048 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const validationSchema = yup.object({
    refugee_bsn: yup.string().required(t("BSN is required")),
    refugee_name: yup
      .string()
      .required(t("Name is required"))
      .matches(/^[A-Za-z ]*$/, t("Please enter valid name"))
      .max(40, t("Name must be at most 40 characters")),
    refugee_email: yup
      .string()
      .email()
      .required(t("Email is Required"))
      .max(60, t("Email must be at most 60 characters")),
    refugee_phone_no: yup
      .string()
      .required(t("Phone Number is required"))
      .matches(phoneRegExp, t("Phone Number is not valid"))
      .min(10, t("too short"))
      .max(15, t("too long")),
    photo_id: yup
      .mixed()
      .test(
        "fileSize",
        t("Image too large, (Image size must be less than 2mb)"),
        (value) => !value || value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        t("Unsupported Format"),
        (value) => !value || SUPPORTED_FORMATS.includes(value.type)
      ),
      pdf_file: yup
      .mixed()
      .test(
        "fileSize",
        t("File too large, (File size must be less than 2mb)"),
        (value) => !value || value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        t("Unsupported Format"),
        (value) => !value || value.type === "application/pdf"
      ),
  });

  const { handleSubmit, handleChange, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: {
        refugee_bsn:
          refugeeSummary && refugeeSummary.getRefugeeSummary
            ? refugeeSummary.getRefugeeSummary.bsn
            : "",
        refugee_name:
          refugeeSummary && refugeeSummary.getRefugeeSummary
            ? refugeeSummary.getRefugeeSummary.name
            : "",
        refugee_email:
          refugeeSummary && refugeeSummary.getRefugeeSummary
            ? refugeeSummary.getRefugeeSummary.email
            : "",
        refugee_phone_no:
          refugeeSummary && refugeeSummary.getRefugeeSummary
            ? refugeeSummary.getRefugeeSummary.phoneNumber
            : "",
        photo_id: "",
        pdf_file: "",
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit(values) {
        dispatch(
          Actions.updateRefugee(values, sessionStorage.getItem("user_id"))
        );
   
      },
    });

    useEffect(() => {
      if (values.pdf_file) {
        setLoadingBI(true);
        const reader = new FileReader();
        reader.onloadend = () => {
          setLoadingBI(false);
          setBIFile(reader.result);
        };
        reader.readAsDataURL(values.pdf_file);
      }
    }, [values.pdf_file]);



  useEffect(() => {
    if (values.file) {
      setLoadingg(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLoadingg(false);
        setThumbb(reader.result);
      };
      reader.readAsDataURL(values.file);
    }
  }, [values.file]);

  useEffect(() => {
    if (values.photo_id) {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLoading(false);
        setThumb(reader.result);
      };
      reader.readAsDataURL(values.photo_id);
    }
  }, [values.photo_id]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const skipClick = () => {
    setSkip(true);
    handleSubmit();
  };

  const assignClick = () => {
    setSkip(false);
    handleSubmit();
  };

  useEffect(() => {
    if (update_confirmation?.data?.data?.updateRefugee && !skip) {
      sessionStorage.setItem("entity_name", values.refugee_name);
      navigate("/editAssignSupplierToRefugeeByMentor");
    }
    if (update_confirmation?.data?.data?.updateRefugee && skip) {
      sessionStorage.setItem("entity_name", values.refugee_name);
      navigate("/ViewEditRefugeeSummaryByMentor");
    }
  }, [update_confirmation, skip]);
  useEffect(() => {
    sessionStorage.setItem("EditOrSave", true)
  },[])
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.con}>
          <Grid item sm={2} xs={4}>
            <p className="create-user-label-text">{t("BSN")}</p>
          </Grid>
          <Grid item sm={10} xs={8}>
            <TextField
              id="refugee_bsn"
              onChange={(event) => {
                var reg = /^0+/gi;
                if (event.target.value.match(reg)) {
                  values.refugee_bsn = values.refugee_bsn.replace(reg, "");
                } else {
                  setFieldValue("refugee_bsn", event.target.value);
                }
              }}
              value={values.refugee_bsn}
              name="refugee_bsn"
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: { min: 0, onKeyDown: blockInvalidChar },
                classes: {
                  input: classes.input1,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={t("Enter Refugee's BSN")}
              error={errors.refugee_bsn && touched.refugee_bsn ? true : false}
            />
            {errors.refugee_bsn && touched.refugee_bsn ? (
              <p className="error-input">{errors.refugee_bsn}</p>
            ) : (
              false
            )}
          </Grid>
          <Grid item sm={2} xs={4}>
            <p className="create-user-label-text">{t("Name")}</p>
          </Grid>
          <Grid item sm={10} xs={8}>
            <TextField
              id="refugee_name"
              onChange={handleChange}
              value={values.refugee_name}
              name="refugee_name"
              className={classes.textField}
              type="text"
              InputProps={{
                classes: {
                  input: classes.input1,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={t("Enter Refugee's Full Name")}
              error={errors.refugee_name && touched.refugee_name ? true : false}
            />
            {errors.refugee_name && touched.refugee_name ? (
              <p className="error-input">{errors.refugee_name}</p>
            ) : (
              false
            )}
          </Grid>
          <Grid item sm={2} xs={4}>
            <p className="create-user-label-text">{t("Email")}</p>
          </Grid>
          <Grid item sm={10} xs={8}>
            <TextField
              id="refugee_email"
              onChange={handleChange}
              value={values.refugee_email}
              name="refugee_email"
              className={classes.textField}
              type="email"
              InputProps={{
                classes: {
                  input: classes.input1,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder="Enter Refugee's Email"
              error={
                errors.refugee_email && touched.refugee_email ? true : false
              }
            />
            {errors.refugee_email && touched.refugee_email ? (
              <p className="error-input">{errors.refugee_email}</p>
            ) : (
              false
            )}
          </Grid>
          <Grid item sm={2} xs={4}>
            <p className="create-user-label-text">{t("Telephone")}</p>
          </Grid>
          <Grid item sm={10} xs={8}>
            <PhoneInput
              country={"nl"}
              value={values.refugee_phone_no}
              onChange={(phone) =>
                setFieldValue("refugee_phone_no", "+" + phone)
              }
              countryCodeEditable={true}
              id="refugee_phone_no"
              name="refugee_phone_no"
              excludeCountries={["bg"]}
              inputProps={{
                name: "refugee_phone_no",
                id: "refugee_phone_no",
                style: {
                  backgroundColor: "#93C7D2",
                  width: "100%",
                  color: "black",
                  border: "none",
                  borderRadius: 0,
                  fontFamily: "opensans-Regular",
                },
              }}
              buttonStyle={{
                backgroundColor: "transparent",
                border: "none",
                borderRadius: 0,
              }}
            />
            {errors.refugee_phone_no && touched.refugee_phone_no ? (
              <p className="error-input">{errors.refugee_phone_no}</p>
            ) : (
              false
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.con}>
          <Grid item sm={2} xs={4}>
            <p className="create-user-label-text">{t("Upload Photo (ID)")}</p>
          </Grid>
          <Grid item sm={3} xs={4}>
            <input
              accept="image/*"
              className={classes.input}
              id="photo_id"
              onChange={(event) => {
                if (!values.photo_id || event.currentTarget.files.length) {
                  setFieldValue("photo_id", event.currentTarget.files[0]);
                }
              }}
              name="photo_id"
              type="file"
            />
            <label htmlFor="photo_id">
              <Button
                variant="contained"
                color="primary"
                className={classes.btnupload}
                size="small"
                component="span"
              >
                {t("Upload Photo")}
              </Button>
            </label>
            {errors.photo_id && touched.photo_id ? (
              <p className="error-input">{errors.photo_id}</p>
            ) : (
              false
            )}
          </Grid>
          
          
          <Grid item sm={7} xs={12}>
            {thumb && (
              <p className="create-user-logo-name-text">
                {values.photo_id.name}
              </p>
            )}
          </Grid>
          {update_confirmation.errMsg && (
            <Grid item sm={12} xs={12}>
              <Alert severity="error">{t(update_confirmation.errMsg)}</Alert>
            </Grid>
          )}
          {update_confirmation.isLoading && (
            <Grid item sm={12} xs={12}>
              Loading.....
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} className={classes.con}>
  <Grid item sm={2} xs={4}>
    <p className="create-user-label-text">{t("Upload BI")}</p>
  </Grid>
  <Grid item sm={3} xs={4}>
    <input
      accept="application/pdf" 
      className={classes.input}
      id="pdf_file"
      onChange={(event) => {
        if (!values.pdf_file || event.currentTarget.files.length) {
          setFieldValue("pdf_file", event.currentTarget.files[0]);
        }
      }}
      name="pdf_file"
      type="file"
    />
    <label htmlFor="pdf_file">
      <Button
        variant="contained"
        color="primary"
        className={classes.btnupload}
        size="small"
        component="span"
      >
        {t("Upload PDF")}
      </Button>
    </label>
    {errors.pdf_file && touched.pdf_file ? (
      <p className="error-input">{errors.pdf_file}</p>
    ) : (
      false
    )}
  </Grid>
  
  <Grid item sm={7} xs={12}>
    {values.pdf_file && (
      <p className="create-user-logo-name-text">
        {values.pdf_file.name}
      </p>
    )}
  </Grid>
  
  {update_confirmation.errMsg && (
    <Grid item sm={12} xs={12}>
      <Alert severity="error">{t(update_confirmation.errMsg)}</Alert>
    </Grid>
  )}
  {update_confirmation.isLoading && (
    <Grid item sm={12} xs={12}>
      Loading.....
    </Grid>
  )}
</Grid>

    <AssignSupplierToRefugee
       setClickState={setClickState}
       clickState={clickState}
    />
       

        <div className="create-user-skip-div">
          <Button
            variant="contained"
            color="primary"
            className={classes.skipBtn}
            size="small"
            onClick={() => skipClick()}
          >
            {t("Save")}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default withReducer("RefugeeFormReducer", reducer)(RefugeeForm);
