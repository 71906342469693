import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import RefugeesData from "./sub_components/AssignRefugeeToSupplierRefugeesData";
import AssignedRefugeesData from "./sub_components/AssignedRefugeeToSupplierRefugeesData";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import { useTranslation } from "react-i18next";
// store

import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../../../store/withReducer";
import * as Actions from "./store/actions";
const useStyles = makeStyles((theme) => ({
  backButton: {
    // margin: theme.spacing(1),
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "140px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
  },
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    // borderWidth: "0px",
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignRefugeeToSupplier(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState("");
  const [selectedRefugees, setSelectedRefugees] = React.useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = React.useState(
    []
  );
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = React.useState(false);
  const [unAssignId, setUnAssignId] = React.useState(false);
  const [editCheck, setEditCheck] = React.useState(false);
  const [state, setState] = React.useState(1)
  const add_confirmation = useSelector(
    ({ AssignRefugeeToSupplierReducer }) =>
      AssignRefugeeToSupplierReducer?.assignUnassignRolesToSupplierReducer
  );
  React.useEffect(() => {
    return () => {
      dispatch(Actions.resetAssignUnassignRolesToSupplier(true));
    };
  }, []);
  React.useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
      setEditCheck(true);
    }
  }, [setSelectedMunicipalities]);
  React.useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
      setEditCheck(true);
    }
  }, [setSelectedRefugees]);
  const handleChangeSearch = (value) => {
    setSearchText(value);
  };
  const createRefugee = () => {
    let values = {
      supplier_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      arefugeeIds: [],
      umunicipalityIds: [],
      urefugeeIds: [],
    };
    if (selectedMunicipalities.length > 0) {
      for (let i = 0; i < selectedMunicipalities.length; i++) {
        const element = selectedMunicipalities[i];
        values.amunicipalityIds.push(element?.id);
      }
    }
    if (selectedRefugees.length > 0) {
      for (let i = 0; i < selectedRefugees.length; i++) {
        const element = selectedRefugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToSupplier(values));
  };
  React.useEffect(() => {
    if (
      add_confirmation.data &&
      add_confirmation.data.data &&
      add_confirmation.data.data.assignUnassignRolesToSupplier
    ) {
    
      
    }
  }, [add_confirmation]);
  const unAssignRefugee = (data) => {
    setUnAssignId(true);
    setSelectedRefugees(
      selectedRefugees.filter((item) => item?.id !== data?.id)
    );
  };
  const clickBack = () => {
    sessionStorage.setItem("user_type", "supplier");
    history.goBack();
  };
  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };
  const handleClickLeaveScreenDialogopen = () => {
    setLeaveScreenDialog(true);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  return (

        <Container fixed>
         
          <Grid container spacing={4} className={classes.con}>
            <Grid item md={7} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                
                <RefugeesData
                  searchText={searchText}
                  selectedRefugees={selectedRefugees}
                  setSelectedRefugees={setSelectedRefugees}
                  unAssignId={unAssignId}
                  setUnAssignId={setUnAssignId}
                  editCheck={editCheck}
                  setEditCheck={setEditCheck}
                  setState={setState}
                  disabled={props?.disabled}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
              
                <AssignedRefugeesData
                  selectedRefugees={selectedRefugees}
                  unAssignRefugee={unAssignRefugee}
                  state={state}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
   
  );
}
export default withReducer(
  "AssignRefugeeToSupplierReducer",
  reducer
)(AssignRefugeeToSupplier);
