import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import StyledCheckbox from "../../../../shared/StyledCheckbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import reducer from "../store/reducers";
import withReducer from "../../../../../store/withReducer";
import * as Actions from "../store/actions";
import CustomScrollbar from "../../../../shared/CustomScrollbar/index"
const useStyles = makeStyles((theme) => ({
  tablePagination: {
    overflow: "visible",
  },
  customScrollbar: {
    maxHeight: "200px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
    },
  },
  listItem: {
    "&.MuiListItem-gutters": {
      marginLeft: "-26px",
      marginRight: "16px",
    },
  },
}));

function AssignSupplierToRefugeeSuppliersData(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [suppliers, setSuppliers] = useState({});
  const [selectedSupplierIds, setSelectedSupplierIds] = useState([]);
  const SearchedSuppliers = useSelector(
    ({ AssignSupplierToRefugeeSuppliersDataReducer }) =>
      AssignSupplierToRefugeeSuppliersDataReducer
        ?.allMiniSearchedSuppliersReducer?.data
  );
  const loading = useSelector(
    ({ AssignSupplierToRefugeeSuppliersDataReducer }) =>
      AssignSupplierToRefugeeSuppliersDataReducer
        ?.allMiniSearchedSuppliersReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignSupplierToRefugeeSuppliersDataReducer }) =>
      AssignSupplierToRefugeeSuppliersDataReducer
        ?.allMiniSearchedSuppliersReducer?.errMsg
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.resetMiniSearchedSuppliers(true));
    };
  }, []);

  useEffect(() => {
    dispatch(
      Actions.getAllMiniSearchedSuppliers(
        page + 1,
        10000,
        props.searchText,
        sessionStorage.getItem("user_id")
      )
    );
  }, [page, rowsPerPage, props.searchText, dispatch]);

  useEffect(() => {
    const storedSupplierIds = JSON.parse(
      localStorage.getItem("selectedSupplierIds")
    );
    dispatch(
      Actions.getAllMiniSearchedSuppliers(
        page + 1,
        10000,
        props.searchText,
        sessionStorage.getItem("user_id")
      )
    );
    setSelectedSupplierIds(storedSupplierIds || []);
  }, [page, rowsPerPage, props.searchText, dispatch]);

  const handleChangeSelected = (index) => {
    const existingSelectedIds =
      JSON.parse(localStorage.getItem("selectedSupplierIds")) || [];
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    props.setClickState(randomNumber);
    const newSuppliersObj = { ...suppliers };
    newSuppliersObj.docs[index].isSelected = !suppliers.docs[index].isSelected;

    if (newSuppliersObj.docs[index].isSelected) {
      const selectedSupplier = {
        id: newSuppliersObj.docs[index]?.id,
        name: newSuppliersObj.docs[index].name,
        type: newSuppliersObj.docs[index].type,
        contactPerson: newSuppliersObj.docs[index].contactPerson,
      };

      props.setSelectedSuppliers((selectedSuppliers) => [
        ...selectedSuppliers,
        selectedSupplier,
      ]);

      if (newSuppliersObj.docs[index].isAssigned) {
        props.setUnSelectedSuppliers((unSelectedSuppliers) =>
          unSelectedSuppliers.filter(
            (unSelectedSupplier) =>
              unSelectedSupplier?.id !== newSuppliersObj.docs[index]?.id
          )
        );
      }
    } else {
      props.setSelectedSuppliers((selectedSuppliers) =>
        selectedSuppliers.filter(
          (selectedSupplier) =>
            selectedSupplier?.id !== newSuppliersObj.docs[index]?.id
        )
      );

      if (newSuppliersObj.docs[index].isAssigned) {
        const unSelectedSupplier = {
          id: newSuppliersObj.docs[index]?.id,
        };
        props.setUnSelectedSuppliers((unSelectedSuppliers) => [
          ...unSelectedSuppliers,
          unSelectedSupplier,
        ]);
      }
    }
    const newSelectedIds = newSuppliersObj.docs
      .filter((supplier) => supplier.isSelected)
      .map((supplier) => supplier.id);
    const combinedIds = Array.from(
      new Set([...existingSelectedIds, ...newSelectedIds])
    );
    localStorage.setItem("selectedSupplierIds", JSON.stringify(combinedIds));
    setSelectedSupplierIds(combinedIds);
  };

  useEffect(() => {
    const suppliersObj = SearchedSuppliers?.miniSearchAllSuppliers;

    if (suppliersObj) {
      const newSuppliersObj = { ...suppliersObj };

      if (newSuppliersObj.docs && newSuppliersObj.docs.length) {
        for (let i = 0; i < newSuppliersObj.docs.length; i++) {
          if (newSuppliersObj.docs[i].isAssigned)
            newSuppliersObj.docs[i].isSelected = true;
          else newSuppliersObj.docs[i].isSelected = false;
          if (props.selectedSuppliers.length) {
            for (let j = 0; j < props.selectedSuppliers.length; j++) {
              if (
                newSuppliersObj.docs[i]?.id === props.selectedSuppliers[j]?.id
              ) {
                newSuppliersObj.docs[i].isSelected = true;
              }
            }
          }
          if (props.unSelectedSuppliers.length) {
            for (let j = 0; j < props.unSelectedSuppliers.length; j++) {
              if (
                newSuppliersObj.docs[i]?.id === props.unSelectedSuppliers[j]?.id
              ) {
                newSuppliersObj.docs[i].isSelected = false;
              }
            }
          }
        }
      }
      setSuppliers(newSuppliersObj);
    }
  }, [SearchedSuppliers, props.selectedSuppliers, props.unSelectedSuppliers]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <h3>Select the Supplier(s)</h3>
      <div>
        <CustomScrollbar>
        <List>
          {suppliers && suppliers.docs && suppliers.docs.length > 0 && (
            <>
              {suppliers.docs
                .sort((a, b) => {
                  if (a.isSelected && !b.isSelected) return -1;
                  if (!a.isSelected && b.isSelected) return 1;
                  const isSelectedA = selectedSupplierIds?.includes(a.id);
                  const isSelectedB = selectedSupplierIds?.includes(b.id);
                  if (isSelectedA && !isSelectedB) return -1;
                  if (!isSelectedA && isSelectedB) return 1;

                  if (isSelectedA === isSelectedB) {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  }
                  return 0;
                })
                .map((doc, index) => (
                  <ListItem
                    key={doc.id}
                    className={classes.listItem}
                    button
                    onClick={() => handleChangeSelected(index)}
                    disabled={props?.disabled}
                  >
                    <Checkbox checked={doc.isSelected} />
                    <ListItemText primary={doc.name} />
                  </ListItem>
                ))}
            </>
          )}
        </List>
        </CustomScrollbar>
     
      </div>
    </div>
  );
}

export default withReducer(
  "AssignSupplierToRefugeeSuppliersDataReducer",
  reducer
)(AssignSupplierToRefugeeSuppliersData);
