import { Text, View, Page } from "@react-pdf/renderer";
import styles from "../pdfStyles";
import i18next from "i18next";
import { formatDate, isAdult } from "../../../utils/helpers";

const DETAILS_PAGE_LABELS = {
  "firstName": "First Name",
  "lastName": "Last Name",
  "gender": "Gender",
  "birthDate": "Birthday",
  "streetAndHouseNumber": "Street and house number",
  "postalCode": "Postal Code",
  "place": "Place",
  "phoneNumber": "Phone number",
  "emailAddress": "E-mail address",
  "haveChildren": "Do you have any children",
  "countChildren": "How many children do you have",
  "childName": "Name",
  "needChildcare": "Need childcare",
  "organized": "Organized",
  "organizedDeadline": "Arrange childcare organizedDeadline",
  "comments": "Comments",
  "childcareStartDate": "Childcare start date",
  "childcareName": "Childcare name"
};

function DetailsPagePdf({ detailsPageData, headerText }) {
  const pdfContent = { ...detailsPageData }
  const { myDetails, dataPartner, childrenData } = pdfContent;
  delete myDetails.__typename
  delete dataPartner.__typename
  // delete childrenData.__typename
  const { countChildren, haveChildren, childrens } = childrenData;

  return (
    <Page
      size={{
        width: 595.28
      }}
      style={styles.body}
    >
      <View>
        <View style={styles.header}>
          <Text style={styles.headingText}>
            {i18next.t(headerText)}
          </Text>
        </View>
        <View><Text style={styles.subtitle}>{i18next.t('My details')}</Text></View>
        <View style={styles.viewRowContainer}>
          {Object.entries(myDetails)?.map((content) => {
            const [key, value] = content;
            return (
              <View
                key={`myDetails_${key}`}
                style={styles.viewRow}>
                <View style={{ width: "50%", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(DETAILS_PAGE_LABELS[key])}
                  </Text>
                </View>
                <View style={styles.viewRowInput}>
                  <Text>{key === "birthDate" ? formatDate(value) : value}</Text>
                </View>
              </View>
            )
          })}
        </View>
        <View><Text style={styles.subtitle}>{i18next.t('Data partner')}</Text></View>
        <View style={styles.viewRowContainer}>
          {Object.entries(dataPartner)?.map((content) => {
            const [key, value] = content;
            return (
              <View
                key={`dataPartner_${key}`}
                style={styles.viewRow}>
                <View style={{ width: "50%", fontSize: 10 }}>
                  <Text style={styles.label}>
                    {i18next.t(DETAILS_PAGE_LABELS[key])}
                  </Text>
                </View>
                <View style={styles.viewRowInput}>
                  <Text>{key === "birthDate" ? formatDate(value) : value}</Text>
                </View>
              </View>
            )
          })}
        </View>

        <View><Text style={styles.subtitle}>{i18next.t("Children's data")}</Text></View>
        <View style={styles.viewRowContainer}>
          <View
            style={styles.viewRow}>
            <View style={{ width: "50%", fontSize: 10 }}>
              <Text style={styles.label}>
                {i18next.t(DETAILS_PAGE_LABELS?.["haveChildren"])}
              </Text>
            </View>
            <View style={styles.viewRowInput}>
              <Text>{haveChildren === true ? i18next.t("Yes") : i18next.t("No")}</Text>
            </View>
          </View>
          {haveChildren && (
            <View
              style={styles.viewRow}>
              <View style={{ width: "50%", fontSize: 10 }}>
                <Text style={styles.label}>
                  {i18next.t(DETAILS_PAGE_LABELS?.["countChildren"])}
                </Text>
              </View>
              <View style={styles.viewRowInput}>
                <Text>{countChildren}</Text>
              </View>
            </View>
          )}
          {haveChildren && (
            childrens?.map((children, index) => {
              delete children.__typename
              if (children?.childcareFor) {
                delete children?.childcareFor.__typename
              }
              return (
                <View key={`childrenData_${index}`}>
                  <View>
                    <Text style={styles.subtitle}>
                      {`${i18next.t('Children')} ${index + 1}`}
                    </Text>
                  </View>
                  <View style={styles.viewRow}>
                    <View style={{ width: "45%", fontSize: 10 }}>
                      <Text style={styles.label}>
                        {i18next.t(DETAILS_PAGE_LABELS['childName'])}
                      </Text>
                    </View>
                    <View style={{ width: "45%", fontSize: 10 }}>
                      <Text>{children.childName}</Text>
                    </View>
                  </View>
                  <View style={styles.viewRow}>
                    <View style={{ width: "45%", fontSize: 10 }}>
                      <Text style={styles.label}>
                        {i18next.t(DETAILS_PAGE_LABELS['birthDate'])}
                      </Text>
                    </View>
                    <View style={{ width: "45%", fontSize: 10 }}>
                      <Text>{formatDate(children.birthDate)}</Text>
                    </View>
                  </View>
                  {children?.birthDate && !isAdult(children.birthDate) && (
                    <View
                      style={styles.viewRow}
                    >
                      <View style={{ width: "45%", fontSize: 10 }}>
                        <Text style={styles.label}>
                          {i18next.t(DETAILS_PAGE_LABELS['needChildcare'])}
                        </Text>
                      </View>
                      <View style={{ width: "45%", fontSize: 10 }}>
                        <Text>{children.needChildcare ? i18next.t("Yes") : i18next.t("No")}</Text>
                      </View>
                    </View>
                  )}
                  {children?.needChildcare === true && (
                    <View
                      style={styles.viewRow}
                    >
                      <View style={{ width: "45%", fontSize: 10 }}>
                        <Text style={styles.label}>
                          {i18next.t(DETAILS_PAGE_LABELS['organized'])}
                        </Text>
                      </View>
                      <View style={{ width: "45%", fontSize: 10 }}>
                        <Text>{children.organized ? i18next.t("Yes") : i18next.t("No")}</Text>
                      </View>
                    </View>

                  )}
                  {children?.organized === false && (
                    <View
                      style={styles.viewRow}
                    >
                      <View style={{ width: "45%", fontSize: 10 }}>
                        <Text style={styles.label}>
                          {i18next.t(DETAILS_PAGE_LABELS['organizedDeadline'])}
                        </Text>
                      </View>
                      <View style={{ width: "45%", fontSize: 10 }}>
                        <Text>{formatDate(children.organizedDeadline)}</Text>
                      </View>
                    </View>
                  )}
                  {children.needChildcare === false && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        padding: 10,
                        width: "90%",
                        maxWidth: "90%"
                      }}
                      wrap
                    >
                      <View
                        style={{
                          width: "100%",
                          fontSize: 10,
                          margin: 20,
                          }}
                          wrap
                        >
                        <Text>{`${children.comments}`.split('')}</Text>
                      </View>
                    </View>
                  )}
                  {!isAdult(children?.birthDate) && children?.organized === true && (
                    <View>
                      <Text style={styles.subtitle}>
                        {`${i18next.t('Childcare for')} ${children.childName}`}
                      </Text>
                      <View style={styles.viewRowContainer}>
                        {Object.entries(children.childcareFor)?.map((content) => {
                          const [key, value] = content;
                          return (
                            <View
                              key={`myDetails_${key}`}
                              style={styles.viewRow}>
                              <View style={{ width: "50%", fontSize: 10 }}>
                                <Text style={styles.label}>
                                  {i18next.t(DETAILS_PAGE_LABELS[key])}
                                </Text>
                              </View>
                              <View style={styles.viewRowInput}>
                                <Text>{key === "childcareStartDate" ? formatDate(value) : value}</Text>
                              </View>
                            </View>
                          )
                        })}
                      </View>
                    </View>
                  )}
                </View>
              )
            })
          )}
        </View>

      </View>
    </Page>
  );
};

export default DetailsPagePdf;

