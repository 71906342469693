import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import LimitWarningDialog from "./sub_components/LimitWarningDialog";
import MunicipalitiesData from "./sub_components/AssignMunicipalityToRefugeeMunicipalitiesData";
import AssignedMunicipalitiesData from "./sub_components/AssignedMunicipalityToRefugeeMunicipalitiesData";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

function AssignMunicipalityToRefugee(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [unSelectedMunicipalities, setUnSelectedMunicipalities] = useState([]);
  const [unSelectedMentors, setUnSelectedMentors] = useState([]);
  const [unSelectedSuppliers, setUnSelectedSuppliers] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [userState, setUserState] = useState(1)
  const assignedMunicipalities = useSelector(
    ({ AssignMunicipalityToRefugeeReducer }) =>
      AssignMunicipalityToRefugeeReducer?.AllAssignedMunicipalitiesReducer?.data
  );
  const loading = useSelector(
    ({ AssignMunicipalityToRefugeeReducer }) =>
      AssignMunicipalityToRefugeeReducer?.AllAssignedMunicipalitiesReducer
        ?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMunicipalityToRefugeeReducer }) =>
      AssignMunicipalityToRefugeeReducer?.AllAssignedMunicipalitiesReducer
        ?.errMsg
  );
  const add_confirmation = useSelector(
    ({ AssignMunicipalityToRefugeeReducer }) =>
      AssignMunicipalityToRefugeeReducer?.assignUnassignRolesToRefugeeReducer
  );

  useEffect(() => {
    dispatch(
      Actions.getAllAssignedMunicipalities(
        page + 1,
        10000,
        "",
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
    return () => {
      dispatch(Actions.resetAssignedMunicipality(true));
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
    }
  }, [setSelectedMunicipalities]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedMunicipalities")) {
      setUnSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("unAssignedMunicipalities"))
      );
    }
  }, [setUnSelectedMunicipalities]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedMentors")) {
      setUnSelectedMentors(
        JSON.parse(sessionStorage.getItem("unAssignedMentors"))
      );
    }
  }, [setUnSelectedMentors]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedSuppliers")) {
      setUnSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("unAssignedSuppliers"))
      );
    }
  }, [setUnSelectedSuppliers]);

  const fetchMoreData = () => {
    dispatch(
      Actions.getAllAssignedMunicipalities(
        page + 1,
        10000,
        "",
        "",
        "",
        sessionStorage.getItem("user_id")
      )
    );
  };

  useEffect(() => {
    if (
      assignedMunicipalities?.listAssignedMunicipalities?.docs &&
      assignedMunicipalities.listAssignedMunicipalities.docs.length
    ) {
      setPage(page + 1);
      setTotalDocs(assignedMunicipalities.listAssignedMunicipalities.totalDocs);
      setHasNextPage(
        assignedMunicipalities.listAssignedMunicipalities.hasNextPage
      );
      if (!selectedMunicipalities.length)
        setSelectedMunicipalities(
          assignedMunicipalities.listAssignedMunicipalities.docs
        );
        
       
    }
  }, [assignedMunicipalities]);

  const createMunicipality = () => {
    const values = {
      refugee_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      amentorIds: [],
      asupplierIds: [],
      umunicipalityIds: [],
      umentorIds: [],
      usupplierIds: [],
    };
    if (selectedMunicipalities.length > 0) {
      for (let i = 0; i < selectedMunicipalities.length; i++) {
        const element = selectedMunicipalities[i];
        values.amunicipalityIds.push(element?.id);
      }
    }
    if (selectedMentors.length > 0) {
      for (let i = 0; i < selectedMentors.length; i++) {
        const element = selectedMentors[i];
        values.amentorIds.push(element?.id);
      }
    }
    if (selectedSuppliers.length > 0) {
      for (let i = 0; i < selectedSuppliers.length; i++) {
        const element = selectedSuppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (unSelectedMunicipalities.length > 0) {
      for (let i = 0; i < unSelectedMunicipalities.length; i++) {
        const element = unSelectedMunicipalities[i];
        values.umunicipalityIds.push(element?.id);
      }
    }
    if (unSelectedMentors.length > 0) {
      for (let i = 0; i < unSelectedMentors.length; i++) {
        const element = unSelectedMentors[i];
        values.umentorIds.push(element?.id);
      }
    }
    if (unSelectedSuppliers.length > 0) {
      for (let i = 0; i < unSelectedSuppliers.length; i++) {
        const element = unSelectedSuppliers[i];
        values.usupplierIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToRefugee(values));
  };

  useEffect(() => {
    if (add_confirmation?.data?.data?.assignUnassignRolesToRefugee) {
      
      sessionStorage.setItem("user_type", "municipality");
      
      
    }
  }, [add_confirmation]);

  const unAssignMunicipality = (data) => {
    setSelectedMunicipalities(
      selectedMunicipalities.filter((item) => item?.id !== data?.id)
    );
  };

  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };

  const handleClickLeaveScreenDialogopen = () => {
    if (!selectedMunicipalities.length) setWarningOpen(true);
    else setLeaveScreenDialog(true);
  };

  const handleClickWarningDialogclose = () => {
    setWarningOpen(false);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };
  
  useEffect(() => {
    
    if(props?.isEdit === "true"){
      const Municipalitie = sessionStorage.getItem("assignedMunicipalities")
      if(Municipalitie){
        setSelectedMunicipalities(Municipalitie)
        }
    }
  },[])
  return (
   
   
     
        <Container fixed>
          <Grid container spacing={4} className={classes.con}>
            <Grid item sm={12} md={7} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                 
                  
                </div>
                <MunicipalitiesData
                  searchText={searchText}
                  selectedMunicipalities={selectedMunicipalities}
                  setSelectedMunicipalities={setSelectedMunicipalities}
                  setState={props.setState}
                  setUserState={setUserState}
                  setChangeMunicipality={props.setChangeMunicipality}
                  disabled={props?.disabled}
                  isEdit={props?.isEdit}
                />
              </div>
            </Grid>
            <Grid item sm={12} md={5} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                 
                </div>
                <AssignedMunicipalitiesData
                  selectedMunicipalities={selectedMunicipalities}
                  unAssignMunicipality={unAssignMunicipality}
                  totalDocs={totalDocs}
                  hasNextPage={hasNextPage}
                  fetchMoreData={fetchMoreData}
                  isButtonDisabled={loading}
                  userState={userState}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      
  );
}
export default withReducer(
  "AssignMunicipalityToRefugeeReducer",
  reducer
)(AssignMunicipalityToRefugee);
