import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MentorsData from "./sub_components/AssignMentorToMunicipalityMentorsData";
import AssignedMentorsData from "./sub_components/AssignedMentorToMunicipalityMentorsData";
import LeaveScreenDialog from "./sub_components/LeaveScreenDialog";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

const useStyles = makeStyles((theme) => ({
  con: {
    marginTop: "30px",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "42px",
    backgroundColor: "#ffffff",
  },
  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "0px",
  },
  input1: {
    height: "21px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  createButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
}));

const AssignMentorToMunicipality = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [assignedMentorsState, setAssignedMentorsState] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedRefugees, setSelectedRefugees] = useState([]);
  const [leaveScreenDialogOpen, setLeaveScreenDialog] = useState(false);

  const assignedMentors = useSelector(
    ({ AssignMentorToMunicipalityReducer }) =>
      AssignMentorToMunicipalityReducer?.AllAssignedMentorsReducer?.data
  );
  const loading = useSelector(
    ({ AssignMentorToMunicipalityReducer }) =>
      AssignMentorToMunicipalityReducer?.AllAssignedMentorsReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ AssignMentorToMunicipalityReducer }) =>
      AssignMentorToMunicipalityReducer?.AllAssignedMentorsReducer?.errMsg
  );
  const add_confirmation = useSelector(
    ({ AssignMentorToMunicipalityReducer }) =>
      AssignMentorToMunicipalityReducer?.assignUnassignRolesToMunicipalityReducer
  );

  useEffect(() => {
    
    dispatch(
      Actions.getAllAssignedMentors(
        page + 1,
        rowsPerPage,
        sessionStorage.getItem("user_id")
      )
    );
    return () => {
      dispatch(Actions.resetAllAssignedMentors(true));
      dispatch(Actions.resetAssignUnassignRolesToMunicipality(true));
    };
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedRefugees")) {
      setSelectedRefugees(
        JSON.parse(sessionStorage.getItem("assignedRefugees"))
      );
    }
  }, [setSelectedRefugees]);

  const fetchMoreData = () => {
    dispatch(
      Actions.getAllAssignedMentors(
        page + 1,
        rowsPerPage,
        sessionStorage.getItem("user_id")
      )
    );
  };
  
  useEffect(() => {
    if (
      assignedMentors?.listAssignedMentors?.docs &&
      assignedMentors?.listAssignedMentors?.docs.length
    ) {
      setPage(page + 1);
      setTotalDocs(assignedMentors.listAssignedMentors.totalDocs);
      setHasNextPage(assignedMentors.listAssignedMentors.hasNextPage);
      setAssignedMentorsState((assignedMentorsState) => [
        ...assignedMentorsState,
        ...assignedMentors.listAssignedMentors.docs,
      ]);
    }
  }, [assignedMentors]);

  const createMentor = () => {
    const values = {
      municipality_id: sessionStorage.getItem("user_id"),
      amentorIds: [],
      asupplierIds: [],
      arefugeeIds: [],
      umentorIds: [],
      usupplierIds: [],
      urefugeeIds: [],
    };
    if (selectedMentors.length > 0) {
      for (let i = 0; i < selectedMentors.length; i++) {
        const element = selectedMentors[i];
        values.amentorIds.push(element?.id);
      }
    }
    if (selectedSuppliers.length > 0) {
      for (let i = 0; i < selectedSuppliers.length; i++) {
        const element = selectedSuppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (selectedRefugees.length > 0) {
      for (let i = 0; i < selectedRefugees.length; i++) {
        const element = selectedRefugees[i];
        values.arefugeeIds.push(element?.id);
      }
    }
    dispatch(Actions.assignUnassignRolesToMunicipality(values));
  };

  useEffect(() => {
    if (add_confirmation?.data?.data?.assignRolesToMunicipality) {
      // sessionStorage.clear();
      // sessionStorage.setItem("user_type", "mentor");
      // setLeaveScreenDialog(false);
      // navigate("/createUserByAdmin");
    }
  }, [add_confirmation]);

  const unAssignMentor = (data) => {
    setSelectedMentors(
      selectedMentors.filter(
        (selectedMentor) => selectedMentor?.id !== data?.id
      )
    );
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleClickLeaveScreenDialogclose = () => {
    setLeaveScreenDialog(false);
  };

  const handleClickLeaveScreenDialogopen = () => {
    setLeaveScreenDialog(true);
  };

  return (
    <div className="main">
      {leaveScreenDialogOpen === true && (
        <LeaveScreenDialog
          status={leaveScreenDialogOpen}
          methodd={handleClickLeaveScreenDialogclose}
          role="Municipality"
          continueClick={createMentor}
          loading={add_confirmation.isLoading}
          errMsg={add_confirmation.errMsg}
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <Grid container spacing={4} className={classes.con}>
            <Grid item md={7} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-left-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  <div className="assign-mentor-to-municipality-title-div">
                    <h3 className="assign-mentor-to-municipality-title">
                      {t("Choose Mentors from the list")}
                    </h3>
                  </div>
                  <Grid container spacing={4}>
                    <Grid item sm={8}>
                      <div className="assign-mentor-to-municipality-search-div">
                        <div className="assign-mentor-to-municipality-search-div-search-field-wrapper">
                          <TextField
                            id="search"
                            onChange={(e) => handleChangeSearch(e.target.value)}
                            value={searchText}
                            name="search"
                            className={classes.textFieldSearch}
                            type="text"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ClearIcon
                                    style={{
                                      fontSize: "24px",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleClearSearch()}
                                  />
                                </InputAdornment>
                              ),
                              classes: {
                                notchedOutline: classes.notchedOutline,
                                input: classes.input1,
                              },
                            }}
                            placeholder={t("Search by name or BSN number")}
                            margin="normal"
                            variant="outlined"
                            size="small"
                          />
                        </div>
                        <div className="assign-mentor-to-municipality-search-div-search-btn-wrapper">
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "0 auto",
                              marginTop: "5px",
                            }}
                          >
                            <SearchIcon
                              style={{
                                color: "white",
                                fontSize: "30px",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={4}>
                      <div className="assign-mentor-to-municipality-btn-div">
                        <Button
                          variant="contained"
                          className={classes.createButton}
                          color="primary"
                          onClick={() => handleClickLeaveScreenDialogopen()}
                        >
                          {t("Create New")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <MentorsData
                  searchText={searchText}
                  selectedMentors={selectedMentors}
                  setSelectedMentors={setSelectedMentors}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <div className="assign-mentor-to-municipality-right-grid-div">
                <div className="assign-mentor-to-municipality-left-grid-child-div">
                  <div className="assign-mentor-to-municipality-title-div">
                    <h3 className="assign-mentor-to-municipality-title">
                      {t("Municipality")}:{" "}
                      {sessionStorage.getItem("entity_name")}
                    </h3>
                    <p className="assigned-mentors-to-municipality-total-text">
                      {t("Total Assigned Mentors")}:{" "}
                      {selectedMentors.length + totalDocs}
                    </p>
                  </div>
                </div>
                <AssignedMentorsData
                  selectedMentors={selectedMentors}
                  unAssignMentor={unAssignMentor}
                  assignedMentorsState={assignedMentorsState}
                  totalDocs={totalDocs}
                  hasNextPage={hasNextPage}
                  fetchMoreData={fetchMoreData}
                  isButtonDisabled={loading}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default withReducer(
  "AssignMentorToMunicipalityReducer",
  reducer
)(AssignMentorToMunicipality);
