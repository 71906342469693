import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "130px",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "130px",
  },
  tickimage: {
    width: "9px",
    height: "9px",
  },
  footimage: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    // height: "",
  },
  mainView: {
    width: "100%",
    top: 127,
  },
  mainViewSecond: {
    width: "100%",
    top: 85,
  },
  headingView: {
    width: "100%",
    marginTop: "20px",
    textAlign: "center",
  },
  headingText: {
    fontSize: 20,
    color: "#253c8c",
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  labelText: {
    fontSize: 12,
    color: "#253c8c",
    fontWeight: "bold",
  },
  label: {
    fontSize: 10,
    color: "#253c8c",
  },
  labelsign: {
    fontSize: 10,
    color: "#253c8c",
  },
  lableWrap: {
    height: 14,
    width: "100%",
    marginTop: "2px",
  },
  dateInput: {
    backgroundColor: "#daeff0",
    height: 12,
    width: 65,
    marginLeft: "7px",
    display: "flex",
    textAlign: "center",
  },
  input: {
    backgroundColor: "#daeff0",
    height: 14,
    width: "100%",
    marginTop: "2px",
    padding: 1,
  },
  inputlast: {
    backgroundColor: "#daeff0",
    height: 14,
    width: "50%",
    marginTop: "2px",
    padding: 1,
  },
  inputnexttocheck: {
    backgroundColor: "#daeff0",
    height: 14,
    width: "80%",
    marginLeft: 10,
  },
  inputlargefirst: {
    backgroundColor: "#daeff0",
    height: 80,
    width: "100%",
    marginTop: "2px",
    padding: 2,
  },
  inputlargesecond: {
    backgroundColor: "#daeff0",
    height: 130,
    width: "100%",
    marginTop: "2px",
    padding: 2,
  },
  inputlargethird: {
    backgroundColor: "#daeff0",
    height: 210,
    width: "100%",
    // marginTop:"2px",
    padding: 2,
  },
  inputlargelast: {
    backgroundColor: "#daeff0",
    height: 55,
    width: "50%",
    marginTop: "2px",
    padding: 2,
  },
  checkwrap: {
    width: "100%",
    marginTop: "2px",
    display: "flex",
    flexDirection: "row",
    height: 14,
  },
  check: {
    backgroundColor: "white",
    height: 10,
    width: "3%",
    //  marginTop:"2px",
    border: 1,
  },
  circle: {
    backgroundColor: "#253c8c",
    height: 6,
    width: 6,
    //  marginTop:"2px",
    //  border:1,
    borderRadius: 50,
  },
  circlewrap: {
    marginTop: 6,
    height: 10,
    // backgroundColor:"black",
    paddingTop: 3,
  },
  circletextwrap: {
    marginTop: 6,
    minHeight: 10,
    height: "auto",
    // backgroundColor:"red"
  },

  contentView: {
    // backgroundColor: "grey",
    marginTop: "50px",
    marginLeft: "67px",

    width: "78%",
    backgroundColor: "#253c8c"
    // height: "100px",
  },
  formHead: { backgroundColor: "#253c8c", marginTop: "12px", padding: 3 },
  formHeadText: {
    fontSize: "10",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  formLeft: {
    display: "flex",
    //  backgroundColor: "pink",
    width: "30%",
  },
  formLeftRules: {
    display: "flex",
    // backgroundColor: "pink",
    width: "3%",
  },
  formRight: {
    display: "flex",
    //  backgroundColor: "yellow",
    width: "70%",
  },
  formRightRules: {
    display: "flex",
    //  backgroundColor: "yellow",
    width: "97%",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 16,
    margin: 12,
    color: "#253c8c",
  },
  subBodyTitle: {
    fontSize: 12,
    margin: 12,
    color: "#253c8c",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    display: "flex",
    textAlign: "center",
    paddingBottom: 20
  },
  viewRowContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 20
  },
  viewRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 5
  },
  viewRowInput: {
    width: "50%",
    fontSize: 10,
  }
});

export default styles;
