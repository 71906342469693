import React from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";

function Appointment({ children, style, ...restProps }) {
    console.log("children", children)
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        borderRadius: "8px",
      }}
    >
      {children}
    </Appointments.Appointment>
  );
}

export default Appointment;
