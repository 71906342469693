import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import DetailsForm from "./DetailsForm";

function DetailsPage({ data, setPipPlanPage }) {
  const [t] = useTranslation();

  return (
    <Grid
      container
      direction="column"
    >
      <Grid
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          display: "flex",
          minWidth: "100%",
          color: "#253c8c",
        }}
      >
        <h1 style={{marginRight: "100px"}}>{t("My details")}</h1>
      </Grid>
      <Grid
        container
        item
        style={{
          minWidth: "100%",
        }}
      >
        {<DetailsForm data={data} setPipPlanPage={setPipPlanPage}/>}
      </Grid>
    </Grid>
  );
};

export default DetailsPage;
