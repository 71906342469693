import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from 'formik';
import { getBooleanValue, removeKey } from "../../../../utils/helpers";
import { pipTrainingAndDigitalSkillsSchema } from "../../../../resources/scheme";
import { createOrUpdateDraftPipPlan, getPipPlanByRefugee } from '../../store/actions';
import { TRANSPORTS_TYPES, pipPlanNavigationKeys, TYPE_OF_DRIVING_LICENSE } from "../../../../resources/constants";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#253c8c",
    textAlign: "start",
  },
  subTitle: {
    fontSize: "10px",
    color: "#253c8c"
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "fit-content"
  },
  marginTopAndBottom: {
    margin: "10px 0"
  },
  buttonStyle: {
    mt: 3, mb: 2, width: "auto", marginBottom: "20px",
    height: "40px",
    fontSize: "16px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454a92",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#454a92",
      opacity: 0.9
    }
  }
}));

function TrainingAndDigitalSkillsForm({ data, setPipPlanPage }) {
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState("")

  const classes = useStyles();

  const params = useParams();
  const { refugeeId } = params;

  const sendTrainingAndDigitalSkillsCallback = useCallback(
    (values, helpers) => {
      if (refugeeId) {
        helpers.setSubmitting(true);
        const queryString = JSON
          .stringify(removeKey(values, '__typename'), null, 2)
          .replace(/"([^"]+)":/g, '$1:');
        const trainingAndDigitalSkillQuery = `trainingAndDigitalSkills: ${queryString}`;
        createOrUpdateDraftPipPlan({ refugeeId, section: trainingAndDigitalSkillQuery })
          .then(() => {
            dispatch(getPipPlanByRefugee(refugeeId))

            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.healthAndLeisure)
            } else {
              setPipPlanPage(pipPlanNavigationKeys.workAndActivities)
            }
          })
          .finally(() => {
            helpers.setSubmitting(false);
            if (selectedPage === "saveAndPrevious") {
              setPipPlanPage(pipPlanNavigationKeys.healthAndLeisure)
            } else {
              setPipPlanPage(pipPlanNavigationKeys.workAndActivities)
            }
          })
      }
    },
    [refugeeId, selectedPage],
  );

  const formik = useFormik({
    onSubmit: sendTrainingAndDigitalSkillsCallback,
    initialValues: {
      course: {
        schoolInMyCountry: {
          answer: data?.course?.schoolInMyCountry?.answer ?? null,
          comment: data?.course?.schoolInMyCountry?.comment ?? null
        },
        totalNumberOfYearEducation: data?.course?.totalNumberOfYearEducation ?? null,
        nameOfHighestLevelOfEducation: data?.course?.nameOfHighestLevelOfEducation ?? null,
        haveHighestEducationDiploma: {
          answer: data?.course?.haveHighestEducationDiploma?.answer ?? null,
          comment: data?.course?.haveHighestEducationDiploma?.comment ?? null
        },
        attendedISK: {
          answer: data?.course?.attendedISK?.answer ?? null,
          comment: data?.course?.attendedISK?.comment ?? null
        },
        haveOneOrMoreCertificates: {
          answer: data?.course?.haveOneOrMoreCertificates?.answer ?? null,
          comment: data?.course?.haveOneOrMoreCertificates?.comment ?? null
        },
        wouldLikeToFollowACourse: {
          answer: data?.course?.wouldLikeToFollowACourse?.answer ?? null,
          comment: data?.course?.wouldLikeToFollowACourse?.comment ?? null
        },
      },
      digitalSkills: {
        haveComputerOrLaptopAtHome: data?.digitalSkills?.haveComputerOrLaptopAtHome ?? null,
        doEverythingOnPhone: data?.digitalSkills?.doEverythingOnPhone ?? null,
        canLookUpInformationOnInternet: data?.digitalSkills?.canLookUpInformationOnInternet ?? null,
        canSendAnEmail: data?.digitalSkills?.canSendAnEmail ?? null,
        canSaveDownloadFiles: data?.digitalSkills?.canSaveDownloadFiles ?? null,
        useFacebookTwitterInstagram: data?.digitalSkills?.useFacebookTwitterInstagram ?? null,
        canPayOnline: data?.digitalSkills?.canPayOnline ?? null,
        useWhatsApp: data?.digitalSkills?.useWhatsApp ?? null,
        canUseWord: data?.digitalSkills?.canUseWord ?? null,
      },
      transport: {
        transportType: data?.transport?.transportType ?? [],
        almostWalking: data?.transport?.almostWalking ?? null,
        haveNnOVChipCard: data?.transport?.haveNnOVChipCard ?? null,
        canCycle: data?.transport?.canCycle ?? null,
        wantToLearnCycle: data?.transport?.wantToLearnCycle ?? null,
        commentForBicycleQuestion: data?.transport?.commentForBicycleQuestion ?? null,
        travelByBusOrTrain: data?.transport?.travelByBusOrTrain ?? null,
        wouldLikeAnExplanation: data?.transport?.wouldLikeAnExplanation ?? null,
        haveDrivingLicense: data?.transport?.haveDrivingLicense ?? null,
        typeOfDrivingLicense: data?.transport?.typeOfDrivingLicense ?? null,
        busyWith: data?.transport?.busyWith ?? null,
      },
    },
    enableReinitialize: true,
    validationSchema: pipTrainingAndDigitalSkillsSchema,
  });

  const onRadioChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if(name === "transport.travelByBusOrTrain" && booleanValue) {
      formik.setFieldValue("transport.wouldLikeAnExplanation", null)
    }
    formik.setFieldValue(name, booleanValue)
  }

  const onTransportTypeChange = (event) => {
    const { name, checked } = event.target;
    const formikTransportType = formik.values.transport.transportType;
    const tempTransportType = [...formikTransportType];
    if (tempTransportType?.includes(name) && !checked) {
      formik.setFieldValue(
        "transport.transportType",
        tempTransportType.filter((transportType) => transportType !== name)
      )
    } else {
      tempTransportType.push(name)
      formik.setFieldValue("transport.transportType", tempTransportType)
    }
  }

  const onCanCycleChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = getBooleanValue(value);
    if (booleanValue) {
      formik.setFieldValue("transport.wantToLearnCycle", null)
    }
    formik.setFieldValue(name, booleanValue)
  }

  const onDrivingLicenseChange = (event) => {
    {
      const { name, value } = event.target;
      const booleanValue = getBooleanValue(value);
      if (booleanValue) {
        formik.setFieldValue("transport.busyWith", null)
      } else {
        formik.setFieldValue("transport.typeOfDrivingLicense", null)
      }

      formik.setFieldValue(name, booleanValue)
    }
  }

  return (
    <Grid container>
      <form
        noValidate
        style={{ width: "100%" }}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container item
        >
          <Grid
            style={{ padding: "0 60px" }}
            container
            item
            display="flex"
            direction="row"
          >
            <Grid container>
              <Typography variant="h2" className={classes.title}>{t('Course')}</Typography>
            </Grid>
            <Box mt={4} mb={4}>
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={
                  Boolean(formik.touched?.course?.schoolInMyCountry?.answer) &&
                  Boolean(formik.errors?.course?.schoolInMyCountry?.answer)
                }
              >
                <FormLabel component="legend">{t("I went to school in my country of origin")}</FormLabel>
                <RadioGroup
                  aria-label="I went to school in my country of origin"
                  name="course.schoolInMyCountry.answer"
                  value={`${formik.values.course.schoolInMyCountry?.answer}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.course?.schoolInMyCountry?.answer) &&
                  Boolean(formik.errors?.course?.schoolInMyCountry?.answer) && (
                    <FormLabel component="legend">
                      {t(formik.errors?.course?.schoolInMyCountry?.answer)}
                    </FormLabel>
                  )}
              </FormControl>

              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="course.schoolInMyCountry.comment"
                error={
                  Boolean(formik.touched?.course?.schoolInMyCountry?.comment) &&
                  Boolean(formik.errors?.course?.schoolInMyCountry?.comment)
                }
                helperText={
                  Boolean(formik.errors?.course?.schoolInMyCountry?.comment) &&
                  t(formik.errors?.course?.schoolInMyCountry?.comment)
                }
                onBlur={formik.handleBlur}
                value={formik.values?.course?.schoolInMyCountry?.comment}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />
            </Box>

            <Grid item xs={12} container>
              <FormControl
                className={classes.margin}
                fullWidth
                style={{
                  marginBottom: "20px",
                  maxWidth: "300px"
                }}
                label={t('The total number of years that I have attended education is')}
                error={
                  Boolean(formik.touched.course?.totalNumberOfYearEducation) &&
                  Boolean(formik.errors.course?.totalNumberOfYearEducation)
                }
              >
                <FormLabel component="legend" style={{ width: 'max-content', marginBottom: "6px" }}>
                  {t('The total number of years that I have attended education is')}
                </FormLabel>
                <Select
                  name='course.totalNumberOfYearEducation'
                  labelId="course.totalNumberOfYearEducation"
                  id="demo-customized-select"
                  value={formik.values.course?.totalNumberOfYearEducation}
                  onChange={formik.handleChange}
                  selected={formik.values.course?.totalNumberOfYearEducation}
                >
                  {Array(30).fill().map((_, index) => (
                    <MenuItem
                      value={index + 1}
                      key={`totalNumberOfYearEducation_${index + 1}`}
                    >
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik.touched?.course?.totalNumberOfYearEducation) &&
                  Boolean(formik.errors?.course?.totalNumberOfYearEducation) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.course?.totalNumberOfYearEducation)}
                    </FormLabel>
                  )}
              </FormControl>
            </Grid>

            <TextField
              minRows={3}
              maxRows={6}
              required
              style={{ marginBottom: "20px", maxWidth: "400px" }}
              fullWidth
              name="course.nameOfHighestLevelOfEducation"
              label={t("The name of my highest level of education is")}
              onBlur={formik.handleBlur}
              value={formik.values.course.nameOfHighestLevelOfEducation}
              disabled={formik.isSubmitting}
              InputLabelProps={formik.values.course.nameOfHighestLevelOfEducation ? { shrink: true } : {}}
              onChange={formik.handleChange}
              helperText={
                Boolean(formik.touched?.course?.nameOfHighestLevelOfEducation) &&
                t(formik?.errors?.course?.nameOfHighestLevelOfEducation)
              }
              error={
                Boolean(formik.touched?.course?.nameOfHighestLevelOfEducation) &&
                Boolean(formik.errors?.course?.nameOfHighestLevelOfEducation)
              }
            />

            <Box mt={4} mb={4}>
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={
                  Boolean(formik.touched.course?.haveHighestEducationDiploma?.answer) &&
                  Boolean(formik.errors.course?.haveHighestEducationDiploma?.answer)
                }
              >
                <FormLabel component="legend">{t("I have completed my highest education with a diploma")}</FormLabel>
                <RadioGroup
                  aria-label="I have completed my highest education with a diploma"
                  name="course.haveHighestEducationDiploma.answer"
                  value={`${formik.values.course.haveHighestEducationDiploma?.answer}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.course?.haveHighestEducationDiploma?.answer) &&
                  Boolean(formik.errors?.course?.haveHighestEducationDiploma?.answer) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.course?.haveHighestEducationDiploma?.answer)}
                    </FormLabel>
                  )}
              </FormControl>

              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="course.haveHighestEducationDiploma.comment"
                helperText={
                  Boolean(formik.touched?.course?.haveHighestEducationDiploma?.comment) &&
                  t(formik.errors?.course?.haveHighestEducationDiploma?.comment)
                }
                error={
                  Boolean(formik.touched?.course?.haveHighestEducationDiploma?.comment) &&
                  Boolean(formik.errors?.course?.haveHighestEducationDiploma?.comment)
                }
                onBlur={formik.handleBlur}
                value={formik.values?.course?.haveHighestEducationDiploma?.comment}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />
            </Box>


            <Box mt={4} mb={4}>
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={
                  Boolean(formik.touched.course?.attendedISK?.answer) &&
                  Boolean(formik.errors.course?.attendedISK?.answer)
                }
              >
                <FormLabel component="legend">{t("I attended ISK")}</FormLabel>
                <RadioGroup
                  aria-label="I attended ISK"
                  name="course.attendedISK.answer"
                  value={`${formik.values.course.attendedISK?.answer}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.course?.attendedISK?.answer) &&
                  Boolean(formik.errors?.course?.attendedISK?.answer) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.course?.attendedISK?.answer)}
                    </FormLabel>
                  )}
              </FormControl>

              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="course.attendedISK.comment"
                helperText={
                  Boolean(formik.touched?.course?.attendedISK?.comment) &&
                  t(formik.errors?.course?.attendedISK?.comment)
                }
                error={
                  Boolean(formik.touched?.course?.attendedISK?.comment) &&
                  Boolean(formik.errors?.course?.attendedISK?.comment)
                }
                onBlur={formik.handleBlur}
                value={formik.values?.course?.attendedISK?.comment}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />
            </Box>

            <Box mt={4} mb={4}>
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={
                  Boolean(formik.touched.course?.wouldLikeToFollowACourse?.answer) &&
                  Boolean(formik.errors.course?.wouldLikeToFollowACourse?.answer)
                }
              >
                <FormLabel component="legend">{t("I would like to follow a course in the future")}</FormLabel>
                <RadioGroup
                  aria-label="I would like to follow a course in the future"
                  name="course.wouldLikeToFollowACourse.answer"
                  value={`${formik.values.course.wouldLikeToFollowACourse?.answer}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.course?.wouldLikeToFollowACourse?.answer) &&
                  Boolean(formik.errors?.course?.wouldLikeToFollowACourse?.answer) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.course?.wouldLikeToFollowACourse?.answer)}
                    </FormLabel>
                  )}
              </FormControl>

              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="course.wouldLikeToFollowACourse.comment"
                helperText={
                  Boolean(formik.touched?.course?.wouldLikeToFollowACourse?.comment) &&
                  t(formik.errors?.course?.wouldLikeToFollowACourse?.comment)
                }
                error={
                  Boolean(formik.touched?.course?.wouldLikeToFollowACourse?.comment) &&
                  Boolean(formik.errors?.course?.wouldLikeToFollowACourse?.comment)
                }
                onBlur={formik.handleBlur}
                value={formik.values?.course?.wouldLikeToFollowACourse?.comment}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />
            </Box>

            <Box mt={4} mb={4}>
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={
                  Boolean(formik.touched.course?.haveOneOrMoreCertificates?.answer) &&
                  Boolean(formik.errors.course?.haveOneOrMoreCertificates?.answer)
                }
              >
                <FormLabel component="legend">{t("I have one or more certificates")}</FormLabel>
                <RadioGroup
                  aria-label="I have one or more certificates"
                  name="course.haveOneOrMoreCertificates.answer"
                  value={`${formik.values.course.haveOneOrMoreCertificates?.answer}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.course?.haveOneOrMoreCertificates?.answer) &&
                  Boolean(formik.errors?.course?.haveOneOrMoreCertificates?.answer) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.course?.haveOneOrMoreCertificates?.answer)}
                    </FormLabel>
                  )}
              </FormControl>

              <TextField
                required
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                name="course.haveOneOrMoreCertificates.comment"
                helperText={
                  Boolean(formik.touched?.course?.haveOneOrMoreCertificates?.comment) &&
                  t(formik.errors?.course?.haveOneOrMoreCertificates?.comment)
                }
                error={
                  Boolean(formik.touched?.course?.haveOneOrMoreCertificates?.comment) &&
                  Boolean(formik.errors?.course?.haveOneOrMoreCertificates?.comment)
                }
                onBlur={formik.handleBlur}
                value={formik.values?.course?.haveOneOrMoreCertificates?.comment}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
              />
            </Box>


            <Box mt={4} mb={4} style={{ width: "inherit" }}>
              <Typography variant="h2" className={classes.title}>{t('Digital skills')}</Typography>
            </Box>

            <Grid container xs={12}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.haveComputerOrLaptopAtHome) &&
                    Boolean(formik.errors.digitalSkills?.haveComputerOrLaptopAtHome)
                  }
                >
                  <FormLabel component="legend">{t("I have a computer/laptop at home")}</FormLabel>
                  <RadioGroup
                    aria-label="I have a computer/laptop at home"
                    name="digitalSkills.haveComputerOrLaptopAtHome"
                    value={`${formik.values.digitalSkills.haveComputerOrLaptopAtHome}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.haveComputerOrLaptopAtHome) &&
                    Boolean(formik.errors?.digitalSkills?.haveComputerOrLaptopAtHome) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.haveComputerOrLaptopAtHome)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.doEverythingOnPhone) &&
                    Boolean(formik.errors.digitalSkills?.doEverythingOnPhone)
                  }
                >
                  <FormLabel component="legend">{t("I do everything on my phone")}</FormLabel>
                  <RadioGroup
                    aria-label="I do everything on my phone"
                    name="digitalSkills.doEverythingOnPhone"
                    value={`${formik.values.digitalSkills.doEverythingOnPhone}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.doEverythingOnPhone) &&
                    Boolean(formik.errors?.digitalSkills?.doEverythingOnPhone) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.doEverythingOnPhone)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.canLookUpInformationOnInternet) &&
                    Boolean(formik.errors.digitalSkills?.canLookUpInformationOnInternet)
                  }
                >
                  <FormLabel component="legend">{t("I can look up information on the internet")}</FormLabel>
                  <RadioGroup
                    aria-label="I can look up information on the internet"
                    name="digitalSkills.canLookUpInformationOnInternet"
                    value={`${formik.values.digitalSkills.canLookUpInformationOnInternet}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.canLookUpInformationOnInternet) &&
                    Boolean(formik.errors?.digitalSkills?.canLookUpInformationOnInternet) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.canLookUpInformationOnInternet)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.canSendAnEmail) &&
                    Boolean(formik.errors.digitalSkills?.canSendAnEmail)
                  }
                >
                  <FormLabel component="legend">{t("I can send an email")}</FormLabel>
                  <RadioGroup
                    aria-label="I can send an email:"
                    name="digitalSkills.canSendAnEmail"
                    value={`${formik.values.digitalSkills.canSendAnEmail}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.canSendAnEmail) &&
                    Boolean(formik.errors?.digitalSkills?.canSendAnEmail) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.canSendAnEmail)}
                      </FormLabel>
                    )}
                </FormControl>

              </Grid>

              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.canSaveDownloadFiles) &&
                    Boolean(formik.errors.digitalSkills?.canSaveDownloadFiles)
                  }
                >
                  <FormLabel component="legend">{t("I can save/download files")}</FormLabel>
                  <RadioGroup
                    aria-label="I can save/download files"
                    name="digitalSkills.canSaveDownloadFiles"
                    value={`${formik.values.digitalSkills.canSaveDownloadFiles}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.canSaveDownloadFiles) &&
                    Boolean(formik.errors?.digitalSkills?.canSaveDownloadFiles) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.canSaveDownloadFiles)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.useFacebookTwitterInstagram) &&
                    Boolean(formik.errors.digitalSkills?.useFacebookTwitterInstagram)
                  }
                >
                  <FormLabel component="legend">{t("I use Facebook, Twitter, Instagram")}</FormLabel>
                  <RadioGroup
                    aria-label="I use Facebook, Twitter, Instagram"
                    name="digitalSkills.useFacebookTwitterInstagram"
                    value={`${formik.values.digitalSkills.useFacebookTwitterInstagram}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.useFacebookTwitterInstagram) &&
                    Boolean(formik.errors?.digitalSkills?.useFacebookTwitterInstagram) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.useFacebookTwitterInstagram)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  variant="primary"
                  error={
                    Boolean(formik.touched.digitalSkills?.canPayOnline) &&
                    Boolean(formik.errors.digitalSkills?.canPayOnline)
                  }
                >
                  <FormLabel component="legend">{t("I can pay online")}</FormLabel>
                  <RadioGroup
                    aria-label="I can pay online"
                    name="digitalSkills.canPayOnline"
                    value={`${formik.values.digitalSkills.canPayOnline}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.canPayOnline) &&
                    Boolean(formik.errors?.digitalSkills?.canPayOnline) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.canPayOnline)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.useWhatsApp) &&
                    Boolean(formik.errors.digitalSkills?.useWhatsApp)
                  }
                >
                  <FormLabel component="legend">{t("I use WhatsApp")}</FormLabel>
                  <RadioGroup
                    aria-label="I use WhatsApp"
                    name="digitalSkills.useWhatsApp"
                    value={`${formik.values.digitalSkills.useWhatsApp}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.useWhatsApp) &&
                    Boolean(formik.errors?.digitalSkills?.useWhatsApp) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.useWhatsApp)}
                      </FormLabel>
                    )}
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  error={
                    Boolean(formik.touched.digitalSkills?.canUseWord) &&
                    Boolean(formik.errors.digitalSkills?.canUseWord)
                  }
                >
                  <FormLabel component="legend">{t("I can use Word")}</FormLabel>
                  <RadioGroup
                    aria-label="I can use Word"
                    name="digitalSkills.canUseWord"
                    value={`${formik.values.digitalSkills.canUseWord}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.digitalSkills?.canUseWord) &&
                    Boolean(formik.errors?.digitalSkills?.canUseWord) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.digitalSkills?.canUseWord)}
                      </FormLabel>
                    )}
                </FormControl>
              </Grid>
            </Grid>


            <Box mt={4} mb={4} style={{ width: "inherit" }}>
              <Typography variant="h2" className={classes.title}>{t('Transport')}</Typography>
            </Box>

            <FormControl required component="fieldset">
              <FormLabel component="legend" style={{ marginTop: "6px" }}>
                {`${t('I have a')}:`}
              </FormLabel>
              <FormGroup>
                {TRANSPORTS_TYPES.map((transportType) => (
                  <FormControlLabel
                    key={transportType}
                    control={
                      <Checkbox
                        checked={
                          formik.values.transport.transportType?.includes(transportType)
                        }
                        color='primary'
                        onChange={onTransportTypeChange}
                        name={transportType}
                      />
                    }
                    label={t(transportType)}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl
              fullWidth
              className={classes.marginTopAndBottom}
              component="fieldset"
              error={
                Boolean(formik.touched.transport?.haveNnOVChipCard) &&
                Boolean(formik.errors.transport?.haveNnOVChipCard)
              }
            >
              <FormLabel component="legend">{t("I have an OV chip card")}</FormLabel>
              <RadioGroup
                aria-label="I have an OV chip card"
                name="transport.haveNnOVChipCard"
                value={`${formik.values.transport?.haveNnOVChipCard}`}
                onChange={onRadioChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.transport?.haveNnOVChipCard) &&
                Boolean(formik.errors?.transport?.haveNnOVChipCard) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.transport?.haveNnOVChipCard)}
                  </FormLabel>
                )}
            </FormControl>

            <FormControl
              fullWidth
              className={classes.marginTopAndBottom}
              component="fieldset"
              error={
                Boolean(formik.touched.transport?.almostWalking) &&
                Boolean(formik.errors.transport?.almostWalking)
              }
            >
              <FormLabel component="legend">{t("I do almost everything walking")}</FormLabel>
              <RadioGroup
                aria-label="I do almost everything walking"
                name="transport.almostWalking"
                value={`${formik.values.transport?.almostWalking}`}
                onChange={onRadioChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.transport?.almostWalking) &&
                Boolean(formik.errors?.transport?.almostWalking) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.transport?.almostWalking)}
                  </FormLabel>
                )}
            </FormControl>


            <FormControl
              className={classes.marginTopAndBottom}
              component="fieldset"
              fullWidth
              error={
                Boolean(formik.touched?.transport?.canCycle) &&
                Boolean(formik.errors?.transport?.canCycle)
              }
            >
              <FormLabel component="legend">{t("I can cycle")}</FormLabel>
              <RadioGroup
                aria-label="I can cycle"
                name="transport.canCycle"
                value={`${formik.values?.transport?.canCycle}`}
                onChange={onCanCycleChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.transport?.canCycle) &&
                Boolean(formik.errors?.transport?.canCycle) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.transport?.canCycle)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values.transport?.canCycle !== null && !formik.values.transport?.canCycle && (
              <Grid>
                <FormControl
                  className={classes.marginTopAndBottom}
                  component="fieldset"
                  fullWidth
                  error={Boolean(formik.touched?.transport?.wantToLearnCycle)
                    && Boolean(formik.errors?.transport?.wantToLearnCycle)
                  }
                >
                  <FormLabel component="legend">{t("I want to learn to cycle")}</FormLabel>
                  <RadioGroup
                    aria-label="I want to learn to cycle"
                    name="transport.wantToLearnCycle"
                    value={`${formik.values.transport?.wantToLearnCycle}`}
                    onChange={onRadioChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                  </RadioGroup>
                  {Boolean(formik.touched?.transport?.wantToLearnCycle) &&
                    Boolean(formik.errors?.transport?.wantToLearnCycle) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.transport?.wantToLearnCycle)}
                      </FormLabel>
                    )}
                </FormControl>
              </Grid>
            )}

            <TextField
              required
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              name="transport.commentForBicycleQuestion"
              label={t('Type here...')}
              helperText={
                Boolean(formik.touched?.transport?.commentForBicycleQuestion) &&
                t(formik.errors?.transport?.commentForBicycleQuestion)
              }
              error={Boolean(formik.touched?.transport?.commentForBicycleQuestion)
                && Boolean(formik.errors?.transport?.commentForBicycleQuestion)
              }
              onBlur={formik.handleBlur}
              value={formik.values?.transport?.commentForBicycleQuestion}
              InputLabelProps={formik.values.transport.commentForBicycleQuestion ? { shrink: true } : {}}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
            />
            <FormControl
              fullWidth
              className={classes.marginTopAndBottom}
              component="fieldset"
              error={Boolean(formik.touched?.transport?.travelByBusOrTrain)
                && Boolean(formik.errors?.transport?.travelByBusOrTrain)
              }
            >
              <FormLabel component="legend">{t("I travel by bus or train")}</FormLabel>
              <RadioGroup
                aria-label="I travel by bus or train"
                name="transport.travelByBusOrTrain"
                value={`${formik.values.transport?.travelByBusOrTrain}`}
                onChange={onRadioChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.transport?.travelByBusOrTrain) &&
                Boolean(formik.errors?.transport?.travelByBusOrTrain) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.transport?.travelByBusOrTrain)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values?.transport?.travelByBusOrTrain === false &&
              (<FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={Boolean(formik.touched?.transport?.wouldLikeAnExplanation)
                  && Boolean(formik.errors?.transport?.wouldLikeAnExplanation)
                }
              >
                <FormLabel component="legend">{t("I would like an explanation")}</FormLabel>
                <RadioGroup
                  aria-label="would like an explanation"
                  name="transport.wouldLikeAnExplanation"
                  value={`${formik.values.transport.wouldLikeAnExplanation}`}
                  onChange={onRadioChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
                </RadioGroup>
                {Boolean(formik.touched?.transport?.wouldLikeAnExplanation) &&
                  Boolean(formik.errors?.transport?.wouldLikeAnExplanation) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.transport?.wouldLikeAnExplanation)}
                    </FormLabel>
                  )}
              </FormControl>)}

            <FormControl
              fullWidth
              className={classes.marginTopAndBottom}
              component="fieldset"
              error={Boolean(formik.touched?.transport?.haveDrivingLicense)
                && Boolean(formik.errors?.transport?.haveDrivingLicense)
              }
            >
              <FormLabel component="legend">{t("I have a driver's license")}</FormLabel>
              <RadioGroup
                aria-label="I have a driver's license"
                name="transport.haveDrivingLicense"
                value={`${formik.values.transport?.haveDrivingLicense}`}
                onChange={onDrivingLicenseChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t("Yes")} />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t("No")} />
              </RadioGroup>
              {Boolean(formik.touched?.transport?.haveDrivingLicense) &&
                Boolean(formik.errors?.transport?.haveDrivingLicense) && (
                  <FormLabel component="legend" style={{ marginTop: "6px" }}>
                    {t(formik.errors?.transport?.haveDrivingLicense)}
                  </FormLabel>
                )}
            </FormControl>

            {formik.values.transport?.haveDrivingLicense ? (
              <Grid item xs={12} container>
                <FormControl
                  className={classes.margin}
                  fullWidth
                  style={{
                    marginBottom: "20px",
                    maxWidth: "300px"
                  }}
                  label={t('Type of driving license')}
                  error={
                    Boolean(formik.touched?.transport?.typeOfDrivingLicense) &&
                    Boolean(formik?.errors?.transport?.typeOfDrivingLicense)
                  }
                >
                  <FormLabel component="legend" style={{ width: 'max-content', marginBottom: "6px" }}>
                    {t('Type of driving license')}
                  </FormLabel>
                  <Select
                    name='transport.typeOfDrivingLicense'
                    labelId="transport?.typeOfDrivingLicense"
                    id="demo-customized-select"
                    value={formik.values.transport?.typeOfDrivingLicense}
                    onChange={formik.handleChange}
                    selected={formik.values.transport?.typeOfDrivingLicense}
                  >
                    {TYPE_OF_DRIVING_LICENSE.map((transportType) => (
                      <MenuItem
                        value={transportType}
                        key={transportType}
                      >
                        {t(`${transportType}`)}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.touched?.transport?.typeOfDrivingLicense) &&
                    Boolean(formik.errors?.transport?.typeOfDrivingLicense) && (
                      <FormLabel component="legend" style={{ marginTop: "6px" }}>
                        {t(formik.errors?.transport?.typeOfDrivingLicense)}
                      </FormLabel>
                    )}
                </FormControl>
              </Grid>
            ) : (
              <FormControl
                fullWidth
                className={classes.marginTopAndBottom}
                component="fieldset"
                error={Boolean(formik.touched?.transport?.busyWith)
                  && Boolean(formik.errors?.transport?.busyWith)
                }
              >
                <FormLabel component="legend">{t("I am busy with")}</FormLabel>
                <RadioGroup
                  aria-label="I am busy with"
                  name="transport.busyWith"
                  value={formik.values.transport?.busyWith}
                  onChange={formik.handleChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel value={"Theory"} control={<Radio color="primary" />} label={t("Theory")} />
                  <FormControlLabel value={"Practice"} control={<Radio color="primary" />} label={t("Practice")} />
                </RadioGroup>
                {Boolean(formik.touched?.transport?.busyWith) &&
                  Boolean(formik.errors?.transport?.busyWith) && (
                    <FormLabel component="legend" style={{ marginTop: "6px" }}>
                      {t(formik.errors?.transport?.busyWith)}
                    </FormLabel>
                  )}
              </FormControl>
            )}
          </Grid>
          <Grid
            item
            style={{
              marginTop: "20px",
              minWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 60px"
            }}
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              onClick={() => setSelectedPage("saveAndPrevious")}
            >
              {t("SAVE & PREVIOUS")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              isSubmitting={true}
              disabled={formik.isSubmitting}
              className={classes.buttonStyle}
              endIcon={formik.isSubmitting && (
                <CircularProgress size={18} color="inherit" />
              )}
            >
              {t("Save and Next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid >
  );
};

export default TrainingAndDigitalSkillsForm;