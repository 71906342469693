
import SystemSearchComponent from "../../../SearchComponent/index";








function SystemSearch() {
  
  const roles = [
    {
      id: "all",
      name: "All",
      default: false,
    },
    {
      id: "mentors",
      name: "Mentors",
      default: false,
    },
    {
      id: "suppliers",
      name: "Suppliers",
      default: false,
    },
    {
      id: "refugees",
      name: "Refugees",
      default: true,
    },
  ];
  
  const tabs = [
    {
      id: "mentors",
      name: "Mentors",
      default: false,
    },
    {
      id: "suppliers",
      name: "Suppliers",
      default: false,
    },
    {
      id: "refugees",
      name: "Refugees",
      default: true,
    },
  ];
  
  return(
    <SystemSearchComponent roles={roles} tabs={tabs} rang={"mentor"}/>
  )
}
export default SystemSearch;
