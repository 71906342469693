import { useState, useEffect } from "react";
import jwt from "jsonwebtoken";
import {
  Container,
  Button,
  makeStyles,
  TextField,
  InputAdornment,
  ListItemText,
  Typography,
  ListItem,
  ClickAwayListener,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
} from "@material-ui/icons";
import MunicipalitiesData from "./ComponentDialogData/MunicipalitiesData";
import ManagersData from "./ComponentDialogData/ManagersData";
import MentorsData from "./ComponentDialogData/MentorsData";
import SuppliersData from "./ComponentDialogData/SuppliersData";
import RefugeesData from "./ComponentDialogData/RefugeesData";
import { useLocation, BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MentorsDataMentor from "./ComponentDataMentor/MentorsDataMentor";
import RefugeesDataMentor from "./ComponentDataMentor/RefugeesDataMentor";
import SuppliersDataMentor from "./ComponentDataMentor/SuppliersDataMentor";
const useStyles = makeStyles((theme) => ({
  dropdownButton: {
    // margin: theme.spacing(1),
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    width: "157px",
    height: "40px",
    opacity: 1,
    color: "white",
    marginLeft: "15px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    textTransform: "capitalize",
    justifyContent: "space-between",
    boxShadow: "none",
    // paddingTop: "10px"
  },
  filterButton: {
    fontSize: "14px",
    fontFamily: "opensans-semibold",
    backgroundColor: "#454A92",
    height: "35px",
    color: "white",
    width: "140px",
    borderRadius: "0px",
    boxShadow: "none",
  },
  textFieldSearch: {
    width: "100%",
    margin: 0,
    height: "40px",
    backgroundColor: "#ffffff",
    borderRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  notchedOutline: {
    // borderWidth: "0px",
    borderColor: "black !important",
    borderRadius: "0px",
    borderLeft: 0,
  },
  input1: {
    // height: "19px",
    border: 0,
    "&::placeholder": {
      fontSize: "16px",
      color: "black",
      opacity: "1",
      fontFamily: "opensans-LightItalic",
    },
  },
  tablePagination: {
    overflow: "visible",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  roott: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    width: "155px",
    // top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    // padding: theme.spacing(1),
    marginLeft: "15px",
    border: "none",
    backgroundColor: "#E7E7E7",
    borderRadius: "0px 0px 5px 5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function SystemSearchComponent({ roles, tabs, rang }) {
  const [t] = useTranslation();

 
  const SEARCH_PLACEHOLDER_EMAIL = t("Search by name or e-mail");
  const SEARCH_PLACEHOLDER_BSN = t("Search by name, e-mail or BSN number");

  const classes = useStyles();
  const query = useQuery();
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Search by land, name or contact person"
  );
  const [rolesState, setRolesState] = useState(roles);
  const [tabsState, setTabsState] = useState(tabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);

   

  useEffect(() => {
  const entity = query.get("entity");

  if (entity) {
    let selectedRoleData = {};
    let placeholderText = "";

    if (entity === "refugees" || entity === "mentors" || entity === "municipalities") {
      selectedRoleData = {
        id: entity,
        name: t(entity.charAt(0).toUpperCase() + entity.slice(1)),
        default: false,
      };
      placeholderText = t("Search by name, e-mail or BSN number")
    } else if (entity === "managers" || entity === "suppliers") {
      const roleName = entity === "managers" ? t("Managers") : t("Suppliers");
      selectedRoleData = {
        id: entity,
        name: roleName,
        default: false,
      };
      placeholderText = t("Search by name or e-mail");
    }

   
    if (JSON.stringify(selectedRoleData) !== JSON.stringify(selectedRole)) {
      setSelectedRole(selectedRoleData);
    }
    if (placeholderText !== searchPlaceholder) {
      setSearchPlaceholder(placeholderText);
    }
  }
}, [query, t, SEARCH_PLACEHOLDER_BSN, SEARCH_PLACEHOLDER_EMAIL, selectedRole, searchPlaceholder]);

  useEffect(() => {
    const fetchData = () => {
      if (rang === "manager") {
        let role = jwt.decode(localStorage.getItem("jwtToken"))?.role;
        if (role) {
          
          let placeholderText = "";

          switch (role) {
            case roles?.refugee?.id:
              selectedRoleData = {
                id: "refugees",
                name: t("Refugees"),
                default: false,
              };
              placeholderText = t("Search by name, e-mail or BSN number");
              break;
            case roles?.mentor?.id:
              selectedRoleData = {
                id: "mentors",
                name: t("Mentors"),
                default: false,
              };
              placeholderText = t("Search by name, e-mail or BSN number");
              break;
            case roles?.manager?.id:
              placeholderText = t("Search by name, e-mail or BSN number");
              break;
            case roles?.school_supplier?.id:
            case roles?.work_supplier?.id:
              selectedRoleData = {
                id: "suppliers",
                name: t("Suppliers"),
                default: false,
              };
              placeholderText =  t("Search by name or e-mail");
              break;
            default:
              break;
          }

          setSearchPlaceholder(placeholderText)

        }
      }
    };
    fetchData();
  }, [roles, t]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
    
  };
  
  const handleChangeRole = (role) => {
    let searchPlaceholderText = "";

    if (role?.id === "all") {
      switch (selectedTab?.id) {
        case "municipalities":
          searchPlaceholderText = "Search by land, name or contact person";
          break;
        case "managers":
        case "suppliers":
          searchPlaceholderText =  "Search by name or e-mail";
          break;
        default:
          searchPlaceholderText = "Search by name, e-mail or BSN number"
          break;
      }
    } else {
      switch (role?.id) {
        case "municipalities":
          searchPlaceholderText = "Search by land, name or contact person";
          break;
        case "managers":
        case "suppliers":
          searchPlaceholderText =  "Search by name or e-mail";
          break;
        default:
          searchPlaceholderText = "Search by name, e-mail or BSN number"
          break;
      }
    }

    setSelectedRole(role)
    setSearchPlaceholder(searchPlaceholderText);
    setOpen(false);
  };

  const handleChangeTab = (tab) => {
    let placeholderText = "Search by name, e-mail or BSN number"
    
    switch (tab?.id) {
      case "municipalities":
        placeholderText = "Search by land, name or contact person";
        break;
      case "managers":
      case "suppliers":
        placeholderText =  "Search by name or e-mail";
        break;
      default:
        break;
    }
    setSelectedTab(tab);
    setSearchPlaceholder(placeholderText)
  };
 
  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <div className="main">
      <div className="bgforform">
        <Container fixed>
          <div className="system-search-main-div">
            <div className="system-search-title-div">
              <h3 className="system-search-title">{t("System Search")}</h3>
            </div>
            <div className="system-search-search-div">
              <div className="system-search-search-div-menu-wrapper">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.root}>
                    <Button
                      variant="contained"
                      onClick={handleClick}
                      color="primary"
                      endIcon={<ExpandMoreIcon style={{ fontSize: 22 }} />}
                      className={classes.dropdownButton}
                    >
                      {t(selectedRole.name)}
                    </Button>
                    {open ? (
                      <div className={classes.dropdown}>
                        {rolesState.map((doc, firstIndex) => (
                          <ListItem
                            button
                            divider
                            onClick={() => handleChangeRole(doc)}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  type="body2"
                                  style={{
                                    fontFamily: "opensans-semibold",
                                    fontSize: "14px",
                                  }}
                                >
                                  {t(doc.name)}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </ClickAwayListener>
              </div>
                              
              <div className="system-search-search-div-search-field-wrapper">
                <TextField
                  id="search"
                  onChange={(e) => handleChangeSearch(e.target.value)}
                  name="search"
                  value={searchText}
                  className={classes.textFieldSearch}
                  type="text"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClearIcon
                          style={{
                            fontSize: "24px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClearSearch()}
                        />
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input1,
                    },
                  }}
                  placeholder={t(searchPlaceholder)}
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="system-search-search-div-search-btn-wrapper">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "0 auto",
                    marginTop: "5px",
                  }}
                >
                  <SearchIcon
                    style={{
                      color: "white",
                      fontSize: "30px",
                      margin: "0 auto",
                    }}
                  />
                </div>
              </div>
            </div>
            {selectedRole?.id === "all" && (
              <div className="system-search-tabs-div">
                {tabsState.map((doc, firstIndex) => (
                  <div
                    onClick={() => handleChangeTab(doc)}
                    className={
                      doc?.id === selectedTab?.id
                        ? "system-search-single-tab-selective-div"
                        : "system-search-single-tab-unselective-div"
                    }
                  >
                    {t(doc.name)}
                    {doc?.id === selectedTab?.id && (
                      <hr className="system-search-single-tab-selective-div-hr" />
                    )}
                  </div>
                ))}
              </div>
            )}
            {rang === "mentor" ? (
              <>
                {selectedTab?.id === "mentors" &&
                  selectedRole?.id === "all" && (
                    <MentorsDataMentor searchText={searchText} />
                  )}
                {selectedTab?.id === "suppliers" &&
                  selectedRole?.id === "all" && (
                    <SuppliersDataMentor searchText={searchText} />
                  )}
                {selectedTab?.id === "refugees" &&
                  selectedRole?.id === "all" && (
                    <RefugeesDataMentor searchText={searchText} />
                  )}

                {selectedRole?.id === "mentors" && (
                  <MentorsDataMentor searchText={searchText} />
                )}
                {selectedRole?.id === "suppliers" && (
                  <SuppliersDataMentor searchText={searchText} />
                )}
                {selectedRole?.id === "refugees" && (
                  <RefugeesDataMentor searchText={searchText} />
                )}
              </>
            ) : (
              <>
                {selectedTab?.id === "municipalities" &&
                  selectedRole?.id === "all" && (
                    <MunicipalitiesData searchText={searchText} />
                  )}
                {selectedTab?.id === "managers" &&
                  selectedRole?.id === "all" && (
                    <ManagersData searchText={searchText} />
                  )}
                {selectedTab?.id === "mentors" &&
                  selectedRole?.id === "all" && (
                    <MentorsData rang={rang} searchText={searchText} />
                  )}
                {selectedTab?.id === "suppliers" &&
                  selectedRole?.id === "all" && (
                    <SuppliersData rang={rang} searchText={searchText} />
                  )}
                {selectedTab?.id === "refugees" &&
                  selectedRole?.id === "all" && (
                    <RefugeesData rang={rang} searchText={searchText} />
                  )}

                {selectedRole?.id === "municipalities" && (
                  <MunicipalitiesData searchText={searchText} />
                )}
                {selectedRole?.id === "managers" && (
                  <ManagersData rang={rang} searchText={searchText} />
                )}
                {selectedRole?.id === "mentors" && (
                  <MentorsData rang={rang} searchText={searchText} />
                )}
                {selectedRole?.id === "suppliers" && (
                  <SuppliersData rang={rang} searchText={searchText} />
                )}
                {selectedRole?.id === "refugees" && (
                  <RefugeesData rang={rang} searchText={searchText} />
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}
export default SystemSearchComponent;
