import React from "react";

import Container from "@material-ui/core/Container";
import { ReactComponent as Ballot } from "../../../../../assets/images/ballot.svg";
import { makeStyles } from "@material-ui/core/styles";
import logob from "../../../../../assets/images/logob.png";
import phoneIcon from "../../../../../assets/images/phone_icon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  con: {
    // backgroundColor: "red"
  },
}));
function Footer(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    sessionStorage.clear();
    navigate("/managerdashboard");
  };

  return (
    <footer id="footerhome">
      <Container fixed className={classes.con}>
        <img
          className="blockf-logob"
          onClick={() => handleNavigateToDashboard()}
          src={logob}
          alt="logob"
        ></img>

        <div id="rightmenu">
          <p className="citemsf">
            <i
              style={{
                fontSize: "18px",
                display: "inline-block",
                color: "white",
              }}
              className="fa fa-envelope"
            ></i>
            <span
              style={{
                marginLeft: "6px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {t("Mail")}
            </span>
            <p style={{ marginTop: "5px" }}>info@vluchtelingaanzet.com</p>
          </p>
        </div>

        <div id="rightmenusecond">
          <p className="citemsf">
            <img
              src={phoneIcon}
              className="footer-phone-icon"
              alt="Smiley face"
            />
            <span
              style={{
                marginLeft: "6px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {t("Telephone")}
            </span>
            <p style={{ marginTop: "5px" }}>+31 (0)43 352 3604</p>
          </p>
        </div>
        <div id="rightmenusecond" style={{ marginRight: "85px" }}>
          <p
            className="citemsf"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Ballot
             style={{cursor: "pointer"}}
              onClick={() => {
                navigate("/privacyPolicy");
              }}
              className="footer-phone-icon changeColor"
              alt="Smiley face"
            />
            <span
              onClick={() => {
                navigate("/privacyPolicy");
              }}
              style={{
                marginLeft: "6px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer"
              }}
            >
              {t("Privacy policy")}
            </span>
          </p>
        </div>
        <div id="rightmenusecond">
          <p
            className="citemsf"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Ballot
              onClick={() => {
                navigate("/TermsAndConditions");
              }}
              style={{cursor: "pointer"}}
              className="footer-phone-icon changeColor"
              alt="Smiley face"
            />
            <span
            
              onClick={() => {
                navigate("/TermsAndConditions");
              }}
              style={{
                marginLeft: "6px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer"
              }}
            >
              {t("Terms and conditions")}
            </span>
          </p>
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
