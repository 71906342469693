import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import SuccessDialog from "./sub_components/AddMunicipalityDetailSuccessDialog";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "./store/actions";

import AssignMunicipalityToRefugee from "./AssignMunicipalityToRefugee";
import AssignMentorToRefugee from "./AssignMentorToRefugee";
import AssignSupplierToRefugee from "./AssignSupplierToRefugee";

const useStyles = makeStyles((theme) => ({
  editButton: {
    fontSize: "16px",
    backgroundColor: "#454A92",
    width: "150px",
    height: "40px",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
    marginLeft: "auto",
    marginTop: "50px",
    boxShadow: "none",
  },
  btnsCon: {
    marginTop: "20px",
  },
  button: {
    fontSize: "16px",
    backgroundColor: "transparent",
    width: "150px",
    height: "40px",
    border: "1px solid white",
    opacity: 1,
    textTransform: "uppercase",
    fontFamily: "opensans-semibold",
    float: "right",
    borderRadius: "2px",
  },
}));

function ViewEditRefugeeSummary(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [unSelectedMunicipalities, setUnSelectedMunicipalities] = useState([]);
  const [unSelectedMentors, setUnSelectedMentors] = useState([]);
  const [unSelectedSuppliers, setUnSelectedSuppliers] = useState([]);
  const [totalMentors, setTotalMentors] = useState(0);
  const [totalSuppliers, setTotalSuppliers] = useState(0);
  const [totalMunicipalities, setTotalMunicipalities] = useState(0);
  const [state, setState] = useState({});
  const [stateRefugee, setStateRefugee] = useState("1");
  const [clikcState, setClickState] = useState("1");
  const [clickState, setClick] = useState(1);
  const [changeMunicipality, setChangeMunicipality] = useState(1);

  const refugeeSummary = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.getRefugeeSummaryReducer?.data
  );
  const loading = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.getRefugeeSummaryReducer?.isLoading
  );
  const errMsg = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.getRefugeeSummaryReducer?.errMsg
  );
  const add_confirmation = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.assignUnassignRolesToRefugeeReducer
  );

  useEffect(() => {
    dispatch(Actions.getRefugeeSummary(sessionStorage.getItem("user_id")));
    return () => {
      dispatch(Actions.resetRefugeeSummary(true));
      dispatch(Actions.resetAssignUnassignRolesToRefugee(true));
    };
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("user_id")) {
      dispatch(Actions.getBredeintake(sessionStorage.getItem("user_id")));
    }
  }, [dispatch, sessionStorage.getItem("user_id")]);

  const brede_confirmation = useSelector(
    ({ ViewEditRefugeeSummaryReducer }) =>
      ViewEditRefugeeSummaryReducer?.BredeintakeReducer?.data
  );
  const loadingg = useSelector(
    ({ BImodalReducer }) => BImodalReducer?.BredeintakeReducer?.isLoading
  );
  useEffect(() => {
    if (sessionStorage.getItem("EditOrSave")) {
    } else {
      sessionStorage.setItem("EditOrSave", false);
    }
  }, []);
  const isEdit = sessionStorage.getItem("EditOrSave");
  useEffect(() => {
    if (sessionStorage.getItem("assignedMunicipalities")) {
      setSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
      );
    }
  }, [setSelectedMunicipalities, clikcState, state, stateRefugee, clickState]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedMentors")) {
      setSelectedMentors(JSON.parse(sessionStorage.getItem("assignedMentors")));
    }
  }, [setSelectedMentors, clikcState, state, stateRefugee, clickState]);

  useEffect(() => {
    if (sessionStorage.getItem("assignedSuppliers")) {
      setSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("assignedSuppliers"))
      );
    }
  }, [setSelectedSuppliers, clickState, clikcState, state, stateRefugee]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedMunicipalities")) {
      setUnSelectedMunicipalities(
        JSON.parse(sessionStorage.getItem("unAssignedMunicipalities"))
      );
    }
  }, [setUnSelectedMunicipalities, clickState, changeMunicipality]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedMentors")) {
      setUnSelectedMentors(
        JSON.parse(sessionStorage.getItem("unAssignedMentors"))
      );
    }
  }, [setUnSelectedMentors, clickState, changeMunicipality]);

  useEffect(() => {
    if (sessionStorage.getItem("unAssignedSuppliers")) {
      setUnSelectedSuppliers(
        JSON.parse(sessionStorage.getItem("unAssignedSuppliers"))
      );
    }
  }, [setUnSelectedSuppliers, clickState]);

  const handleClickSuccessDialogclose = () => {
    setSuccessOpen(false);
  };

  const handleClickSuccessDialogopen = (data) => {
    setSuccessOpen(true);
  };

  const setPreviousMunicipality = () => {
    if (!sessionStorage.getItem("previousMunicipalityId")) {
      sessionStorage.setItem(
        "previousMunicipalityId",
        refugeeSummary?.getRefugeeSummary?.municipality?._id || ""
      );
    }
  };

  const handleClickEditUser = () => {
    setPreviousMunicipality();
    sessionStorage.removeItem("assignedMentors")
    sessionStorage.removeItem("unAssignedMentors")
    sessionStorage.removeItem("assignedSuppliers")
    sessionStorage.setItem("user_type", "refugee");
    sessionStorage.setItem('hasReloaded', 'false');
    navigate("/editUserByAdmin");
  };

  const handleNavigateToDashboard = () => {
   
    sessionStorage.clear();
    navigate("/dashboard");
  };
  useEffect(() => {
    const suppliers = JSON.parse(sessionStorage.getItem("assignedSuppliers"))
    const unsuppliers = JSON.parse(sessionStorage.getItem("unAssignedSuppliers"))
    const mentors = JSON.parse(sessionStorage.getItem("assignedMentors"))
    const unmentors = JSON.parse(sessionStorage.getItem("unAssignedMentors"))
    const municipality = JSON.parse(sessionStorage.getItem("assignedMunicipalities"))
   
    setClick(true);
    const values = {
      refugee_id: sessionStorage.getItem("user_id"),
      amunicipalityIds: [],
      amentorIds: [],
      asupplierIds: [],
      umunicipalityIds: [],
      umentorIds: [],
      usupplierIds: [],
    };
    if (municipality?.length > 0) {
      for (let i = 0; i < municipality?.length; i++) {
        const element = municipality[i];
        values.amunicipalityIds.push(element?.id);
      }
    }
    if (mentors?.length > 0) {
      for (let i = 0; i < mentors?.length; i++) {
        const element = mentors[i];
        values.amentorIds.push(element?.id);
      }
    }
    if (suppliers?.length > 0) {
      for (let i = 0; i < suppliers?.length; i++) {
        const element = suppliers[i];
        values.asupplierIds.push(element?.id);
      }
    }
    if (unSelectedMunicipalities?.length > 0) {
      for (let i = 0; i < unSelectedMunicipalities?.length; i++) {
        const element = unSelectedMunicipalities[i];
        values.umunicipalityIds.push(element?.id);
      }
    }
    if (unmentors?.length > 0) {
      for (let i = 0; i < unmentors?.length; i++) {
        const element = unmentors[i];
        values.umentorIds.push(element?.id);
      }
    }
    if (unsuppliers?.length > 0) {
      for (let i = 0; i < unsuppliers?.length; i++) {
        const element = unsuppliers[i];
        values.usupplierIds.push(element?.id);
      }
    }

    dispatch(Actions.assignUnassignRolesToRefugee(values));
    sessionStorage.removeItem("assignedMentors")
    sessionStorage.removeItem("unAssignedMentors")
    sessionStorage.removeItem("assignedSuppliers")
  },[])

  useEffect(() => {
    if (changeMunicipality !== 1) {
      setClick(true);
      const values = {
        refugee_id: sessionStorage.getItem("user_id"),
        amunicipalityIds: [],
        amentorIds: [],
        asupplierIds: [],
        umunicipalityIds: [],
        umentorIds: [],
        usupplierIds: [],
      };
      const storedMunicipalities = JSON.parse(
        sessionStorage.getItem("assignedMunicipalities")
      );
      if (selectedMunicipalities?.length > 0) {
        for (let i = 0; i < selectedMunicipalities?.length; i++) {
          const element = storedMunicipalities[0];
          values.amunicipalityIds.push(element?.id);
        }
      }
      if (selectedMentors?.length > 0) {
        for (let i = 0; i < selectedMentors?.length; i++) {
          const element = selectedMentors[i];
          values.amentorIds.push(element?.id);
        }
      }
      if (selectedSuppliers?.length > 0) {
        for (let i = 0; i < selectedSuppliers?.length; i++) {
          const element = selectedSuppliers[i];
          values.asupplierIds.push(element?.id);
        }
      }
      if (unSelectedMunicipalities?.length > 0) {
        for (let i = 0; i < unSelectedMunicipalities?.length; i++) {
          const element = unSelectedMunicipalities[i];
          values.umunicipalityIds.push(element?.id);
        }
      }
      if (unSelectedMentors?.length > 0) {
        for (let i = 0; i < unSelectedMentors?.length; i++) {
          const element = unSelectedMentors[i];
          values.umentorIds.push(element?.id);
        }
      }
      if (unSelectedSuppliers?.length > 0) {
        for (let i = 0; i < unSelectedSuppliers?.length; i++) {
          const element = unSelectedSuppliers[i];
          values.usupplierIds.push(element?.id);
        }
      }
     
      dispatch(Actions.assignUnassignRolesToRefugee(values));
    }
  }, [changeMunicipality]);

  useEffect(() => {
    if (add_confirmation?.data?.data?.assignUnassignRolesToRefugee) {
      sessionStorage.removeItem("assignedSuppliers")
      sessionStorage.removeItem("assignedMentors")
      sessionStorage.removeItem("unAssignedSuppliers")
    }
  }, [add_confirmation]);

  useEffect(() => {
    if (refugeeSummary?.getRefugeeSummary) {
      setTotalMunicipalities(
        selectedMunicipalities.length ||
          refugeeSummary.getRefugeeSummary.totalMunicipalities
      );
      if (
        selectedMunicipalities.length &&
        selectedMunicipalities[0]?.id !==
          refugeeSummary.getRefugeeSummary.municipality?._id
      ) {
        setTotalMentors(selectedMentors.length);
        setTotalSuppliers(selectedSuppliers.length);
      } else {
        setTotalMentors(
          refugeeSummary.getRefugeeSummary.totalMentors +
            selectedMentors.length -
            unSelectedMentors.length
        );
        setTotalSuppliers(
          refugeeSummary.getRefugeeSummary.totalSuppliers +
            selectedSuppliers.length -
            unSelectedSuppliers.length
        );
      }
    }
  }, [
    refugeeSummary,
    selectedMunicipalities,
    selectedMentors,
    selectedSuppliers,
    unSelectedMentors,
    unSelectedSuppliers,
  ]);
  const UrlBI = brede_confirmation?.getBredeIntakeFile?.bredeIntakeFileUrl;
  const filenameParts =
    refugeeSummary?.getRefugeeSummary?.bredeIntakeFile?.split("-");
  const displayedFilename =
    filenameParts?.length > 1
      ? filenameParts?.slice(1).join("-").trim()
      : refugeeSummary?.getRefugeeSummary?.bredeIntakeFile;

 
  return (
    <div className="main">
      {successOpen === true && (
        <SuccessDialog
          status={successOpen}
          methodd={handleClickSuccessDialogclose}
          role="Refugee"
        />
      )}
      <div className="bgforform">
        <Container fixed>
          <div className="edit-municipality-back-btn-div"></div>

          <div className="edit-municipality-detail-view-div">
            <h3 className="edit-municipality-detail-view-title">
              {t("Refugee")}
            </h3>
            {refugeeSummary?.getRefugeeSummary && (
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Name")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.name}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("BSN")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.bsn}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={3}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Email")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.email}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Phone")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.phoneNumber}
                    </span>
                  </p>
                </Grid>
                <Grid item sm={6}>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("POI (DISK) Link")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      {refugeeSummary.getRefugeeSummary.municipality ? (
                        <a
                          target="_blank"
                          href={
                            refugeeSummary.getRefugeeSummary.municipality
                              .poiLink
                          }
                        >
                          {
                            refugeeSummary.getRefugeeSummary.municipality
                              .poiLink
                          }
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </p>
                  <p className="edit-municipality-detail-view-data">
                    <span className="edit-municipality-detail-view-label">
                      {t("Brede Intake")}:{" "}
                    </span>{" "}
                    <span className="edit-municipality-detail-view-text">
                      <a target="_blank" href={UrlBI} rel="noreferrer">
                      {refugeeSummary.getRefugeeSummary.profileImageName}
                      </a>
                    </span>
                  </p>

                  <div className="edit-municipality-detail-view-image-div">
                    <p className="edit-municipality-detail-view-logo-label">
                      {t("Photo")}:&nbsp;
                    </p>
                    <p className="edit-municipality-detail-view-logo-text">
                    {refugeeSummary.getRefugeeSummary.bredeIntakeName}
                    </p>
                    <div className="edit-municipality-detail-view-image-span">
                      <img
                        alt="logo"
                        className="edit-municipality-detail-view-image"
                        src={refugeeSummary.getRefugeeSummary.profileImageUrl}
                      ></img>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}></Grid>
          </div>

          <div className="edit-municipality-detail-view-div">
            <Grid container spacing={2}>
              <Grid item sm={7}>
                <AssignMunicipalityToRefugee
                  setChangeMunicipality={setChangeMunicipality}
                  setState={setState}
                  disabled={true}
                  isEdit={isEdit}
                />
              </Grid>
              {add_confirmation.errMsg && (
                <Grid item sm={12} xs={12}>
                  <Alert severity="error">{t(add_confirmation.errMsg)}</Alert>
                </Grid>
              )}
              {add_confirmation.isLoading && (
                <Grid item sm={12} xs={12}>
                  Loading.....
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={5}>
                <AssignMentorToRefugee
                  setClick={setClick}
                  clickState={clickState}
                  state={state}
                  changeMunicipality={changeMunicipality}
                  disabled={true}
                  isEdit={isEdit}
                />
              </Grid>

              <Grid item sm={5}>
                <AssignSupplierToRefugee
                  setClick={setClick}
                  clickState={clickState}
                  state={state}
                  disabled={true}
                  isEdit={isEdit}
                />
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={2} className={classes.btnsCon}>
            <Grid item sm={8} xs={12}></Grid>
            <Grid item sm={2} xs={12}></Grid>

            <Grid item sm={2} xs={12}>
             
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => handleClickEditUser()}
                  color="primary"
                >
                  {t("Edit")}
                </Button>
            
         
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withReducer(
  "ViewEditRefugeeSummaryReducer",
  reducer
)(ViewEditRefugeeSummary);
