import SystemSearchComponent from "../../../SearchComponent/index";





function SystemSearch(props) {
  
let roles = [
  {
    id: "all",
    name: "All",
    default: false,
  },
  {
    id: "mentors",
    name: "Mentors",
    default: false,
  },
  {
    id: "suppliers",
    name: "Suppliers",
    default: false,
  },
  {
    id: "refugees",
    name: "Refugees",
    default: true,
  },
];
let tabs = [
  {
    id: "mentors",
    name: "Mentors",
    default: false,
  },
  {
    id: "suppliers",
    name: "Suppliers",
    default: false,
  },
  {
    id: "refugees",
    name: "Refugees",
    default: true,
  },
];

  return(
    <SystemSearchComponent roles={roles} tabs={tabs} rang={"manager"}/>
  )
}
export default SystemSearch;
