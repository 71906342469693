import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PanoramaIcon from '@material-ui/icons/Panorama';
import FrontPageForm from "./FrontPageForm";

function FrontPage({ data, setPipPlanPage }) {
  const [t] = useTranslation();

  const useStyles = makeStyles(() => ({
    title: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    subTitle: {
      fontSize: "10px",
    },
  }));

  const classes = useStyles();


  const municipalityInfo = useSelector(
    ({ PipPlanState }) => PipPlanState.municipalityInfo.data
  );

  return (
    <Grid
      container
      display="flex"
      direction="column"
    >
      {municipalityInfo &&
        (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
              minWidth: "100%",
              color: "#253c8c",
            }}
          >
            <h1>{t("My Future Plan")}</h1>
            <h2 className={classes.title}>
              {t("In the municipality of")} {municipalityInfo.name}
            </h2>
            <Paper variant="outlined">
              {
                municipalityInfo.logoUrl ? (
                  <img
                    alt="logo"
                    className="edit-municipality-detail-view-image"
                    src={
                      municipalityInfo.logoUrl
                    }
                  />

                ) : (
                  <PanoramaIcon fontSize="large" />
                )
              }
            </Paper>
            <p className={classes.subTitle}>
              {t("Logo Municipality")}
            </p>
            <h2 className={classes.title}>
              {t("Integration and participation plan (PIP)")}
            </h2>
          </Grid>
        )}
      <Grid
        container
        item
        style={{
          minWidth: "100%",
        }}
      >
        {<FrontPageForm data={data} setPipPlanPage={setPipPlanPage}/>}
      </Grid>
    </Grid>
  );
};

export default FrontPage;
